import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultHeaders = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0"
};
export const defaultAxios = axios.create({
});

const getCookies = (cookie) => {
    let getCookie;
    let da = document.cookie.split(';');
    da.map((data) => {
        let cookiess = data.split("=")[0].trim() === "HTTP_SERVER_SELECT" ? data.split("=")[1] : undefined;
        if(cookiess !== undefined){
            getCookie = cookiess;
        }
    })
    if(getCookie === cookie){
      return true;
    }else{
      return false;
    }
  };

export function apiClient({ url, data = {}, method = "POST", headers = {}, noHeaders, ...rest }) {
    if (localStorage.getItem("token") === "" || localStorage.getItem("token") === null) {
        delete defaultAxios.defaults.headers.common['Authorization'];
    }
    if(getCookies("BETA")){
        defaultAxios.defaults.headers.serverselect = "BETA";
    }
    return new Promise((resolve, reject) => {
        defaultAxios({
            method,
            url,
            headers: {
                ...(noHeaders ? {} : defaultHeaders),
                ...headers,
            },
            data,
            ...rest,
        }).then((res) => {
            if (res && res.status) {
                resolve(res.data);
            } else {
                if (res.data && res.data.error) {
                    console.error(res.data.error);
                    reject(res.data.error);
                    if (res.data.error.code === "ER_UNAUTHORIZED_CLIENT") {
                        localStorage.clear();
                        delete defaultAxios.defaults.headers.common['Authorization'];
                    } else if (res.data.statusCode === 400) {

                    } else {
                        if (res.data.error.code !== "ER_USER_NOT_FOUND" && res.data.error.code !== "ER_ONBOARDING_LINK_EXPIRED") {
                            toast.error(res.data.error.message);
                        }
                    }
                } else {
                    reject(res.data.error);
                }
            }
        }).catch((err) => {
            reject(err);
        });
    });
}
