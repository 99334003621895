import { HOSPITAL_LIST, HOSPITAL_SETTING } from "../actions/types";

const initialState = {
    count: 0,
    data: []
};

export default function hospitals(state = initialState, action) {
    switch (action.type) {
        case HOSPITAL_LIST:
            return {
                data: action.payload.data,
                count: action.payload.count
            };
        case HOSPITAL_SETTING:
            return action.payload.data;
        default:
            return state;
    }
};