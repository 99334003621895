import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import FormBuilders from '../../builder';
import FormElementsEdit from '../../builder/form-elements-edit';
import 'react-form-builder2/dist/app.css';

const CmpVitals = React.forwardRef((props, ref) => {
    const { inputName, defaultValue, min_value, max_value, unit, is_numeric, unitValue, onChange, is_readonly } = props;
    return (
        <>
            <input ref={ref} type={is_numeric ? "number" : "text"} readOnly={is_readonly ? is_readonly : false} className="form-control" min={min_value} onChange={onChange} max={max_value} name={inputName} defaultValue={defaultValue} />
            {unit} {unitValue}
        </>
    );
});
FormBuilders.Registry.register('CmpVitals', CmpVitals);

// side bar toolbox for me.. 
const items = [{
    key: 'Header',
}, {
    key: 'TextInput',
}, {
    key: 'TextArea',
}, {
    key: 'RadioButtons',
}, {
    key: 'Checkboxes',
}, {
    key: 'Image',
}, {
    key: 'CmpVitals',
    element: 'CustomElement',
    component: CmpVitals,
    type: 'custom',
    forwardRef: true,
    field_name: 'my_input_',
    name: 'Vitals',
    icon: 'fa fa-cog',
    props: {
        test: 'test_input',
        inputName: 'Name1',
        min_value: '',
        max_value: '',
        unit: "pulse",
        unitValue: "pulse",
        is_numeric: true,
        is_readonly: false,
        formula: "",
        multiple: false,
        onChange: "",
        multipleOptions: [
            { label: '', min: "", max: "", is_normal: false },
        ]
    },
    default_value: 'Vish',
    bold: "20",
    canDraggable: false,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
}];

export default function Builder(props) {
    const onPost = (data) => {
        return props.setBuilderData(data);
    };

    return (
        <>
            {/* <Demobar /> */}
            <div className="App">
                <FormBuilders.ReactFormBuilder
                    toolbarItems={items}
                    data={(props.fields && props.fields.length > 0) ? props.fields : null}
                    onLoad={(props.fields && props.fields.length > 0) ? props.fields : null}
                    onPost={onPost}
                    renderEditForm={props => <FormElementsEdit {...props} />}
                />
            </div>
        </>
    );

}
