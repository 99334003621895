import { apiClient } from '../api/general';
import { API_URL } from "../api/apiRoutes";

export const adminDashboardUserList = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.dashboard.userList}`,
            data: data,
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        });
    });
}