import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { HOSPITAL_FILTER_DATA, HOSPITAL_LIST } from "./types";

//list of hospitals
export const fetchHospitalList = (pagination = {}, filter = {}, search = {}, expandSearch) => async (dispatch) => {
    let body = {
        pagination: pagination,
        filter: filter,
        search: search,
        expandSearch: expandSearch,
    };
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.hospitals.list}`,
            data: { ...body },
        }).then((response) => {
            resolve(response)
            dispatch({
                type: HOSPITAL_LIST,
                payload: {
                    count: response.data.count,
                    data: response.data.data
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Add hospitals
export const addHospital = (data, hospitals) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.hospitals.add}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...hospitals.data];
            let tmp = mainArray.some(v => v._id === response.data._id);
            if (!tmp) {
                mainArray.push(response.data);
            }
            dispatch({
                type: HOSPITAL_LIST,
                payload: {
                    count: hospitals.count + 1,
                    data: mainArray
                },
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update hospital
export const updateHospital = (hospitalId, data, hospitals) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.hospitals.update}/${hospitalId}`,
            data: data,
        }).then((response) => {
            let mainArray = [...hospitals.data]
            let tmp = mainArray.findIndex(hospital => hospital._id === hospitalId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data)
            }
            dispatch({
                type: HOSPITAL_LIST,
                payload: {
                    count: hospitals.count,
                    data: mainArray
                },
            });
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

//approve and reject hospitals hospital
export const approvalHospital = (hospitalId, data, hospitals) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.hospitals.approval}`,
            data: data
        }).then((response) => {
            let mainArray = [...hospitals.data];
            let tmp = mainArray.findIndex(hospital => hospital._id === hospitalId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data[0])
            }
            dispatch({
                type: HOSPITAL_LIST,
                payload: {
                    count: hospitals.count,
                    data: mainArray
                },
            });
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete hospital
export const deleteHospital = (hospitalId, headers, hospitals) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "DELETE",
            url: `${API_URL.hospitals.delete}/${hospitalId}`,
            headers
        }).then((response) => {
            resolve(response);
            let mainArray = [...hospitals.data];
            let tmp = mainArray.findIndex(hospital => hospital._id === hospitalId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1);
            }
            dispatch({
                type: HOSPITAL_LIST,
                payload: {
                    count: hospitals.count - 1,
                    data: mainArray
                },
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

// Archive patient
export const unarchiveHospital = (hospitalId, headers, hospitals) => async(dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.hospitals.unArchive}/${hospitalId}`,
            headers
        }).then((response) => {
            resolve(response);
            let mainArray = [...hospitals.data];
            let tmp = mainArray.findIndex(hospital => hospital._id === hospitalId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data)
            }
            dispatch({
                type: HOSPITAL_LIST,
                payload: {
                    count: hospitals.count,
                    data: mainArray
                },
            });
        }).catch((err) => {
            reject(err)
        });
    })
}

//disable hospital
export const disableEnableHospital = (hospitalId, body, hospitals) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.hospitals.blocking}`,
            data: body,
        }).then((response) => {
            let mainArray = [...hospitals.data];
            let tmp = mainArray.findIndex(v => v._id === hospitalId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data[0]);
            }
            dispatch({
                type: HOSPITAL_LIST,
                payload: {
                    count: hospitals.count,
                    data: mainArray
                },
            });
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

//go to hospital setting
export const goToHospitalSettings = (hospitalId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "GET",
            url: `${API_URL.hospitals.gotoSetting}/${hospitalId}`,
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

// hospital/filterData
export const hospitalFilters = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "POST",
            url: `${API_URL.hospitals.filterData}`,
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: HOSPITAL_FILTER_DATA,
                payload: response.data
            })
        }).catch((error) => {
            reject(error);
        });
    });
}