import React, { useEffect, useRef, useState } from "react";
import { PatternFormat } from 'react-number-format';
import FormInput from "../../../Components/FormInput";
import Modal from "../../../Components/Modal";
import GoogleAddress from "../../../Components/GoogleAddress";
import Button from "../../../Components/Button";
import Creatable from "react-select/creatable";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { countryCodes } from "../../../common";

export default function ReceptionistAddModal({ showBtnLoader, addHospitalName, setAddHospitalName, modal, modalTitle, modalAction, receptionistFormData, setReceptionistFormData, FormErrors, setFormErrors, modalClickAction, hospitals, modalButton1, modalButton2, resetForm, dispatchAction}) {

    const [hospitalNames, setHopspitalNames] = useState([]);

    const handleFormChange = (e) => {
        let changedField = { ...receptionistFormData };
        changedField[e.target.name] = e.target.value;
        setReceptionistFormData({ ...changedField });
        let changeError = { ...FormErrors };
        delete changeError[e.target.name];
        setFormErrors({ ...changeError });
    };

    useEffect(() => {
        let arr = [];
        if (hospitals?.data?.length > 0) {
            hospitals.data.map((v) => {
                arr.push({ value: v._id, label: v.name })
            })
        }
        setHopspitalNames([...arr])
    }, [hospitals.data])

    // Multi select on change event function
    const handleSelectChange = (e) => {
        let arr = [...hospitalNames]
        let changedField = { ...receptionistFormData };
        let changeError = { ...FormErrors };
        if (e?.__isNew__) {
            changedField['hospitalIds'] = "";
            changedField['hospitalName'] = e.value;
            arr.push(e);
            delete changeError['hospitalName'];
        } else {
            if (e?.value) {
                changedField['hospitalIds'] = e.value;
                changedField['hospitalName'] = "";
                delete changeError['hospitalName'];
            } else {
                changedField['hospitalIds'] = "";
                changedField['hospitalName'] = "";
            }
        }
        setFormErrors({ ...changeError });
        setHopspitalNames([...arr]);
        setReceptionistFormData({ ...changedField });
    }

    const handleCodeChange = (e, name) =>{
        let changedField = { ...receptionistFormData };
        changedField[name] = e?.value ? e.value : "";
        setReceptionistFormData({ ...changedField });
    }

    const addressRef = useRef(null);

    return (
        ['add', 'update'].includes(modalAction) &&
        <Modal
            ModalTitle={modalTitle}
            onClose={() => modalClickAction('close')}
            Modalclass={(modal && ['add', 'update'].includes(modalAction)) ? 'show-modal' : ''}
        >
            <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="First Name"
                name="firstName"
                required="required"
                onChange={(e) => { handleFormChange(e); }}
                value={receptionistFormData.firstName}
                errorMessage={FormErrors.firstName}
            />
            <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="Last Name"
                name="lastName"
                required="required"
                onChange={(e) => { handleFormChange(e); }}
                value={receptionistFormData.lastName}
                errorMessage={FormErrors.lastName}
            />
            <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="Receptionist Email"
                // required="required"
                name="emailId"
                isReadonly={receptionistFormData.isPasswordSet ? true : false}
                onChange={(e) => { handleFormChange(e); }}
                value={receptionistFormData.emailId}
                // errorMessage={FormErrors.emailId}
            />
            <div className="formField contactformField">
                <div className="ex-label">
                    <label><div className="feildRequired">Contact<span className="errorBox">*</span></div></label>
                </div>
                {/* <FormInput
                    className={"countrycode"}
                    name="countryCode"
                    value={receptionistFormData.countryCode}
                    isReadonly={true}
                    title=""
                /> */}
                 <Select
                    isClearable={false}
                    isSearchable={true}
                    className="AutoSuggest"
                    classNamePrefix="react-select"
                    options={countryCodes}
                    value={countryCodes.filter((a) => a.value === receptionistFormData.countryCode)}
                    onChange={(e)=> handleCodeChange(e, "countryCode")}
                />
                <div className="usernumber formField">
                    <PatternFormat
                        format="##########"
                        mask="_"
                        name="contact"
                        id="contact"
                        placeholder="Enter contact number"
                        value={receptionistFormData.contact}
                        onChange={(e) => handleFormChange(e)}
                    />
                </div>
                {FormErrors.contact && <div className="actionField errorBox">{FormErrors.contact}</div>}
            </div>
            <div className="formField">
                <label><div className="feildRequired">Hospital/Clinic Name<span className="errorBox">*</span></div></label>
                <Select
                    isClearable={true}
                    isSearchable={true}
                    classNamePrefix="react-select"
                    options={hospitalNames}
                    tabSelectsValue={false}
                    name="hospitalIds"
                    id="hospitalIds"
                    value={hospitalNames.filter((option) => {
                        return option.value === (receptionistFormData.hospitalIds === "" ? receptionistFormData.hospitalName : receptionistFormData.hospitalIds);
                    })}
                    onChange={(e) => {
                        handleSelectChange(e);
                    }}
                    placeholder="Search/Add Hospital"
                    openMenuOnFocus={true}
                    closeMenuOnSelect={true}
                />
                {FormErrors.hospitalName && <div className="actionField errorBox">{FormErrors.hospitalName}</div>}
            </div>
            <div className="formField contactformField">
                <div className="ex-label">
                    <label><div className="feildRequired">Address<span className="errorBox"></span></div></label>
                </div>
                <FormInput
                    className={"countrycode"}
                    name="plotNo"
                    value={receptionistFormData.plotNo}
                    title=""
                    placeholder="Plot"
                    onChange={(e) => handleFormChange(e)}
                />
                <div className="usernumber formField">
                    <GoogleAddress
                        id="onBoardDoctorAddress"
                        reference={addressRef}
                        showMap={false}
                        formData={receptionistFormData}
                        setFormData={setReceptionistFormData}
                    />
                </div>
            </div>
            <div className="activeButton right">
                <Button
                    title={modalButton1}
                    buttonType="reset"
                    onClick={(modalAction === 'approve') ? () => modalClickAction('close') : () => resetForm()}
                />
                <Button
                    loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                    title={modalButton2}
                    type="button"
                    onClick={(e) => { dispatchAction(modalAction, e); }}
                />
            </div>
        </Modal>
    )
};