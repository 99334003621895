import './style.scss';
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableTitle from '../../Components/TableTitle';
import FormInput from '../../Components/FormInput';
import { capitalizeFirstLetter, emptyField, removeMainLoaderClass, validEmail } from '../../common';
import { addLab, approvalLab, deleteLab, enableLab, fetchLabList, updateLab } from '../../actions/labs';
import HospitalOptionModal from '../HospitalMx/HospitalOptionModal';
import HospitalAddModal from '../HospitalMx/HospitalAddModal';
import { closeModal, openModal } from '../../actions/modal';
import Button from '../../Components/Button';
import HospitalsList from '../HospitalMx/HospitalsList';
import { Skeleton } from 'antd';

export default function LabMx(props) {

    //for skeleton
    const [loading, setLoading] = useState(false);

    let currentFormErrors = {};
    const { labs = [], modal } = useSelector((state) => state);

    const dispatch = useDispatch();

    const [labId, setLabId] = useState(null);

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [search, setSearch] = useState('');

    /**
     * state list which is used in api 
     */
    const [labFormData, setLabFormData] = useState({
        "name": '',
        "regNo": '',
        "countryCode": '+91',
        "contact": '',
        "emailId": '',
        "address": '',
        "address2": '',
        "city": '',
        "state": '',
        "country": '',
        "pincode": '',
        "latitude": '',
        "longitude": '',
        "plotNo": ''
    });

    const [FormErrors, setFormErrors] = useState(currentFormErrors);

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton1, setModalButton1] = useState('');
    const [modalButton2, setModalButton2] = useState('');

    const searchRef = useRef(null);

    const [showBtnLoader, setShowBtnLoader] = useState('');

    const resetForm = () => {
        currentFormErrors.name = '';
        currentFormErrors.contact = '';
        currentFormErrors.emailId = '';
        setFormErrors(currentFormErrors);
        setLabFormData({
            "name": '',
            "regNo": '',
            "countryCode": "+91",
            "contact": '',
            "emailId": '',
            "address": '',
            "address2": '',
            "city": '',
            "state": '',
            "country": '',
            "pincode": '',
            "latitude": '',
            "longitude": '',
            "plotNo": ''
        });
    };

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton1('');
        setModalButton2('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                setModalTitle('Add Lab');
                setModalButton1('Reset');
                setModalButton2('Add Lab');
                break;
            case 'update':
                setModalTitle('Edit Lab');
                setModalButton1('Reset');
                setModalButton2('Edit Lab');
                setLabId(data._id);
                let changedField = { ...labFormData };
                changedField["name"] = data.name;
                changedField["regNo"] = data.regNo;
                changedField["contact"] = data.contact;
                changedField["emailId"] = data.emailId;
                changedField["plotNo"] = data.address.plotNo;
                changedField["city"] = data.address.city;
                changedField["state"] = data.address.state;
                changedField["country"] = data.address.country;
                changedField["pincode"] = data.address.pincode;
                changedField["address"] = data.address.line1;
                changedField["address2"] = data.address.line2;
                changedField["latitude"] = data.address.latitude;
                changedField["longitude"] = data.address.longitude;
                setLabFormData({ ...changedField });
                break;
            case 'approve':
                setModalTitle('Approve Lab');
                setModalButton1('Reset');
                setModalButton2('Approve Lab');
                setLabId(data._id);
                let changedField2 = { ...labFormData };
                changedField2["name"] = data.name;
                changedField2["regNo"] = data.regNo;
                changedField2["contact"] = data.contact;
                changedField2["emailId"] = data.emailId;
                if (data.address) {
                    changedField2["plotNo"] = data.address.plotNo;
                    changedField2["city"] = data.address.city;
                    changedField2["state"] = data.address.state;
                    changedField2["country"] = data.address.country;
                    changedField2["pincode"] = data.address.pincode;
                    changedField2["address"] = data.address.line1;
                    changedField2["address2"] = data.address.line2;
                    changedField2["latitude"] = data.address.latitude;
                    changedField2["longitude"] = data.address.longitude;
                }
                setLabFormData({ ...changedField2 });
                break;
            case 'reject':
            case 'disable':
            case 'enable':
            case 'delete':
                let changedField1 = { ...labFormData };
                changedField1["name"] = data.name;
                setLabFormData({ ...changedField1 });
                setLabId(data._id);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        currentFormErrors = {};
        const payload = {
            name: capitalizeFirstLetter(labFormData.name),
            regNo: labFormData.regNo,
            contact: labFormData.contact.trim(),
            countryCode: labFormData.countryCode,
            emailId: labFormData.emailId.trim(),
            "address": {
                "plotNo": labFormData.plotNo,
                "line1": labFormData.address,
                "line2": labFormData.address2,
                "city": labFormData.city,
                "state": labFormData.state,
                "country": labFormData.country,
                "pincode": labFormData.pincode,
                "latitude": labFormData.latitude,
                "longitude": labFormData.longitude
            }
        };
        if (emptyField(labFormData.name)) {
            currentFormErrors.name = 'Name is required';
        }
        if (emptyField(labFormData.emailId)) {
            currentFormErrors.emailId = 'Email is required';
        }
        if (emptyField(labFormData.contact)) {
            currentFormErrors.contact = 'Contact is required';
        }
        if (emptyField(labFormData.regNo)) {
            currentFormErrors.regNo = 'Register No is required';
        }
        if (labFormData.emailId && validEmail(labFormData.emailId)) {
            currentFormErrors.emailId = 'Email is invalid';
        }
        if (labFormData.contact && labFormData.contact.length !== 10) {
            currentFormErrors.contact = 'Contact length must be 10';
        }
        setFormErrors(currentFormErrors);
        if (Object.keys(currentFormErrors).length === 0 && (action === 'add' || action === 'update') || action === 'disable' || action === 'enable' || action === 'delete' || action === 'approve' || action === 'reject') {
            switch (action) {
                case 'add':
                    setShowBtnLoader('show-button-loader');
                    dispatch(addLab(payload, labs)).then(() => { LoadApiCalls(1, pageSize, search); setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'approve':
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateLab(labId, payload, labs)).then(() => {
                        const aprroveData = { "labIds": [labId], "approval": "APPROVE" }
                        dispatch(approvalLab(labId, aprroveData, labs)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    });
                    break;
                case 'update':
                    delete payload.emailId;
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateLab(labId, payload, labs)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'delete':
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteLab(labId, labs)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'reject':
                    setShowBtnLoader('show-button-loader');
                    const rejectData = { "labIds": [labId], "approval": "REJECT" }
                    dispatch(approvalLab(labId, rejectData, labs)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'disable':
                    setShowBtnLoader('show-button-loader');
                    const dataDisable = { "labIds": [labId], "block": true }
                    dispatch(enableLab(labId, dataDisable, labs)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'enable':
                    setShowBtnLoader('show-button-loader');
                    const dataEnable = { "labIds": [labId], "block": false }
                    dispatch(enableLab(labId, dataEnable, labs)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                default:
                    throw new Error('Modal action not match');
            }
        }
    };

    const LoadApiCalls = (page, pageSize, search) => {
        let pagination = { "pageNo": page, "pageSize": pageSize };
        let filter = { "isApproved": ["0", "-1", "1"], "isActive": "all" };
        let value = search;
        let paramSearch = { value };
        let sort = {
            "orderBy": "createdAt",
            "order": "DESC"
        }
        if (search === "") setLoading(true);
        dispatch(fetchLabList(pagination, filter, paramSearch, sort)).then(() => {
            removeMainLoaderClass();
            if (search === "") setLoading(false);
        }).catch(() => {
            removeMainLoaderClass();
            if (search === "") setLoading(false);
        });
    }

    useEffect(() => {
        LoadApiCalls(page, pageSize, search);
    }, [page, pageSize, search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable full-height">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Lab List" />
                            <div className="table-header-search">
                                <div className="no-label table-search">
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); setPage(1) }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add New"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List import hospital list from hospitalmx*/}
                        <div className="table labs">
                            <HospitalsList
                                hospitals={labs}
                                modalClickAction={modalClickAction}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                isLab={true}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            {/* Add/Edit/Approve Modal import from hospital mx add, update , approve modal*/}
            <div className="AddNew">
                <HospitalAddModal
                    showBtnLoader={showBtnLoader}
                    modal={modal}
                    modalAction={modalAction}
                    modalButton1={modalButton1}
                    modalButton2={modalButton2}
                    modalClickAction={modalClickAction}
                    modalTitle={modalTitle}
                    hospitalFormData={labFormData}
                    setHospitalFormData={setLabFormData}
                    FormErrors={FormErrors}
                    setFormErrors={setFormErrors}
                    resetForm={resetForm}
                    dispatchAction={dispatchAction}
                    isLab={true}
                />
            </div>
            {/* Delete Modal import from hospital delete, disable, reject, enable modal*/}
            <HospitalOptionModal
                showBtnLoader={showBtnLoader}
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                dispatchAction={dispatchAction}
                data={labFormData.name}
            />
        </>
    );
}
