import { CLOSE_MODAL, OPEN_MODAL } from "../actions/types";

const initialState = false;

export default function modal(state = initialState, action) {
    switch (action.type) {
        case OPEN_MODAL:
            return true;
        case CLOSE_MODAL:
            document.body.classList.remove('popup-open');
            return false;
        default:
            return state;
    }
};