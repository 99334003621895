import { Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { S3_BASE_URL } from "../../../common";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';

export default function PaymentList({ paymentList, modalClickAction }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Key", dataIndex: "key", fixed: "left", sorter: (a, b) => a.key.localeCompare(b.key) },
        { title: "Type", dataIndex: "type" , sorter: (a, b) => a?.type.localeCompare(b?.type) },
        {
            title: "Icon", dataIndex: "imageSrc", render: (row) => {
                return (
                    <span className="payment-icon">
                        <img src={S3_BASE_URL + row} alt="payment" />
                    </span>
                )
            }
        },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (paymentList?.length > 0) {
            paymentList.map((v, i) => {
                let obj = {
                    no: i + 1,
                    key: v.key,
                    type: v.name,
                    imageSrc: v.imageSrc,
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [paymentList])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
        </>
    )
}