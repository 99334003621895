import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './style.scss';
import { MENUITEMS } from "./menu";

export default function AdminSidebar() {
	const [mainmenu, setMainMenu] = useState(MENUITEMS);
	const location = useLocation();
	const isMenuActive = (item, isSubMenu) => {
		let arr = location?.pathname?.split('/');
		let arr2 = item?.path?.split('/');
		if (!isSubMenu && arr2 && arr2[1] && arr && arr[1] && arr2[1] === arr[1]) {
			return true;
		}
		if (isSubMenu && arr2 && arr2[1] && arr2[2] && arr && arr[1] && arr[2] && (arr2[1] === arr[1] && arr2[2] === arr[2])) {
			return true;
		}
	};

	const handleClick = (item) => {
		if (!item.active) {
			mainmenu.forEach((a) => {
				if (a.title === item.title) {
					if (a?.isSubMenuShow) a.isSubMenuShow = !a.isSubMenuShow
					a.active = true;
				} else {
					a.active = false;
				}
				if (!a.children) return false;
				a.children.forEach((b) => {
					if (a.children.includes(item)) {
						b.active = false;
					}
					if (!b.children) return false;
					b.children.forEach((c) => {
						if (b.children.includes(item)) {
							c.active = false;
						}
					});
				});
			});
		}
		setMainMenu(mainmenu);
	};

	const toggleSubMenu = (item) => {
		let mainArr = [];
		mainmenu.forEach((a) => {
			if (a.title === item.title) {
				if (a?.isSubMenuShow !== undefined) a.isSubMenuShow = !a.isSubMenuShow
				a.active = true;
			} else {
				if (a?.isSubMenuShow !== undefined) a.isSubMenuShow = false
			}
			mainArr.push(a);
		});
		setMainMenu([...mainArr]);
	}

	const navLinks = mainmenu.map((menuItem, i) => {
		return (
			<li key={i} onClick={() => menuItem?.subMenu ? "" : handleClick(menuItem)} className={`side_nav_item ${menuItem.isSubMenuShow === true ? "show-sub-menu" : ""} ${isMenuActive(menuItem) ? "active" : ""}`} >
				<Link className="menuBox " to={menuItem?.subMenu ? "#" : menuItem.path} onClick={() => toggleSubMenu(menuItem)}>
					<span>
						{menuItem.icon}
						{menuItem.title}
					</span>
				</Link>
				{
					menuItem?.subMenu &&
					<div className="menu-submenu-arrow">
						<i className="submenu-arrow"></i>
					</div>
				}
				<ul className="submenu-sidebar">
					{
						menuItem?.subMenu?.map((sub, j) => {
							return (
								<li key={j} className={`submenu-sidebar-element ${isMenuActive(sub, true) ? "active" : ""}`}>
									<Link className="menuBox" to={sub.path}>
										<span>{sub.title}</span>
									</Link>
								</li>
							)
						})
					}
				</ul>
			</li>
		);
	});

	return (
		<>
			<div className="adminSidebar">
				<ul className="menuList">
					{navLinks}
				</ul>
			</div>
		</>
	);

}
