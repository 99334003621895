import React, { forwardRef } from "react";
import './style.scss';

function ForwardRefButton({ btn, isDisabled, buttonType, onClick, value, defaultValue, title, id, name, loaderClass, tabIndex }, ref) {
    return (
        <div className={`button ${loaderClass ? loaderClass : ""}`}>
            <button
                id={id}
                name={name}
                ref={ref}
                className={btn}
                disabled={loaderClass === "show-button-loader" || isDisabled ? true : false}
                type={buttonType}
                onClick={(e) => { onClick && onClick(e); document.body.classList.remove('popup-open'); document?.querySelectorAll(".main-rout-inner")[0]?.focus(); }}
                value={(value ? value : defaultValue)}
                tabIndex={tabIndex}
            >
                {title}
            </button>
        </div>
    );
}

const Button = forwardRef(ForwardRefButton);
export default Button;
