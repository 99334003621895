let serviceUrl = process.env.REACT_APP_SERVICE_URL;
const BASE_URL = `${serviceUrl}`;
const VERSION = 'v1';

const HOSPITAL_MODULE = 'hospital';
const PRICING_BILLING_MODULE = 'pricing-billing';
const PATIENT_DOCTOR_MODULE = 'patient-doctor';
const USER_MODULE = 'user';
const PHARMACY_MODULE = 'pharmacy';
const ADMIN_MODULE = 'admin';
const NOTIFICATION_MODULE = 'notification';

const PATIENT_ROUTE = `${BASE_URL}/${VERSION}/${PATIENT_DOCTOR_MODULE}`;
const HOSPITAL_ROUTE = `${BASE_URL}/${VERSION}/${HOSPITAL_MODULE}`;
const PRICING_PLAN_ROUTE = `${BASE_URL}/${VERSION}/${PRICING_BILLING_MODULE}`;
const USER_ROUTE = `${BASE_URL}/${VERSION}/${USER_MODULE}`;
const ADMIN_ROUTE = `${BASE_URL}/${VERSION}/${ADMIN_MODULE}`;
const PHARMACY_ROUTE = `${BASE_URL}/${VERSION}/${PHARMACY_MODULE}`;
const NOTIFICATION_ROUTE = `${BASE_URL}/${VERSION}/${NOTIFICATION_MODULE}`;

export const API_URL = {
    auth: {
        login: `${ADMIN_ROUTE}/auth/login`,
        logout: `${ADMIN_ROUTE}/auth/logout`,
    },
    dashboard: {
        userList: `${USER_ROUTE}/user-active-deactive-new`,
    },
    hospitals: {
        add: `${HOSPITAL_ROUTE}/hospital/add`,
        update: `${HOSPITAL_ROUTE}/hospital/update`,
        list: `${HOSPITAL_ROUTE}/hospital/list`,
        delete: `${HOSPITAL_ROUTE}/hospital/delete`,
        approval: `${HOSPITAL_ROUTE}/hospital/approval`,
        blocking: `${HOSPITAL_ROUTE}/hospital/blocking`,
        gotoSetting: `${ADMIN_ROUTE}/auth/goto-hospital`,
        filterData: `${HOSPITAL_ROUTE}/hospital/filterData`,
        unArchive: `${HOSPITAL_ROUTE}/hospital/unarchive`
    },
    doctors: {
        add: `${USER_ROUTE}/create`,
        update: `${USER_ROUTE}/update`,
        get: `${USER_ROUTE}/list`,
        delete: `${USER_ROUTE}/delete`,
        approval: `${USER_ROUTE}/approval`,
        blocking: `${USER_ROUTE}/blocking`,
        analytics: `${USER_ROUTE}/doctor/analytics`,
        gotoSetting: `${ADMIN_ROUTE}/auth/goto-doctor`,
        verificationEmail: `${USER_ROUTE}/verification-email`
    },
    receptionist: {
        add: `${USER_ROUTE}/create`,
        update: `${USER_ROUTE}/update`,
        get: `${USER_ROUTE}/list`,
        delete: `${USER_ROUTE}/delete`,
        approval: `${USER_ROUTE}/approval`,
        blocking: `${USER_ROUTE}/blocking`,
        analytics: `${USER_ROUTE}/receptionist/analytics`,
        verificationEmail: `${USER_ROUTE}/verification-email`
        // gotoSetting: `${BASE_URL}/${API}/${opd_module}/auth/goto-doctor`,
    },
    hospitalSetting: {
        update: `${HOSPITAL_ROUTE}/hospital/update`,
        getSetting: `${HOSPITAL_ROUTE}/hospital-settings/list-one`,
        setting: `${HOSPITAL_ROUTE}/hospital-settings/change`,
        payment: `${HOSPITAL_ROUTE}/options/list-autoload`,
        uhidShow: `${HOSPITAL_ROUTE}/hospital-settings/uhid-show`,
        uhidUpdate: `${HOSPITAL_ROUTE}/hospital-settings/uhid-update`,
        aptIdShow: `${HOSPITAL_ROUTE}/hospital-settings/appointment-id-show`,
        aptIdUpdate: `${HOSPITAL_ROUTE}/hospital-settings/appointment-id-update`,
        aptTokenShow: `${HOSPITAL_ROUTE}/hospital-settings/appointment-token-show`,
        aptTokenUpdate: `${HOSPITAL_ROUTE}/hospital-settings/appointment-token-update`,
        billUpdate: `${HOSPITAL_ROUTE}/hospital-settings/bill-id-update`,
        billShow: `${HOSPITAL_ROUTE}/hospital-settings/bill-id-show`,
    },
    doctorSetting: {
        getSetting: `${PATIENT_ROUTE}/doctor-settings/list-one`,
        referDoctorList: `${PATIENT_ROUTE}/refer/list`,
        referDocUpdate: `${PATIENT_ROUTE}/refer/update`,
        addReferDoctor: `${PATIENT_ROUTE}/refer/add`,
        deleteReferDoctor: `${PATIENT_ROUTE}/refer/delete`,
        doctorChangePassword: `${USER_ROUTE}/change-password`,
        updateVitals: `${PATIENT_ROUTE}/doctor-settings/change`,
        manageSettingsList: `${PATIENT_ROUTE}/prscription-doctor-settings/list-one`,
        updateSettings: `${PATIENT_ROUTE}/prscription-doctor-settings/change`,
        sign: `${PATIENT_ROUTE}/doctor-settings/doctor-setting-signture`,
        treatment: {
            list: `${HOSPITAL_ROUTE}/medicine-templates/list`,
            add: `${HOSPITAL_ROUTE}/medicine-templates/add`,
            update: `${HOSPITAL_ROUTE}/medicine-templates/update`,
            delete: `${HOSPITAL_ROUTE}/medicine-templates/delete`,
        },
        labTestTemplete: {
            list: `${HOSPITAL_ROUTE}/lab-test-templates/search`,
            add: `${HOSPITAL_ROUTE}/lab-test-templates/add`,
            update: `${HOSPITAL_ROUTE}/lab-test-templates/update`,
            delete: `${HOSPITAL_ROUTE}/lab-test-templates/delete`,
        },
        adviseTemplete: {
            list: `${HOSPITAL_ROUTE}/adviseTemplate/search`,
            add: `${HOSPITAL_ROUTE}/adviseTemplate/add`,
            update: `${HOSPITAL_ROUTE}/adviseTemplate/update`,
            delete: `${HOSPITAL_ROUTE}/adviseTemplate/delete`,
        },
        billingTemp: {
            list: `${PRICING_PLAN_ROUTE}/billingTemplates/list`,
            add: `${PRICING_PLAN_ROUTE}/billingTemplates/add`,
            update: `${PRICING_PLAN_ROUTE}/billingTemplates/update`,
            delete: `${PRICING_PLAN_ROUTE}/billingTemplates/delete`,
        },

    },
    department: {
        departmentList: `${HOSPITAL_ROUTE}/department/list`,
        add: `${HOSPITAL_ROUTE}/department/add`,
        update: `${HOSPITAL_ROUTE}/department/update`,
        delete: `${HOSPITAL_ROUTE}/department/delete`,
    },
    lab: {
        add: `${PHARMACY_ROUTE}/lab/add`,
        update: `${PHARMACY_ROUTE}/lab/update`,
        get: `${PHARMACY_ROUTE}/lab/list`,
        getOneLab: `${PHARMACY_ROUTE}/lab/getlab`,
        delete: `${PHARMACY_ROUTE}/lab/delete`,
        approval: `${PHARMACY_ROUTE}/lab/approval`,
        blocking: `${PHARMACY_ROUTE}/lab/blocking`,
    },
    disease: {
        search: `${HOSPITAL_ROUTE}/disease/search`,
        list: `${HOSPITAL_ROUTE}/disease/list`,
        add: `${HOSPITAL_ROUTE}/disease/insert-many`,
        update: `${HOSPITAL_ROUTE}/disease/update`,
        approve: `${HOSPITAL_ROUTE}/disease/approval`,
        merge: `${HOSPITAL_ROUTE}/disease/merge`,
        delete: `${HOSPITAL_ROUTE}/disease/delete`,
    },
    symptom: {
        list: `${HOSPITAL_ROUTE}/symptom/list`,
        add: `${HOSPITAL_ROUTE}/symptom/insert-many`,
        approve: `${HOSPITAL_ROUTE}/symptom/approval`,
        merge: `${HOSPITAL_ROUTE}/symptom/merge`,
        update: `${HOSPITAL_ROUTE}/symptom/update`,
        delete: `${HOSPITAL_ROUTE}/symptom/delete`,
        isCommon: `${HOSPITAL_ROUTE}/symptom/iscommon`,
        categoryList: `${HOSPITAL_ROUTE}/symptom-category/list`,
    },
    habit: {
        add: `${PHARMACY_ROUTE}/habits/insert-many`,
        update: `${PHARMACY_ROUTE}/habits/update`,
        delete: `${PHARMACY_ROUTE}/habits/delete`,
        search: `${PHARMACY_ROUTE}/habits/search`,
        list: `${PHARMACY_ROUTE}/habits/list`,
        approve: `${PHARMACY_ROUTE}/habits/approval`,
        merge: `${PHARMACY_ROUTE}/habits/merge`,
    },
    pastHistory: {
        search: `${PATIENT_ROUTE}/past-history/search`,
        add: `${PATIENT_ROUTE}/past-history/add-one`,
        update: `${PATIENT_ROUTE}/past-history/update`,
        delete: `${PATIENT_ROUTE}/past-history/delete`,
        approve: `${PATIENT_ROUTE}/past-history/approval`,
        merge: `${PATIENT_ROUTE}/past-history/merge`,
    },
    menstrualType: {
        list: `${HOSPITAL_ROUTE}/gynecHistory/listAllMenstrualType`,
        add: `${HOSPITAL_ROUTE}/gynecHistory/add-menstrual-type`,
        approve: `${HOSPITAL_ROUTE}/gynecHistory/menstrual-type-approval`,
        update: `${HOSPITAL_ROUTE}/gynecHistory/update-menstrual-type`,
        delete: `${HOSPITAL_ROUTE}/gynecHistory/update-menstrual-type`,
        isCommon: `${HOSPITAL_ROUTE}/gynecHistory/menstrual-type-iscommon`,
    },
    vitals: {
        change: `${ADMIN_ROUTE}/builder/vitals/change`,
        find: `${ADMIN_ROUTE}/builder/vitals/find`,
        findMany: `${ADMIN_ROUTE}/builder/vitals/find-many`,
        delete: `${ADMIN_ROUTE}/builder/vitals/delete`,
    },
    laboratoryTests: {
        labTests: {
            add: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_add_test`,
            get: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_test_list`,
            update: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_test_update`,
            getOne: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_test_find_record`,
            delete: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_test_remove_record`,
            search: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_test_search`,
            searchRecord: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_record_search`,
            approve: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_test_approve`,
            mergeTest: `${PHARMACY_ROUTE}/doccineLabTestMeta/doc_test_merge`,
        },
        labPanel: {
            add: `${PHARMACY_ROUTE}/labAdminPanel/admin_add_lab_panel`,
            get: `${PHARMACY_ROUTE}/labAdminPanel/admin_find_all_lab_panel`,
            update: `${PHARMACY_ROUTE}/labAdminPanel/admin_update_lab_panel`,
            search: `${PHARMACY_ROUTE}/labAdminPanel/admin_search_lab_panel`
        },
        reportTemplate: {
            add: `${PHARMACY_ROUTE}/report-templates/admin_add_report_template`,
            put: `${PHARMACY_ROUTE}/report-templates/admin_find_all_report_template`,
            update: `${PHARMACY_ROUTE}/report-templates/admin_update_report_template`,
            search: `${PHARMACY_ROUTE}/report-templates/admin_search_report_template`,
            delete: `${PHARMACY_ROUTE}/report-templates/delete`
        },
    },
    medicine: {
        get: `${PHARMACY_ROUTE}/medicine/list`,
        insertMany: `${PHARMACY_ROUTE}/medicine/insert-many`,
        therapeuticClass: {
            get: `${PHARMACY_ROUTE}/therapeutic-class/list`,
            add: `${PHARMACY_ROUTE}/therapeutic-class/insert-many`,
            update: `${PHARMACY_ROUTE}/therapeutic-class/update`,
            delete: `${PHARMACY_ROUTE}/therapeutic-class/delete`,
        },
        therapeuticSubClass: {
            get: `${PHARMACY_ROUTE}/therapeutic-sub-class/list`,
            add: `${PHARMACY_ROUTE}/therapeutic-sub-class/insert-many`,
            update: `${PHARMACY_ROUTE}/therapeutic-sub-class/update`,
            delete: `${PHARMACY_ROUTE}/therapeutic-sub-class/delete`,
        },
        saltComposition: {
            get: `${PHARMACY_ROUTE}/salt-composition/list`,
            add: `${PHARMACY_ROUTE}/salt-composition/insert-many`,
            update: `${PHARMACY_ROUTE}/salt-composition/update`,
            delete: `${PHARMACY_ROUTE}/salt-composition/delete`,
        },
        manufacturer: {
            get: `${PHARMACY_ROUTE}/manufacturer/list`,
            add: `${PHARMACY_ROUTE}/manufacturer/insert-many`,
            update: `${PHARMACY_ROUTE}/manufacturer/update`,
            delete: `${PHARMACY_ROUTE}/manufacturer/delete`,
        },
        search: `${PHARMACY_ROUTE}/medicine/search`,
        approve: `${PHARMACY_ROUTE}/medicine/approval`,
        delete: `${PHARMACY_ROUTE}/medicine/delete`,
        merge: `${PHARMACY_ROUTE}/medicine/merge`,
        update: `${PHARMACY_ROUTE}/medicine/update`,
        typeList: `${PHARMACY_ROUTE}/medicine/type-list`,
    },
    payment: {
        adminGet: `${HOSPITAL_ROUTE}/options/list-one/payment_methods`,
        updatePaymentMethod: `${HOSPITAL_ROUTE}/options/update-payment-methods`,
    },
    headerChange: {
        adminGet: `${HOSPITAL_ROUTE}/options/list-one/payment_methods`,
        updateHeader: `${HOSPITAL_ROUTE}/options/update-header-change`,
    },
    formfields: {
        adminGet: `${ADMIN_ROUTE}/formfields/admin/fieldlist`,
        updateAdminAptFields: `${ADMIN_ROUTE}/formfields/admin/updatefield`,
    },
    fileCategory: {
        adminGet: `${HOSPITAL_ROUTE}/options/list-one-without-prefix/DEFAULT_FILE_CATEGORIES`,
        updateFileCategory: `${HOSPITAL_ROUTE}/options/update-file-categories`,
    },
    doccienUniversity: {
        list: `${HOSPITAL_ROUTE}/doc-uni/list`,
        category: `${HOSPITAL_ROUTE}/doc-uni/get-video-category`,
        add: `${HOSPITAL_ROUTE}/doc-uni/add`,
        delete: `${HOSPITAL_ROUTE}/doc-uni/delete`,
        update: `${HOSPITAL_ROUTE}/doc-uni/update`,
    },
    multiLingual: {
        insertMany: `${ADMIN_ROUTE}/multiLunge/insertMany`,
        upsert: `${ADMIN_ROUTE}/multiLunge/upsert`,
        list: `${ADMIN_ROUTE}/multiLunge/list-groupBy`,
        delete: `${ADMIN_ROUTE}/multiLunge/group-delete`,
    },
    whatsNew: {
        update: `${ADMIN_ROUTE}/whatsNew/update`,
        delete: `${ADMIN_ROUTE}/whatsNew/delete`,
        insert: `${ADMIN_ROUTE}/whatsNew/add`,
        list: `${ADMIN_ROUTE}/whatsNew/list`,
    },
    userRole: {
        list: `${ADMIN_ROUTE}/user-roles/list`,
        add: `${ADMIN_ROUTE}/user-roles/add`,
        update: `${ADMIN_ROUTE}/user-roles/update`,
        delete: `${ADMIN_ROUTE}/user-roles/delete`,
    },
    moduleAccess: {
        list: `${ADMIN_ROUTE}/module-access/list`,
        update: `${ADMIN_ROUTE}/module-access/change`,
        delete: `${ADMIN_ROUTE}/module-access/delete`,
    },
    onBoardVideo: {
        upsert: `${HOSPITAL_ROUTE}/options/upsert-without-prefix`,
        list: `${HOSPITAL_ROUTE}/options/list-groupBy`,
        delete: `${HOSPITAL_ROUTE}/options/list-delete`,
    },
    specialty: {
        search: `${PATIENT_ROUTE}/specialty/search`,
        change: `${PATIENT_ROUTE}/specialty/change`,
        delete: `${PATIENT_ROUTE}/specialty/delete`,
        get: `${HOSPITAL_ROUTE}/disease-specialty/list`,
    },
    canvasIcon: {
        list: `${ADMIN_ROUTE}/icon/list`,
        change: `${ADMIN_ROUTE}/icon/change`,
        delete: `${ADMIN_ROUTE}/icon/delete`,
    },
    notification: {
        emailTemplates: {
            add: `${HOSPITAL_ROUTE}/message-templates/add`,
            update: `${HOSPITAL_ROUTE}/message-templates/update`,
            delete: `${HOSPITAL_ROUTE}/message-templates/delete`,
            list: `${HOSPITAL_ROUTE}/message-templates/list`,
            getAllTrigger: `${HOSPITAL_ROUTE}/message-templates/get-all-trigger`,

            hospitalList: `${HOSPITAL_ROUTE}/hospital-message-templates/list`,
            hospitalupdate: `${HOSPITAL_ROUTE}/hospital-message-templates/update`
        },
        smsTemplates: {
            add: `${NOTIFICATION_ROUTE}/sms-trigger/add`,
            update: `${NOTIFICATION_ROUTE}/sms-trigger/update`,
            delete: `${NOTIFICATION_ROUTE}/sms-trigger/delete`,
            list: `${NOTIFICATION_ROUTE}/sms-trigger/list`,
        },
        wpTemplates: {
            add: `${NOTIFICATION_ROUTE}/wp-trigger/add`,
            update: `${NOTIFICATION_ROUTE}/wp-trigger/update`,
            delete: `${NOTIFICATION_ROUTE}/wp-trigger/delete`,
            list: `${NOTIFICATION_ROUTE}/wp-trigger/list`,
        },
        errorLog: {
            list: `${ADMIN_ROUTE}/error/getError`,
            update: `${ADMIN_ROUTE}/error/changeError`,
        }
    },
    degree: {
        list: `${ADMIN_ROUTE}/degree/list`,
        add: `${ADMIN_ROUTE}/degree/add`,
        delete: `${ADMIN_ROUTE}/degree/delete`,
        update: `${ADMIN_ROUTE}/degree/update`,
    },
    pricingPlan: {
        add: `${PRICING_PLAN_ROUTE}/pricingPlanMeta/create`,
        update: `${PRICING_PLAN_ROUTE}/pricingPlanMeta/update`,
        list: `${PRICING_PLAN_ROUTE}/pricingPlanMeta/list`,
        delete: `${PRICING_PLAN_ROUTE}/pricingPlanMeta/delete`,
    }
};
