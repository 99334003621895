import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { EMAIL_TEMPLATE_LIST } from "./types";

// Fetching all data of Template
export const fetchEmailTemplatesList = (data, isUsed) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${isUsed !== "hospitalSetting" ? API_URL.notification.emailTemplates.list : API_URL.notification.emailTemplates.hospitalList}`,
            data: data
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: EMAIL_TEMPLATE_LIST,
                payload: response.data
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Adding email Templates
export const addEmailTemplates = (data, emailTemplates) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.notification.emailTemplates.add}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...emailTemplates];
            let temp = mainArray.some(v => v._id === response.data._id);
            if (!temp) {
                mainArray.unshift(response.data);
            }
            dispatch({
                type: EMAIL_TEMPLATE_LIST,
                payload: mainArray
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Updating the Template
export const updateEmailTemplates = (templateId, data, emailTemplates, isUsed) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${isUsed !== "hospitalSetting" ? API_URL.notification.emailTemplates.update : API_URL.notification.emailTemplates.hospitalupdate}/${templateId}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...emailTemplates];
            let temp = mainArray.findIndex(email => email._id === templateId);
            if (temp >= 0) {
                mainArray.splice(temp, 1, response.data)
            }
            dispatch({
                type: EMAIL_TEMPLATE_LIST,
                payload: mainArray
            });
        }).catch((error) => {
            reject(error);
        });
    })
}

//delete message Template apis
export const deleteEmailTemplates = (templateId, emailTemplates) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.notification.emailTemplates.delete}/${templateId}`,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...emailTemplates];
            let temp = mainArray.findIndex(email => email._id === templateId);
            if (temp >= 0) {
                mainArray.splice(temp, 1);
            }
            dispatch({
                type: EMAIL_TEMPLATE_LIST,
                payload: mainArray
            });
        }).catch((error) => {
            reject(error);
        });
    })
}

//get all trigger type list for email template
export const getAllTriggerTypes = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.notification.emailTemplates.getAllTrigger}`,
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    })
}
