import React from "react";
import './style.scss';

export default function AdminCounter({ number, title, img, isRupee = false }) {
	return (
		<div className="counterBox">
			{isRupee ? <span>&#8377;{number || 0}</span> : <span>{number}</span>}
			<label>{title}</label>
			<span className="image">
				<img src={img} alt="img" />
			</span>
		</div>
	);
}
