import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { VITALS_LIST } from "./types";

//get vitals list
export const getVitalsData = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.vitals.findMany}`,
            data: data
        }).then((response) => {
            resolve(response);
            dispatch({
                type: VITALS_LIST,
                payload: {
                    count: response.data.count,
                    data: response.data.data,
                }
            });
            return response;
        }).catch((error) => {
            reject(error);
        });
    });
}

//update vitals
export const updateVitals = (data, key) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.vitals.change}/${key}`,
            data: data
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete vitals
export const deleteVitals = (key, vitals) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.vitals.delete}/${key}`,
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

//add vitals
export const addVitals = (data, key) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.vitals.change}/${key}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}