import { Dropdown, Pagination, Switch, Table, Tag, Tooltip } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Restore from "../../../assets/images/restore.svg";
import Unarchive from "../../../assets/images/unarchive.svg";
import { capitalizeFirstLetter, nameSortingForTable } from "../../../common";

export default function HospitalsList({
  hospitals,
  modalClickAction,
  page,
  setPage,
  pageSize,
  setPageSize,
  isLab,
  auth,
  isHospital,
}) {
  const [data, setData] = useState([]);

  // add columns which you wonna add in table
  const [columns, setColumns] = useState([
    { title: "No", dataIndex: "no", fixed: "left", width: 50 },
    {
      title: isLab ? "Lab Name" : "Hospital Name",
      dataIndex: "name",
      fixed: "left",
      width: 250,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, { name, data }) => {
        return (
          <div className="inline-flex">
            {/* <a onClick={() => navigate("/hospitalmx/hospitalsettings", { state: { id: data._id, allValue: data } })}>{name}</a> &nbsp;&nbsp; */}
            {name} &nbsp;&nbsp;
            <span>
              {!data?.isDeleted && data?.isApproved === "-1" && (
                <Tag color={"darkred"} key={data._id}>
                  Rejected
                </Tag>
              )}
              {!data?.isDeleted &&
                data?.isActive &&
                data?.isApproved === "1" && (
                  <Tag color={"green"} key={data._id}>
                    Active
                  </Tag>
                )}
              {!data?.isDeleted && !data?.isActive && (
                <Tag color={"red"} key={data._id}>
                  Disabled
                </Tag>
              )}
              {data?.isDeleted && (
                <Tag color={"purple"} key={data._id}>
                  Archived
                </Tag>
              )}
              {!data?.isDeleted && data?.isApproved === "0" && (
                <Tag color={"yellow"} key={data._id}>
                  Pending
                </Tag>
              )}
            </span>
          </div>
        );
      },
    },
    { title: "Reg No", dataIndex: "regNo", width: 150, sorter: (a, b) => nameSortingForTable(a?.regNo, b?.regNo) },
    {
      title: "Contact",
      dataIndex: "contact",
      width: 150,
      sorter: (a, b) => a.contact.localeCompare(b.contact),
    },
    {
      title: "Email",
      dataIndex: "emailId",
      width: 300,
      sorter: (a, b) => a.emailId.localeCompare(b.emailId),
    },
    { title: "Address", dataIndex: "address", width: 400, sorter: (a, b) => nameSortingForTable(a?.address, b?.address) },
    { title: "Postal Code", dataIndex: "pincode", width: 150, sorter: (a, b) => nameSortingForTable(a?.pincode, b?.pincode) },
    {
      title: "Action",
      width: 100,
      dataIndex: "",
      key: "x",
      fixed: "right",
      render: (row) => {
        // return CommonActionIconWithTooltipInANTDTable(row, modalClickAction)
        return (
          <>
            {row.data.isApproved === "0"  && (
              <span
                className="approve link"
                onClick={() => modalClickAction("open", "approve", row.data)}
              >
                <a>Review</a>
              </span>
            )}
            {!row.data?.isDeleted && row.data.isApproved === "1"  && (
              <Tooltip
                title={!row.data.isActive ? "Enable Login" : "Disable Login"}
              >
                <Switch
                  className="disable"
                  checked={row.data.isActive}
                  onChange={(e) => handleEnableDisableChange(e, row)}
                />
              </Tooltip>
            )}
            {!row.data?.isDeleted && row.data.isApproved === "1" && (
              <Dropdown
                placement="bottomRight"
                trigger={["click"]}
                dropdownRender={() => {
                  return (
                    <div className="action">
                      <p
                        className="edit"
                        onClick={() =>
                          modalClickAction("open", "update", row.data)
                        }
                      >
                        Edit Hospital
                      </p>
                      {
                        <p
                          className="changeQuota"
                          onClick={() =>
                            modalClickAction("open", "changeQuota", row.data)
                          }
                        >
                          Change Quota
                        </p>
                      }
                      {
                        <p
                          className="setting"
                          onClick={() =>
                            modalClickAction("open", "goToSettings", row.data)
                          }
                        >
                          Go to Hospital Setting
                        </p>
                      }
                      {
                        <p
                          className="delete"
                          onClick={() =>
                            modalClickAction("open", "delete", row.data)
                          }
                        >
                          Archive
                        </p>
                      }
                    </div>
                  );
                }}
              >
                <span className="icon">
                  <svg
                    width="18"
                    height="4"
                    viewBox="0 0 18 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="2"
                      r="2"
                      transform="rotate(90 16 2)"
                      fill="#CFCFCF"
                    />
                    <circle
                      cx="9"
                      cy="2"
                      r="2"
                      transform="rotate(90 9 2)"
                      fill="#CFCFCF"
                    />
                    <circle
                      cx="2"
                      cy="2"
                      r="2"
                      transform="rotate(90 2 2)"
                      fill="#CFCFCF"
                    />
                  </svg>
                </span>
              </Dropdown>
            )}
            {!row.data?.isDeleted && row.data.isApproved === "-1" && (
              <Tooltip title="Undo Reject">
                <span
                  className="delete"
                  onClick={() =>
                    modalClickAction("open", "undoReject", row.data)
                  }
                >
                  <img src={Restore} alt={Restore} />
                </span>
              </Tooltip>
            )}
            { row.data?.isDeleted && (
              <Tooltip title="Unarchive">
                <span
                  className="delete"
                  onClick={() =>
                    {modalClickAction("open", "unarchive", row.data); console.log('hospitals')}
                  }
                >
                  <img src={Unarchive} alt={Unarchive} />
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ]);

  useEffect(() => {
    if (isHospital) {
      let arr = [...columns];
      arr.splice(2, 0, {
        title: "Pricing Plan",
        dataIndex: "pricingPlan",
        width: 150,
        sorter: (a, b) => nameSortingForTable(a?.pricingPlan, b?.pricingPlan)
      });
      arr.splice(3, 0, {
        title: "Plan Expiry",
        dataIndex: "planExpiry",
        width: 150,
        sorter: (a, b) =>
          new Date(a?.data?.pricingPlanExpireDate) -
          new Date(b?.data?.pricingPlanExpireDate),
      });
      arr.splice(4, 0, {
        title: "Plan Added by",
        dataIndex: "planAddedby",
        width: 150,
        sorter: (a, b) => nameSortingForTable(a?.planAddedby, b?.planAddedby)
      });
      setColumns([...arr]);
    }
  }, [isHospital]);

  const handleEnableDisableChange = (e, row) => {
    if (e) {
      modalClickAction("open", "enable", row.data);
    } else {
      modalClickAction("open", "disable", row.data);
    }
  };

  // set data for table which object keys come from columns dataIndex
  useEffect(() => {
    let arr = [];
    if (hospitals?.data?.length > 0) {
      hospitals?.data?.map((v, i) => {
        let obj = {
          key: v._id,
          no: i + 1,
          name: capitalizeFirstLetter(v.name),
          regNo: v?.regNo && v?.regNo !== 'undefined' ? v?.regNo : "-",
          contact:
            v?.countryCode && v.contact
              ? v?.countryCode + " " + v.contact
              : v.contact
              ? v.contact
              : "-",
          emailId: v?.emailId && v?.emailId !== 'undefined' ? v.emailId : "-",
          address: v?.address?.line1
            ? v?.address?.plotNo
              ? v?.address?.plotNo + ", " + v?.address?.line1
              : v?.address?.line1
            : "-",
          data: {
            ...v,
            isHospital: true,
          },
          pincode: v?.address?.pincode ? v?.address?.pincode : "-",
        };
        if (!isLab) {
          obj.pricingPlan = v?.pricingId?.planName ? v.pricingId.planName : "-";
          obj.planExpiry = v?.pricingPlanExpireDate
            ? v.pricingPlanNoExpiry === true
              ? "No Expiry"
              : moment(v?.pricingPlanExpireDate).format("DD-MM-yyyy")
            : "-";
          obj.planAddedby = v?.pricingAddedbyAdmin?._id
            ? v.pricingAddedbyAdmin._id === auth.id
              ? "Admin"
              : v.pricingAddedbyAdmin.emailId
            : "-";
        }
        arr.push({ ...obj });
      });
    }
    setData([...arr]);
  }, [hospitals?.data]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        sticky={true}
        scroll={{ x: 1500 }}
      />
      <Pagination
        total={hospitals.count}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        pageSize={pageSize}
        current={page}
        onChange={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
      />
    </>
  );
}
