import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { PAYMENT_LIST } from "./types";

//fetch payment list
export const fetchPaymentList = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.payment.adminGet}`,
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: PAYMENT_LIST,
                payload: response.data
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update payments
export const updatePayment = (data, key, payments, action) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.payment.updatePaymentMethod}`,
            data: data,
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...payments.value];
            let tmp = mainArray.findIndex(paymentl => paymentl.key === key);
            if (action === "delete") {
                if (tmp >= 0) mainArray.splice(tmp, 1);
            } else if (action === "update") {
                if (tmp >= 0) mainArray.splice(tmp, 1, response.data);
            } else if (action === "add") {
                if (tmp < 0) mainArray.unshift(response.data)
            }
            payments.value = mainArray
            dispatch({
                type: PAYMENT_LIST,
                payload: payments
            });
        }).catch((error) => {
            reject(error);
        });
    });
}


//update header
export const updateHeader = (data, key, payments, action) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.headerChange.updateHeader}`,
            data: data,
        }).then((response) => {
            resolve(response.data)
            
        }).catch((error) => {
            reject(error);
        });
    });
}