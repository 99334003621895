import { API_URL } from "../api/apiRoutes";
import { apiClient } from '../api/general';
import { PASTHISTORY_LIST } from './types';

//pastHistory search and fetch pastHistory list
export const searchPastHisotryList = (filter, pagination, extraData, isType) => async (dispatch) => {
    let body = { filter, pagination, extraData };
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.pastHistory.search}`,
            data: body
        }).then((response) => {
            resolve(response.data)
            if (!isType) {
                dispatch({
                    type: PASTHISTORY_LIST,
                    payload: {
                        count: response.data.count,
                        data: response.data.data
                    }
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

//add pastHistory
export const addPastHistory = (data, pastHistory) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.pastHistory.add}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let obj = response.data;
            let mainArray = [...pastHistory.data];
            let tmp = mainArray.some(v => v._id === obj._id);
            if (!tmp) {
                mainArray.push(obj);
            }
            dispatch({
                type: PASTHISTORY_LIST,
                payload: {
                    count: pastHistory.count,
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update pastHistory
export const updatePastHistory = (data, pastHistory) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.pastHistory.update}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let obj = response.data;
            let mainArray = [...pastHistory.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: PASTHISTORY_LIST,
                payload: {
                    count: pastHistory.count,
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//merge pastHistory
export const mergePastHistory = (docs, pastHistory, type) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.pastHistory.merge}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let obj = response.data;
            let mainArray = [...pastHistory.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: PASTHISTORY_LIST,
                payload: {
                    count: pastHistory.count,
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

// approve and reject pastHistory
export const approvePastHistory = (data, pastHistory) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.pastHistory.approve}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let obj = response.data[0];
            let mainArray = [...pastHistory.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: PASTHISTORY_LIST,
                payload: {
                    count: pastHistory.count,
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete pastHistory
export const deletePastHistory = (data, pastHistory, pId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.pastHistory.delete}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...pastHistory.data];
            if (Array.isArray(pId)) {
                pId.map((item) => {
                    let index = mainArray.findIndex(v => v._id === item);
                    if (index >= 0) mainArray.splice(index, 1);
                })
            } else {
                let index = mainArray.findIndex(v => v._id === pId);
                if (index >= 0) mainArray.splice(index, 1);
            }
            dispatch({
                type: PASTHISTORY_LIST,
                payload: {
                    count: pastHistory.count - (Array.isArray(pId) ? pId.length : 1),
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}