import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExtraSubMenu, ListApisForDataMx } from "../Common";
import { approveHabbits, deleteHabbits, InsertManyHabbits, mergeHabbits, searchHabbitsList, updateHabbits } from "../../actions/habit";
import { closeModal, openModal } from "../../actions/modal";
import DiseaseList from "../Diseases/DiseaseList";
import { debounce, emptyField } from "../../common";
import TableTitle from "../../Components/TableTitle";
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import Select from "react-select";
import { Skeleton } from "antd";
import DiseaseModals from "../Diseases/DiseaseModals";

export default function Habits(props) {

    const { habits = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);
    const [isCommon, setIsCommon] = useState(false);

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    // const [habbitsData, setHabbitsData] = useState([{ name: "" }])
    const [habbitsId, setHabbitsId] = useState('');
    const [habbitsName, setHabbitsName] = useState('');
    const [habbitsSearch, setHabbitsSearch] = useState("");
    const [mergeHabbitsID, setMergeHabbitsID] = useState("");
    const [habbitsNameErr, setHabbitsNameErr] = useState('');
    const [mergeError, setMergeError] = useState("")
    const [modalButton1, setModalButton1] = useState('');
    const [modalButton2, setModalButton2] = useState('');

    const [showBtnLoader, setShowBtnLoader] = useState('');

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    //For multiple delete options
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [activeSubMenu, setActiveSubMenu] = useState('inapporval');

    const resetForm = () => {
        // setHabbitsData([{ name: "" }])
        setHabbitsName("")
        setHabbitsSearch("")
        setHabbitsId("")
        setMergeHabbitsID("")
        setHabbitsNameErr("")
        setIsCommon(false);
    };

    const resetModal = () => {
        setModalAction("");
        setModalTitle("");
        setModalButton1("")
        setModalButton2("")
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case 'add':
                setModalTitle("Add Habits")
                setModalButton1("Reset")
                setModalButton2("Add")
                break;
            case 'delete':
            case 'reject':
            case 'update':
                setModalTitle("Edit Habits")
                setModalButton1('Reset');
                setModalButton2('Edit Habits');
                setHabbitsId(data._id);
                setHabbitsName(data.name);
                setIsCommon(data.isCommon);
                break;
            case 'approve':
                setModalTitle("Confirm Habits")
                setModalButton1('Reset');
                setModalButton2('Confirm Habits');
                setHabbitsId(data._id);
                setHabbitsName(data.name);
                setIsCommon(data.isCommon);
                break;
            case 'merge':
                setModalTitle("Merge Habits")
                setModalButton1('Cancel');
                setModalButton2('Merge Habits');
                setHabbitsId(data._id);
                setHabbitsName(data.name);
                setIsCommon(data.isCommon);
                break;
            case 'deleteMultiple':
                setHabbitsName("Selected Habits");
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        let updatePayload = {
            _id: habbitsId,
            name: habbitsName,
            isCommon: isCommon
        }
        if (action === "merge") {
            if (emptyField(habbitsName)) {
                setHabbitsNameErr("Habits Name is Required")
            }
            if (emptyField(mergeHabbitsID)) {
                setMergeError("Habits Merge is Required")
            }
        }
        switch (action) {
            case 'add':
                // let manus = habbitsData.filter((v) => v.name !== "");
                if (habbitsName !== "") {
                    let addPaylaod = {
                        docs: [{ name: habbitsName, isCommon: isCommon }]
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(InsertManyHabbits(addPaylaod, habits)).then(() => {
                        ListApisForDataMx(activeSubMenu, page, pageSize, habbitsSearch, searchHabbitsList, setSelectedRowKeys, dispatch, setLoading);
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                    modalClickAction('close')
                } else {
                    setHabbitsNameErr("Habits Name is Required")
                }
                break;
            case 'update':
                if (habbitsId !== "" && habbitsName !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateHabbits(updatePayload, habits)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                } else {
                    setHabbitsNameErr('Habits name is required')
                }
                break;
            case 'approve':
                let approvePayload = {
                    ids: [habbitsId],
                    approval: "APPROVE"
                }
                setShowBtnLoader('show-button-loader');
                dispatch(updateHabbits(updatePayload, habits)).then(() => {
                    dispatch(approveHabbits(approvePayload, habits)).then(() => {
                        modalClickAction('close');
                        ListApisForDataMx(activeSubMenu, page, pageSize, habbitsSearch, searchHabbitsList, setSelectedRowKeys, dispatch, setLoading);
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                })
                break;
            case 'reject':
                let rejectPayload = {
                    ids: [habbitsId],
                    approval: "REJECT"
                }
                if (rejectPayload.ids) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(approveHabbits(rejectPayload, habits)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                }
                break;
            case 'merge':
                let mergePayload = {
                    id: habbitsId,
                    withId: mergeHabbitsID
                }
                if (mergePayload.id && mergePayload.withId) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(mergeHabbits(mergePayload, habits)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                }
                break;
            case 'delete':
                let deletePayload = {
                    ids: [habbitsId],
                }
                if (deletePayload.ids) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteHabbits(deletePayload, habits, habbitsId)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                }
                break;
            case 'deleteMultiple':
                const deleteMultiplePayload = {
                    ids: selectedRowKeys
                }
                setShowBtnLoader('show-button-loader');
                dispatch(deleteHabbits(deleteMultiplePayload, habits, selectedRowKeys)).then(() => {
                    modalClickAction('close');
                    setShowBtnLoader('');
                    setSelectedRowKeys([]);
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            default:
                throw new Error('Modal action not match');
        }
    };

    useEffect(() => {
        debounce(ListApisForDataMx(activeSubMenu, page, pageSize, habbitsSearch, searchHabbitsList, setSelectedRowKeys, dispatch, setLoading), 300)
    }, [habbitsSearch, activeSubMenu, page, pageSize])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Habits List" />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenu}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setPage(1); setHabbitsSearch('') }}
                                        placeholder="Select Status"
                                        value={ExtraSubMenu.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setHabbitsSearch(e.target.value); }}
                                        value={habbitsSearch}
                                    />
                                </div>
                                {
                                    activeSubMenu === "approved" &&
                                    <div className="add-btn">
                                        <Button
                                            title="Add"
                                            onClick={() => modalClickAction('open', 'add')}
                                        />
                                    </div>
                                }
                                {
                                    selectedRowKeys?.length > 0 &&
                                    <div className="delete-btn">
                                        <Button
                                            btn="btn-outline-primary"
                                            title="Delete"
                                            onClick={() => modalClickAction('open', 'deleteMultiple')}
                                        />
                                    </div>
                                }

                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table symptoms">
                            <DiseaseList
                                diseases={habits}
                                modalClickAction={modalClickAction}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                isCategory={false}
                                isCommon={true}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                api={searchHabbitsList}
                dispatch={dispatch}
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={habbitsName}
                setName={setHabbitsName}
                nameError={habbitsNameErr}
                setNameError={setHabbitsNameErr}
                mergeDiseaseId={mergeHabbitsID}
                setMergeDiseaseId={setMergeHabbitsID}
                mergeError={mergeError}
                setMergeError={setMergeError}
                modalButton1={modalButton1}
                modalButton2={modalButton2}
                modalTitle={modalTitle}
                isCommon={isCommon}
                setIsCommon={setIsCommon}
                resetForm={resetForm}
                isCatAvailable={false}
                isComAvailable={true}
                isDelete={true}
                isMerge={true}
                isAdd={true}
            />
        </>
    )
}