import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Modal from '../../../../components/common/Modal';
// import ModuleAccessList from "./ModuleAccessList";
// import AddModuleAccess from './addModuleAccess';
import Select from 'react-select';
import { deleteModule, fetchModuleAccessList, updateModule } from "../../actions/moduleAccess";
import { Skeleton } from "antd";
import TableTitle from "../../Components/TableTitle";
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import { emptyField, removeMainLoaderClass } from "../../common";
import { closeModal, openModal } from "../../actions/modal";
import ModuleAccessList from "./ModuleAccessList";
import DiseaseModals from "../Diseases/DiseaseModals";
import AddModuleAccessModal from "./AddModuleAccessModal";

export default function ModuleAccess(props) {
    const { modal, moduleAccesses } = useSelector((state) => state);

    //for skeleton
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    let currentFormErrors = [];
    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton2, setModalButton2] = useState('');
    const [FormModuleData, setFormData] = useState({ "pname": '', "pkey": '', "pid": '', "ptype": '', "imageSrc": "", });
    const [FormErrors, setFormErrors] = useState(currentFormErrors);

    const [showBtnLoader, setShowBtnLoader] = useState('');
    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    const [iconImage, setIconImage] = useState('');

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton2('');
    };

    const resetForm = () => {
        setIconImage('')
        setFormData({
            "pname": '',
            "pkey": '',
            "pid": '',
            "ptype": '',
            "imageSrc": "",
        });
        setFormErrors('');
    };
    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        let changedField;
        switch (action) {
            case 'add':
                setModalTitle('Add Module');
                setModalButton2('Add');
                break;
            case 'update':
                setModalTitle('Update Module');
                setModalButton2('Update');
                changedField = { ...FormModuleData };
                changedField["pname"] = data.name;
                changedField["pkey"] = data.key;
                changedField["ptype"] = data.type;
                changedField["imageSrc"] = data.icon;
                setFormData({ ...changedField });
                setIconImage(data.icon)
                break;
            case 'delete':
                setModalTitle('Delete Module');
                setModalButton2('Delete');
                changedField = { ...FormModuleData };
                changedField["pname"] = data.name;
                changedField["pkey"] = data.key;
                changedField["pid"] = data._id;
                setFormData({ ...changedField });
                setIconImage(data.icon)
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action, data) => {
        if (emptyField(FormModuleData.pname)) {
            currentFormErrors.pname = "Module Name is required";
        }
        if (emptyField(FormModuleData.pkey)) {
            currentFormErrors.pkey = "Module Key is required";
        }
        if (!iconImage) {
            currentFormErrors.imageSrc = "Image is required";
        }
        setFormErrors(currentFormErrors);
        if ((Object.keys(currentFormErrors).length === 0 && (action === 'add' || action === 'update')) || action === 'delete') {
            switch (action) {
                case 'add':
                    let formData = new FormData();
                    formData.append("name", FormModuleData.pname);
                    formData.append("key", FormModuleData.pkey);
                    formData.append("icon", iconImage);
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateModule(formData, FormModuleData.pkey, moduleAccesses, 'add')).then(() => {
                        LoadApiCalls(search, 1, pageSize)
                        modalClickAction('close');
                        setShowBtnLoader('')
                    }).catch(() => setShowBtnLoader(''));
                    break;
                case 'delete':
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteModule(FormModuleData.pkey, moduleAccesses)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('')
                    }).catch(() => setShowBtnLoader(''));
                    break;
                case 'update':
                    const updateFormData = new FormData();
                    updateFormData.append("key", FormModuleData.pkey);
                    updateFormData.append("name", FormModuleData.pname);
                    updateFormData.append("icon", iconImage);
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateModule(updateFormData, FormModuleData.pkey, moduleAccesses, 'update')).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('')
                    }).catch(() => setShowBtnLoader(''));
                    break;
                default:
                    throw new Error('Modal action not match');
            }
        }
    };

    const [search, setSearch] = useState('');

    const LoadApiCalls = (search, page, pageSize) => {
        let pagination = { "pageNo": page, "pageSize": pageSize }
        if (search === "") setLoading(true)
        dispatch(fetchModuleAccessList(pagination, search)).then((res) => {
            removeMainLoaderClass();
            if (search === "") setLoading(false)
        }).catch(() => {
            removeMainLoaderClass();
            if (search === "") setLoading(false)
        })
    }

    useEffect(() => {
        LoadApiCalls(search, page, pageSize)
        //eslint-disable-next-line
    }, [search, page, pageSize]);

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: moduleAccesses.data.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Module Access" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table moduleAccesses">
                            <ModuleAccessList
                                moduleAccesses={moduleAccesses}
                                modalClickAction={modalClickAction}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={FormModuleData.pname}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            <AddModuleAccessModal
                showBtnLoader={showBtnLoader}
                FormModuleData={FormModuleData}
                setFormData={setFormData}
                FormErrors={FormErrors}
                modalAction={modalAction}
                modalTitle={modalTitle}
                modal={modal}
                modalClickAction={modalClickAction}
                dispatchAction={dispatchAction}
                modalButton2={modalButton2}
                iconImage={iconImage}
                setIconImage={setIconImage}
            />
        </>
    );
};