/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { closeModal, openModal } from "../../actions/modal";
import { addPricingPlanName, deletePricingPlanName, editPricingPlanName, listPricingPlanName } from "../../actions/pricingPlan";
import { emptyField, removeMainLoaderClass } from "../../common";
import Button from "../../Components/Button";
import FormInput from "../../Components/FormInput";
import Modal from "../../Components/Modal";
import TableTitle from "../../Components/TableTitle";
import { NumericFormat } from 'react-number-format';
import DiseaseModals from "../Diseases/DiseaseModals";
import AddOnsList from "./AddOnsList";

export default function AddOns() {

    const { modal } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    //modal states
    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton2, setModalButton2] = useState('');

    //plan states
    const [planId, setPlanId] = useState('');
    const [planName, setPlanName] = useState('');
    const [planNameError, setPlanNameError] = useState('');

    //button loader
    const [showBtnLoader, setShowBtnLoader] = useState('');

    //type is add-ons
    const [addType, setAddType] = useState('');
    const [typeErr, setTypeErr] = useState('');
    const [price, setPrice] = useState('');
    const [priceErr, setPriceErr] = useState('');
    const [value, setValue] = useState('');
    const [valueErr, setValueErr] = useState('');
    //type is add-ons
    const ADD_ONS = [
        { value: "EXTRA_GBS", label: "Extra GBs - Azure" },
        { value: "EXTRA_SMS", label: "SMS" }
    ]

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton2('');
    };

    const resetForm = () => {
        setPlanName('');
        setPlanNameError('');
        setPlanId('');
        //type is add-ons
        setAddType('');
        setTypeErr('');
        setPrice('');
        setPriceErr('');
        setValue('');
        setValueErr('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            //type is add-ons
            case 'addOns':
                setModalTitle('Add Add-on');
                setModalButton2('Add');
                break;
            case 'viewAddOns':
            case 'updateAddOns':
                setModalTitle(action === 'updateAddOns' ? 'Edit Add-on' : 'View Add-on');
                setModalButton2('Edit');
                setPlanId(data._id);
                setPlanName(data.planName);
                setAddType(data.planDetail.type);
                setValue(data.planDetail.value);
                setPrice(data.charges);
                break;
            case 'delete':
                setPlanId(data._id);
                setPlanName(data.planName);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        if (["addOns", "updateAddOns"].includes(action)) {
            if (emptyField(planName)) {
                setPlanNameError('Pricing Plan Name is required.')
            }
            if (emptyField(addType)) {
                setTypeErr('Type is required.')
            }
            if (emptyField(value)) {
                setValueErr('Value is required.')
            }
            if (emptyField(price)) {
                setPriceErr('Price is required.')
            }
        }
        let addOns = {
            planName: planName,
            planDetail: { value: value, type: addType, unit: addType === "EXTRA_GBS" ? "GB" : "SMS" },
            planType: "ADD_ON",
            planSize: "monthly",
            charges: parseInt(price)
        }
        switch (action) {
            //type is add-ons
            case 'addOns':
                if (!emptyField(planName) && !emptyField(price) && !emptyField(value) && !emptyField(addType)) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(addPricingPlanName(addOns)).then(() => {
                        setShowBtnLoader('');
                        modalClickAction('close');
                        listPricingList();
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            //type is add-ons
            case 'updateAddOns':
                if (planId && !emptyField(planName) && !emptyField(price) && !emptyField(value) && !emptyField(addType)) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(editPricingPlanName(addOns, planId)).then(() => {
                        modalClickAction('close');
                        listPricingList();
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            case 'delete':
                if (planId) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(deletePricingPlanName(planId)).then(() => {
                        setShowBtnLoader('');
                        modalClickAction('close');
                        listPricingList();
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            default:
                throw new Error('Modal action not match');
        }
    };

    const [list, setList] = useState({});

    //list of pricing plan api
    const listPricingList = () => {
        setLoading(true);
        dispatch(listPricingPlanName({ planType: "ADD_ON" })).then((res) => {
            setLoading(false);
            setList({ ...res });
            removeMainLoaderClass();
        }).catch(() => {
            removeMainLoaderClass();
        })
    }

    const getLabelFromValue = (value) => {
        return ADD_ONS.find((v) => v.value === value).label;
    }

    //listing api
    useEffect(() => {
        listPricingList();
    }, [])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: list?.records?.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Add-on List" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'addOns')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table fullheight-table pricing-plans">
                            <AddOnsList
                                records={list.records}
                                modalClickAction={modalClickAction}
                                getLabelFromValue={getLabelFromValue}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={planName}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            <div className="AddON-New">
                {
                    (['addOns', 'updateAddOns', 'viewAddOns']).includes(modalAction) &&
                    <Modal
                        ModalTitle={modalTitle}
                        onClose={() => modalClickAction('close')}
                        Modalclass={((modal) && (['addOns', 'updateAddOns', 'viewAddOns']).includes(modalAction)) ? 'show-modal' : ''}
                        type="center"
                    >
                        <>
                            <div className="formField">
                                <label><div className="feildRequired">Add-on Type<span className="actionField errorBox">*</span></div></label>
                                <Select
                                    isSearchable={false}
                                    isClearable={false}
                                    required="required"
                                    classNamePrefix="react-select"
                                    options={ADD_ONS}
                                    placeholder="Select value"
                                    isDisabled={modalAction === 'viewAddOns' ? true : false}
                                    onChange={(e) => { setAddType(e.value); setTypeErr(''); }}
                                    value={ADD_ONS.filter((option) => addType === option.value)}
                                />
                                {typeErr && <div className="actionField errorBox">{typeErr}</div>}
                            </div>
                            <FormInput
                                inputType="text"
                                placeholder="Enter your name"
                                title="Add-on Name"
                                name="addOnName"
                                required="required"
                                onChange={(e) => { setPlanName(e.target.value); setPlanNameError('') }}
                                value={planName}
                                isReadonly={modalAction === 'viewAddOns' ? true : false}
                                errorMessage={planNameError}
                            />
                            <div className="contactformField">
                                <div className="ex-label">
                                    <label><div className="feildRequired">{addType === "EXTRA_GBS" ? "Extra GBs" : "Extra SMS"}<span className="errorBox">*</span></div></label>
                                </div>
                                <div className="usernumber formField">
                                    <NumericFormat
                                        name="value"
                                        id="value"
                                        placeholder="Enter value"
                                        value={value}
                                        readOnly={modalAction === 'viewAddOns' ? true : false}
                                        onChange={(e) => { setValue(e.target.value); setValueErr('') }}
                                    />
                                    {valueErr && <div className="actionField errorBox">{valueErr}</div>}
                                </div>
                            </div>
                            <div className="contactformField">
                                <div className="ex-label">
                                    <label><div className="feildRequired">Charges(₹)<span className="errorBox">*</span></div></label>
                                </div>
                                <div className="usernumber formField">
                                    <NumericFormat
                                        name="price"
                                        id="price"
                                        placeholder="Enter price"
                                        value={price}
                                        readOnly={modalAction === 'viewAddOns' ? true : false}
                                        onChange={(e) => { setPrice(e.target.value); setPriceErr('') }}
                                    />
                                    {priceErr && <div className="actionField errorBox">{priceErr}</div>}
                                </div>
                            </div>
                            {
                                modalAction !== "viewAddOns" &&
                                <div className="activeButton right">
                                    <Button
                                        title="Reset"
                                        buttonType="reset"
                                        onClick={() => resetForm()}
                                    />
                                    <Button
                                        loaderClass={['addOns', 'updateAddOns'].includes(modalAction) ? showBtnLoader : ""}
                                        title={modalButton2}
                                        onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                                    />
                                </div>
                            }
                        </>
                    </Modal>
                }
            </div>
        </>
    )
}