import { useState } from "react";
import LabTests from "./LabTests";
import Panel from "./Panel";
import Templates from "./Templates";

export default function LaboratoryTests(props) {

    const [activeSubMenu, setActiveSubMenu] = useState('inapporval');

    const renderComponentSubMenu = (activeSubMenu) => {
        switch (activeSubMenu) {
            case "inapporval":
                return <Templates activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            case "approved":
                return <Templates activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            case "rejected":
                return <Templates activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            // case "merged":
            //     return <Templates activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            // // case "panel":
            //     return <Panel activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            // case "templates":
            //     return <Templates activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            default:
                break;
        }
    }

    return (
        <>
            {renderComponentSubMenu(activeSubMenu)}
        </>
    )
}