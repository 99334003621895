import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { CANVAS_ICON_LIST } from "./types";

//list of canvas  icon
export const listCanvasIcon = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.canvasIcon.list}`,
            data: data
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: CANVAS_ICON_LIST,
                payload: {
                    count: response.data.count,
                    data: response.data.data,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//add canvas  icon
export const addCanvasIcon = (data, canvasIcon) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.canvasIcon.change}`,
            data: data,
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...canvasIcon.data];
            let temp = mainArray.some(v => v._id === response.data._id);
            if (!temp) {
                mainArray.push(response.data);
            } else {
                let index = mainArray.findIndex(v => v._id === response.data._id);
                mainArray[index] = response.data
            }
            dispatch({
                type: CANVAS_ICON_LIST,
                payload: {
                    count: canvasIcon.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete canvas icon
export const deleteCanvasIcon = (id, canvasIcon) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.canvasIcon.delete}/${id}`,
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...canvasIcon.data]
            let index = mainArray?.findIndex(v => v._id === response.data._id);
            if (index >= 0) {
                mainArray.splice(index, 1);
            }
            dispatch({
                type: CANVAS_ICON_LIST,
                payload: {
                    count: canvasIcon?.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}