import { Skeleton, notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { closeModal, openModal } from "../../actions/modal";
import { addPricingPlanName, deletePricingPlanName, editPricingPlanName, listPricingPlanName } from "../../actions/pricingPlan";
import { emptyField, removeMainLoaderClass } from "../../common";
import Button from "../../Components/Button";
import Formcheckbox from "../../Components/Formcheckbox";
import FormInput from "../../Components/FormInput";
import Modal from "../../Components/Modal";
import TableTitle from "../../Components/TableTitle";
import { NumericFormat } from 'react-number-format';
import PricingPlanList from "./PricingPlanList";
import DiseaseModals from "../Diseases/DiseaseModals";
import FormRadio from "../../Components/FormRadio";
import deleteicon from "../../assets/images/delete.svg";
import addicon from "../../assets/images/add-icon.svg";
import './style.scss';

export default function PrincingPlans() {

    const { modal } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    //if field type is select then show this options
    const VITALS_OPTIONS = [
        { value: "DEFAULT", label: "Default" },
        { value: "ALL", label: "All" },
    ]
    //if field type is select then show this options
    const PLAN_SIZE_OPTIONS = [
        { value: "monthly", label: "Monthly" },
        { value: "yearly", label: "Yearly" },
        { value: "forever", label: "Forever" },
    ]

    //state save as planDetails
    const [princingPlan, setPricingPlan] = useState([
        {
            key: "HOSPITAL_SERVER_SIZE",
            value: "",
            label: "Hospital Server Size",
            unit: "MB",
        },
        {
            key: "NO_OF_APPOINTMENTS",
            value: "",
            label: "No of Appoitments",
            unit: "month",
        },
        {
            key: "MANAGE_VITALS",
            value: "",
            label: "Manage Vitals",
        },
        {
            key: "MANAGE_PRESCRIPTION",
            value: "",
            label: "Manage Prescription",
        },
        {
            key: "EXPORT_FINANCE",
            value: false,
            label: "Export Finance Data",
        },
        {
            key: "SMS_NOTIFICATIONS",
            value: "",
            label: "SMS Notifications",
            unit: "month",
        },
        {
            key: "EMAIL_NOTIFICATIONS",
            value: "",
            label: "Email Notifications",
            unit: "month",
        },
    ]);

    //radio button state to define unlimited also
    const [isRadio, setIsRadio] = useState({
        "HOSPITAL_SERVER_SIZE": "CUSTOMISE",
        "NO_OF_APPOINTMENTS": "CUSTOMISE",
        "SMS_NOTIFICATIONS": "CUSTOMISE",
        "EMAIL_NOTIFICATIONS": "CUSTOMISE",
    })

    //default array which showing in plan
    const PRINCING_PLAN = [
        {
            name: "HOSPITAL_SERVER_SIZE", label: "Hospital Server Size", field: "number", unit: "MB", value: princingPlan.find((v) => v.key === "HOSPITAL_SERVER_SIZE").value,
            radioValue: isRadio.HOSPITAL_SERVER_SIZE, value1: "CUSTOMISE", value2: "UNLIMITED", radioId1: "HSS1", radioId2: "HSS2"
        },
        {
            name: "APPOINTMENTS", label: "Appointments", isSub: true,
            subModuleName: [
                {
                    name: "NO_OF_APPOINTMENTS", label: "No of Appoitments (per doctor)", module: "APPOINTMENTS", field: "number", unit: "month", value: princingPlan.find((v) => v.key === "NO_OF_APPOINTMENTS").value,
                    radioValue: isRadio.NO_OF_APPOINTMENTS, value1: "CUSTOMISE", value2: "UNLIMITED", radioId1: "NOA1", radioId2: "NOA2"
                },
            ]
        },
        {
            name: "PRESCRIPTIONS", label: "Prescriptions", isSub: true,
            subModuleName: [
                { name: "MANAGE_VITALS", label: "Manage Vitals", module: "PRESCRIPTIONS", field: "select", options: VITALS_OPTIONS, value: princingPlan.find((v) => v.key === "MANAGE_VITALS").value },
                { name: "MANAGE_PRESCRIPTION", label: "Manage Prescription", module: "PRESCRIPTIONS", field: "select", options: VITALS_OPTIONS, value: princingPlan.find((v) => v.key === "MANAGE_PRESCRIPTION").value },
            ]
        },
        {
            name: "FINANCE", label: "Finance", isSub: true,
            subModuleName: [
                { name: "EXPORT_FINANCE", label: "Export Finance Data", module: "FINANCE", field: "checkbox", value: princingPlan.find((v) => v.key === "EXPORT_FINANCE").value },
            ]
        },
        {
            name: "NOTIFICATIONS", label: "Notifications", isSub: true,
            subModuleName: [
                {
                    name: "SMS_NOTIFICATIONS", label: "SMS Notifications (per hospital)", module: "NOTIFICATIONS", field: "number", unit: "month", value: princingPlan.find((v) => v.key === "SMS_NOTIFICATIONS").value,
                    radioValue: isRadio.SMS_NOTIFICATIONS, value1: "CUSTOMISE", value2: "UNLIMITED", radioId1: "SMSN1", radioId2: "SMSN2"
                },
                {
                    name: "EMAIL_NOTIFICATIONS", label: "Email Notifications (per hospital)", module: "NOTIFICATIONS", field: "number", unit: "month", value: princingPlan.find((v) => v.key === "EMAIL_NOTIFICATIONS").value,
                    radioValue: isRadio.EMAIL_NOTIFICATIONS, value1: "CUSTOMISE", value2: "UNLIMITED", radioId1: "EMAILN1", radioId2: "EMAILN2"
                },
            ]
        },
    ]

    //modal states
    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton2, setModalButton2] = useState('');

    //plan states
    const [planId, setPlanId] = useState('');
    const [planName, setPlanName] = useState('');
    const [planNameError, setPlanNameError] = useState('');
    //type is plan
    //prices state
    const [planSize, setPlanSize] = useState('');
    const [planSizeErr, setPlanSizeErr] = useState('');
    const [price, setPrice] = useState('');

    //show extra label for pricing plans
    const singleLabel = {
        label: ""
    }
    const [extraLabel, setExtraLabel] = useState([singleLabel]);

    //button loader
    const [showBtnLoader, setShowBtnLoader] = useState('');

    //modal open and close this call
    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton2('');
    };

    //all state of forms reseting
    const resetForm = () => {
        setPlanName('');
        setPlanNameError('');
        setPlanId('');
        setPlanSize('');
        setPlanSizeErr('');
        setPrice('');
        setPricingPlan([
            {
                key: "HOSPITAL_SERVER_SIZE",
                value: "",
                label: "Hospital Server Size",
                unit: "MB",
            },
            {
                key: "NO_OF_APPOINTMENTS",
                value: "",
                label: "No of Appoitments",
                unit: "month",
            },
            {
                key: "MANAGE_VITALS",
                value: "",
                label: "Manage Vitals",
            },
            {
                key: "MANAGE_PRESCRIPTION",
                value: "",
                label: "Manage Prescription",
            },
            {
                key: "EXPORT_FINANCE",
                value: false,
                label: "Export Finance Data",
            },
            {
                key: "SMS_NOTIFICATIONS",
                value: "",
                label: "SMS Notifications",
                unit: "month",
            },
            {
                key: "EMAIL_NOTIFICATIONS",
                value: "",
                label: "Email Notifications",
                unit: "month",
            },
        ]);
        setIsRadio({
            "HOSPITAL_SERVER_SIZE": "CUSTOMISE",
            "NO_OF_APPOINTMENTS": "CUSTOMISE",
            "SMS_NOTIFICATIONS": "CUSTOMISE",
            "EMAIL_NOTIFICATIONS": "CUSTOMISE",
        });
        setExtraLabel([singleLabel]);
    };

    //when modal open and close action
    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                setModalTitle('Add Plan');
                setModalButton2('Add');
                break;
            case 'view':
            case 'update':
                setModalTitle(action === 'update' ? 'Edit Plan' : 'View Plan');
                setModalButton2('Edit');
                setPlanId(data._id);
                setPlanName(data.planName);
                setPlanSize(data.planSize);
                setPrice(data.charges);
                let changeField = [...princingPlan]
                let changeLabels = []
                let radioChange = { ...isRadio }
                data.planDetail.map((v) => {
                    let i = changeField.findIndex((a) => a.key === v?.key)
                    if (v?.key !== "EXTRA_LABELS" && i !== -1) {
                        changeField[i]['value'] = v?.value
                        if (`${v?.key}` in radioChange) {
                            if (v?.value === "UNLIMITED") {
                                radioChange[v?.key] = "UNLIMITED"
                            } else {
                                radioChange[v?.key] = "CUSTOMISE"
                            }
                        }
                    } else {
                        changeLabels.push({ label: v.label })
                    }
                });
                if (changeLabels.length === 0) {
                    changeLabels.push(singleLabel);
                }
                setExtraLabel([...changeLabels]);
                setIsRadio({ ...radioChange })
                setPricingPlan([...changeField]);
                break;
            case 'delete':
                setPlanId(data._id);
                setPlanName(data.planName);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    //when dispatch api calls
    const dispatchAction = (action) => {
        //type is plan
        if (["add", "update"].includes(action)) {
            if (emptyField(planName)) {
                setPlanNameError('Pricing Plan Name is required.')
            }
            if (emptyField(planSize)) {
                setPlanSizeErr('Pricing Plan Size is required.')
            }
        }
        let extraPricingPlan = [...princingPlan]
        let extraLabelsArr = extraLabel.filter(v => !emptyField(v.label));
        if (extraLabelsArr.length > 0) {
            extraLabelsArr.forEach((v) => {
                extraPricingPlan.push({
                    key: "EXTRA_LABELS",
                    value: "",
                    label: v.label
                })
            })
        }
        let payload = {
            planName: planName,
            planDetail: extraPricingPlan,
            planSize: planSize,
            charges: parseInt(price),
            planType: "PLAN"
        }
        switch (action) {
            case 'add':
                if (!emptyField(planName) && !emptyField(planSize)) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(addPricingPlanName(payload)).then(() => {
                        setShowBtnLoader('');
                        modalClickAction('close');
                        listPricingList();
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            case 'update':
                if (planId && !emptyField(planName) && !emptyField(planSize)) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(editPricingPlanName(payload, planId)).then(() => {
                        modalClickAction('close');
                        listPricingList();
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            case 'delete':
                if (planId) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(deletePricingPlanName(planId)).then(() => {
                        setShowBtnLoader('');
                        modalClickAction('close');
                        listPricingList();
                    }).catch((err) => {
                        setShowBtnLoader('');
                        if(err?.response?.data.statusCode === 403){
                            notification.error({
                                description: err?.response?.data?.message,
                                message: undefined,
                            });
                        }
                        modalClickAction('close');
                    })
                }
                break;
            default:
                throw new Error('Modal action not match');
        }
    };

    //form change
    const handleOnChange = (e, name, type) => {
        let changedField = [...princingPlan];
        let index = changedField.findIndex(v => v.key === name);
        if (type === "text") {
            changedField[index]['value'] = e.target.value;
        } else if (type === "multiselect") {
            let selectedValue = [];
            e.forEach((v) => {
                selectedValue.push(v.value);
            })
            changedField[index]['value'] = selectedValue
        } else if (type === "select") {
            changedField[index]['value'] = e.value
        } else if (type === "check") {
            changedField[index]['value'] = e.target.checked
        }
        setPricingPlan([...changedField])
    }

    //radio button change
    const handleRadioChange = (e, name, value, otherValue) => {
        let changedField = { ...isRadio }
        changedField[name] = e.target.checked ? value : otherValue
        setIsRadio({ ...changedField })
        let changedPField = [...princingPlan];
        let index = changedPField.findIndex(v => v.key === name);
        if (changedField[name] === "UNLIMITED") {
            changedPField[index]['value'] = "UNLIMITED"
        } else {
            changedPField[index]['value'] = ""
        }
        setPricingPlan([...changedPField])
    }

    //on change event of extra label changes
    const handleLabelChange = (e, i) => {
        let changeLabel = [...extraLabel];
        changeLabel[i]['label'] = e.target.value;
        setExtraLabel([...changeLabel]);
    }

    //add extra label field
    const handleGroupAdd = () => {
        let changeValues = [...extraLabel];
        let tmp = changeValues.some(v => JSON.stringify(v) === JSON.stringify(singleLabel));
        if (!tmp) {
            changeValues.push(singleLabel);
        }
        setExtraLabel([...changeValues]);
    }

    //remove extra label field
    const handleGroupRemove = (i) => {
        let changeLang = [...extraLabel];
        if (changeLang.length > 1) {
            changeLang.splice(i, 1);
            setExtraLabel([...changeLang]);
        } else {
            changeLang = { ...singleLabel };
            setExtraLabel([changeLang]);
        }
    }

    const [list, setList] = useState({});

    //list of pricing plan api
    const listPricingList = () => {
        setLoading(true);
        dispatch(listPricingPlanName({ planType: "PLAN" })).then((res) => {
            setLoading(false);
            setList({ ...res });
            removeMainLoaderClass();
        }).catch(() => {
            removeMainLoaderClass();
        })
    }

    //listing api
    useEffect(() => {
        listPricingList();
    }, [])

    //status change 
    const onChange = (checked, id) => {
        let change = [...list.records];
        let index = change.findIndex((v) => v._id === id);
        change[index]["isActive"] = checked;
        setList({ records: [...change] });
        dispatch(editPricingPlanName({ isActive: checked }, id))
    }

    //show fields dynamic from array
    const moduleWiseShowFields = (item) => {
        return (
            item.field === "number" ?
                <div className="full-width">
                    <div className="contactformField">
                        <div className="ex-label">
                            <label><div className="feildRequired">{item.label}<span className="errorBox">*</span></div></label>
                        </div>
                    </div>
                    <div className="radio-buttons">
                        <FormRadio
                            inputType="radio"
                            id={item.radioId1}
                            name={item.name}
                            title={"Customize"}
                            value={item.value1}
                            isChecked={item.radioValue === "CUSTOMISE"}
                            onChange={(e) => { handleRadioChange(e, item.name, item.value1, item.value2); }}
                        />
                        <FormRadio
                            inputType="radio"
                            id={item.radioId2}
                            name={item.name}
                            title={"Unlimited"}
                            value={item.value2}
                            isChecked={item.radioValue === "UNLIMITED"}
                            onChange={(e) => { handleRadioChange(e, item.name, item.value2, item.value1); }}
                        />
                    </div>
                    {
                        item.radioValue === "CUSTOMISE" &&
                        <div className="contactformField">
                            <div className="usernumber formField">
                                <NumericFormat
                                    name={item.name}
                                    id={item.name}
                                    placeholder="Enter value"
                                    value={item.value}
                                    readOnly={modalAction === 'view' ? true : false}
                                    onChange={(e) => handleOnChange(e, item.name, 'text')}
                                />
                                {item?.unit ? <span>/ {item.unit}</span> : ''}
                            </div>
                        </div>
                    }
                </div>
                :
                item.field === "multiSelect" ?
                    <div className="formField">
                        <label><div className="feildRequired">{item.label}<span className="actionField errorBox">*</span></div></label>
                        <Select
                            isSearchable={false}
                            isClearable={false}
                            isMulti={true}
                            required="required"
                            classNamePrefix="react-select"
                            options={item.options}
                            placeholder="Select value"
                            isDisabled={modalAction === 'view' ? true : false}
                            onChange={(e) => handleOnChange(e, item.name, 'multiselect')}
                            value={item.options.filter((option) => {
                                return (item.value.includes(option.value));
                            })}
                        />
                    </div>
                    :
                    item.field === "select" ?
                        <div className="formField">
                            <label><div className="feildRequired">{item.label}<span className="actionField errorBox">*</span></div></label>
                            <Select
                                isSearchable={false}
                                isClearable={false}
                                required="required"
                                classNamePrefix="react-select"
                                options={item.options}
                                placeholder="Select value"
                                isDisabled={modalAction === 'view' ? true : false}
                                onChange={(e) => handleOnChange(e, item.name, 'select')}
                                value={item.options.filter((option) => item.value === option.value)}
                            />
                        </div>
                        :
                        item.field === "checkbox" ?
                            <Formcheckbox
                                inputType="checkbox"
                                id={item.name}
                                name={item.name}
                                title={item.label}
                                isDisabled={modalAction === 'view' ? true : false}
                                onChange={(e) => handleOnChange(e, item.name, 'check')}
                                isChecked={item.value ? "checked" : ""}
                            />
                            :
                            ""
        )
    }

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: list?.records?.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Pricing Plan List" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table fullheight-table pricing-plans">
                            <PricingPlanList
                                onChange={onChange}
                                records={list.records}
                                modalClickAction={modalClickAction}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={planName}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            <div className="Add-New_Plan medium-modal">
                {
                    (['add', 'update', 'view']).includes(modalAction) &&
                    <Modal
                        ModalTitle={modalTitle}
                        onClose={() => modalClickAction('close')}
                        Modalclass={((modal) && (['add', 'update', 'view']).includes(modalAction)) ? 'show-modal' : ''}
                        type="center"
                    >
                        <>
                            <div className="Price-plan-name-box">
                                <FormInput
                                    inputType="text"
                                    placeholder="Enter your name"
                                    title="Pricing Plan Name"
                                    name="planName"
                                    required="required"
                                    onChange={(e) => { setPlanName(e.target.value); setPlanNameError('') }}
                                    value={planName}
                                    isReadonly={modalAction === 'view' ? true : false}
                                    errorMessage={planNameError}
                                />
                                <div className="contactformField">
                                    <div className="ex-label">
                                        <label><div className="feildRequired">Plan Size<span className="errorBox">*</span></div></label>
                                    </div>
                                    <div className="usernumber formField">
                                        <Select
                                            isSearchable={false}
                                            isClearable={false}
                                            required="required"
                                            classNamePrefix="react-select"
                                            options={PLAN_SIZE_OPTIONS}
                                            placeholder="Select value"
                                            isDisabled={modalAction === 'view' ? true : false}
                                            onChange={(e) => { setPlanSize(e.value); setPlanSizeErr('') }}
                                            value={PLAN_SIZE_OPTIONS.filter((option) => planSize === option.value)}
                                        />
                                        {planSizeErr && <div className="actionField errorBox">{planSizeErr}</div>}
                                    </div>
                                </div>
                                <div className="contactformField">
                                    <div className="ex-label">
                                        <label><div className="feildRequired">Charges (₹)</div></label>
                                    </div>
                                    <div className="usernumber formField">
                                        <NumericFormat
                                            name="price"
                                            id="price"
                                            placeholder="Enter value"
                                            value={price}
                                            readOnly={modalAction === 'view' ? true : false}
                                            onChange={(e) => { setPrice(e.target.value); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                PRINCING_PLAN.map((main, i) => {
                                    return (
                                        <div key={i} className="main-module">
                                            <h5>{main.label}</h5>
                                            <div className="sub-module">
                                                {
                                                    main.isSub ?
                                                        main?.subModuleName?.map((sub, j) => {
                                                            return (
                                                                sub.module === main.name &&
                                                                moduleWiseShowFields(sub)
                                                            )
                                                        })
                                                        :
                                                        main.field && moduleWiseShowFields(main)
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="main-module">
                                <h5>Extra Labels</h5>
                                <div className="sub-module single-field">
                                    {
                                        extraLabel.map((v, i) => {
                                            return (
                                                <div className="extra-field-labels" key={i}>
                                                    <FormInput
                                                        inputType="text"
                                                        placeholder="Enter value"
                                                        name="extraLabel"
                                                        onChange={(e) => { handleLabelChange(e, i) }}
                                                        value={v.label}
                                                        isReadonly={modalAction === 'view' ? true : false}
                                                    />
                                                    {
                                                        modalAction !== 'view' &&
                                                        <div className="action">
                                                            <span className="add-group" onClick={() => handleGroupAdd()}>
                                                                <img alt={addicon} src={addicon} />
                                                            </span>
                                                            <span className="remove-lang" onClick={() => handleGroupRemove(i)}>
                                                                <img alt={deleteicon} src={deleteicon} />
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {
                                modalAction !== 'view' &&
                                <div className="activeButton right">
                                    <Button
                                        title="Reset"
                                        buttonType="reset"
                                        onClick={() => resetForm()}
                                    />
                                    <Button
                                        loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                                        title={modalButton2}
                                        onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                                    />
                                </div>
                            }
                        </>
                    </Modal>
                }
            </div>
        </>
    )
}