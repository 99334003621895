/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Components/Button";
import FormInput from "../../Components/FormInput";
import Modal from "../../Components/Modal";
import TableTitle from "../../Components/TableTitle";
import { addDegree, deleteDegree, listDegree, updateDegree } from "../../actions/degree";
import { closeModal, openModal } from "../../actions/modal";
import { emptyField, removeMainLoaderClass } from "../../common";
import DiseaseModals from "../Diseases/DiseaseModals";
import SpecialtyList from "../Specialty/SpecialtyList";

export default function Degrees(props) {

    const { degree = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);

    //Modal state
    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton, setModalButton] = useState('');

    const [showBtnLoader, setShowBtnLoader] = useState('');

    //Degree state
    const [name, setName] = useState('');
    const [degreeId, setDegreeId] = useState('')
    const [search, setSearch] = useState('')

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    // error state
    let degreeErrors = []
    const [degreeErr, setDegreeErr] = useState(degreeErrors)

    //reset form state
    const resetForm = () => {
        setName('')
        setDegreeId('')
        setDegreeErr('')
    }

    //reset modal state
    const resetModal = () => {
        setModalAction('')
        setModalTitle('')
        setModalButton('')
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case 'add':
                setModalTitle('Add Degrees');
                setModalButton('Add');
                break;
            case 'update':
                setModalTitle('Edit Degree');
                setModalButton('Edit');
                setName(data.name);
                setDegreeId(data._id);
                break;
            case 'delete':
                setName(data.name);
                setDegreeId(data._id);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    useEffect(() => {
        let pagination = { "pageNo": page, "pageSize": pageSize }
        if (search === "") setLoading(true);
        dispatch(listDegree(search, pagination)).then(() => {
            if (search === "") setLoading(false);
            removeMainLoaderClass();
        }).catch(() => {
            if (search === "") setLoading(false);
            removeMainLoaderClass()
        })
    }, [search, page, pageSize])

    const dispatchAction = (action) => {
        if (emptyField(name)) {
            degreeErrors.name = "Degree name is required";
        }
        setDegreeErr(degreeErrors)
        switch (action) {
            case 'add':
                if (Object.keys(degreeErrors).length === 0) {
                    let payload = {
                        docs: [{ name: name }]
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(addDegree(payload, degree)).then(() => {
                        setPage(1);
                        modalClickAction('close')
                        setShowBtnLoader('');
                    }).catch(() => setShowBtnLoader(''));
                }
                break;
            case 'update':
                if (Object.keys(degreeErrors).length === 0) {
                    let payload = {
                        name: name,
                        id: degreeId
                    }
                    if (degreeId !== "") {
                        setShowBtnLoader('show-button-loader');
                        dispatch(updateDegree(payload, degree)).then(() => {
                            modalClickAction('close')
                            setShowBtnLoader('')
                            modalClickAction('close');
                        }).catch(() => setShowBtnLoader(''));
                    }
                }
                break;
            case 'delete':
                if (degreeId !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteDegree(degreeId, degree)).then(() => { modalClickAction('close'); setShowBtnLoader('') }).catch(() => setShowBtnLoader(''));
                }
                break;
            default:
                throw new Error('Modal action not match');
        }
    }

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: degree.data.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Degree List" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="table degrees">
                            <SpecialtyList
                                specialty={degree}
                                modalClickAction={modalClickAction}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            {/* Add and update modal */}
            {(modalAction === "add" || modalAction === "update") &&
                <Modal
                    ModalTitle={modalTitle}
                    type="center"
                    onClose={() => modalClickAction('close')}
                    Modalclass={(modal && (modalAction === "add" || modalAction === "update")) ? 'show-modal' : ''}
                >
                    <div>
                        <FormInput
                            title="Degree Name"
                            name="addDegree"
                            id="addDegree"
                            value={name}
                            onChange={(e) => { setName(e.target.value); setDegreeErr('') }}
                            required="required"
                            placeholder="Type new degree"
                        />
                        {degreeErr.name && <div className="actionField errorBox">{degreeErr.name}</div>}
                        <div className="activeButton right">
                            <Button
                                title="Cancel"
                                buttonType="reset"
                                onClick={() => modalClickAction('close')}
                            />
                            <Button
                                loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                                title={modalButton}
                                onClick={(e) => { e.preventDefault(); dispatchAction(modalAction) }}
                            />
                        </div>
                    </div>
                </Modal>
            }
        </>
    )

}