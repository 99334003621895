import {
    APPOINTMENT_ID_PREFIX_N_SUFFIX
} from "../actions/types";

const initialState = {
    appointmentId: [],
    appointmentToken: []
};

export default function aptPrefixSuffix(state = initialState, action) {
    switch (action.type) {
        case APPOINTMENT_ID_PREFIX_N_SUFFIX:
            return {
                ...state,
                appointmentId: action.payload.appointmentId
            };
        default:
            return state;
    }
};