import { Button, Modal } from 'antd'
import React from 'react'
import { useRef } from 'react'
import ReactToPrint from 'react-to-print'

const ViewErrorLogModal = ({state, setState, errordetails, dispatchAction}) => {
  const printLabelRef = useRef(null)
  const modalCloseAction =()=>{
    setState(false)
    dispatchAction("visited", "visited", errordetails, true)
  }

  return (
    <div className='error-modal'>
    <Modal 
      type="center"
      className="error-log-modal"
      open={state}
      onOk={() => modalCloseAction()}
      onCancel={() => modalCloseAction()}
      title="Error Log"
      centered={true}
      width={950}
      Modalclass='show-modal'
      footer={[
        <div key="error-log-label-modal" className="modal-footer-button">
            <div className="modal-left-right-btn">
                    <Button title="Cancel" onClick={()=> modalCloseAction()} >Cancel</Button>
                    <ReactToPrint
                        trigger={() => {
                            // document.title = "Error Log"
                           
                            return (
                                    <Button
                                        type="primary"
                                        title="Print"
                                    >Print</Button>
                                
                            )
                        }}
                        content={() => printLabelRef.current}
                    />
            </div>
        </div>
    ]}
    >
          <div className='modal-details' ref={printLabelRef}>
            <div className='error-name'>
              <strong>By:</strong> {errordetails?.name}
            </div>
            <div className='error-time'>
              <strong>At:</strong> {errordetails?.time}
            </div>
            {/* <div>
              <strong>Error Message:</strong> {errordetails?.message}
            </div> */}
            <div style={{display: "flex"}} className='error-message'>
              <div>
                  <strong>Error Message:</strong>
              </div> 
              <div style={{paddingLeft: "10px"}}>
                {errordetails?.data?.message?.stack?.split(" at").map((data, i)=>{
                   if (i === 0) {
                    return <div key={i}>{data}</div>;
                  }
                  return(
                    <div key={i}>at {data}</div>
                  )
                  })
                }
              </div>
            </div>

          </div>

    </Modal>

    </div>
  )
}

export default ViewErrorLogModal