import { FILE_CATEGORY_LIST } from "../actions/types";

const initialState = {};

export default function payments(state = initialState, action) {

    switch (action.type) {
        case FILE_CATEGORY_LIST:
            return action.payload;
        default:
            return state;
    }
};