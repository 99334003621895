import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { DISEASES_LIST } from "./types";

//Disease list
export const fetchDiseaseList = (filter, pagination, extraData = {}, isType) => async (dispatch) => {
    let body = { filter, pagination, extraData }
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            data: body,
            url: `${API_URL.disease.list}`
        }).then((response) => {
            resolve(response.data)
            if (!isType) {
                dispatch({
                    type: DISEASES_LIST,
                    payload: {
                        count: response.data.approvalCount,
                        data: response.data.data,
                    }
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

//add disease 
export const addDisease = (docs, diseases) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.disease.add}`,
            data: docs
        }).then((response) => {
            resolve(response.data);
            let obj = response.data[0];
            let mainArray = [...diseases.data];
            let tmp = mainArray.some(v => v._id === obj._id);
            if (!tmp) {
                mainArray.push(obj);
            }
            dispatch({
                type: DISEASES_LIST,
                payload: {
                    count: diseases.count + 1,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update disease
export const updateDisease = (docs, diseases) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.disease.update}`,
            data: docs
        }).then((response) => {
            resolve(response.data);
            let obj = response.data;
            let mainArray = [...diseases.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: DISEASES_LIST,
                payload: {
                    count: diseases.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//approve and reject disease
export const approveDisease = (docs, diseases) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.disease.approve}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let obj = response.data[0];
            let mainArray = [...diseases.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: DISEASES_LIST,
                payload: {
                    count: diseases.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//merege disease
export const mergeDisease = (docs, diseases) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.disease.merge}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let obj = response.data;
            let mainArray = [...diseases.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: DISEASES_LIST,
                payload: {
                    count: diseases.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete disease
export const deleteDisease = (docs, diseases, diseaseId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.disease.delete}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...diseases.data]
            if (Array.isArray(diseaseId)) {
                diseaseId.map((item) => {
                    let index = mainArray.findIndex(v => v._id === item);
                    mainArray.splice(index, 1);
                })
            } else {
                let index = mainArray.findIndex(v => v._id === diseaseId);
                mainArray.splice(index, 1);
            }
            dispatch({
                type: DISEASES_LIST,
                payload: {
                    count: diseases.count - (Array.isArray(diseaseId) ? diseaseId.length : 1),
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}