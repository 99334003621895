import { Pagination, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { S3_BASE_URL } from "../../../common";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';

export default function ModuleAccessList({ moduleAccesses, modalClickAction, page, pageSize, setPage, setPageSize }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Key", dataIndex: "key", fixed: "left", sorter: (a, b) => a.key.localeCompare(b.key) },
        { title: "Name", dataIndex: "name", sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: "Type", dataIndex: "type" , sorter: (a, b) => a.type.localeCompare(b.type)},
        {
            title: "Icon", dataIndex: "icon", render: (row) => {
                return (
                    <span className="access-icon">
                        <img src={S3_BASE_URL + row} alt="moduleAccess" />
                    </span>
                )
            }
        },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (moduleAccesses?.data?.length > 0) {
            moduleAccesses?.data.map((v, i) => {
                let obj = {
                    no: i + 1,
                    key: v.key,
                    name: v.name,
                    type: v.type,
                    icon: v.icon,
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [moduleAccesses])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
            <Pagination
                total={moduleAccesses.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={pageSize}
                current={page}
                onChange={(page, pageSize) => { setPage(page); setPageSize(pageSize); }}
            />
        </>
    )
}