import React, { useEffect } from "react";
import FormRadio from "../../Components/FormRadio";
import Button from "../../Components/Button";
import InfoIcon from "../../assets/images/info-icon.svg";
import { Tooltip } from "antd";
import { removeMainLoaderClass } from "../../common";

export default function Maintenance(props) {

    useEffect(() => {
        removeMainLoaderClass()
    }, [])

    return (
        <>
            <div className="tab-white-bg">
                <div className="departmentsSettings">
                    <div className="departmentsoption">
                        <p>Maintenance Mode
                            <Tooltip title="Maintenance Mode">
                                <span className="info-icon">
                                    <img src={InfoIcon} alt={InfoIcon} />
                                </span>
                            </Tooltip>
                        </p>
                        <div>
                            <FormRadio
                                inputType="radio"
                                id="refDept"
                                name="departmentsSettings"
                                title="ON"
                            // value="DEPARTMENT"
                            // isChecked={departmentRefernce === "DEPARTMENT" && true}
                            // onChange={() => setDepartmentRefernce("DEPARTMENT")}
                            />
                            <FormRadio
                                inputType="radio"
                                id="refdoctor"
                                name="departmentsSettings"
                                title="OFF"
                            // value="DOCTOR"
                            // isChecked={departmentRefernce === "DOCTOR" && true}
                            // onChange={() => setDepartmentRefernce("DOCTOR")}
                            />
                        </div>
                    </div>
                    <div className="action-field right">
                        <Button
                            // loaderClass={props.isKey === false && showBtnLoader ? showBtnLoader : ""}
                            title="Update"
                        // onClick={() => { dispatchAction('departmentRefernce') }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
