import Reject from '../../assets/images/reject.svg';
import Edit from '../../assets/images/Edit.svg';
import EnableLogin from '../../assets/images/enable-login.svg';
import DisableLogin from '../../assets/images/disabled-login.svg';
import Delete from '../../assets/images/delete.svg';
import Approve from '../../assets/images/approve.svg';
import Setting from '../../assets/images/Settings.svg';
import Merge from '../../assets/images/merge-horizontal.svg';
import Mail from '../../assets/images/Email.svg';
import { Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { removeMainLoaderClass } from '../../common';

export const CommonActionIconWithTooltipInANTDTable = (row, modalClickAction, isDataMx, isReception=false, isDoctor= false,  isMerge = true) => {
    return (
        <div className="action">
            {
                (row.data.isApproved === "0" || row.data.isApproved === "1") && isDataMx && isMerge &&
                <Tooltip title="Merge">
                    <span className="merge" onClick={() => modalClickAction('open', 'merge', row.data)}>
                        <img src={Merge} alt={Merge} />
                    </span>
                </Tooltip>
            }
            {
                (row.data.isApproved === "0") &&
                <Tooltip title="Approve">
                    <span className="approve" onClick={() => modalClickAction('open', 'approve', row.data)}>
                        <img src={Approve} alt={Approve} />
                    </span>
                </Tooltip>
            }
            {
                (row.data.isApproved === "0") &&
                <Tooltip title="Reject">
                    <span className="reject" onClick={() => modalClickAction('open', 'reject', row.data)}>
                        <img src={Reject} alt={Reject} />
                    </span>
                </Tooltip>
            }
            <Tooltip title="Edit">
                <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                    <img src={Edit} alt={Edit} />
                </span>
            </Tooltip>
            {
                !isDataMx && row.data.isApproved === "1" && row.data.isActive &&
                <Tooltip title="Disable Login">
                    <span className="disable" onClick={() => modalClickAction('open', 'disable', row.data)}>
                        <img src={DisableLogin} alt={DisableLogin} />
                    </span>
                </Tooltip>
            }
            {
                !isDataMx && row.data.isApproved === "1" && !row.data.isActive &&
                <Tooltip title="Enable Login">
                    <span className="enable" onClick={() => modalClickAction('open', 'enable', row.data)}>
                        <img src={EnableLogin} alt={EnableLogin} />
                    </span>
                </Tooltip>
            }
            {
                row.data.isApproved !== "1" &&
                <Tooltip title="Delete">
                    <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                        <img src={Delete} alt={Delete} />
                    </span>
                </Tooltip>
            }
            {
                row.data.isApproved === "-1" &&!isReception && !isDoctor &&
                <Tooltip title="Undo Reject">
                    <span className="delete" onClick={() => modalClickAction('open', 'undoReject', row.data)}>
                        Undo
                    </span>
                </Tooltip>
            }
            {
                !isDataMx && !isReception &&
                <Tooltip title="Go to setting">
                    <span className="setting" onClick={() => modalClickAction('open', 'goToSettings', row.data)}>
                        <img src={Setting} alt={Setting} />
                    </span>
                </Tooltip>
            }
            {
                // (row.data.isApproved === "0") && isDataMx &&
                !isDataMx && !row.data.isHospital && row.data.isApproved === "1" && !row.data.isPasswordSet &&
                <Tooltip title="Email Change">
                    <span className="changeemail" onClick={() => modalClickAction('open', 'changeEmail', row.data)}>
                        <img src={Mail} alt={Mail} />
                    </span>
                </Tooltip>
            }
        </div>
    )
}

export const ListApisForDataMx = async(activeSubMenu, page, pageSize, search, api, setSelectedRowKeys, dispatch, setLoading) => {
    let filter = {};
    let pagination = {};
    let extraData = {};
    switch (activeSubMenu) {
        case "inapporval":
            filter = { "isApproved": "0", "isDeleted": false };
            pagination = { "pageNo": page, "pageSize": pageSize };
            extraData = { "search": search }
            break;
        case "approved":
            filter = { "isApproved": "1", "isDeleted": false };
            pagination = { "pageNo": page, "pageSize": pageSize };
            extraData = { "search": search }
            break;
        case "merged":
            filter = { "isDeleted": false };
            pagination = { "pageNo": page, "pageSize": pageSize };
            extraData = { "search": search, type: "merge" }
            break;
        case "rejected":
            filter = { "isApproved": "-1", "isDeleted": false };
            pagination = { "pageNo": page, "pageSize": pageSize };
            extraData = { "search": search }
            break;
        default:
            break;
    }
    if (search === "") setLoading(true);
    if(search.length >= 1){
        pagination.pageNo = 1
    }
    await dispatch(api(filter, pagination, extraData)).then(() => {
        if (search === "") setLoading(false);
        setSelectedRowKeys([]);
        removeMainLoaderClass();
    }).catch(() => {
        if (search === "") setLoading(false);
        removeMainLoaderClass();
    })
}

export const ExtraSubMenu = [
    { label: "In Approval", value: "inapporval" },
    { label: "Approved", value: "approved" },
    { label: "Merged", value: "merged" },
    { label: "Rejected", value: "rejected" },
]

export const ExtraSubMenuLabTest = [
    { label: "In Approval", value: "inapporval" },
    { label: "Approved", value: "approved" },
    // { label: "Merged", value: "merged" },
    { label: "Rejected", value: "rejected" },
    // { label: "Panel", value: "panel" },
    // { label: "Templates", value: "templates" },
]

export const ExtraSubMenuNotifications = [
    { label: "Email", value: "EMAIL" },
    { label: "SMS", value: "SMS" },
    { label: "WhatsApp", value: "WHATSUP" }
]