import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { Skeleton } from "antd";
import CanvasIconList from "./CanvasIconList";
import { closeModal, openModal } from "../../actions/modal";
import Button from "../../Components/Button";
import TableTitle from "../../Components/TableTitle";
import FormInput from "../../Components/FormInput";
import { listCanvasIcon, addCanvasIcon, deleteCanvasIcon } from "../../actions/canvasIcon";
import DiseaseModals from "../Diseases/DiseaseModals";
import Modal from "../../Components/Modal";
import FormUpload from "../../Components/FormUpload";
import { removeMainLoaderClass } from "../../common";

export default function CanvasIcon(props) {

    const { canvasIcon = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton, setModalButton] = useState('');
    const [iconName, setIconName] = useState([]);
    const [iconImage, setIconImage] = useState('');
    const [iconId, setIconId] = useState('');
    const [iconNameErr, setIconNameErr] = useState('');
    const [iconImageErr, setIconImageErr] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    const resetForm = () => {
        setIconName('');
        setIconImage('');
        setIconId('')
    }

    const resetModal = () => {
        setModalButton('');
        setModalTitle('');
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case 'add':
                setModalTitle('Add Canvas Icon');
                setModalButton('Add');
                break;
            case 'update':
                setModalTitle('Edit Canvas Icon');
                setModalButton('Edit');
                setIconName(data.name);
                setIconImage(data.iconImage);
                setIconId(data._id);
                break;
            case 'delete':
                setIconName(data.name);
                setIconId(data._id);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        switch (action) {
            case 'add':
                let add = new FormData();
                add.append("name", iconName);
                add.append("iconImage", iconImage);
                if (iconName !== "" && iconImage !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(addCanvasIcon(add, canvasIcon)).then(() => {
                        LoadApiCalls(search, 1, pageSize)
                        modalClickAction('close');
                        setShowBtnLoader('')
                    }).catch(() => setShowBtnLoader(''));
                } else {
                    if (iconName === "") {
                        setIconNameErr('Icon Name is required')
                    }
                    if (iconImage === "") {
                        setIconImageErr('Icon Image is required')
                    }
                }
                break;
            case 'update':
                let update = new FormData();
                update.append("name", iconName);
                update.append("iconImage", iconImage);
                update.append("iconId", iconId);
                if (iconName !== "" && iconId !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(addCanvasIcon(update, canvasIcon)).then(() => { modalClickAction('close'); setShowBtnLoader('') }).catch(() => setShowBtnLoader(''));
                } else {
                    setIconNameErr('Icon Name is required')
                }
                break;
            case 'delete':
                if (iconId !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteCanvasIcon(iconId, canvasIcon)).then(() => { modalClickAction('close'); setShowBtnLoader('') }).catch(() => setShowBtnLoader(''));
                }
                break;
            default:
                throw new Error('Modal action not match');
        }
    }

    const [search, setSearch] = useState('');

    const LoadApiCalls = (search, page, pageSize) => {
        let pagination = {
            "pagination": { "pageNo": page, "pageSize": pageSize },
            "search": search
        }
        if (search === "") setLoading(true);
        dispatch(listCanvasIcon(pagination)).then(() => {
            if (search === "") {
                setLoading(false);
                removeMainLoaderClass();
            }
        }).catch(() => {
            if (search === "") {
                setLoading(false);
                removeMainLoaderClass();
            }
        })
    }

    useEffect(() => {
        LoadApiCalls(search, page, pageSize)
        //eslint-disable-next-line
    }, [search, page, pageSize])

    useEffect(() => {
        if (iconImage !== "") {
            setIconImageErr('')
        }
        //eslint-disable-next-line
    }, [])

    const iconList = [
        { label: "Line", value: "line" },
        { label: "Pen", value: "pen" },
        { label: "Rect", value: "rect" },
        { label: "Circle", value: "circle" },
        { label: "Eraser", value: "eraser" },
        { label: "AddImgBG", value: "addImgBG" },
        { label: "RecSelect", value: "recSelect" },
        { label: "MobilerecSelect", value: "mobilerecSelect" },
        { label: "EraseRec", value: "eraseRec" },
        { label: "AddTextBox", value: "addTextBox" },
        { label: "SetTextboxText", value: "setTextboxText" },
        { label: "RemoveTextbox", value: "removeTextbox" },
        { label: "SetTextboxPosition", value: "setTextboxPosition" },
        { label: "SetTextboxFontSize", value: "setTextboxFontSize" },
        { label: "SetTextboxFontColor", value: "setTextboxFontColor" },
        { label: "SetTextboxBackgroundColor", value: "setTextboxBackgroundColor" }
    ]

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: canvasIcon.data.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Canvas Icon List" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table canvasIcon">
                            <CanvasIconList
                                canvasIcon={canvasIcon}
                                modalClickAction={modalClickAction}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={iconName}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            {(modalAction === "add" || modalAction === "update") &&
                <div className="DocProfilePopup">
                    <Modal
                        ModalTitle={modalTitle}
                        type="center"
                        onClose={() => modalClickAction('close')}
                        Modalclass={(modal && (modalAction === "add" || modalAction === "update")) ? 'show-modal' : ''}
                    >
                        <div>
                            <div className="formField">
                                <label><div className="feildRequired">Icon Name<span className="actionField errorBox">*</span></div></label>
                                <Select
                                    required="required"
                                    classNamePrefix="react-select"
                                    options={iconList}
                                    placeholder="Type Icon Name"
                                    name="iconName"
                                    id="iconName"
                                    onChange={(e) => { setIconName(e.value); setIconNameErr('') }}
                                    value={iconList.filter((option) => {
                                        return option.value === iconName;
                                    })}
                                />
                                {iconNameErr && <div className="actionField errorBox">{iconNameErr}</div>}
                            </div>
                            <div className="formField">
                                <label><div className="feildRequired">Icon Image<span className="actionField errorBox">*</span></div></label>
                                <FormUpload
                                    logo={iconImage}
                                    setLogo={setIconImage}
                                    message="Drag and Drop image here"
                                />
                                {iconImageErr && <div className="actionField errorBox">{iconImageErr}</div>}
                            </div>
                            <div className="activeButton right">
                                <Button
                                    title="Cancel"
                                    buttonType="reset"
                                    onClick={() => modalClickAction('close')}
                                />
                                <Button
                                    loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                                    title={modalButton}
                                    onClick={(e) => { e.preventDefault(); dispatchAction(modalAction) }}
                                />
                            </div>
                        </div>
                    </Modal>
                </div>
            }
        </>
    )
}