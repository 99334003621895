/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { S3_BASE_URL } from "../../common";
import './style.scss';
import uploadicon from "../../assets/images/cloud-computing.png";


const FormUpload = (props) => {
  const [highlight, setHighlight] = React.useState(false);
  const [preview, setPreview] = React.useState("");
  const [drop, setDrop] = React.useState(false);

  useEffect(() => {
    console.log(props.logo,"props.logo", props.type)
    // var filename = props.logo.substring(props.logo.lastIndexOf('/')+1);
    // alert(filename);
    if (props.logo) {
      setHighlight(false);
      setDrop(true);
      setPreview(S3_BASE_URL + props.logo);
      if (props.isSign) {
        props.setOnChangePreview(S3_BASE_URL + props.logo)
      }
    } else {
      setDrop(false);
      setPreview('');
      if (props.isSign) {
        props.setOnChangePreview('')
      }
    }
  }, [props.logo]);

  useEffect(() => {
    if (props.firstName && props.lastName) {
      setHighlight(false);
      setDrop(true);
    }
    //eslint-disable-next-line
  }, [props.firstName, props.lastName])

  const handleEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    preview === "" && setHighlight(true);
  };

  const handleOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    preview === "" && setHighlight(true);
  };

  const handleLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
    setDrop(true);
    const [file] = e?.target?.files?.length > 0 ? e?.target?.files : e?.dataTransfer?.files;
    uploadFile(file);
    props.setLogo(file);
    if (props.isApiCall) {
      props.handleProfileChange(file);
    }
  };

  function uploadFile(file) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      // this is the base64 data
      const fileRes = btoa(reader.result);
      setPreview(`data:image/jpg;base64,${fileRes}`);
      
      // setPreview(`data:image/jpg;base64,${fileRes}`);
      if (props.isSign) {
        props.setOnChangePreview(`data:image/jpg;base64,${fileRes}`)
      }
    };

    reader.onerror = () => {
    };
  }

  return (
    <>
      {props.firstName && props.lastName && preview === "" ?
        <div className="uploadlogo">
          {props.title &&
            <div className="formField">
              <label>{props.title}</label>
            </div>
          }
          <div className="dragDrop">
            <div
              onDragEnter={(e) => handleEnter(e)}
              onDragLeave={(e) => handleLeave(e)}
              onDragOver={(e) => handleOver(e)}
              onDrop={(e) => handleUpload(e)}
              className={`upload${highlight ? " is-highlight" : drop ? " is-drop" : ""
                }`}
            >
              <div className="alter-text">
                {props?.firstName?.charAt(0).toUpperCase() + props?.lastName?.charAt(0).toUpperCase()}
              </div>
              <div className="file-upload">
                <p>{props.message}</p>
                <div className="upload-button">
                  <input
                    type="file"
                    className="upload-file"
                    id="uploadimg"
                    name="hospitalLogo"
                    accept="image/*"
                    onChange={(e) => handleUpload(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="uploadlogo">
          {props.title &&
            <div className="formField">
              <label>{props.title}</label>
            </div>
          }
          <div className="dragDrop">
            <div
              onDragEnter={(e) => handleEnter(e)}
              onDragLeave={(e) => handleLeave(e)}
              onDragOver={(e) => handleOver(e)}
              onDrop={(e) => handleUpload(e)}
              className={`upload${highlight ? " is-highlight" : drop ? " is-drop" : ""
                }`}
              style={{ backgroundImage: `url(${ preview?.replace(/\ /g, "%20")})` }}
            >
              <div className="file-upload">
                <span className="upload-icon">
                  <img src={uploadicon} alt='uploadicon' />
                </span>
                <p>{props.message}</p>
                <div className="upload-button">
                  <input
                    type="file"
                    className="upload-file"
                    id="uploadimg"
                    name="hospitalLogo"
                    accept="image/*"
                    onChange={(e) => handleUpload(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default FormUpload;