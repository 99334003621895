import { Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { closeModal, openModal } from "../../actions/modal";
import { addPastHistory, approvePastHistory, deletePastHistory, mergePastHistory, searchPastHisotryList, updatePastHistory } from "../../actions/pastHistory";
import { debounce, emptyField } from "../../common";
import Button from "../../Components/Button";
import FormInput from "../../Components/FormInput";
import TableTitle from "../../Components/TableTitle";
import { ExtraSubMenu, ListApisForDataMx } from "../Common";
import DiseaseList from "../Diseases/DiseaseList";
import DiseaseModals from "../Diseases/DiseaseModals";

export default function PastHistory(props) {
    const { pastHistory = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [pastHistoryId, setPastHistoryId] = useState("");
    const [mergePastHistoryId, setMergePastHistoryId] = useState("");
    const [pastHistoryArr, setPastHistoryArr] = useState([]);
    const [isCommon, setIsCommon] = useState(false);

    //for skeleton
    const [loading, setLoading] = useState(false);
    //For multiple delete options
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [activeSubMenu, setActiveSubMenu] = useState('inapporval');

    let pastHistoryErrors = [];
    const [pastHistoryData, setPastHistoryData] = useState({
        pastname: "",
        pastcategory: "",
        "pastimage": "",
    });

    const [name, setName] = useState("");
    const [category, setCategory] = useState('');

    const [nameError, setNameError] = useState('');
    const [mergeError, setMergeError] = useState("");
    const [categoryError, setCategoryError] = useState('');

    const [modalAction, setModalAction] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalButton1, setModalButton1] = useState("");
    const [modalButton2, setModalButton2] = useState("");
    const [pastHistorySearch, setPastHistorySearch] = useState("");

    const [showBtnLoader, setShowBtnLoader] = useState('');

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    const resetForm = () => {
        setName("");
        setPastHistoryId("");
        setMergePastHistoryId("");
        setMergeError("");
        setPastHistoryData({
            pastname: "",
            pastcategory: "",
            "pastimage": "",
        });
        setCategory('');
        setNameError('');
        setCategoryError('');
        setIsCommon(false);
    };

    const resetModal = () => {
        setModalAction("");
        setModalTitle("");
        setModalButton1("");
        setModalButton2("");
    };

    const modalClickAction = (type, action = "", data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case "add":
                setModalTitle("Add Past History");
                setModalButton1("Reset");
                setModalButton2("Add");
                break;
            case "update":
                setModalTitle("Edit Past History");
                setModalButton1("Reset");
                setModalButton2("Edit Past History");
                setPastHistoryId(data._id);
                setPastHistoryData({
                    pastname: data.name,
                    pastcategory: data.type,
                    "pastimage": "",
                });
                setName(data.name);
                setCategory(data.type);
                setIsCommon(data.isCommon);
                break;
            case "approve":
                setModalTitle("Confirm Past History");
                setModalButton1("Reset");
                setModalButton2("Confirm Past History");
                setPastHistoryId(data._id);
                setPastHistoryData({
                    pastname: data.name,
                    pastcategory: data.type,
                    "pastimage": "",
                });
                setName(data.name);
                setCategory(data.type);
                setIsCommon(data.isCommon);
                break;
            case "reject":
                setPastHistoryId(data._id);
                setName(data.name);
                break;
            case "disable":
            case "merge":
                setModalTitle("Merge Past History");
                setModalButton1("Cancel");
                setModalButton2("Merge Past History");
                setPastHistoryId(data._id);
                setName(data.name);
                setIsCommon(data.isCommon);
                break;
            case "delete":
                setPastHistoryId(data._id);
                setName(data.name);
                break;
            case 'deleteMultiple':
                setName("Selected Past History");
                break;
            default:
        }
        setModalAction(action);
        if (type === "open") {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        const updatePayload = {
            id: pastHistoryId,
            name: name,
            type: category,
            isCommon: isCommon
        };

        if (action === "add" || action === "update") {
            if (emptyField(category)) {
                setCategoryError("Category name is required");
            }
        }
        switch (action) {
            case "add":
                const payload = {
                    name: name,
                    type: category,
                    isCommon: isCommon
                };
                if(!emptyField(name)){
                    if (!nameError && !categoryError) {
                        setShowBtnLoader('show-button-loader');
                        dispatch(addPastHistory(payload, pastHistory)).then(() => {
                            ListApisForDataMx(activeSubMenu, 1, pageSize, pastHistorySearch, searchPastHisotryList, setSelectedRowKeys, dispatch, setLoading);
                            modalClickAction('close');
                            setShowBtnLoader('');
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    }
                } else {
                    setNameError("Past History Name is required");
                }
                break;
            case "update":
                if(!emptyField(name)){
                    if (!nameError && !categoryError) {
                        setShowBtnLoader('show-button-loader');
                        dispatch(updatePastHistory(updatePayload, pastHistory)).then(() => {
                            modalClickAction('close');
                            setShowBtnLoader('');
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    }
                } else {
                    setNameError("Past History Name is required");
                }
                break;
            case "reject":
                const rejectPayload = {
                    ids: [pastHistoryId],
                    approval: "REJECT",
                };
                setShowBtnLoader('show-button-loader');
                dispatch(approvePastHistory(rejectPayload, pastHistory)).then(() => {
                    modalClickAction('close');
                    ListApisForDataMx(activeSubMenu, 1, pageSize, pastHistorySearch, searchPastHisotryList, setSelectedRowKeys, dispatch, setLoading);
                    setShowBtnLoader('');
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            case "approve":
                const ApprovePayload = {
                    ids: [pastHistoryId],
                    approval: "APPROVE",
                };
                setShowBtnLoader('show-button-loader');
                dispatch(updatePastHistory(updatePayload, pastHistory)).then(() => {
                    dispatch(approvePastHistory(ApprovePayload, pastHistory)).then(() => {
                        modalClickAction('close');
                        ListApisForDataMx(activeSubMenu, 1, pageSize, pastHistorySearch, searchPastHisotryList, setSelectedRowKeys, dispatch, setLoading);
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                });
                break;
            case "merge":
                const mergePayload = {
                    id: pastHistoryId,
                    withId: mergePastHistoryId,
                };
                if (mergePayload.id && mergePayload.withId) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(mergePastHistory(mergePayload, pastHistory)).then(() => {
                        modalClickAction('close');
                        ListApisForDataMx(activeSubMenu, 1, pageSize, pastHistorySearch, searchPastHisotryList, setSelectedRowKeys, dispatch, setLoading);
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                } else {
                    setMergeError("Past History merge is required")
                }
                break;
            case "delete":
                const deletePayload = {
                    ids: [pastHistoryId],
                };
                setShowBtnLoader('show-button-loader');
                dispatch(deletePastHistory(deletePayload, pastHistory, pastHistoryId)).then(() => {
                    modalClickAction('close');
                    setShowBtnLoader('');
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            case 'deleteMultiple':
                const deleteMultiplePayload = {
                    "ids": selectedRowKeys
                }
                setShowBtnLoader('show-button-loader');
                dispatch(deletePastHistory(deleteMultiplePayload, pastHistory, selectedRowKeys)).then(() => {
                    modalClickAction('close');
                    setShowBtnLoader('');
                    setSelectedRowKeys([]);
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            default:
                throw new Error("Modal action not match");
        }
    };

    const categoryList = [
        { label: "Disease", value: "DISEASE" },
        { label: "Operation", value: "OPERATION" },
        { label: "Accident", value: "ACCIDENT" },
        { label: "Transplantation", value: "TRANSPLANTATION" },
    ];

    useEffect(() => {
        debounce(ListApisForDataMx(activeSubMenu, page, pageSize, pastHistorySearch, searchPastHisotryList, setSelectedRowKeys, dispatch, setLoading),300)
    }, [pastHistorySearch, activeSubMenu, page, pageSize])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Past History List" />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenu}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setPage(1); setPastHistorySearch('') }}
                                        placeholder="Select Status"
                                        value={ExtraSubMenu.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setPastHistorySearch(e.target.value); }}
                                        value={pastHistorySearch}
                                    />
                                </div>
                                {
                                    activeSubMenu === "approved" &&
                                    <div className="add-btn">
                                        <Button
                                            title="Add"
                                            onClick={() => modalClickAction('open', 'add')}
                                        />
                                    </div>
                                }
                                {
                                    selectedRowKeys?.length > 0 &&
                                    <div className="delete-btn">
                                        <Button
                                            btn="btn-outline-primary"
                                            title="Delete"
                                            onClick={() => modalClickAction('open', 'deleteMultiple')}
                                        />
                                    </div>
                                }

                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table symptoms">
                            <DiseaseList
                                diseases={pastHistory}
                                modalClickAction={modalClickAction}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                isCategory={true}
                                isCommon={true}
                                isHab={true}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            {/* habits modal import from disease */}
            <DiseaseModals
                api={searchPastHisotryList}
                dispatch={dispatch}
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                setName={setName}
                nameError={nameError}
                setNameError={setNameError}
                mergeDiseaseId={mergePastHistoryId}
                setMergeDiseaseId={setMergePastHistoryId}
                mergeError={mergeError}
                setMergeError={setMergeError}
                modalButton1={modalButton1}
                modalButton2={modalButton2}
                modalTitle={modalTitle}
                isCommon={isCommon}
                setIsCommon={setIsCommon}
                resetForm={resetForm}
                categories={categoryList}
                category={category}
                setCategory={setCategory}
                categoryError={categoryError}
                setCategoryError={setCategoryError}
                isCatAvailable={true}
                isComAvailable={true}
                isDelete={true}
                isMerge={true}
                isAdd={true}
            />
        </>
    );
};
