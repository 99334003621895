/* eslint-disable no-mixed-operators */
import React from "react";
import { PatternFormat } from "react-number-format";
import Select from "react-select";
import FormInput from "../../../Components/FormInput";
import GoogleAddress from "../../../Components/GoogleAddress";
import { countryCodes } from "../../../common";

export default function HospitalInfo({
  showBtnLoader,
  setFormErrors,
  modal,
  modalAction,
  modalButton1,
  modalButton2,
  modalClickAction,
  modalTitle,
  hospitalFormData,
  setHospitalFormData,
  FormErrors,
  resetForm,
  dispatchAction,
  isLab,
  isHospital,
  list,
  addressRef,
  handleFormChange,
  handleCheckBox,
  handleSelectChange,
  handleDateChange,
}) {
  return (
    ["add", "update", "approve","changeQuota"].includes(modalAction) && (
      <>
        <div className="d-flex">
          <FormInput
            inputType="text"
            placeholder="Enter your text"
            title={isLab ? "Lab Name" : "Hospital Name"}
            required="required"
            name="name"
            onChange={(e) => {
              handleFormChange(e);
            }}
            value={hospitalFormData.name}
            errorMessage={FormErrors.name}
          />
          <FormInput
            inputType="text"
            placeholder="Enter your text"
            title="Doctor's Email ID"
            // required="required"
            name="emailId"
            isReadonly={modalAction === "add" ? false : true}
            onChange={(e) => {
              handleFormChange(e);
            }}
            value={hospitalFormData.emailId}
            // errorMessage={FormErrors.emailId}
          />
          <FormInput
            inputType="text"
            placeholder="Enter your text"
            title="Reg No."
            name="regNo"
            onChange={(e) => {
              handleFormChange(e);
            }}
            value={hospitalFormData.regNo}
          />
        </div>
        <div className="d-flex">
          <div className="formField contactformField">
            <div className="ex-label">
              <label>
                <div className="feildRequired">
                  Contact<span className="errorBox">*</span>
                </div>
              </label>
            </div>
            <Select
              isClearable={false}
              isSearchable={true}
              className="AutoSuggest"
              classNamePrefix="react-select"
              options={countryCodes}
              value={countryCodes.filter(
                (a) => a.value === hospitalFormData.countryCode
              )}
              onChange={(e) => handleSelectChange(e, "countryCode")}
            />
            <div className="usernumber formField">
              <PatternFormat
                format="##########"
                name="contact"
                id="contact"
                placeholder="Enter contact number"
                value={hospitalFormData.contact}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            {FormErrors.contact && (
              <div className="actionField errorBox"> {FormErrors.contact}</div>
            )}
          </div>

          <FormInput
            inputType="text"
            placeholder="Enter postal code"
            title="Postal code"
            name="pincode"
            onChange={(e) => {
              handleFormChange(e);
            }}
            value={hospitalFormData.pincode}
          />

          <div className="formField contactformField">
            <div className="ex-label">
              <label>
                <div className="feildRequired">
                  Address<span className="errorBox"></span>
                </div>
              </label>
            </div>
            <FormInput
              className={"countrycode"}
              name="plotNo"
              value={hospitalFormData.plotNo}
              title=""
              placeholder="Plot"
              onChange={(e) => handleFormChange(e)}
            />
            <div className="usernumber formField">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="19"
                  viewBox="0 0 16 19"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.56 8.16536C11.56 9.04942 11.2088 9.89727 10.5837 10.5224C9.95855 11.1475 9.1107 11.4987 8.22664 11.4987C7.34259 11.4987 6.49474 11.1475 5.86962 10.5224C5.2445 9.89727 4.89331 9.04942 4.89331 8.16536C4.89331 7.28131 5.2445 6.43346 5.86962 5.80834C6.49474 5.18322 7.34259 4.83203 8.22664 4.83203C9.1107 4.83203 9.95855 5.18322 10.5837 5.80834C11.2088 6.43346 11.56 7.28131 11.56 8.16536ZM9.89331 8.16536C9.89331 8.60739 9.71772 9.03131 9.40516 9.34388C9.09259 9.65644 8.66867 9.83203 8.22664 9.83203C7.78462 9.83203 7.36069 9.65644 7.04813 9.34388C6.73557 9.03131 6.55998 8.60739 6.55998 8.16536C6.55998 7.72334 6.73557 7.29941 7.04813 6.98685C7.36069 6.67429 7.78462 6.4987 8.22664 6.4987C8.66867 6.4987 9.09259 6.67429 9.40516 6.98685C9.71772 7.29941 9.89331 7.72334 9.89331 8.16536Z"
                    fill="#CFCFCF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.82834 13.3712C1.75404 12.3483 1.00717 11.0299 0.6822 9.58255C0.357225 8.13522 0.468732 6.62403 1.00262 5.24007C1.53651 3.85612 2.4688 2.66156 3.6816 1.80746C4.8944 0.953353 6.33323 0.47807 7.81615 0.441708C9.29907 0.405346 10.7595 0.809538 12.0127 1.60317C13.2659 2.39681 14.2556 3.54424 14.8567 4.90036C15.4577 6.25649 15.6432 7.7604 15.3895 9.22192C15.1359 10.6834 14.4545 12.0369 13.4317 13.1112L8.26 18.5429L2.82834 13.3712ZM12.225 11.962L8.2025 16.187L3.9775 12.1645C3.14198 11.369 2.56112 10.3435 2.30838 9.21784C2.05564 8.09217 2.14237 6.91683 2.5576 5.84044C2.97283 4.76406 3.69792 3.83497 4.64117 3.17067C5.58442 2.50637 6.70348 2.13668 7.85683 2.10837C9.01018 2.08005 10.146 2.39437 11.1208 3.01158C12.0955 3.62879 12.8653 4.52117 13.3328 5.57588C13.8004 6.63059 13.9447 7.80026 13.7475 8.93698C13.5503 10.0737 13.0205 11.1264 12.225 11.962Z"
                    fill="#CFCFCF"
                  />
                </svg>
              </span>
              <GoogleAddress
                id="hospitalAdminAddress"
                reference={addressRef}
                showMap={false}
                formData={hospitalFormData}
                setFormData={setHospitalFormData}
              />
            </div>
          </div>
        </div>
        {!["add"].includes(modalAction) && (hospitalFormData?.usedBefore && hospitalFormData?.usedBefore?.length > 0  || hospitalFormData?.noOfDoctors && hospitalFormData?.noOfDoctors !== ""  || hospitalFormData?.heardAbout && hospitalFormData?.heardAbout !== "") && (
          <div className="hosptial-other-info">
            <h4>Other info</h4>
            <div className="other-info-list">
              <ul>
                {hospitalFormData?.usedBefore?.length > 0 && (
                  <li>
                    <p>System used before doccine :</p>
                    <div className="box-list">
                      {hospitalFormData?.usedBefore.map((e, i) => {
                        return <span key={e + i}>{e}</span>;
                      })}
                    </div>
                  </li>
                )}
                {hospitalFormData?.noOfDoctors !== "" && (
                  <li>
                    <p>Number of doctors in hospital/clinic :</p>
                    <div className="box-list">
                      <span>{hospitalFormData?.noOfDoctors}</span>
                    </div>
                  </li>
                )}
                {hospitalFormData?.heardAbout !== "" && (
                  <li>
                    <p>Heard about doccine via :</p>
                    <div className="box-list">
                      <span>{hospitalFormData?.heardAbout}</span>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </>
    )
  );
}
