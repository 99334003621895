import React from "react";
import './style.scss';
import close from '../../assets/images/Close.png';
import Collapse from '../../assets/images/Collapse.svg';

const Modal = ({ ModalTitle, children, Modalclass, onClose, modalCloseClass, modalHeadingClass, closeOnOutside = false, type = "right", isWidgetModal, ...attrs }) => {
	// const [modalKeyPress, setModalKeyPress] = useState('');
	const handleKeyPress = (event) => {
		if ((event.key === "Escape" || event.key === "escape")) {
			// setModalKeyPress(event.key);
			onClose();
			document?.querySelectorAll(".main-rout-inner")[0]?.focus();
			// document.getElementById("root").focus();
		}
	};
	if (Modalclass === "show-modal") {
		document.body.classList.add('popup-open');
	} else {
		document.body.classList.remove('popup-open');
	}
	return (
		<div className={`modalBox hasmodal ${Modalclass} ${type}`} tabIndex="0" onKeyDown={handleKeyPress} >
			{closeOnOutside &&
				<div className="outerClick no-print"
					onClick={(e) => { onClose && onClose(e); document.body.classList.remove('popup-open'); document?.querySelectorAll(".main-rout-inner")[0]?.focus(); }} >
					&nbsp;
				</div>
			}
			<div className="modalMain">
				<span className={`close no-print`} onClick={(e) => { onClose && onClose(e); document.body.classList.remove('popup-open'); document?.querySelectorAll(".main-rout-inner")[0]?.focus(); }} >
					{
						isWidgetModal ?
							<img src={Collapse} alt={Collapse} />
							:
							<img src={close} alt={close} />
					}
				</span>
				<h3 className={`${modalHeadingClass}`}>{ModalTitle}</h3>
				<div className="modal-inner-scroll">
					{children}
				</div>
			</div>
		</div>
	);
};

export default Modal;
