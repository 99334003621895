import { Dropdown, Switch, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Button";
import FormInput from "../../../Components/FormInput";
import TableTitle from "../../../Components/TableTitle";
import { S3_BASE_URL, capitalizeFirstLetter } from "../../../common";

export default function HospitalDoctorList({
  modalAction,
  modalClickAction,
  search,
  pageSize,
  pageNo,
  handleSearchChange,
  hospitalFormData,
  hospitalId,
  handlePaginationChange
}) {
  const { doctors = {}, moduleAccesses = {} } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const columns = [
    { title: "No", dataIndex: "no", fixed: "left", width: 50 },
    {
      title: "Members Name",
      dataIndex: "name",
      fixed: "left",
      width: 150,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, { name, data }) => {
        return <div className="inline-flex">{name}</div>;
      },
    },
    {
      title: "Contact",
      dataIndex: "contact",
      width: 130,
      sorter: (a, b) => a.contact.localeCompare(b.contact),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      width: 150,
      sorter: (a, b) => a.emailId.localeCompare(b.emailId),
    },
    {
      title: "Roles",
      dataIndex: "role",
      width: 150,
      render: (_, { role }) => {
        return role.map((a) => {
          return a?.name
        });
      },
    },
    {
      title: "Access",
      dataIndex: "access",
      width: 250,
      render: (_, { access }) => {
        return access?.map((a) => {
          const acc = moduleAccesses?.data?.find((b) => b.key === a);
          return (
            acc && (
              <Tooltip title={acc.name} key={acc.key}>
                <span className="icon">
                  <img src={S3_BASE_URL + acc.icon} alt="moduleAccess" />
                </span>
              </Tooltip>
            )
          );
        });
      },
    },
    {
      title: "Action",
      width: 75,
      dataIndex: "",
      key: "x",
      fixed: "right",
      render: (_, row) => {
        return (
          <div>
            <Switch
              checked={row.data.isActive ? true : false}
              onChange={(checked) => handleSwitchChange(checked, row.data)}
            />
            {/* {CommonActionIconWithTooltipInANTDTable(row, modalClickAction)} */}
            <Dropdown
              placement="bottomRight"
              trigger={["click"]}
              dropdownRender={() => {
                return (
                  <div className="action">
                    <p
                      className="edit"
                      onClick={() =>
                        modalClickAction("open", "update", row.data)
                      }
                    >
                      Edit Doctor
                    </p>
                    {!row.data.isPasswordSet && (
                      <p
                        className="changeemail"
                        onClick={() =>
                          modalClickAction("open", "changeEmail", row.data)
                        }
                      >
                        Email change
                      </p>
                    )}
                    <p
                      className="edit"
                      onClick={() =>
                        modalClickAction("open", "moduleAccess", row.data)
                      }
                    >
                      Manage Access
                    </p>
                    {
                      <p
                        className="setting"
                        onClick={() =>
                          modalClickAction("open", "goToSettings", row.data)
                        }
                      >
                        Setting
                      </p>
                    }
                    {row.data.isApproved !== "1" && (
                      <p
                        className="delete"
                        onClick={() =>
                          modalClickAction("open", "delete", row.data)
                        }
                      >
                        Archive
                      </p>
                    )}
                  </div>
                );
              }}
            >
              <span className="icon">
                <svg
                  width="18"
                  height="4"
                  viewBox="0 0 18 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="16"
                    cy="2"
                    r="2"
                    transform="rotate(90 16 2)"
                    fill="#CFCFCF"
                  />
                  <circle
                    cx="9"
                    cy="2"
                    r="2"
                    transform="rotate(90 9 2)"
                    fill="#CFCFCF"
                  />
                  <circle
                    cx="2"
                    cy="2"
                    r="2"
                    transform="rotate(90 2 2)"
                    fill="#CFCFCF"
                  />
                </svg>
              </span>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  // find common module access from this arrays
  // const findIconOfModuleAccess = (roleModuleAccess, moduleAcces) => {
  //   let arr = [];
  //   arr = [...new Set([...arr, ...roleModuleAccess.create])];
  //   arr = [...new Set([...arr, ...roleModuleAccess.delete])];
  //   arr = [...new Set([...arr, ...roleModuleAccess.edit])];
  //   arr = [...new Set([...arr, ...roleModuleAccess.view])];
  //   arr = [...new Set([...arr, ...moduleAcces.create])];
  //   arr = [...new Set([...arr, ...moduleAcces.delete])];
  //   arr = [...new Set([...arr, ...moduleAcces.edit])];
  //   arr = [...new Set([...arr, ...moduleAcces.view])];
  //   return arr;
  // };

    // find common module access from this arrays
    const findIconOfModuleAccess = (moduleAcces) => {
      let arr = [];
      arr = [...new Set([...arr, ...moduleAcces.create])];
      arr = [...new Set([...arr, ...moduleAcces.delete])];
      arr = [...new Set([...arr, ...moduleAcces.edit])];
      arr = [...new Set([...arr, ...moduleAcces.view])];
      return arr;
    };

  const handleSwitchChange = (checked, data) => {
    if (checked) {
      modalClickAction('open', 'enable', data)
    } else {
      modalClickAction('open', 'disable', data)
    }
  }
  // set data for table which object keys come from columns dataIndex
  useEffect(() => {
    let arr = [];
    if (doctors?.userData?.length > 0) {
      doctors?.userData?.map((v, i) => {
        let obj = {
          key: v._id,
          no: i + 1,
          name:
            (v?.firstName &&
              capitalizeFirstLetter(v?.firstName) +
              " " +
              capitalizeFirstLetter(v?.lastName || "-")) ||
            "-",
          contact:
            v?.countryCode && v.contact
              ? v?.countryCode + " " + v.contact
              : v.contact
                ? v.contact
                : "-",
          emailId: v?.emailId ? v.emailId : "-",
          jobTitle: v.jobTitle ? v.jobTitle : "-",
          role: v?.userRole ? v?.userRole : [],
          access: v?.hospitals?.[0]?.moduleAccess && findIconOfModuleAccess(
            v.hospitals?.[0]?.moduleAccess,
          ),
          // role: v?.hospitals?.[0]?.roles
          //   ? v?.hospitals?.[0]?.roles.map((e, i) =>
          //     v?.hospitals?.[0]?.roles?.length - 1 === i
          //       ? e.name
          //       : `${e.name}, `
          //   )
          //   : [],
          // access:
          //   v?.hospitals?.[0]?.roleModuleAccess &&
          //   findIconOfModuleAccess(
          //     v?.hospitals?.[0]?.roleModuleAccess,
          //     v?.hospitals?.[0]?.moduleAccess
          //   ),
          data: {
            ...v,
            isHospital: true,
          },
        };
        arr.push({ ...obj });
      });
    }
    setData([...arr]);
  }, [doctors]);

  return (
    ["update", "approve"].includes(modalAction) && (
      <>
        <div className="adminTable ">
          <div className="adminTableHeader">
            <TableTitle title="Members List" />
            <div className="table-header-search">
              <div className="no-label table-search">
                <FormInput
                  inputType="text"
                  placeholder="Search for data"
                  onChange={(e) => {
                    handleSearchChange(e);
                  }}
                  value={search}
                />
              </div>
              <div className="add-btn">
                {
                hospitalFormData?.isApproved == '0' ? 
                  <Tooltip title={"Users can be added after account approval"}>
                    <span>
                      <Button
                        title="Add New"
                        isDisabled={true}
                        className="disabled"
                        // onClick={() => modalClickAction("open", "add")}
                      />
                    </span>
                  </Tooltip>
                  : 
                  <Button
                  title="Add New"
                  onClick={() => modalClickAction("open", "add")}
                />
                }
              </div>
            </div>
          </div>


          <div className="table member-list">
            <Table
              columns={columns}
              dataSource={data}
              sticky={true}
              scroll={{ x: 1100 }}
              pagination={{
                total: doctors?.userCount,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                pageSize,
                current: pageNo,
                onChange: (pages, pageSizes) => {
                  handlePaginationChange(pages, pageSizes);
                },
                showLessItems: true,
                responsive: true,
                pageSizeOptions: [10, 25, 50, 100],
              }}
            />
          </div>
        </div>
      </>
    )
  );
}
