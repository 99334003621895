import { useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Select from "react-select";
import FormInput from "../../../../Components/FormInput";
import { Tooltip } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';

export default function EmailTemplateAddModal({ mailData, setMailData, editor, setEditor, error, setError, activeSubMenu, triggerType }) {

    const CATEGORY = [
        { value: 'DOCTOR', label: 'Doctor' },
        { value: 'PATIENT', label: 'Patient' },
        { value: 'ADMIN', label: 'Admin' },
    ]

    const [accordianMenu, setAccordianMenu] = useState([]);

    const [copy, setCopy] = useState('');

    //accordian menu when select trigger type
    const handleChangeAccordianMenu = (trigger) => {
        if (triggerType.length > 0) {
            let sameTrigger = triggerType.find(v => v.value === trigger);
            if (sameTrigger?.allValue) {
                let menus = sameTrigger.allValue.body;
                let arr = [];
                if (menus.length > 0) {
                    menus.map((v) => {
                        let obj = { title: v.title, variables: v.variables }
                        arr.push(obj);
                    })
                }
                setAccordianMenu([...arr]);
            }
        }
    }

    useEffect(() => {
        if (mailData.triggerType !== "") {
            handleChangeAccordianMenu(mailData.triggerType)
        }
    }, [mailData.triggerType])

    //on chnage event of form
    const handleChange = (e, name, isSelect) => {
        let change = { ...mailData };
        if (isSelect) {
            const value = e.value;
            change[name] = value;
        } else {
            const value = e.target.value;
            change[name] = value;
        }
        setMailData({ ...change });
        let err = { ...error };
        if (err[name]) {
            delete err[name];
        }
        setError({ ...err });
    }

    //chnage handler in editor
    const onChangeHandler = (content) => {
        setEditor(content);
    };

    //editor button options
    const editorOptions = {
        height: 200,
        buttonList: [
            ["undo", "redo"],
            ["removeFormat"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontSize", "font"],
            ["removeFormat"],
            ["fontColor", "hiliteColor"],
            ["align", "horizontalRule", "list"],
            // ["table", "link"],
            ["showBlocks", "codeView"]
        ],
        fontSize: [12, 14, 16, 18, 20],
    };

    return (
        <>
            <div className="input-fields-templates">
                <FormInput
                    inputType="text"
                    placeholder="Name"
                    name="templateName"
                    title="Template Name"
                    required="required"
                    onChange={(e) => { handleChange(e, 'name', false); }}
                    value={mailData.name}
                    errorMessage={error?.name}
                />
                <div className="formField">
                    <label><div className="feildRequired">Trigger Type<span className="actionField errorBox">*</span></div></label>
                    <Select
                        classNamePrefix="react-select"
                        options={triggerType}
                        name="triggerType"
                        value={triggerType.filter((option) => {
                            return option.value === mailData.triggerType
                        })}
                        onChange={(e) => { handleChange(e, 'triggerType', true); }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                    />
                    {error?.triggerType && <div className="actionField errorBox">{error.triggerType}</div>}
                </div>
            </div>
            <div className="editor-with-accordian">
                <div className="template-editor">
                    <h1>{activeSubMenu === "EMAIL" ? "Email" : "SMS"}</h1>
                    <div className="formField add-zindex">
                        <label><div className="feildRequired">Category<span className="actionField errorBox">*</span></div></label>
                        <Select
                            classNamePrefix="react-select"
                            options={CATEGORY}
                            name="category"
                            value={CATEGORY.filter((option) => {
                                return option.value === mailData.category
                            })}
                            onChange={(e) => { handleChange(e, 'category', true); }}
                            placeholder="Select"
                            openMenuOnFocus={true}
                        />
                        {error?.category && <div className="actionField errorBox">{error.category}</div>}
                    </div>
                    {activeSubMenu === "EMAIL" &&
                        <FormInput
                            inputType="text"
                            placeholder="Subject"
                            name="subject"
                            title="Subject"
                            onChange={(e) => { handleChange(e, 'subject', false); }}
                            value={mailData.subject}
                        />
                    }
                    <div className="only-editor">
                        <SunEditor
                            defaultValue={editor}
                            setOptions={editorOptions}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
                <div className="template-accordian">
                    {
                        accordianMenu.length > 0 ?
                            accordianMenu.map((v, i) => {
                                return (
                                    <div className="variable-wrap" key={i}>
                                        <h4>{v.title}</h4>
                                        {
                                            v.variables.map((a, j) => {
                                                return (
                                                    <div className="variable-item" key={j}>
                                                        <span>
                                                            <Tooltip title={copy === a.value ? "Copied" : "Copy"}>
                                                                <CopyToClipboard text={a.value} onCopy={() => setCopy(a.value)}>
                                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.3135 3.37012H12.9502C13.0469 3.37012 13.126 3.29102 13.126 3.19434C13.126 2.9541 13.0176 2.72559 12.833 2.57031L10.5742 0.689453C10.4307 0.569336 10.1582 0.472656 9.9707 0.472656C9.85059 0.472656 9.75391 0.569336 9.75391 0.689453V2.81348C9.75684 3.12109 10.0059 3.37012 10.3135 3.37012Z" fill="#696969" />
                                                                        <path d="M8.99414 2.8125V0.46875H5.15625C4.64062 0.46875 4.21875 0.890625 4.21875 1.40625V11.25C4.21875 11.7656 4.64062 12.1875 5.15625 12.1875H12.1875C12.7031 12.1875 13.125 11.7656 13.125 11.25V4.13086H10.3125C9.58594 4.13086 8.99414 3.53906 8.99414 2.8125Z" fill="#696969" />
                                                                        <path d="M3.39844 12.0703V2.34375H2.8125C2.29688 2.34375 1.875 2.76562 1.875 3.28125V13.5938C1.875 14.1094 2.29688 14.5312 2.8125 14.5312H10.3125C10.8281 14.5312 11.25 14.1094 11.25 13.5938V13.0078H4.33594C3.82031 13.0078 3.39844 12.5859 3.39844 12.0703Z" fill="#696969" />
                                                                    </svg>
                                                                </CopyToClipboard>
                                                            </Tooltip>
                                                        </span>
                                                        <p>{a.lable}: {a.value}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                            :
                            <div className='no-templtes'>
                                Please select Trigger type
                            </div>
                    }
                </div>
            </div>
        </>
    )
}