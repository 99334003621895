import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import DefaultLayout from "../Layouts/default";
import DoccineLogo from '../assets/images/doccineLogo.png';
import Login from '../Views/Login';
import Dashboard from '../Views/Dashboard';
import { CONSTANT_ROUTES } from "../config/constantRoutes";
import { useSelector } from "react-redux";
import HospitalMx from "../Views/HospitalMx";
import DoctorMx from "../Views/DoctorsMx";
import ReceptionistMx from "../Views/ReceptionistMx";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LabMx from "../Views/LabMx";
import Diseases from "../Views/Diseases";
import Symptoms from "../Views/Symptoms";
import Habits from "../Views/Habits";
import PastHistory from "../Views/PastHistory";
import Medicines from "../Views/Medicines";
import PageNotFound from "../Views/PageNotFound";
import Payments from "../Views/Payments";
import MultiLingual from "../Views/MultiLingual";
import WhatsNew from "../Views/WhatsNew";
import UserRoles from "../Views/UserRoles";
import ModuleAccess from "../Views/ModuleAccess";
import Languages from "../Views/Languages";
import OnBoardVideos from "../Views/OnBoardVideos";
import Specialty from "../Views/Specialty";
import CanvasIcon from "../Views/CanvasIcon";
import LaboratoryTests from "../Views/LaboratoryTests";
import Vitals from "../Views/Vitals";
import Notifications from "../Views/Notifications";
import NotificationSettings from "../Views/NotificationSettings";
import Degrees from "../Views/Degrees";
import ManageSettings from "../Views/ManageSettings";
import PrincingPlans from "../Views/PricingPlans";
import AddOns from "../Views/AddOns";
import PricingPlanSettings from "../Views/PricingPlanSettings";
import DowngradeReasons from "../Views/DowngradeReasons";
import HospitalSetting from "../Views/HospitalSetting";
import DoctorSettings from "../Views/DoctorSettings";
import Maintenance from "../Views/Maintenance";
import FileCategory from "../Views/FileCategory";
import CancelAppointmentReasons from "../Views/CancelAppointmentReasons";
import RefundReasons from "../Views/RefundReasons";
import DoccineUniversity from "../Views/DoccineUniversity";
import ErrorLog from "../Views/ErrorLog";
import AptCustomForm from "../Views/AptCustomForm";
import HeaderChange from "../Views/HeaderChange";
import MenstrualType from "../Views/MenstrualType";

export default function RouteList() {
  const { auth = [] } = useSelector((state) => state);
  return (
    <>
      <div className="main-route">
        <div className={`r-loader main-loader-done`} id="mainLoader">
          <div className="spinner-box">
            <div className="circle-border">
              <div className="circle-core"></div>
            </div>
            <em><img src={DoccineLogo} alt={DoccineLogo} /></em>
          </div>
        </div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path={CONSTANT_ROUTES.admin.login} element={<Login />} />
            <Route exact path={CONSTANT_ROUTES.admin.dashboard} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Dashboard />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.hospitals} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <HospitalMx />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.doctors} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <DoctorMx />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.receptionist} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <ReceptionistMx />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            {/* <Route exact path={CONSTANT_ROUTES.admin.doctorSetting} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <DoctorSettings />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
             <Route exact path={CONSTANT_ROUTES.admin.doctorSettingkey} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <DoctorSettings />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.hospitalSetting} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <HospitalSetting />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
               <Route exact path={CONSTANT_ROUTES.admin.hospitalSettingkey} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <HospitalSetting />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            /> */}
            <Route exact path={CONSTANT_ROUTES.admin.labs} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <LabMx />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.diseases} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Diseases />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.symptoms} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Symptoms />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            {/* <Route exact path={CONSTANT_ROUTES.admin.menstrualType} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <MenstrualType />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            /> */}
            <Route exact path={CONSTANT_ROUTES.admin.laboratoryTests} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <LaboratoryTests />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.medicines} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Medicines />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.pastHistory} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <PastHistory />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.habits} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Habits />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.payments} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Payments />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.doccineUniversity} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <DoccineUniversity />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.multiLingual} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <MultiLingual />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.whatsNew} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <WhatsNew />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.vitals} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Vitals />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminUserRole.userRoles} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <UserRoles />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminUserRole.moduleAccess} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <ModuleAccess />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.languages} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Languages />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.notifications.eventTriggers} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Notifications />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.notifications.setting} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <NotificationSettings />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.onboard} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <OnBoardVideos />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.specialty} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Specialty />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.canvasIcon} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <CanvasIcon />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.degrees} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Degrees />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.managesettings} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <ManageSettings />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.maintenance} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <Maintenance />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.filecategory} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <FileCategory />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.aptcustom} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <AptCustomForm />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.header} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <HeaderChange />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.pricing.plans} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <PrincingPlans />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.pricing.addons} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <AddOns />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.pricing.settings} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <PricingPlanSettings />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.pricing.downgradeReasons} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <DowngradeReasons keyName={"DOWNGRADE_REASONS"} title={"Downgrade Reason List"} />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.aptCancelReasons} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <CancelAppointmentReasons />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.adminMaster.refundAmtReasons} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <RefundReasons />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route exact path={CONSTANT_ROUTES.admin.notifications.errorlog} element={
              auth.token && auth.role.includes("ADMIN") ?
                <DefaultLayout sidebar={true} isFooter={true}>
                  <ErrorLog />
                </DefaultLayout>
                :
                <Navigate replace to={"/"} />
            }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter >
      </div>
    </>
  )
}