/**
  * <DynamicOptionList />
  */

import React from 'react';
import { pushInArray, popFromArray, findFromArray } from "../common";

export default class DynamicVitalsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      element: this.props.element,
      data: this.props.data,
      dirty: false,
    };
  }

  _setValue(text) {
    return text.replace(/[^A-Z0-9]+/ig, '_').toLowerCase();
  }

  validateRange = ( option_index, type, e ) => {
    let validdata = { "is_valid": true, "value": e.target.value };
    if( type === "min" ){
      if( this.state.element.props.multipleOptions[option_index].max && parseInt( e.target.value ) > parseInt( this.state.element.props.multipleOptions[option_index].max ) ){
        validdata.is_valid = false;
        // validdata.value = this.state.element.props.multipleOptions[option_index].max;
      } else if( this.state.element.props.min_value && parseInt( e.target.value ) < parseInt( this.state.element.props.min_value ) ) {
        validdata.is_valid = false;
        // validdata.value = this.state.element.props.min_value;
      }
    } else if( type === "max" ){
      if( this.state.element.props.multipleOptions[option_index].min && parseInt( e.target.value ) < parseInt( this.state.element.props.multipleOptions[option_index].min ) ){
        validdata.is_valid = false;
        // validdata.value = this.state.element.props.multipleOptions[option_index].min;
      } else if( this.state.element.props.max_value && parseInt( e.target.value ) > parseInt( this.state.element.props.max_value ) ) {
        validdata.is_valid = false;
        // validdata.value = this.state.element.props.max_value;
      }
    }
    return validdata;
  };

  editOption(option_index, elName, e) {
    const this_element = this.state.element;
    let isValid = this.validateRange( option_index, elName, e );
    if( isValid.is_valid ){
      if( !this_element.props.multipleOptions[option_index]['invalid'] ){
        this_element.props.multipleOptions[option_index]['invalid'] = [];
      }
      popFromArray( this_element.props.multipleOptions[option_index]['invalid'], elName );
      this_element.props.multipleOptions[option_index][elName] = isValid.value;
      this.setState({
        element: this_element,                
        dirty: true,
      });
    } else {
      if( !this_element.props.multipleOptions[option_index]['invalid'] ){
        this_element.props.multipleOptions[option_index]['invalid'] = [];
      }
      pushInArray( this_element.props.multipleOptions[option_index]['invalid'], elName, true );
      this_element.props.multipleOptions[option_index][elName] = isValid.value;
      this.setState({
        element: this_element,                
        dirty: true,
      });
    }
  }

  // editValue(option_index, e) {
  //   const this_element = this.state.element;
  //   let elName = e.target.name;
  //   const val = (e.target.value === '') ? this._setValue(this_element.props.multipleOptions[option_index][elName]) : e.target.value;
    
  //   this_element.props.multipleOptions[option_index][elName] = val;
  //   this.setState({
  //     element: this_element,
  //     dirty: true,
  //   });
  // }

  // eslint-disable-next-line no-unused-vars
  editOptionCorrect(option_index, e) {
    const this_element = this.state.element;
    if (this_element.props.multipleOptions[option_index].hasOwnProperty('is_normal')) {
      delete (this_element.props.multipleOptions[option_index].is_normal);
    } else {
      this_element.props.multipleOptions[option_index].is_normal = true;
    }
    this.setState({ element: this_element });
    this.props.updateElement.call(this.props.preview, this_element);
  }

  updateOption() {
    const this_element = this.state.element;
    
    // to prevent ajax calls with no change
    if (this.state.dirty) {
      this.props.updateElement.call(this.props.preview, this_element);
      this.setState({ dirty: false });
    }
  }

  addOption(index) {
    const this_element = this.state.element;
    this_element.props.multipleOptions.splice(index + 1, 0, { label: '', min: '', max:"", });
    this.props.updateElement.call(this.props.preview, this_element);
  }

  removeOption(index) {
    const this_element = this.state.element;
    this_element.props.multipleOptions.splice(index, 1);
    this.props.updateElement.call(this.props.preview, this_element);
  }

  render() {
    if (this.state.dirty) {
      this.state.element.dirty = true;
    }
    return (
      <div className="dynamic-option-list">
        <ul>
          <li>
            <div className="row">
              <div className="col-sm-2"><b>Min</b></div>
              <div className="col-sm-2"><b>Max</b></div>
              <div className="col-sm-3"><b>Label</b></div>
              <div className="col-sm-2"><b>Is Normal</b></div>
              <div className="col-sm-3"></div>
            </div>
          </li>
          {
            this.props.element.props.multipleOptions.map((option, index) => {
              // const this_key = `edit_${option.key}`;
              // const val = (option.value !== this._setValue(option.text)) ? option.value : '';
              return (
                <li className="clearfix" key={index}>
                  <div className="row">
                    <div className="col-sm-2">

                      <input tabIndex={index + 1}  type="number" name={`min_${index}`} className={`form-control ${typeof option.invalid !== "undefined" ? findFromArray(option.invalid, "min") ? "cl_required" : "" : ""}`} value={option.min}
                        onBlur={this.updateOption.bind(this)} onChange={this.editOption.bind(this, index, "min")} />
                    </div>

                    <div className="col-sm-2">
                        <input tabIndex={index + 1} type="number" name={`max_${index}`} className={`form-control ${typeof option.invalid !== "undefined" ? findFromArray(option.invalid, "max") ? "cl_required" : "" : ""}`} value={option.max}
                            onBlur={this.updateOption.bind(this)} onChange={this.editOption.bind(this, index, "max")} />
                      {/* <input className="form-control" type="text" name={`value_${index}`} value={val} onChange={this.editValue.bind(this, index)} /> */}
                    </div>

                    <div className="col-sm-3">
                      <input className="form-control" type="text" name={`label_${index}`} placeholder="Label" value={option.label}
                        onBlur={this.updateOption.bind(this)}
                        onChange={this.editOption.bind( this, index, "label")} />
                    </div>
                    <div className="col-sm-2">
                      <input className="form-control" type="checkbox" value="0" onChange={this.editOptionCorrect.bind(this, index)} checked={option.is_normal} />                    </div>
                    <div className="col-sm-3">
                      <div className="dynamic-options-actions-buttons">
                        <button onClick={this.addOption.bind(this, index)} className="btn btn-success"><i className="fas fa-plus-circle"></i></button>
                        {index > 0
                          && <button onClick={this.removeOption.bind(this, index)} className="btn btn-danger"><i className="fas fa-minus-circle"></i></button>
                        }
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }
}
