import { Tabs } from "antd";
import React, { useRef, useState } from "react";
import Button from "../../../Components/Button";
import Formcheckbox from "../../../Components/Formcheckbox";
import Modal from "../../../Components/Modal";
import HospitalDoctorTab from "../HospitalDoctorTab";
import HospitalInfo from "./hospitalInfo";
import PricingAndSms from "./pricingAndSms";

export default function HospitalAddModal({
  showBtnLoader,
  setFormErrors,
  modal,
  modalAction,
  modalButton1,
  modalButton2,
  modalClickAction,
  modalTitle,
  hospitalFormData,
  setHospitalFormData,
  FormErrors,
  resetForm,
  dispatchAction,
  isLab,
  isHospital,
  list,
  setPage,
  setSearch,
  handleDebounceSearch,
  hospitalId,
  activeKey,
  setActiveKey,
}) {
  const addressRef = useRef(null);
  const handleFormChange = (e) => {
    let changedField = { ...hospitalFormData };
    changedField[e.target.name] = e.target.value;
    setHospitalFormData({ ...changedField });
    let changeError = FormErrors;
    delete changeError[e.target.name];
    setFormErrors({ ...changeError });
  };

  const handleCheckBox = (e) => {
    let changedField = { ...hospitalFormData };
    changedField[e.target.name] = !changedField[e.target.name];
    setHospitalFormData({ ...changedField });
  };

  const handleSelectChange = (e, name) => {
    let changedField = { ...hospitalFormData };
    changedField[name] = e?.value ? e.value : "";
    setHospitalFormData({ ...changedField });
  };

  const handleSwitchChange = (e, name) => {
    let changedField = { ...hospitalFormData };
    changedField[name] = e ? e : "";
    setHospitalFormData({ ...changedField });
  };

  //on date change function
  const handleDateChange = (e) => {
    let changedField = { ...hospitalFormData };
    changedField["expiryDate"] = e;
    setHospitalFormData({ ...changedField });
  };

  const onChange = (key) => {
    const obj = tabitems.find(
      (a) => a?.key?.toLowerCase() === key?.toLowerCase()
    );
    setActiveKey(obj.key);
  };

  // tabination items
  const tabitems = [
    {
      key: "hospitalInfo",
      label: "Hospital Info",
      children: (
        <HospitalInfo
          showBtnLoader={showBtnLoader}
          modal={modal}
          modalAction={modalAction}
          modalButton1={modalButton1}
          modalButton2={modalButton2}
          modalClickAction={modalClickAction}
          modalTitle={modalTitle}
          hospitalFormData={hospitalFormData}
          setHospitalFormData={setHospitalFormData}
          FormErrors={FormErrors}
          setFormErrors={setFormErrors}
          resetForm={resetForm}
          dispatchAction={dispatchAction}
          isHospital={true}
          list={list}
          addressRef={addressRef}
          handleFormChange={handleFormChange}
          handleCheckBox={handleCheckBox}
          handleSelectChange={handleSelectChange}
          handleDateChange={handleDateChange}
        />
      ),
    },
    {
      key: "pricingAndSms",
      label: "Pricing & SMS",
      children: (
        <PricingAndSms
          modalAction={modalAction}
          hospitalFormData={hospitalFormData}
          setHospitalFormData={setHospitalFormData}
          isHospital={true}
          list={list}
          handleFormChange={handleFormChange}
          handleCheckBox={handleCheckBox}
          handleSelectChange={handleSelectChange}
          handleDateChange={handleDateChange}
          handleSwitchChange={handleSwitchChange}
        />
      ),
    },
    {
      key: "hospitalMembers",
      label: "Hospitals Members",
      children: (
        <HospitalDoctorTab
          modalClickAction={modalClickAction}
          modalAction={modalAction}
          hospitalFormData={hospitalFormData}
          setPage={setPage}
          setSearch={setSearch}
          handleDebounceSearch={handleDebounceSearch}
          hospitalId={hospitalId}
        />
      ),
    },
  ];

  return (
    <>
      {["add", "update", "approve", "changeQuota"].includes(modalAction) && (
        <Modal
          ModalTitle={modalTitle}
          onClose={() => {
            modalClickAction("close");
            setActiveKey("hospitalInfo");
          }}
          Modalclass={
            modal &&
            ["add", "update", "approve", "changeQuota"].includes(modalAction)
              ? "show-modal"
              : ""
          }
          type="center"
        >
          <Tabs
            activeKey={activeKey}
            tabPosition="top"
            items={
              ["add"].includes(modalAction)
                ? tabitems.filter((e) => e.key !== "hospitalMembers")
                : tabitems
            }
            onChange={onChange}
          />
          <div className="activeButton right">
            {
              !["add"].includes(modalAction) &&
              <Button
                btn="left btn-outline-primary"
                title="Hospital Settings"
                onClick={(e) => {
                  e.preventDefault();
                  modalClickAction("open", "goToSettings", { _id: hospitalId });
                }}
              />
            }
            {isHospital && (
              <Formcheckbox
                inputType="checkbox"
                id="isBeta"
                name="isBeta"
                title="Is Hospital Beta User?"
                onChange={(e) => handleCheckBox(e)}
                isChecked={hospitalFormData.isBeta === true ? "checked" : ""}
              />
            )}
            {isHospital && (
              <Formcheckbox
                inputType="checkbox"
                id="isTesting"
                name="isTesting"
                title="Mark as testing hospital"
                onChange={(e) => handleCheckBox(e)}
                isChecked={hospitalFormData.isTesting === true ? "checked" : ""}
              />
            )}
            <Button
              title={modalButton1}
              btn="btn-outline-primary red"
              buttonType="close"
              onClick={() => {
                ["approve"].includes(modalAction)
                  ? dispatchAction("reject")
                  : modalClickAction("close");
                setActiveKey("hospitalInfo");
              }}
            />
            <Button
              loaderClass={
                ["add", "update", "approve", "changeQuota"].includes(
                  modalAction
                ) && showBtnLoader
                  ? showBtnLoader
                  : ""
              }
              btn="green"
              title={modalButton2}
              onClick={(e) => {
                e.preventDefault();
                dispatchAction(modalAction);
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
