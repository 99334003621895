import { DEGREE_LIST } from "../actions/types";

const initialState = {
    count: 0,
    data: []
};

export default function degree(state = initialState, action) {

    switch (action.type) {
        case DEGREE_LIST:
            return {
                count: action.payload.count,
                data: action.payload.data,
            };
        default:
            return state;
    }
};