import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { MEDICINES_LIST } from "./types";

//medicine list
export const fetchMedicineList = (filter, pagination, extraData = {}, isType) => async (dispatch) => {
    let body = { filter, pagination, extraData }
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.medicine.get}`,
            data: body
        }).then((response) => {
            resolve(response.data);
            if (!isType) {
                dispatch({
                    type: MEDICINES_LIST,
                    payload: {
                        count: response.data.approvalCount,
                        data: response.data.data,
                    }
                });
            }
        }).catch((error) => {
            reject(error)
        });
    });
}

//insert many medicines
export const InsertManyMedicine = (body, medicines) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.medicine.insertMany}`,
            data: { ...body },
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...medicines.data];
            response?.data?.map((a) => {
                let tmp = mainArray.some(v => v._id === a._id);
                if (!tmp) {
                    mainArray.push(a);
                }
            })
            dispatch({
                type: MEDICINES_LIST,
                payload: {
                    count: medicines.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error)
        });
    });
}

//update medicine api
export const updateMedicine = (docs, medicines) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.medicine.update}`,
            data: docs
        }).then((response) => {
            resolve(response.data);
            let obj = response.data;
            let mainArray = [...medicines.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: MEDICINES_LIST,
                payload: {
                    count: medicines.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//approve medicine api
export const approveMedicine = (docs, medicines) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.medicine.approve}`,
            data: docs
        }).then((response) => {
            resolve(response.data);
            let obj = response.data[0];
            let mainArray = [...medicines.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: MEDICINES_LIST,
                payload: {
                    count: medicines.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//merge medicine api
export const mergeMedicines = (docs, medicines) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.medicine.merge}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let obj = response.data;
            let mainArray = [...medicines.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: MEDICINES_LIST,
                payload: {
                    count: medicines.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete medicines api
export const deleteMedicines = (docs, medicines, medId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.medicine.delete}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...medicines.data]
            if (Array.isArray(medId)) {
                medId.map((item) => {
                    let index = mainArray.findIndex(v => v._id === item);
                    mainArray.splice(index, 1);
                })
            } else {
                let index = mainArray.findIndex(v => v._id === medId);
                mainArray.splice(index, 1);
            }
            dispatch({
                type: MEDICINES_LIST,
                payload: {
                    count: medicines.count - (Array.isArray(medId) ? medId.length : 1),
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//list of theraputic class
export const fetchtherapeuticClassList = (body) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.medicine.therapeuticClass.get}`,
            data: { ...body },
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        });
    });
}

//list of subtheraputic class
export const fetchtherapeuticSubClassList = (body) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.medicine.therapeuticSubClass.get}`,
            data: { ...body },
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        });
    });
}

//list of salt composition api
export const fetchSaltCompositionList = (body) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.medicine.saltComposition.get}`,
            data: { ...body },
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        });
    });
}

//manufacturer list api
export const fetchManufacturerList = (body) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.medicine.manufacturer.get}`,
            data: { ...body },
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        });
    });
}

// medicine type list
export const medicineTypeList = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.medicine.typeList}`,
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}