/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Button from "../../Components/Button";
import TableTitle from "../../Components/TableTitle";
import { addDoccineUniversityVideo, deleteDoccineUniversityVideo, fetchDoccineUniversityCategoryList, fetchDoccineUniversityList, updateDoccineUniversityVideo } from "../../actions/doccineUniversity";
import { closeModal, openModal } from "../../actions/modal";
import { fetchUserRoleList } from "../../actions/userRole";
import { removeMainLoaderClass } from "../../common";
import DoccineUniversityList from "./DoccineUniversityList";
import DoccineUniversityModals from "./DoccineUniversityModals";

export default function DoccineUniversity() {
    const dispatch = useDispatch();
    const { modal } = useSelector((state) => state);

    //for skeleton
    const [loading, setLoading] = useState(false);

    //for list
    const [doccineUniversityList, setDoccineUniversityList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [filterCategoryList, setFilterCategoryList] = useState([])

    //for video list
    const [videolink, setVideoLink] = useState('');
    const [videoTitle, setVideoTitle] = useState('');
    const [roleList, setRoleList] = useState([]);
    const [videoDesc, setVideoDesc] = useState('');
    const [category, setCategory] = useState('');
    const [thisVideoFor, setThisVideoFor] = useState([]);
    
    //for errors
    const [videolinkErr, setVideoLinkErr] = useState('');
    const [videoTitleErr, setVideoTitleErr] = useState('');
    const [videoDescErr, setVideoDescErr] = useState('');
    const [categoryErr, setCategoryErr] = useState('');

    //for modals
    const [showBtnLoader, setShowBtnLoader] = useState('');
    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton, setModalButton] = useState('');

    //for filter
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [selectedVideoFor, setSelectedVideoFor] = useState("all");
    
    //get single row data state
    const [singleRowData, setSingleRowData] = useState({});

    //static array for video for
    // const VideoFor = [{label: "All", value: "All"}, {label: "DOCTOR", value: "DOCTOR"}, {label: "RECEPTIONIST", value: "RECEPTIONIST"}, {label: "ADMIN", value: "ADMIN"}, {label: "OWNER", value: "OWNER"}]


    //reset states
    const resetForm = () => {
        setVideoLink('');
        setVideoLinkErr('');
        setVideoTitle('');
        setVideoTitleErr('');
        setCategory('');
        setCategoryErr('');
        setThisVideoFor([]);
        setVideoDesc('');
        setVideoDescErr('');
    }

    //reset modals state
    const resetModal = () => {
        setModalButton('');
        setModalTitle('');
        setShowBtnLoader('');
    };

    //open modals action
    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case "addNewVideo":
                setModalTitle('Add Doccine University Video');
                setModalButton('Upload Video');
                break;
            case "updateNewVideo":
                setSingleRowData(data);
                setVideoLink(data.link);
                setVideoTitle(data.title);
                setCategory(data.category);
                setThisVideoFor(data.for);
                setVideoDesc(data.description);
                setModalTitle('Update Doccine University Video');
                setModalButton('Upload Video');
                break;
            case "delete": 
                setSingleRowData(data);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    //dispatch Actions
    const dispatchAction = (action) => {
        switch (action) {
            case 'addNewVideo':
                if(videolink !== "" && videoTitle !== "" && category !== "" && videoDesc !==""){
                    setShowBtnLoader('show-button-loader');
                    let payload = {
                        link: videolink,
                        title: videoTitle,
                        description: videoDesc,
                        category: category,
                        for: thisVideoFor
                    }
                    dispatch(addDoccineUniversityVideo(payload)).then((res)=>{
                        let newArray = [...doccineUniversityList];
                        newArray.push(res);
                        setDoccineUniversityList([...newArray]);
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(()=>{
                        setShowBtnLoader('');
                    })
                } else {
                    if(videolink === "") setVideoLinkErr('Video Link is required');
                    if(videoTitle === "") setVideoTitleErr('Video Title is required');
                    if(category === "") setCategoryErr('Category is required');
                    if(videoDesc === "") setVideoDescErr("Video Description is required");
                }
                break;
            case 'updateNewVideo':
                if(videolink !== "" && videoTitle !== "" && category !== "" && videoDesc !==""){
                    setShowBtnLoader('show-button-loader');
                    let payload = {
                        link: videolink,
                        title: videoTitle,
                        description: videoDesc,
                        category: category,
                        for: thisVideoFor
                    }
                    dispatch(updateDoccineUniversityVideo(payload, singleRowData._id)).then((res)=>{
                        const newArray = [...doccineUniversityList];
                        const Index = newArray.findIndex((v)=> v._id === res._id);
                        newArray.splice(Index, 1, res);
                        setDoccineUniversityList([...newArray]);
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(()=>{
                        setShowBtnLoader('');
                    })
                } else {
                    if(videolink === "") setVideoLinkErr('Video Link is required');
                    if(videoTitle === "") setVideoTitleErr('Video Title is required');
                    if(category === "") setCategoryErr('Category is required');
                    if(videoDesc === "") setVideoDescErr("Video Description is required");
                }
                break;
            case 'delete':
                setShowBtnLoader('show-button-loader');
                dispatch(deleteDoccineUniversityVideo(singleRowData._id)).then((res)=>{
                    const newArray = [...doccineUniversityList];
                    const Index = newArray.findIndex((v)=> v._id === res._id);
                    newArray.splice(Index, 1);
                    setDoccineUniversityList([...newArray]);
                    modalClickAction('close');
                    setShowBtnLoader('');
                }).catch(()=>{
                    setShowBtnLoader('');
                })
                break;
            default:
                throw new Error('Modal action not match');
        }
    }

    //get and filter video list
    useEffect(()=>{
        setLoading(true);
        const payload = { 
            filter: { 
                category: selectedCategory,
                for: selectedVideoFor,
            }
        }
        payload.filter.category === "All" && delete payload["filter"]["category"]
        payload.filter.for === "all" && delete payload["filter"]["for"]
        dispatch(fetchDoccineUniversityList(payload)).then((res) => {
            removeMainLoaderClass();
            setLoading(false);
            if(res?.length > 0) {
                setDoccineUniversityList([...res]);
            } else {
                setDoccineUniversityList([]);
            }
        });
    },[selectedVideoFor, selectedCategory])

    // get category list api call
    useEffect(()=>{
        dispatch(fetchDoccineUniversityCategoryList()).then((res)=>{
            if(res?.length > 0) {
                let arr = [];
                let newArray = [{label: "All", value: "All"}]
                res.map((v) => {
                    arr.push({ label: v, value: v });
                    newArray.push({ label: v, value: v });
                })
                setCategoryList([...arr]);
                setFilterCategoryList([...newArray])
            } else {
                setCategoryList([]);
                setFilterCategoryList([])
            }
        });
        dispatch(fetchUserRoleList()).then((res) => {
            if (res?.data?.DEFAULT?.length > 0) {
                let arr = [];
                arr.push({ value: 'all', label: 'All' })

                res.data.DEFAULT.map((v) => {
                    arr.push({ value: v._id, label: v.name })
                })
                setRoleList([...arr]);
            }
        });
    },[])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: doccineUniversityList.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Uploaded Doccine University Video" />
                            <div className="table-header-search">
                                <div className="table-header-dropDown d-flex">
                                    <label>Category</label>
                                    <Select
                                        classNamePrefix="react-select"
                                        options={filterCategoryList}
                                        onChange={(e) => setSelectedCategory(e.value)}
                                        placeholder="Select Category"
                                        value={filterCategoryList && filterCategoryList.filter((v) => v.value === selectedCategory)}
                                    />
                                </div>
                                <div className="table-header-dropDown  d-flex">
                                <label>Video For</label>
                                    <Select
                                        classNamePrefix="react-select"
                                        placeholder="Select Video For"
                                        options={roleList}
                                        value={roleList && roleList?.filter((v) => v.value === selectedVideoFor)}
                                        onChange={(e) => setSelectedVideoFor(e.value)}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button title="Add New Video"  onClick={() => modalClickAction('open', 'addNewVideo')}/>
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table multiLingual">
                            <DoccineUniversityList
                                doccineUniversityList={doccineUniversityList}
                                modalClickAction={modalClickAction}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>

            {/* Doccine University Modals */}
            <DoccineUniversityModals
                modal={modal}
                modalAction={modalAction}
                modalTitle={modalTitle}
                modalClickAction={modalClickAction}
                videolink={videolink}
                setVideoLink={setVideoLink}
                videolinkErr={videolinkErr}
                setVideoLinkErr={setVideoLinkErr}
                videoTitle={videoTitle}
                setVideoTitle={setVideoTitle}
                videoTitleErr={videoTitleErr}
                setVideoTitleErr={setVideoTitleErr}
                categoryList={categoryList}
                category={category}
                setCategory={setCategory}   
                categoryErr={categoryErr}
                setCategoryErr={setCategoryErr}
                thisVideoFor={thisVideoFor}
                setThisVideoFor={setThisVideoFor}
                videoDesc={videoDesc}
                setVideoDesc={setVideoDesc}
                setVideoDescErr={setVideoDescErr}
                videoDescErr={videoDescErr}
                modalButton={modalButton}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                isDelete={true}
                singleRowData={singleRowData}
                roleList={roleList}
            />
        </>
    )
}