import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";

//List of multilanguage data 
export const multiLanguageList = (group) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.multiLingual.list}`,
            data: {
                "groups": group
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

//Delete of multilanguage by group 
export const multiLanguageDelete = (group) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.multiLingual.delete}/${group}`,
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

//Admin side insert many multi languages
export const multiLanguageInsertMany = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.multiLingual.insertMany}`,
            data: data
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

//Admin side add language and their meaning upsert api
export const multiLanguageUpsert = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.multiLingual.upsert}`,
            data
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}
