import { Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import { CommonActionIconWithTooltipInANTDTable } from "../../Common";

export default function MedicineList({ medicines, page, setPage, pageSize, setPageSize, selectedRowKeys, setSelectedRowKeys,
    therapeuticClass, therapeuticSubClass, saltComposition, manufacturer, modalClickAction, isCommon }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "Name", dataIndex: "name", fixed: "left", width: 200, sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: "Type", dataIndex: "type", width: 120, sorter: (a, b) => a.type.localeCompare(b.type) },
        { title: "Added by", dataIndex: "addedBy", width: 200, sorter: (a, b) => a.addedBy.localeCompare(b.addedBy) },
        { title: "Merge Reference", dataIndex: "mergeReference", width: 200, sorter: (a, b) => a.mergeReference.localeCompare(b.mergeReference) },
        { title: "Therapeutic Class", dataIndex: "therapeuticClass", width: 200, sorter: (a, b) => a.therapeuticClass.localeCompare(b.therapeuticClass) },
        { title: "Therapeutic SubClass", dataIndex: "therapeuticSubClass", width: 280, sorter: (a, b) => a.therapeuticSubClass.localeCompare(b.therapeuticSubClass) },
        { title: "Salt Composition", dataIndex: "saltComposition", width: 320, sorter: (a, b) => a.saltComposition.localeCompare(b.saltComposition) },
        { title: "Manufacturer", dataIndex: "manufacturer", width: 250, sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer) },
        // { title: "Description", dataIndex: "description" },
        { title: "Currency", dataIndex: "currency", width: 120, sorter: (a, b) => a.currency.localeCompare(b.currency) },
        { title: "MRP", dataIndex: "regular_price", width: 120, sorter: (a, b) => a.regular_price - b.regular_price },
        { title: "Sale Price", dataIndex: "sale_price", width: 120, sorter: (a, b) => a.sale_price - b.sale_price },
        {
            title: 'Action',
            width: 200,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return CommonActionIconWithTooltipInANTDTable(row, modalClickAction, true);
            },
        },
    ]

    //find name from array
    const getNameByIdFromArrayClass = (id, array) => {
        if (array?.length > 0) {
            let obj = array.find((v) => v.value === id);
            if (obj?.label) return obj?.label;
            else return "-";
        } else return "-";
    }

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (medicines?.data?.length > 0) {
            medicines.data.map((v, i) => {
                let obj = {
                    key: v._id,
                    name: v.name,
                    type: v.type,
                    addedBy: v?.addedBy?._id ? "Dr. " + v?.addedBy?.firstName + " " + v?.addedBy?.lastName : "-",
                    mergeReference: v.mergeReference && v.mergeReference.name ? v.mergeReference.name : "-",
                    therapeuticClass: getNameByIdFromArrayClass(v?.therapeuticClass, therapeuticClass),
                    therapeuticSubClass: getNameByIdFromArrayClass(v?.therapeuticSubClass, therapeuticSubClass),
                    saltComposition: getNameByIdFromArrayClass(v?.saltComposition, saltComposition),
                    manufacturer: getNameByIdFromArrayClass(v?.manufacturer, manufacturer),
                    // description: v.description ? v.description : "-",
                    currency: v.currency ? v.currency : "-",
                    regular_price: v.regular_price ? v.regular_price : "-",
                    sale_price: v.sale_price ? v.sale_price : "-",
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [medicines.data])

    //push keys of table row in state 
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    //when row selected
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    }

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 2000 }} rowSelection={rowSelection} />
            <Pagination
                total={medicines.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={pageSize}
                current={page}
                onChange={(page, pageSize) => { setPage(page); setPageSize(pageSize); }}
            />
        </>
    )
}