import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";

//fetch apt custom field list
export const fetchAdminAptFields = (data = {}) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.formfields.adminGet}`,
            data: data
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

//update apt custom field
export const updateAdminAptFields = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.formfields.updateAdminAptFields}/${id}`,
            data: data,
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}