import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addSpecialty, deleteSpecialty, searchSpecialty, fetchAliaseSpeciality } from "../../actions/specialty";
// import editicon from '../../../../assets/images/pencil.png';
// import close from '../../../../assets/images/close-icon.png';
// import Select from 'react-select';
import { Skeleton } from "antd";
import { closeModal, openModal } from "../../actions/modal";
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import TableTitle from "../../Components/TableTitle";
import { emptyField, removeMainLoaderClass } from "../../common";
// import { listCanvasIcon } from "../../actions/canvasIcon";
import SpecialtyList from "./SpecialtyList";
import DiseaseModals from "../Diseases/DiseaseModals";
import Modal from "../../Components/Modal";
// import FormUpload from "../../Components/FormUpload";
import './style.scss';

export default function Specialty() {

    const { specialty = [], canvasIcon = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton, setModalButton] = useState('');

    const [specialtyArr, setSpecialtyArr] = useState([]);
    // const [specialityAliaseArr, setSpecialityAliaseArr] = useState([])
    // const [iconArr, setIconArr] = useState([]);

    const [name, setName] = useState('');
    // const [aliaseSpecialty, setAliaseSpecialty] = useState([])
    // const [specialtyImage, setSpecialtyImage] = useState('');
    // const [icon, setIcon] = useState([]);
    const [spId, setSpId] = useState('');

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    let specialtyErrors = []
    const [specialtyErr, setSpecialtyErr] = useState(specialtyErrors)
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const resetForm = () => {
        setName('');
        // setSpecialtyImage('');
        // setIcon([]);
        setSpId('');
        // setAliaseSpecialty([])
        setSpecialtyErr('')
    }

    const resetModal = () => {
        setModalButton('');
        setModalAction('')
        setModalTitle('');
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case 'add':
                setModalTitle('Add Specialty');
                setModalButton('Add');
                break;
            case 'update':
                setModalTitle('Edit Specialty');
                setModalButton('Edit');
                setName(data.name);
                // let arr = []
                // data?.aliaseSpecialty?.map((v) => {
                //     arr.push(v._id)
                // })
                // setAliaseSpecialty(arr?.length > 0 ? [...arr] : [])
                // setSpecialtyImage(data?.specialtyImage);
                // setIcon(data?.icon)
                setSpId(data._id);
                break;
            case 'delete':
                setName(data.name);
                setSpId(data._id);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        if (emptyField(name)) {
            specialtyErrors.name = "Specialty name is required";
        }
        // if (emptyField(specialtyImage)) {
        //     specialtyErrors.specialtyImage = 'specialty image is required'
        // }
        setSpecialtyErr(specialtyErrors)
        switch (action) {
            case 'add':
                if (Object.keys(specialtyErrors).length === 0) {
                    let add = new FormData();
                    add.append("name", name);
                    // add.append("aliaseSpecialty", JSON.stringify(aliaseSpecialty))
                    // add.append("specialtyImage", specialtyImage);
                    // add.append("icon", JSON.stringify(icon));
                    setShowBtnLoader('show-button-loader');
                    dispatch(addSpecialty(add, specialty)).then((res) => {
                        // let arr = [...specialtyArr];
                        // let obj = { value: res._id, label: res.name }
                        // if (!arr.includes(obj)) {
                        //     arr.push(obj);
                        // }
                        // setSpecialtyArr([...arr]);
                        LoadApiCalls(search, page, pageSize);
                        setShowBtnLoader('');
                        modalClickAction('close');
                    }).catch(() => setShowBtnLoader(''));
                }
                break;
            case 'update':
                if (Object.keys(specialtyErrors).length === 0) {
                    let update = new FormData();
                    update.append("name", name);
                    // update.append("aliaseSpecialty", JSON.stringify(aliaseSpecialty));
                    // update.append("specialtyImage", specialtyImage);
                    // update.append("icon", JSON.stringify(icon));
                    update.append("specialtiesId", spId);
                    if (spId !== "") {
                        setShowBtnLoader('show-button-loader');
                        dispatch(addSpecialty(update, specialty)).then((res) => {
                            modalClickAction('close')
                            // let arr = [...specialtyArr];
                            // let index = arr.findIndex(v => v.name === res.name);
                            // let obj = { value: res._id, label: res.name }
                            // arr[index] = obj;
                            // setSpecialtyArr([...arr])
                            setShowBtnLoader('')
                            modalClickAction('close');
                        }).catch(() => setShowBtnLoader(''));
                    }
                }
                break;
            case 'delete':
                setShowBtnLoader('show-button-loader');
                dispatch(deleteSpecialty({ _id: spId }, specialty)).then(res => {
                    let arr = [...specialtyArr];
                    let index = arr.findIndex(v => v._id === res._id);
                    if (index >= 0) {
                        arr.splice(index, 1);
                    }
                    setSpecialtyArr([...arr]);
                    setShowBtnLoader('');
                    modalClickAction('close');
                }).catch(() => setShowBtnLoader(''));
                break;
            case 'canvasIcon':
                // dispatch(listCanvasIcon()).then((res) => {
                //     if (res.data.length > 0) {
                //         let suggestion = [];
                //         res.data.forEach((v) => {
                //             if (!suggestion.includes({ value: v._id, label: v.name })) {
                //                 suggestion.push({ value: v._id, label: v.name })
                //             }
                //         });
                //         setIconArr([...suggestion]);
                //     }
                // });
                break;
            default:
                throw new Error('Modal action not match');
        }
    }

    // const findIconImage = (id) => {
    //     let index = canvasIcon?.data?.findIndex((v) => v._id === id);
    //     let image = "";
    //     if (index >= 0) {
    //         image = canvasIcon?.data[index]?.iconImage;
    //     }
    //     return image;
    // }

    // const handleSelectSpeciltyChange = (e) => {
    //     let selectedValue = [];
    //     e.forEach((v) => {
    //         selectedValue.push(v.value);
    //     })
    //     setIcon([...selectedValue]);
    // };

    // const handleSelectAliaseSpeciality = (e) => {
    //     let selectedValue = [];
    //     e.forEach((v) => {
    //         selectedValue.push(v.value);
    //     })
    //     setAliaseSpecialty([...selectedValue]);
    // }

    // useEffect(() => {
    //     if (specialtyImage !== "") {
    //         setSpecialtyErr('')
    //     }
    // }, [specialtyImage])

    //fetch aliaseSpecialty list
    // useEffect(() => {
    //     dispatchAction('canvasIcon');
    //     dispatch(fetchAliaseSpeciality()).then((res) => {
    //         let arr = [];
    //         if (res.data) {
    //             res.data.forEach((v) => {
    //                 let obj = { value: v._id, label: v.name, }
    //                 if (!arr.includes(obj)) {
    //                     arr.push(obj);
    //                 }
    //             })
    //             setSpecialityAliaseArr([...arr]);
    //         }
    //     })
    // }, []);

    const [search, setSearch] = useState('')

    const LoadApiCalls = (search, page, pageSize) => {
        let payload = {
            search: search,
            pagination : { "pageNo": page, "pageSize": pageSize}
        }
        if (search === "") setLoading(true);
        dispatch(searchSpecialty(payload)).then(() => {
            if (search === "") {
                setLoading(false);
            }
            removeMainLoaderClass();
        }).catch(() => {
            if (search === "") {
                setLoading(false);
            }
            removeMainLoaderClass();
        })
    }

    //fetch spacialtylist 
    useEffect(() => {
        LoadApiCalls(search, page, pageSize);
    }, [search, page, pageSize]);

    //display default Page in table
    // useEffect(() => {
    //     let arr = []
    //     for (let i = 0; i < showPage; i++) {
    //         if (i < 5) arr.push({ count: i + 1, isShow: true })
    //         else arr.push({ count: i + 1, isShow: false })
    //     }
    //     setPageArr([...arr]);
    // }, [specialty.specialtyCount, pageSize])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: specialty.data.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Specialty List" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table payments">
                            <SpecialtyList
                                specialty={specialty}
                                modalClickAction={modalClickAction}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                            // findIconImage={findIconImage}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            {(modalAction === "add" || modalAction === "update") &&
                <Modal
                    ModalTitle={modalTitle}
                    type="center"
                    onClose={() => modalClickAction('close')}
                    Modalclass={(modal && (modalAction === "add" || modalAction === "update")) ? 'show-modal' : ''}
                >
                    <div>
                        <FormInput
                            title="Specialty Name"
                            name="addSpecialty"
                            id="addSpecialty"
                            value={name}
                            onChange={(e) => { setName(e.target.value); setSpecialtyErr('') }}
                            required="required"
                            placeholder="Type new Specialty"
                        />
                        {specialtyErr.name && <div className="actionField errorBox">{specialtyErr.name}</div>}
                        {/* <div className="formField Specialty">
                            <label><div className="feildRequired">Specialty Aliase</div></label>
                            <Select
                                classNamePrefix="react-select"
                                placeholder="Select Aliase Speciality"
                                name="aliaseSpecialty"
                                options={specialityAliaseArr}
                                isMulti={true}
                                onChange={(e) => { handleSelectAliaseSpeciality(e); setSpecialtyErr('') }}
                                value={aliaseSpecialty && specialityAliaseArr && specialityAliaseArr.filter((v) =>
                                    aliaseSpecialty.includes(v.value)
                                )}
                            />
                        </div>
                        <div className="formField Specialty">
                            <label><div className="feildRequired">Specialty Image<span className="errorBox">*</span></div></label>
                            <FormUpload
                                logo={specialtyImage}
                                setLogo={setSpecialtyImage}
                                message="Drag and Drop image here"
                            />
                            {specialtyErr.specialtyImage && <div className="actionField errorBox">{specialtyErr.specialtyImage}</div>}
                        </div>
                        <div className="formField Specialty">
                            <label><div className="feildRequired">Specialty Icon</div></label>
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                required
                                classNamePrefix="react-select"
                                isMulti={true}
                                options={iconArr}
                                placeholder="Select Icon"
                                value={icon && iconArr && iconArr.filter((v) => icon.includes(v.value))}
                                onChange={(e) => { handleSelectSpeciltyChange(e); setSpecialtyErr('') }}
                            />
                        </div> */}
                        <div className="activeButton right">
                            <Button
                                title="Cancel"
                                buttonType="reset"
                                onClick={() => modalClickAction('close')}
                            />
                            <Button
                                loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                                title={modalButton}
                                onClick={(e) => { e.preventDefault(); dispatchAction(modalAction) }}
                            />
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}
