/* eslint-disable array-callback-return */
import Button from "../../../Components/Button";
import FormInput from "../../../Components/FormInput";
import Formcheckbox from "../../../Components/Formcheckbox";
import Modal from "../../../Components/Modal";
import Select from "react-select";
import Delete from '../../../assets/images/delete.svg';

export default function DoccineUniversityModals({modal, modalAction, modalTitle, modalClickAction, videolink, setVideoLink, videolinkErr, setVideoLinkErr, videoTitle, setVideoTitle, videoTitleErr, setVideoTitleErr, categoryList,category,  setCategory, categoryErr, setCategoryErr, thisVideoFor, setThisVideoFor, videoDesc, setVideoDesc, setVideoDescErr, videoDescErr, modalButton, showBtnLoader, dispatchAction, isDelete, singleRowData, roleList}) {

    //for selected role
    const handleVideoForChange = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            setThisVideoFor((prevSelected) => prevSelected ? [...prevSelected, name] : [name]);
        } else {
            setThisVideoFor((prevSelected) => prevSelected.filter((checkboxName) => checkboxName !== name));
        }
      };
    return (
        <>
            {(modalAction === "addNewVideo" || modalAction === "updateNewVideo") &&
                <Modal
                    ModalTitle={modalTitle}
                    onClose={() => modalClickAction('close')}
                    type="center"
                    Modalclass={(modal && (modalAction === "addNewVideo" || modalAction === "updateNewVideo")) ? 'show-modal' : ''}
                >
                     <FormInput
                        placeholder="Enter Video URL"
                        inputType="text"
                        title="Enter Video URL"
                        value={videolink}
                        onChange={(e) => { setVideoLink(e.target.value); setVideoLinkErr('') }}
                        required="required"
                        errorMessage={videolinkErr}
                    />
                     <FormInput
                        placeholder="Enter Video Title"
                        inputType="text"
                        title="Video Title"
                        value={videoTitle}
                        onChange={(e) => { setVideoTitle(e.target.value); setVideoTitleErr('') }}
                        required="required"
                        errorMessage={videoTitleErr}
                    />
                    <div className="formField">
                        <label><div className="feildRequired">Video Category<span className="actionField errorBox">*</span></div></label>
                        <Select
                            required="required"
                            classNamePrefix="react-select"
                            options={categoryList}
                            placeholder="Select Video Category"
                            name="VideoCategory"
                            id="VideoCategory"
                            onChange={(e) => { setCategory(e.value); setCategoryErr('') }}
                            value={categoryList.filter((option) => {
                                return option.value === category;
                            })}
                        />
                        {categoryErr && <div className="actionField errorBox">{categoryErr}</div>}
                    </div>
                    <div className="formField">
                        <label><div className="feildRequired">This Video For<span className="actionField errorBox">*</span></div></label>
                        <div className="vitals-checkboxes">
                            {roleList && roleList.map((r) => {
                                if(r.value !== 'all'){
                                    return(
                                        <Formcheckbox
                                            inputType="checkbox"
                                            title={r.label}
                                            required="required"
                                            name={r.value}
                                            id={r.value}
                                            isChecked={thisVideoFor && thisVideoFor.includes(r.value) ? "checked" : ""}
                                            onChange={(e)=>handleVideoForChange(e)}
                                        />
                                    )
                                }
                            })}
                            {/* <Formcheckbox
                                inputType="checkbox"
                                title="Doctors"
                                required="required"
                                name="DOCTOR"
                                id="DOCTOR"
                                isChecked={thisVideoFor.includes("DOCTOR") ? "checked" : ""}
                                onChange={(e)=>handleVideoForChange(e)}
                            />
                            <Formcheckbox
                                inputType="checkbox"
                                title="Receptionist"
                                required="required"
                                name="RECEPTIONIST"
                                id="RECEPTIONIST"
                                isChecked={thisVideoFor.includes("RECEPTIONIST") ? "checked" : ""}
                                onChange={(e)=>handleVideoForChange(e)}
                            />
                            <Formcheckbox
                                inputType="checkbox"
                                id="OWNER"
                                name="OWNER" 
                                title="Owner"
                                required="required"
                                isChecked={thisVideoFor.includes("OWNER") ? "checked" : ""}
                                onChange={(e)=> handleVideoForChange(e)}
                            />
                            <Formcheckbox
                                inputType="checkbox"
                                id="ADMIN"
                                title="Admin"
                                name="ADMIN"
                                required="required"
                                isChecked={thisVideoFor.includes("ADMIN") ? "checked" : ""}
                                onChange={(e)=> handleVideoForChange(e)}
                            /> */}
                        </div>
                    </div>
                    <FormInput
                        placeholder="Enter Video Description"
                        inputType="text"
                        title="Video Description"
                        value={videoDesc}
                        onChange={(e) => { setVideoDesc(e.target.value); setVideoDescErr('') }}
                        required="required"
                        errorMessage={videoDescErr}
                    />
                    <div className="activeButton right">
                        <Button
                            loaderClass={["addNewVideo", "updateNewVideo"].includes(modalAction) ? showBtnLoader : ""}
                            title={modalButton}
                            onClick={(e) => { e.preventDefault(); dispatchAction(modalAction) }}
                        />
                    </div>
                </Modal>
            }
            {
                isDelete && (modalAction === 'delete') &&
                <Modal
                    type="center"
                    className="removedoc"
                    onClose={() => modalClickAction('close')}
                    Modalclass={(modal && (modalAction === 'delete')) ? `show-modal` : ``}
                >
                    <span className="modal-action-icon">
                        {(modalAction === "delete") && <span className="modal-icon"><img className="" src={Delete} alt={Delete} /></span>}
                    </span>
                    <div className="modal-action-info">
                        <p className="text-center">
                            Are you sure you want to
                            <em>{" Delete"}</em> <br />
                            <strong>{singleRowData.title}</strong>
                        </p>
                        <div className="activeButton center">
                            <Button
                                btn="no-bg"
                                title="Cancel"
                                onClick={() => modalClickAction('close')}
                            />
                            <Button
                                loaderClass={['delete'].includes(modalAction) ? showBtnLoader : ""}
                                title="Confirm"
                                onClick={() => dispatchAction(modalAction)}
                            />
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}