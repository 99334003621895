import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import TableTitle from "../../Components/TableTitle";
import Button from "../../Components/Button";
import FormInput from "../../Components/FormInput";
import { closeModal, openModal } from "../../actions/modal";
import { adminOnBoardVideoLinkUpsert, adminOnBoardVideoLinkListOne } from "../../actions/onBoardVideo";
import { Skeleton } from "antd";
import DiseaseModals from "../Diseases/DiseaseModals";
import Modal from "../../Components/Modal";
import { removeMainLoaderClass } from "../../common";
import TextArea from "antd/lib/input/TextArea";
import DowngradeReasonList from "./DowngradeReasonList";

export default function DowngradeReasons({ keyName, title }) {
    const { modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [langList, setLangList] = useState([]);

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [modalAction, setModalAction] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const singleLangs = { "reason": "" }

    const [langs, setLangs] = useState([singleLangs])

    const [editLang, setEditLang] = useState('');
    const [index, setIndex] = useState('');

    const resetForm = () => {
        setLangs([singleLangs]);
        setIndex('');
        setEditLang('');
    }

    const resetModal = () => {
        setModalAction('')
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case "add":
                break;
            case 'delete':
                setLangs([...langList]);
                setIndex(data.key);
                setEditLang(`this reason`);
                break;
            case 'update':
                setLangs([...langList]);
                setIndex(data.key);
                setEditLang(data.reason);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        switch (action) {
            case 'update':
                let newUpdateArr = [...langs];
                if (editLang.trim() !== "") {
                    newUpdateArr.splice(index, 1, { reason: editLang });
                    let editPay = {
                        key: keyName,
                        value: newUpdateArr,
                        group: keyName
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(adminOnBoardVideoLinkUpsert(editPay)).then(res => {
                        setShowBtnLoader('');
                        toast.success('Reason updated successfully.')
                        modalClickAction('close')
                        if (res.value.length > 0) {
                            setLangList([...res.value]);
                        }
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                } else {
                    toast.error("Reason is required.")
                }
                break;
            case 'delete':
                let newArr = [...langs];
                newArr.splice(index, 1);
                let deletePay = {
                    key: keyName,
                    value: newArr,
                    group: keyName
                }
                setShowBtnLoader('show-button-loader');
                dispatch(adminOnBoardVideoLinkUpsert(deletePay)).then(res => {
                    setShowBtnLoader('');
                    toast.success('Reason deleted successfully.')
                    modalClickAction('close')
                    if (res.value.length > 0) {
                        setLangList([...res.value]);
                    }
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            case 'add':
                let array = [...langList];
                if (editLang.trim() !== "") {
                    array.push({ reason: editLang })
                    let add = {
                        key: keyName,
                        value: array,
                        group: keyName
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(adminOnBoardVideoLinkUpsert(add)).then(res => {
                        setShowBtnLoader('');
                        modalClickAction('close')
                        toast.success('Reason added successfully.')
                        if (res.value.length > 0) {
                            setLangList([...res.value]);
                        }
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                } else {
                    toast.error("Reason is required.")
                }
                break;
            default:
        }
    }

    useEffect(() => {
        setLoading(true);
        dispatch(adminOnBoardVideoLinkListOne(keyName)).then((res) => {
            if (res.length > 0) {
                setLoading(false);
                removeMainLoaderClass();
                setLangList([...res[0].value]);
            } else {
                removeMainLoaderClass();
                setLoading(false);
                setLangList([]);
            }
        });
    }, [])

    const [search, setSearch] = useState('');
    const [speList, setSpeList] = useState([]);

    useEffect(() => {
        if (langList?.length > 0) setSpeList([...langList]);
        else setSpeList([]);
    }, [langList])

    useEffect(() => {
        if (langList?.length > 0) {
            if (search !== "") {
                let arr = [];
                langList?.map((v) => {
                    if (v.reason.toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setSpeList([...arr])
            } else {
                setSpeList([...langList])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                        <div className="adminTableHeader">
                            <TableTitle title={title} />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for Reason"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table downgrade-reasons">
                            <DowngradeReasonList
                                speList={speList}
                                modalClickAction={modalClickAction}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={editLang}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            {
                (modalAction === 'update' || modalAction === "add") &&
                <Modal
                    className="removedoc"
                    ModalTitle={modalAction === "add" ? "Add Reason" : "Edit Reason"}
                    type="center"
                    onClose={() => modalClickAction('close')}
                    Modalclass={modal && (modalAction === 'update' || modalAction === "add") ? `show-modal` : ``}
                >
                    <div className="formField">
                        <label>
                            <div className="feildRequired">Reason<span className="actionField errorBox">*</span></div>
                        </label>
                        <TextArea
                            className="formControl"
                            name="name"
                            value={editLang}
                            placeholder="Enter Value"
                            onChange={(e) => { setEditLang(e.target.value); }}
                            required={true}
                        />
                    </div>
                    <div className="activeButton right">
                        <Button
                            title="Cancel"
                            buttonType="reset"
                            onClick={() => modalClickAction('close')}
                        />
                        <Button
                            loaderClass={["update", "add"].includes(modalAction) ? showBtnLoader : ""}
                            title={modalAction === "add" ? "Add" : "Edit"}
                            onClick={(e) => { e.preventDefault(); dispatchAction(modalAction) }}
                        />
                    </div>
                </Modal>
            }
        </>
    )
}