import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { WP_TEMPLATE_LIST } from "./types";

// Fetching all data of Template
export const fetchWPTemplatesList = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.notification.wpTemplates.list}`,
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: WP_TEMPLATE_LIST,
                payload: response.data
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Adding sms Templates
export const addWPTemplates = (data, wpTemplates) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.notification.wpTemplates.add}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...wpTemplates];
            let temp = mainArray.some(v => v._id === response.data._id);
            if (!temp) {
                mainArray.unshift(response.data);
            }
            dispatch({
                type: WP_TEMPLATE_LIST,
                payload: mainArray
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Updating the Template
export const updateWPTemplates = (templateId, data, wpTemplates, isUsed) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.notification.wpTemplates.update}/${templateId}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...wpTemplates];
            let temp = mainArray.findIndex(email => email._id === templateId);
            if (temp >= 0) {
                mainArray.splice(temp, 1, response.data)
            }
            dispatch({
                type: WP_TEMPLATE_LIST,
                payload: mainArray
            });
        }).catch((error) => {
            reject(error);
        });
    })
}

//delete message Template apis
export const deleteWPTemplates = (templateId, wpTemplates) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.notification.wpTemplates.delete}/${templateId}`,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...wpTemplates];
            let temp = mainArray.findIndex(sms => sms._id === templateId);
            if (temp >= 0) {
                mainArray.splice(temp, 1);
            }
            dispatch({
                type: WP_TEMPLATE_LIST,
                payload: mainArray
            });
        }).catch((error) => {
            reject(error);
        });
    })
}
