import Button from "../../../../Components/Button";
import Modal from "../../../../Components/Modal";
import Creatable from 'react-select/creatable';
import { useEffect, useState } from "react";
import FormInput from "../../../../Components/FormInput";
import addicon from "../../../../assets/images/add-icon.svg";
import TableTitle from "../../../../Components/TableTitle";
import LabTestAddModal from "../../LabTests/LabTestAddModal";
import { getAllAdminLabTest } from "../../../../actions/laboratoryTest";
import { Select } from "antd";

export default function PanelAddModal({ dispatch, modal, modalAction, modalClickAction, showBtnLoader, resetForm,
    dispatchAction, panel, record, setRecord, panelId, setPanelId, isPanel }) {

    const initLabTest = {
        testName: "",
        category: "",
        department: "",
        testCode: "",
        sample: "",
        container: "",
        size: "",
        ml: "",
        price: "",
        homeCollection: "",
        reportSize: "",
        timeline: "",
        referenceValues: [],
        notes: "",
        type: ""
    };

    let reportTypes = [
        {value: "SINGLE_TEST", label: "SINGLE_TEST"},
        {value: "PANEL", label: "PANEL"}
    ]

    const [createPanel, setCreatePanel] = useState([]);
    const [heading, setHeading] = useState("")

    useEffect(() => {
        if (panel?.length > 0) {
            let arr = []
            panel.map((v) => {
                if (isPanel) arr.push({ value: v.panelName, label: v.panelName, allValue: v })
                else arr.push({ value: v.reportName, label: v.reportName, allValue: v })
            })
            setCreatePanel([...arr]);
        } else {
            setCreatePanel([]);
        }
    }, [panel])

    const addNewDetailsInRecord = () => {
        let changeRecord = { ...record };
        if (heading !== "" && changeRecord['details'].length > 0) {
            let lastRecord = changeRecord['details'][changeRecord['details'].length - 1];
            const initObj = { headingName: heading, testList: [{ ...initLabTest }] };
            let tmp = lastRecord.testList.some(v => JSON.stringify(v) === JSON.stringify(initLabTest))
            if (tmp) {
                changeRecord['details'].splice(changeRecord['details'].length - 1, 1, initObj)
            } else {
                changeRecord['details'].push(initObj);
            }
            setHeading('');
        }
        setRecord({ ...changeRecord });
    }

    const recordDetailsAllValue = (data) => {
        let details = [];
        if (data && Object.keys(data).length) {
            data && data.details && data.details.length && data.details.map((item) => {
                let data = [];
                item && item.testList && item.testList.map(ele => {
                    const payload = {
                        testName: ele?.testName ? ele?.testName : '',
                        category: ele?.category ? ele?.category : '',
                        department: ele?.department ? ele?.department : '',
                        testCode: ele?.testCode ? ele?.testCode : '',
                        sample: ele?.sample ? ele?.sample : '',
                        container: ele?.container ? ele?.container : '',
                        size: ele?.sampleSize?.size ? ele?.sampleSize?.size : '',
                        ml: ele?.sampleSize?.ml ? ele?.sampleSize?.ml : '',
                        price: ele?.price ? ele?.price : '',
                        reportSize: ele?.report?.size ? ele?.report?.size : '',
                        timeline: ele?.report?.timeline ? ele?.report?.timeline : '',
                        homeCollection: ele?.homeCollection ? ele?.homeCollection : '',
                        referenceValues: ele?.referenceValues ? ele?.referenceValues : [],
                        notes: ele?.notes ? ele?.notes : '',
                    };
                    data.push(payload);
                });
                const list = {
                    headingName: item.headingName ? item.headingName : "",
                    testList: data.length > 0 ? data : [{ ...initLabTest }]
                };
                details.push(list);
            });
            if (details.length === 0) {
                details.push({ headingName: "", testList: [{ ...initLabTest }] })
            }
            return details;
        }
    };

    useEffect(()=>{
        if(record.reportName && record.reportName!=="" && record.details.length===0){
            record.details = [{ headingName: "", testList: [{ ...initLabTest }] }]
            setRecord({...record})
        }   
    },[record])

    const handleChange = (e) => {
        let newArr = { ...record };
        if (isPanel) newArr['panelName'] = e.value;
        else newArr['reportName'] = e.value;
        if (e?.__isNew__) {
            const newValue = { value: e.value, label: e.value, allValue: { headingName: "", testList: [{ ...initLabTest }] } };
            let newpanel = [...createPanel];
            let tmp = newpanel.some(v => v.value === e.value);
            if (!tmp) newpanel.push(newValue);
            setCreatePanel([...newpanel]);
            newArr['details'] = [{ headingName: "", testList: [{ ...initLabTest }] }];
            setPanelId('');
        } else {
            newArr['details'] = recordDetailsAllValue(e.allValue);
            setPanelId(e.allValue._id)
        }
        setRecord({ ...newArr });
    }

    const handleTypeChange = (e) => {
        let newArr = { ...record };
        if(e){
            newArr.type = e
        }
        setRecord({ ...newArr });
    }

    const setLabTestListInRecord = (testList, index) => {
        let changeRecord = { ...record }
        let arr = [];
        testList.map((v) => {
            arr.push(v);
        })
        let mainArray = [];
        changeRecord['details'].map((v, i) => {
            if (index === i) {
                mainArray.push({ headingName: v.headingName, testList: arr })
            } else {
                mainArray.push({ headingName: v.headingName, testList: v.testList })
            }
        })
        changeRecord['details'] = mainArray;
        setRecord({ ...changeRecord });
    }

    const [allLabTest, setAllLabTest] = useState([]);

    useEffect(() => {
        dispatch(getAllAdminLabTest('', '', '', true)).then((res) => {
            if (res?.data?.length > 0) {
                setAllLabTest([...res.data]);
            } else setAllLabTest([]);
        })
    }, [])

    const [selectClass, setSelectClass] = useState('');

    return (
        (modalAction === "add" || modalAction === "update") &&
        <Modal
            ModalTitle={panelId ? (isPanel ? 'Edit Panel' : 'Edit Template') : (isPanel ? 'Add Panel' : 'Add Template')}
            type="center"
            onClose={() => modalClickAction('close')}
            Modalclass={(modal && (modalAction === 'add' || modalAction === "update")) ? `show-modal` : ``}
        >
            <div className="panel-heading">
                <div className="formField">
                    <label><div className="feildRequired">{isPanel ? "Panel Name" : "Report Name"}<span className="actionField errorBox">*</span></div></label>
                    <Creatable
                        classNamePrefix="react-select"
                        options={createPanel}
                        name={isPanel ? "panelName" : "reportName"}
                        value={isPanel ? [{value: record.panelName, label: record.panelName}] : [{value: record.reportName, label: record.reportName}]}
                        onChange={(e) => { handleChange(e); }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                    />
                </div>
                {
                    !isPanel && 
                    <div className="formField">
                        <label><div className="feildRequired">Report Type<span className="actionField errorBox">*</span></div></label>
                        <Select
                            classNamePrefix="react-select"
                            options={reportTypes}
                            name={"reportType"}
                            value={reportTypes.filter((option) => {
                                return option.value === (record.type)
                            })}
                            onChange={(e) => { handleTypeChange(e); }}
                            placeholder="Select"
                            openMenuOnFocus={true}
                        />
                    </div>
                }
                <div className="add-heading">
                    <FormInput
                        title="Heading Name"
                        inputType="text"
                        placeholder="Add Heading"
                        name="headingName"
                        onChange={(e) => { setHeading(e.target.value) }}
                        value={heading}
                    />
                    <span className="addheading" onClick={() => { addNewDetailsInRecord() }} >
                        <img src={addicon} alt="arr" />
                    </span>
                </div>
            </div>
            <div className={`panel-heading-table ${selectClass}`}>
                {
                    record?.details?.length > 0 && record.details.map((v, i) => {
                        return (
                            <div key={i}>
                                {
                                    v.headingName !== "" && <TableTitle title={v.headingName} />
                                }
                                <LabTestAddModal
                                    addLabTest={v.testList}
                                    modalAction={modalAction}
                                    isSingleArray={false}
                                    index={i}
                                    setAddDataValues={setLabTestListInRecord}
                                    creatableLabTest={allLabTest}
                                    setSelectClass={setSelectClass}
                                />
                            </div>
                        )
                    })
                }
            </div>
            <div className="activeButton right">
                <Button
                    title={'Reset'}
                    buttonType="reset"
                    onClick={() => resetForm()}
                />
                <Button
                    loaderClass={['add', 'update'].includes(modalAction) ? showBtnLoader : ""}
                    title={panelId ? 'Edit' : 'Add'}
                    onClick={(e) => { e.preventDefault(); dispatchAction(panelId ? 'update' : 'add'); }}
                />
            </div>
        </Modal>
    )
}