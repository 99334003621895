import { Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import Edit from '../../../assets/images/Edit.svg';
import Delete from '../../../assets/images/delete.svg';
import { dateSortingForTable, formatDate, nameSortingForTable } from "../../../common";

export default function DoccineUniversityList({ doccineUniversityList, modalClickAction }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", width: 50 },
        {
            title: "Video Link", dataIndex: "videoLink", width: 350, sorter: (a, b ) => nameSortingForTable(a.videoLink, b.videoLink), render: (url) => {
                return (
                    <a className="link" onClick={() => window.open(url)}>{url}</a>
                )
            }
        },
        { title: "Video Title", dataIndex: "videoTitle", sorter: (a, b ) => nameSortingForTable(a.videoTitle, b.videoTitle) },
        { title: "Description", dataIndex: "description", sorter: (a, b ) => nameSortingForTable(a.description, b.description) },
        { title: "Category", dataIndex: "category", sorter: (a, b ) => nameSortingForTable(a.category, b.category) },
        { title: "Uploaded Date", dataIndex: "uploadedDate", sorter: (a, b ) => dateSortingForTable(a.uploadedDate, b.uploadedDate), render: (_,{uploadedDate}) => formatDate(uploadedDate, true) },
        { title: "Video For", dataIndex: "videoFor", sorter: (a, b ) => nameSortingForTable(a.videoFor, b.videoFor) },
        { title: "Duration", dataIndex: "duration", sorter: (a, b ) => nameSortingForTable(a.duration, b.duration) },
        {
            title: 'Action',
            width: 100,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'updateNewVideo', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (doccineUniversityList?.length > 0) {
            doccineUniversityList.map((v, i) => {
                let obj = {
                    no: i + 1,
                    key: v._id,
                    videoLink: v.link,
                    videoTitle: v.title,
                    description: v.description,
                    category: v.category,
                    uploadedDate: v?.createdAt || '-',
                    videoFor: v.userRoles && v.userRoles.join(", "),
                    duration: "-",
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [doccineUniversityList])

    return (
        <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
    )
}