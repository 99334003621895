import { Skeleton, TimePicker } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { adminOnBoardVideoLinkListOne, adminOnBoardVideoLinkUpsert } from "../../actions/onBoardVideo";
import { removeMainLoaderClass } from "../../common";
import Button from "../../Components/Button";
import TableTitle from "../../Components/TableTitle";
import './style.scss';

export default function NotificationSettings() {

    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const [emailTime, setEmailTime] = useState("07:00 AM");
    const [smsTime, setSmsTime] = useState("07:00 AM");
    const [wpTime, setWpTime] = useState("07:00 AM");

    useEffect(() => {
        setLoading(true);
        dispatch(adminOnBoardVideoLinkListOne("MESSAGE_TEMPLATE")).then((res) => {
            setLoading(false);
            removeMainLoaderClass();
            if (res && res[0] && res[0].value) {
                setEmailTime(res[0].value.emailTime)
                setSmsTime(res[0].value.smsTime)
                setWpTime(res[0]?.value?.wpTime !== "" ? res[0].value.wpTime : wpTime)
            }
        });
    }, [])

    const updateTimeOfEmailSMS = () => {
        if (emailTime && smsTime) {
            let payload = {
                key: "MESSAGE_TEMPLATE",
                value: {
                    emailTime: emailTime,
                    smsTime: smsTime,
                    wpTime: wpTime,
                },
                group: "MESSAGE_TEMPLATE"
            }
            setShowBtnLoader('show-button-loader');
            dispatch(adminOnBoardVideoLinkUpsert(payload)).then(() => {
                setShowBtnLoader('');
            }).catch(() => {
                setShowBtnLoader('')
            })
        }
    }

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Followup Triggers" />
                        </div>
                        <div className="followup-reminder-setting border-top min-height">
                            <div className="label-with-time">
                                <label className="label-title">Email trigger time for followup appointments:
                                    <span className="label-image">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.50022 0.000223243C8.82296 -0.0099681 10.1239 0.337627 11.2652 1.00622C12.3989 1.66109 13.3404 2.60258 13.9952 3.73622C14.6633 4.8774 15.0105 6.17792 15.0002 7.50022C15.0104 8.82296 14.6628 10.1239 13.9942 11.2652C13.3394 12.3989 12.3979 13.3404 11.2642 13.9952C10.123 14.6633 8.82253 15.0105 7.50022 15.0002C6.17748 15.0104 4.87659 14.6628 3.73522 13.9942C2.60158 13.3394 1.66009 12.3979 1.00522 11.2642C0.337151 10.123 -0.0100907 8.82253 0.000223243 7.50022C-0.0099681 6.17748 0.337627 4.87659 1.00622 3.73522C1.66109 2.60158 2.60258 1.66009 3.73622 1.00522C4.8774 0.337151 6.17792 -0.0100907 7.50022 0.000223243ZM8.75022 12.1782V10.3232C8.75142 10.281 8.74426 10.2389 8.72916 10.1994C8.71406 10.16 8.69131 10.1239 8.66222 10.0932C8.63502 10.0634 8.60179 10.0396 8.56472 10.0236C8.52765 10.0076 8.4876 9.99961 8.44722 10.0002H6.57222C6.48777 10.0032 6.40759 10.0381 6.34784 10.0978C6.28809 10.1576 6.25321 10.2378 6.25022 10.3222V12.1772C6.25321 12.2617 6.28809 12.3419 6.34784 12.4016C6.40759 12.4614 6.48777 12.4962 6.57222 12.4992H8.44722C8.4876 12.4998 8.52765 12.4919 8.56472 12.4758C8.60179 12.4598 8.63502 12.4361 8.66222 12.4062C8.69131 12.3756 8.71406 12.3395 8.72916 12.3C8.74426 12.2605 8.75142 12.2185 8.75022 12.1762V12.1782ZM8.73022 8.81822L8.90622 2.75322C8.9076 2.71748 8.89897 2.68207 8.8813 2.65097C8.86363 2.61988 8.83763 2.59433 8.80622 2.57722C8.74012 2.52551 8.65813 2.4983 8.57422 2.50022H6.42622C6.34118 2.49796 6.25803 2.52556 6.19122 2.57822C6.15982 2.59533 6.13381 2.62088 6.11614 2.65197C6.09848 2.68307 6.08984 2.71848 6.09122 2.75422L6.26022 8.81822C6.26162 8.85273 6.27147 8.88637 6.28891 8.91619C6.30634 8.946 6.33083 8.97108 6.36022 8.98922C6.42826 9.03902 6.51095 9.06471 6.59522 9.06222H8.40022C8.48302 9.06516 8.56428 9.03937 8.63022 8.98922C8.65783 8.96921 8.68098 8.94367 8.6982 8.91423C8.71541 8.8848 8.72632 8.8521 8.73022 8.81822Z" fill="#4677F0" />
                                        </svg>
                                    </span>
                                </label>
                                <TimePicker
                                    clearIcon={false}
                                    value={moment(emailTime, "hh:mm A")}
                                    format="hh:mm A"
                                    onChange={(time, timeString) => setEmailTime(timeString)}
                                />
                            </div>
                            <div className="label-with-time">
                                <label className="label-title">SMS trigger time for followup appointments:
                                    <span className="label-image">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.50022 0.000223243C8.82296 -0.0099681 10.1239 0.337627 11.2652 1.00622C12.3989 1.66109 13.3404 2.60258 13.9952 3.73622C14.6633 4.8774 15.0105 6.17792 15.0002 7.50022C15.0104 8.82296 14.6628 10.1239 13.9942 11.2652C13.3394 12.3989 12.3979 13.3404 11.2642 13.9952C10.123 14.6633 8.82253 15.0105 7.50022 15.0002C6.17748 15.0104 4.87659 14.6628 3.73522 13.9942C2.60158 13.3394 1.66009 12.3979 1.00522 11.2642C0.337151 10.123 -0.0100907 8.82253 0.000223243 7.50022C-0.0099681 6.17748 0.337627 4.87659 1.00622 3.73522C1.66109 2.60158 2.60258 1.66009 3.73622 1.00522C4.8774 0.337151 6.17792 -0.0100907 7.50022 0.000223243ZM8.75022 12.1782V10.3232C8.75142 10.281 8.74426 10.2389 8.72916 10.1994C8.71406 10.16 8.69131 10.1239 8.66222 10.0932C8.63502 10.0634 8.60179 10.0396 8.56472 10.0236C8.52765 10.0076 8.4876 9.99961 8.44722 10.0002H6.57222C6.48777 10.0032 6.40759 10.0381 6.34784 10.0978C6.28809 10.1576 6.25321 10.2378 6.25022 10.3222V12.1772C6.25321 12.2617 6.28809 12.3419 6.34784 12.4016C6.40759 12.4614 6.48777 12.4962 6.57222 12.4992H8.44722C8.4876 12.4998 8.52765 12.4919 8.56472 12.4758C8.60179 12.4598 8.63502 12.4361 8.66222 12.4062C8.69131 12.3756 8.71406 12.3395 8.72916 12.3C8.74426 12.2605 8.75142 12.2185 8.75022 12.1762V12.1782ZM8.73022 8.81822L8.90622 2.75322C8.9076 2.71748 8.89897 2.68207 8.8813 2.65097C8.86363 2.61988 8.83763 2.59433 8.80622 2.57722C8.74012 2.52551 8.65813 2.4983 8.57422 2.50022H6.42622C6.34118 2.49796 6.25803 2.52556 6.19122 2.57822C6.15982 2.59533 6.13381 2.62088 6.11614 2.65197C6.09848 2.68307 6.08984 2.71848 6.09122 2.75422L6.26022 8.81822C6.26162 8.85273 6.27147 8.88637 6.28891 8.91619C6.30634 8.946 6.33083 8.97108 6.36022 8.98922C6.42826 9.03902 6.51095 9.06471 6.59522 9.06222H8.40022C8.48302 9.06516 8.56428 9.03937 8.63022 8.98922C8.65783 8.96921 8.68098 8.94367 8.6982 8.91423C8.71541 8.8848 8.72632 8.8521 8.73022 8.81822Z" fill="#4677F0" />
                                        </svg>
                                    </span>
                                </label>
                                <TimePicker
                                    clearIcon={false}
                                    value={moment(smsTime, "hh:mm A")}
                                    format="hh:mm A"
                                    onChange={(time, timeString) => setSmsTime(timeString)}
                                />
                            </div>
                            <div className="label-with-time">
                                <label className="label-title">WhatsApp trigger time for followup appointments:
                                    <span className="label-image">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.50022 0.000223243C8.82296 -0.0099681 10.1239 0.337627 11.2652 1.00622C12.3989 1.66109 13.3404 2.60258 13.9952 3.73622C14.6633 4.8774 15.0105 6.17792 15.0002 7.50022C15.0104 8.82296 14.6628 10.1239 13.9942 11.2652C13.3394 12.3989 12.3979 13.3404 11.2642 13.9952C10.123 14.6633 8.82253 15.0105 7.50022 15.0002C6.17748 15.0104 4.87659 14.6628 3.73522 13.9942C2.60158 13.3394 1.66009 12.3979 1.00522 11.2642C0.337151 10.123 -0.0100907 8.82253 0.000223243 7.50022C-0.0099681 6.17748 0.337627 4.87659 1.00622 3.73522C1.66109 2.60158 2.60258 1.66009 3.73622 1.00522C4.8774 0.337151 6.17792 -0.0100907 7.50022 0.000223243ZM8.75022 12.1782V10.3232C8.75142 10.281 8.74426 10.2389 8.72916 10.1994C8.71406 10.16 8.69131 10.1239 8.66222 10.0932C8.63502 10.0634 8.60179 10.0396 8.56472 10.0236C8.52765 10.0076 8.4876 9.99961 8.44722 10.0002H6.57222C6.48777 10.0032 6.40759 10.0381 6.34784 10.0978C6.28809 10.1576 6.25321 10.2378 6.25022 10.3222V12.1772C6.25321 12.2617 6.28809 12.3419 6.34784 12.4016C6.40759 12.4614 6.48777 12.4962 6.57222 12.4992H8.44722C8.4876 12.4998 8.52765 12.4919 8.56472 12.4758C8.60179 12.4598 8.63502 12.4361 8.66222 12.4062C8.69131 12.3756 8.71406 12.3395 8.72916 12.3C8.74426 12.2605 8.75142 12.2185 8.75022 12.1762V12.1782ZM8.73022 8.81822L8.90622 2.75322C8.9076 2.71748 8.89897 2.68207 8.8813 2.65097C8.86363 2.61988 8.83763 2.59433 8.80622 2.57722C8.74012 2.52551 8.65813 2.4983 8.57422 2.50022H6.42622C6.34118 2.49796 6.25803 2.52556 6.19122 2.57822C6.15982 2.59533 6.13381 2.62088 6.11614 2.65197C6.09848 2.68307 6.08984 2.71848 6.09122 2.75422L6.26022 8.81822C6.26162 8.85273 6.27147 8.88637 6.28891 8.91619C6.30634 8.946 6.33083 8.97108 6.36022 8.98922C6.42826 9.03902 6.51095 9.06471 6.59522 9.06222H8.40022C8.48302 9.06516 8.56428 9.03937 8.63022 8.98922C8.65783 8.96921 8.68098 8.94367 8.6982 8.91423C8.71541 8.8848 8.72632 8.8521 8.73022 8.81822Z" fill="#4677F0" />
                                        </svg>
                                    </span>
                                </label>
                                <TimePicker
                                    clearIcon={false}
                                    value={moment(wpTime, "hh:mm A")}
                                    format="hh:mm A"
                                    onChange={(time, timeString) => setWpTime(timeString)}
                                />
                            </div>
                        </div>
                        <div className="activeButton right">
                            <Button
                                loaderClass={showBtnLoader ? showBtnLoader : ""}
                                title="Update"
                                onClick={(e) => { e.preventDefault(); updateTimeOfEmailSMS() }}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
        </>
    )
}