import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { FILE_CATEGORY_LIST } from "./types";

//fetch payment list
export const fetchFilesCategory = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.fileCategory.adminGet}`,
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: FILE_CATEGORY_LIST,
                payload: response.data
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update payments
export const updateFileCategory = (data, key, fileCategory, action) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.fileCategory.updateFileCategory}`,
            data: data,
        }).then((response) => {
            resolve(response)
            let mainArray = [...fileCategory.value];
            let tmp = mainArray.findIndex(paymentl => paymentl.key === key);
            if (action === "delete") {
                if (tmp >= 0) mainArray.splice(tmp, 1);
            } else if (action === "update") {
                if (tmp >= 0) mainArray.splice(tmp, 1, response.data);
            } else if (action === "add") {
                if (tmp < 0) mainArray.unshift(response.data)
            }
            fileCategory.value = mainArray
            dispatch({
                type: FILE_CATEGORY_LIST,
                payload: fileCategory
            });
        }).catch((error) => {
            reject(error);
        });
    });
}