import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import deleteicon from "../../assets/images/delete.svg";
import addicon from "../../assets/images/add-icon.svg";
import { toast } from "react-toastify";
import { Skeleton } from "antd";
import TableTitle from "../../Components/TableTitle";
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import MultiLingualList from "./MultiLingualList";
import { multiLanguageDelete, multiLanguageInsertMany, multiLanguageList, multiLanguageUpsert } from "../../actions/multiLingual";
import { adminOnBoardVideoLinkListOne } from "../../actions/onBoardVideo";
import { closeModal, openModal } from "../../actions/modal";
import { emptyField, MEDICINEDAYSRANGE, MEDICINEFREQUENCY, MEDICINETIME, removeMainLoaderClass } from "../../common";
import DiseaseModals from "../Diseases/DiseaseModals";
import Modal from "../../Components/Modal";
import './style.scss';

export default function MultiLingual(props) {

    const { modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [modalAction, setModalAction] = useState('');

    const [langList, setLangList] = useState([]);

    const [name, setName] = useState('');

    const [group, setGroup] = useState('');
    const groups = MEDICINETIME.concat(MEDICINEFREQUENCY).concat(MEDICINEDAYSRANGE);
    const singleGroup = {
        "group": "",
        "value": langList.map(v => {
            return { lang: v.lang, value: "" }
        }),
    }
    const [multiGroups, setMultiGroups] = useState([singleGroup])
    const [multiLangs, setMultiLangs] = useState([]);
    const [valuesError, setValuesError] = useState([]);

    const [showBtnLoader, setShowBtnLoader] = useState('');

    const resetForm = () => {
        setGroup('');
        setMultiGroups([singleGroup]);
        let currentErrors = [];
        setValuesError([...currentErrors]);
        setName('')
    }

    const resetModal = () => {
        setModalAction('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case "add":
                break;
            case 'update':
                let changes = [...multiGroups];
                changes[0]['group'] = data.group;
                let arr = [];
                data.value.forEach((a, i) => {
                    let obj = {};
                    obj.lang = a.lang;
                    obj.value = a.value;
                    arr.push(obj);
                })
                const lis = [...langList]
                lis.forEach((v) => {
                    let tmp = arr.some(a => a.lang === v.lang);
                    if (!tmp) {
                        let obj = {};
                        obj.lang = v.lang;
                        obj.value = '';
                        arr.push(obj);
                    }
                })
                changes[0]['value'] = arr;
                setMultiGroups([...changes]);
                setName(findValueFromArray(groups, data.group))
                break;
            case 'delete':
                setGroup(data.group);
                setName(findValueFromArray(groups, data.group))
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        let currentErrors = [];
        const mainArray = [...multiGroups];
        //Do validation and generate Errors
        mainArray.forEach((v, i) => {
            currentErrors[i] = {};
            if (emptyField(v.group)) {
                currentErrors[i].group = "Group is required."
            }
            if (action === "insertMany") {
                let tmp = multiLangs.some(p => p.group === v.group);
                if (tmp) {
                    currentErrors[i].group = `${findValueFromArray(groups, v.group)} is already added.`
                }
            }
            if (Object.keys(currentErrors[i]).length === 0) {
                delete currentErrors[i];
            }
        })
        setValuesError([...currentErrors]);
        switch (action) {
            //Insert Many Api call of multiple data
            case 'insertMany':
                if (Object.keys(currentErrors).length === 0) {
                    let insertObj = {
                        docs: multiGroups
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(multiLanguageInsertMany(insertObj)).then((res) => {
                        if (res?.data?.length > 0) {
                            let arr = [];
                            res.data.map((v) => {
                                arr.push(v);
                            })
                            let main = [...multiLangs];
                            let mergeArray = main.concat(arr);
                            setMultiLangs([...mergeArray]);
                        }
                        setShowBtnLoader('');
                        modalClickAction('close');
                    }).catch((error) => {
                        setShowBtnLoader('');
                        if (error.code === 11000) toast.error('Value is already added.')
                    })
                }
                break;
            //For Update Purpose of single data
            case 'add':
                if (Object.keys(currentErrors).length === 0) {
                    let payload = {
                        "group": multiGroups[0].group,
                        "value": multiGroups[0].value
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(multiLanguageUpsert(payload)).then((res) => {
                        if (res.data) {
                            let changeLang = [...multiLangs];
                            let index = changeLang.findIndex(v => v.group === res.data.group);
                            if (index >= 0) {
                                changeLang[index] = res.data;
                            } else {
                                changeLang.push(res.data);
                            }
                            setMultiLangs([...changeLang])
                        }
                        setShowBtnLoader('');
                        modalClickAction('close');
                    }).catch((error) => {
                        setShowBtnLoader('')
                        if (error.code === 11000) toast.error('Value is already added.')
                    })
                }
                break;
            //Delete Single data
            case 'delete':
                if (group) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(multiLanguageDelete(group)).then((res) => {
                        let changeLang = [...multiLangs];
                        let index = changeLang.findIndex(v => v.group === group);
                        if (index >= 0) {
                            changeLang.splice(index, 1);
                        }
                        setMultiLangs([...changeLang]);
                        setShowBtnLoader('');
                        modalClickAction('close');
                    })
                }
                break;
            default:
        }
    }

    const handleGroupAdd = () => {
        let changeValues = [...multiGroups];
        let tmp = changeValues.some(v => JSON.stringify(v) === JSON.stringify(singleGroup));
        if (!tmp) {
            changeValues.push(singleGroup);
        }
        setMultiGroups([...changeValues]);
    }

    const handleGroupRemove = (i) => {
        let changeLang = [...multiGroups];
        if (changeLang.length > 1) {
            changeLang.splice(i, 1);
            setMultiGroups([...changeLang]);
        } else {
            changeLang = { ...singleGroup };
            setMultiGroups([changeLang]);
        }
        let changeError = { ...valuesError }
        if (changeError && changeError[i]) {
            delete changeError[i];
        }
        setValuesError({ ...changeError })
    }

    const handleChange = (e, i, j, name) => {
        let changeLang = [...multiGroups];
        changeLang[i].value[j][name] = e.target.value;
        setMultiGroups([...changeLang]);
    }

    const handleSelectChange = (e, i, name) => {
        let changeLang = [...multiGroups];
        changeLang[i][name] = e.value;
        setMultiGroups([...changeLang]);
    }

    const handleGroupErrorChange = (i, name) => {
        let changeError = { ...valuesError }
        if (changeError && changeError[i] && changeError[i][name]) {
            delete changeError[i][name];
        }
        setValuesError({ ...changeError })
    }

    const handleValueErrorChange = (i, j, name) => {
        let changeError = { ...valuesError }
        if (changeError && changeError[i] && changeError[i][j] && changeError[i][j][name]) {
            delete changeError[i][j][name];
        }
        setValuesError({ ...changeError })
    }

    useEffect(() => {
        setLoading(true);
        dispatch(multiLanguageList("")).then((res) => {
            removeMainLoaderClass();
            setLoading(false)
            if (res.data) {
                setMultiLangs([...res.data]);
            }
        });
        dispatch(adminOnBoardVideoLinkListOne("LANGUAGES_LIST")).then((res) => {
            if (res.length > 0) {
                setLangList([...res[0].value]);
            } else {
                setLangList([]);
            }
        });
    }, []);

    const [list, setList] = useState([]);
    useEffect(() => {
        let arr = [];
        if (langList.length > 0) {
            langList.map((v) => {
                arr.push({ value: v.lang, label: v.lang })
            })
        }
        setList([...arr]);
    }, [langList])

    //Find Label from selection array which is use in react-select opions to show in table
    const findValueFromArray = (arr, value) => {
        let str = ""
        arr.map((v) => {
            if (v.value === value) {
                str = v.label;
            }
        })
        return str;
    }

    const [search, setSearch] = useState('');
    const [speList, setSpeList] = useState([]);

    useEffect(() => {
        if (multiLangs?.length > 0) setSpeList([...multiLangs]);
        else setSpeList([]);
    }, [multiLangs])

    useEffect(() => {
        if (multiLangs?.length > 0) {
            if (search !== "") {
                let arr = [];
                multiLangs?.map((v) => {
                    if (findValueFromArray(groups, v.group).toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setSpeList([...arr])
            } else {
                setSpeList([...multiLangs])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: speList.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="MultiLingual" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table multiLingual">
                            <MultiLingualList
                                speList={speList}
                                modalClickAction={modalClickAction}
                                list={list}
                                groups={groups}
                                findValueFromArray={findValueFromArray}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            <div className={`multi-lang-add-popup ${modalAction === "update" ? "update-modal" : ""}`}>
                {
                    (modalAction === "add" || modalAction === "update") &&
                    <Modal
                        ModalTitle={modalAction === "update" ? "Update Language" : "Add Language"}
                        onClose={() => modalClickAction('close')}
                        type="center"
                        Modalclass={(modal && (modalAction === "add" || modalAction === "update")) ? 'show-modal' : ''}
                    >
                        <div className="language-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Value</th>
                                        {
                                            list.map(v => {
                                                return (
                                                    <th>{v.value}</th>
                                                )
                                            })
                                        }
                                        {
                                            modalAction !== "update" && <th className="action"></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        multiGroups && multiGroups.length > 0 && multiGroups.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <div className="formField">
                                                            <Select
                                                                required="required"
                                                                classNamePrefix="react-select"
                                                                options={groups}
                                                                placeholder="Select Group"
                                                                name="groupName"
                                                                id="groupName"
                                                                onChange={(e) => { handleSelectChange(e, i, "group"); handleGroupErrorChange(i, 'group') }}
                                                                value={groups.filter((option) => {
                                                                    return option.value === item.group;
                                                                })}
                                                            />
                                                            {valuesError && valuesError[i] && valuesError[i].group && <div className="actionField errorBox">{valuesError[i].group}</div>}
                                                        </div>
                                                    </td>
                                                    {
                                                        list.map((v, j) => {
                                                            let lang = item?.value.find((m) => { if(m.lang === v.value){ return m.value;}})

                                                            return (
                                                                <td key={j}>
                                                                    <div className="no-label">
                                                                        <FormInput
                                                                            name='value'
                                                                            placeholder="Value"
                                                                            value={lang && lang.value}
                                                                            onChange={(e) => { handleChange(e, i, j, "value"); handleValueErrorChange(i, j, 'value') }}
                                                                            errorMessage={valuesError[i] && valuesError[i][j] && valuesError[i][j]?.value}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        modalAction !== "update" &&
                                                        <td>
                                                            <div className="action">
                                                                <span className="add-group" onClick={() => handleGroupAdd()}>
                                                                    <img alt={addicon} src={addicon} />
                                                                </span>
                                                                <span className="remove-lang" onClick={() => handleGroupRemove(i)}>
                                                                    <img alt={deleteicon} src={deleteicon} />
                                                                </span>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="activeButton right">
                                <Button
                                    title="Cancel"
                                    buttonType="reset"
                                    onClick={() => modalClickAction('close')}
                                />
                                <Button
                                    loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                                    title={modalAction === "add" ? "Add" : "Update"}
                                    onClick={(e) => { e.preventDefault(); modalAction === "add" ? dispatchAction('insertMany') : dispatchAction('add') }}
                                />
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        </>
    )
}