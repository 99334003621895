import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import TableTitle from '../../../Components/TableTitle'; 
import FormInput from '../../../Components/FormInput';
import Button from '../../../Components/Button';
import { ExtraSubMenuNotifications } from '../../Common';
import WPTemplateList from './WPTemplateList';
import { addWPTemplates, deleteWPTemplates, fetchWPTemplatesList, updateWPTemplates } from '../../../actions/wpTemplate';
import { closeModal, openModal } from '../../../actions/modal';
import WPTemplateAddModal from './WPTemplateAddModal';
import { emptyField, removeMainLoaderClass } from '../../../common';
import Modal from '../../../Components/Modal';
import DiseaseModals from '../../Diseases/DiseaseModals';
import { toast } from 'react-toastify';

export default function WPTemplate({ activeSubMenu, setActiveSubMenu }) {

    const { wpTemplates = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [image, setImage] = useState('');
    const [modalAction, setModalAction] = useState('');
    const [templateId, setTemplateId] = useState(null);
    const [showBtnLoader, setShowBtnLoader] = useState('');
    const [WPData, setWPData] = useState({
        "name": '',
        "trigger": '',
        "image": '',
        "category": "",
        'templateid': ''
    });
    const [error, setError] = useState({});

    const resetForm = () => {
        setWPData({
            "name": '',
            "trigger": '',
            "image": '',
            "category": "",
            'templateid': ''
        });
        setError({});
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        switch (action) {
            case 'add':
                break;
            case 'update':
                setTemplateId(data._id);
                let changedField = { ...WPData };
                changedField['name'] = data.name ? data.name : '';
                changedField['trigger'] = data.key_alias ? data.key_alias : '';
                changedField['templateid'] = data.templateid ? data.templateid : '';
                changedField['image'] = data.image ? data.image : '';
                changedField['category'] = data.category ? data.category : '';
                setImage(data.image)
                setWPData({ ...changedField });
                break;
            case 'delete':
                setTemplateId(data._id);
                let changed = { ...WPData };
                changed['name'] = data.name ? data.name : '';
                setWPData({ ...changed });
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    }

    // Dispatching the action
    const dispatchAction = (action) => {
        let payload = {
            name: WPData.name,
            key_alias: WPData.trigger,
            image: WPData.image,
            category: WPData.category,
            templateid: WPData.templateid
        };
        let customError = {}
        if (action === "add" || action === "update") {
            if (emptyField(payload.name)) {
                customError.name = "Trigger name is required";
            }
            if (emptyField(payload.key_alias)) {
                customError.trigger  = "Trigger key is required";
            }
            if (emptyField(payload.category)) {
                customError.category  = "Trigger category is required";
            }
            if (emptyField(payload.templateid)) {
                customError.templateid = "Template name is required";
            }
        }
        setError({ ...customError })
        if (Object.keys(customError).length === 0) {
            switch (action) {
                case 'add':
                    let formData = new FormData();
                    formData.append("name", payload.name);
                    formData.append("key_alias", payload.key_alias);
                    formData.append("templateid", payload.templateid);
                    formData.append("category", payload.category);
                    formData.append("image", image);
                    setShowBtnLoader('show-button-loader')
                    dispatch(addWPTemplates(formData, wpTemplates)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                        toast.success('Template has been added.')
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                    break;
                case 'update':
                    if (templateId) {
                        const updateFormData = new FormData();
                        updateFormData.append("key_alias", payload.key_alias);
                        updateFormData.append("name", payload.name);
                        updateFormData.append("templateid", payload.templateid);
                        updateFormData.append("category", payload.category);
                        updateFormData.append("image", image);
                        setShowBtnLoader('show-button-loader')
                        dispatch(updateWPTemplates(templateId, updateFormData, wpTemplates)).then(() => {
                            setShowBtnLoader('');
                            modalClickAction('close');
                            toast.success('Template has been updated.')
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    }
                    break;
                case 'delete':
                    if (templateId) {
                        
                        setShowBtnLoader('show-button-loader')
                        dispatch(deleteWPTemplates(templateId, wpTemplates)).then(() => {
                            setShowBtnLoader('');
                            modalClickAction('close');
                            toast.success('Template has been deleted.')
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    }
                    break;
                default:
            }
        }
    };

    useEffect(() => {
        if (activeSubMenu) { 
            setLoading(true);
            dispatch(fetchWPTemplatesList()).then(() => {
                setLoading(false);
                removeMainLoaderClass();
            }).catch(() => {
                setLoading(false);
                removeMainLoaderClass();
            });
        }
    }, [activeSubMenu])

    const [temps, setTemps] = useState([]);
    useEffect(() => {
        if (wpTemplates.length > 0) {
            setTemps([...wpTemplates]);
        } else {
            setTemps([]);
        }
    }, [wpTemplates])

    useEffect(() => {
        if (wpTemplates?.length > 0) {
            if (search !== "") {
                let arr = [];
                wpTemplates?.forEach((v) => {
                    if (v.name.toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setTemps([...arr])
            } else {
                setTemps([...wpTemplates])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                        <div className="adminTableHeader">
                            <TableTitle title={"WhatsApp Template List"} />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenuNotifications}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setSearch(''); }}
                                        placeholder="Select Status"
                                        value={ExtraSubMenuNotifications.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table notifications">
                            <WPTemplateList
                                temps={temps}
                                modalClickAction={modalClickAction}
                                dispatch={dispatch}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <div className="medium-modal add-template-editor">
                {
                    ['add', 'update'].includes(modalAction) &&
                    <Modal
                        type="center"
                        ModalTitle={modalAction === "add" ? "Add WhatsApp Template" : "Edit WhatsApp Template"}
                        onClose={() => modalClickAction('close')}
                        Modalclass={(modal && ['add', 'update'].includes(modalAction)) ? 'show-modal' : ''}
                    >
                        <WPTemplateAddModal
                            WPData={WPData}
                            setWPData={setWPData}
                            setImage={setImage}
                            image={image}
                            error={error}
                            setError={setError}
                            activeSubMenu={activeSubMenu}
                            modal={modal}
                            modalAction={modalAction}
                            modalClickAction={modalClickAction}
                            resetForm={resetForm}
                            showBtnLoader={showBtnLoader}
                            dispatchAction={dispatchAction}
                        />
                        <div className="activeButton right">
                            <Button
                                title="Reset"
                                buttonType="reset"
                                onClick={() => resetForm()}
                            />
                            <Button
                                loaderClass={['add', 'update'].includes(modalAction) && showBtnLoader ? showBtnLoader : ""}
                                title={modalAction === "add" ? "Add" : "Edit"}
                                onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                            />
                        </div>
                    </Modal>
                }
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={WPData.name}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
        </>
    )
}