import { Switch, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { formatDate, getUTCTime, nameSortingForTable, S3_BASE_URL } from "../../../../common";
import Edit from '../../../../assets/images/Edit.svg';
import Delete from '../../../../assets/images/delete.svg';
import { updateWPTemplates } from "../../../../actions/wpTemplate";

export default function WPTemplateList({ temps, modalClickAction, dispatch }) {

    const [data, setData] = useState([]);

    const onChange = (checked, i, id) => {
        let change = [...data];
        change[i]['status'] = checked
        setData([...change]);
        dispatch(updateWPTemplates(id, { isActive: checked }, temps));
    }

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", width: 50 },
        { title: "Template Name", dataIndex: "name", sorter: (a, b) => nameSortingForTable(a?.name, b?.name)},
        { title: "Trigger Key", dataIndex: "trigger" , sorter: (a, b) => nameSortingForTable(a?.trigger, b?.trigger)},
        { title: "Trigger Category", dataIndex: "category", sorter: (a, b) => nameSortingForTable(a?.category, b?.category) },
        { title: "Image", dataIndex: "image",
         render: (row) => {
            return (
                <span className="payment-icon">
                    <img src={S3_BASE_URL + row} alt="payment" />
                </span>
            )
        }
    },
        {
            title: "Status", dataIndex: "status", render: (_, { status, index, key }) => {
                return (
                    <Switch
                        checked={status ? true : false}
                        onChange={(checked) => onChange(checked, index, key)}
                    />
                )
            }
        },
        {
            title: 'Action',
            width: 200,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (temps?.length > 0) {
            temps.forEach((v, i) => {
                let obj = {
                    index: i,
                    key: v._id,
                    no: i + 1,
                    name: v.name ? v.name : '-',
                    category: v.category ? v.category : '-',
                    trigger: v.key_alias ? v.key_alias : '-',
                    image: v.image ? v.image : '-',
                    status: v.isActive,
                    updatedAt: v.updatedAt ? formatDate(v.updatedAt, true) + ' ' + getUTCTime(new Date(v.updatedAt)) : '-',
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [temps])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} />
        </>
    )
}