/* eslint-disable array-callback-return */
import { Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';

export default function DowngradeReasonList({ speList, modalClickAction }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Reason", dataIndex: "reason", sorter: (a, b) => a.reason.localeCompare(b.reason) },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (speList?.length > 0) {
            speList.map((v, i) => {
                let obj = {
                    no: i + 1,
                    key: i,
                    reason: v.reason,
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [speList])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
        </>
    )
}