export const CONSTANT_ROUTES = {
    admin: {
        login: "/login",
        dashboard: "/dashboard",
        hospitals: "/hospitalmx/hospital",
        doctors: "/hospitalmx/doctor",
        receptionist: "/hospitalmx/receptionist",
        doctorSetting: "/hospitalmx/doctorSetting",
        doctorSettingkey: "/hospitalmx/doctorSetting/:key",
        hospitalSetting: "/hospitalmx/hospitalsettings",
        hospitalSettingkey: "/hospitalmx/hospitalsettings/:key",
        labs: "/labmx",
        diseases: "/datamx/diseases",
        symptoms: "/datamx/symptoms",
        // menstrualType: "/datamx/menstrual-type",
        habits: "/datamx/habits",
        pastHistory: "/datamx/pasthistory",
        medicines: "/datamx/medicines",
        laboratoryTests: "/datamx/laboratorytests",
        notifications: {
            eventTriggers: "/notifications/eventtriggers",
            setting: "/notifications/settings",
            errorlog: "/notifications/errorlog",
        },
        adminMaster: {
            vitals: "/adminmaster/vitals",
            payments: "/adminmaster/payments",
            doccineUniversity: "/adminmaster/doccineUniversity",
            multiLingual: "/adminmaster/multilingual",
            whatsNew: "/adminmaster/whatsnew",
            languages: "/adminmaster/languages",
            onboard: "/adminmaster/onboard",
            specialty: "/adminmaster/specialty",
            canvasIcon: "/adminmaster/canvasicon",
            degrees: "/adminmaster/degrees",
            managesettings: "/adminmaster/managesettings",
            aptCancelReasons: "/adminmaster/cancelreasons",
            refundAmtReasons: "/adminmaster/refundreasons",
            maintenance: "/adminmaster/maintenance",
            filecategory: "/adminmaster/filecategory",
            aptcustom: "/adminmaster/aptcustom",
            header: "/adminmaster/headerchange",
        },
        adminUserRole: {
            userRoles: "/userroles/roles",
            moduleAccess: "/userroles/moduleaccess"
        },
        pricing: {
            plans: "/pricing/plans",
            addons: "/pricing/addons",
            settings: "/pricing/settings",
            downgradeReasons: "/pricing/reasons",
        }
    }
}