import React from "react";
import Button from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import Reject from '../../../assets/images/reject.svg';
import EnableLogin from '../../../assets/images/enable-login.svg';
import DisableLogin from '../../../assets/images/disabled-login.svg';
import Delete from '../../../assets/images/delete.svg';
import { capitalizeFirstLetter } from "../../../common";
import Unarchive from "../../../assets/images/unarchive.svg";

export default function HospitalOptionModal({ showBtnLoader, modal, modalAction, modalClickAction, dispatchAction, data }) {
    return (
        (modalAction === 'disable' || modalAction === 'enable' || modalAction === 'delete' || modalAction === 'reject' || modalAction === 'undoReject' || modalAction === 'unarchive') &&
        <Modal
            className="removedoc"
            type="center"
            onClose={() => modalClickAction('close')}
            Modalclass={((modal) && (modalAction === 'disable' || modalAction === 'enable' || modalAction === 'delete' || modalAction === 'reject'  || modalAction === 'undoReject' || modalAction === 'unarchive')) ? `show-modal` : ``}
        >
            <span className="modal-action-icon">
                {modalAction === "disable" && <span className="modal-icon"><img className="" src={DisableLogin} alt={DisableLogin} /></span>}
                {modalAction === "enable" && <span className="modal-icon"><img src={EnableLogin} alt={EnableLogin} /></span>}
                {modalAction === "delete" && <span className="modal-icon"><img src={Delete} alt={Delete} /></span>}
                {modalAction === "undoReject" && <span className="modal-icon"><img src={Delete} alt={Delete} /></span>}
                {modalAction === "reject" && <span className="modal-icon"><img src={Reject} alt={Reject} /></span>}
                {modalAction === "unarchive" && <span className="modal-icon"><img src={Unarchive} alt={Unarchive} /></span>}
            </span>
            <div className="modal-action-info">
                {
                    modalAction === "disable" ?
                        <p className="text-center">
                            All users of <strong>{data}</strong> will be disabled. <br />
                            Are you sure you want to <em> {capitalizeFirstLetter(modalAction)} </em> ?
                        </p>
                        :
                        <p className="text-center">
                            Are you sure you want to
                            <em> {capitalizeFirstLetter(modalAction === "undoReject" ? 'Undo Reject' : modalAction)} </em>
                            the request of <br />
                            <strong>{data}</strong> ?
                        </p>
                }
                <div className="activeButton center">
                    <Button
                        loaderClass={['disable', 'enable', 'delete', 'reject', 'undoReject','unarchive'].includes(modalAction) ? showBtnLoader : ""}
                        title="Confirm"
                        onClick={() => dispatchAction(modalAction)}
                    />
                    <Button
                        btn="no-bg"
                        title="Cancel"
                        onClick={() => modalClickAction('close')}
                    />
                </div>
            </div>
        </Modal>
    )
}