import { Switch, Table, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';
import View from '../../../assets/images/eye-outline-blue.svg';
import { capitalizeFirstLetter } from "../../../common";

export default function PricingPlanList({ onChange, records, modalClickAction }) {

    const [data, setData] = useState([]);

    const columns = [
        { title: "No", dataIndex: "no", width: 50 },
        { title: "Name", dataIndex: "name", sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: "Hospital Count", dataIndex: "hospitalCount", sorter: (a, b) => a.hospitalCount - b.hospitalCount },
        { title: "Plan Size", dataIndex: "planSize" },
        { title: "Charges (₹)", dataIndex: "charges", sorter: (a, b) => a.charges - b.charges },
        {
            title: "Status", dataIndex: "status", render: (_, { status, key }) => {
                return (
                    <Switch
                        checked={status ? true : false}
                        onChange={(checked) => onChange(checked, key)}
                    />
                )
            }
        },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="View">
                            <span className="view" onClick={() => modalClickAction('open', "view", row.data)}>
                                <img src={View} alt={View} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (records?.length > 0) {
            records.map((v, i) => {
                let obj = {
                    no: i + 1,
                    key: v._id,
                    name: v.planName,
                    hospitalCount: v.hospitalcount,
                    planSize: v.planSize ? capitalizeFirstLetter(v.planSize) : '-',
                    charges: v.charges ? v.charges : 'Free',
                    status: v.isActive ? v.isActive : false,
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [records])

    return (
        <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
    )
}