/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
import React, { useState, useEffect } from "react";
import HospitalAddModal from './HospitalAddModal';
import HospitalsList from './HospitalsList';
import HospitalOptionModal from './HospitalOptionModal';
import { useSelector, useDispatch } from "react-redux";
import './style.scss';
import TableTitle from "../../Components/TableTitle";
import FormInput from "../../Components/FormInput";
import { closeModal, openModal } from "../../actions/modal";
import { capitalizeFirstLetter, debounce, emptyField, getTodayDate, removeMainLoaderClass, validEmail } from "../../common";
import { addHospital, approvalHospital, deleteHospital, disableEnableHospital, fetchHospitalList, goToHospitalSettings, hospitalFilters, unarchiveHospital, updateHospital } from "../../actions/hospitals";
import Button from "../../Components/Button";
import { Skeleton, notification } from 'antd';
import Formcheckbox from "../../Components/Formcheckbox";
import { listPricingPlanName } from "../../actions/pricingPlan";
import { useCallback } from "react";
import Select from "react-select";

export default function HospitalMx(props) {

    //for skeleton
    const [loading, setLoading] = useState(false);

    let currentFormErrors = [];
    const { hospitals = [], modal, auth, hospitalFilterData = {} } = useSelector((state) => state);

    const dispatch = useDispatch();

    const [hospitalId, setHospitalId] = useState(null);
    const [isBeta, setIsBeta] = useState(false);
    //pricing plan state
    const [plan, setPlan] = useState('');

    // Filters data list
    let statusListData = [
        {label: 'Status - All', value: '',  name: ''},
        {label: 'Pending', value: 'pending', name: 'pending'},
        {label: 'Active', value: 'active', name: 'active'},
        {label: 'Disabled', value: 'disabled', name: 'disabled'},
        {label: 'Archived', value: 'archived', name: 'archived'},
        {label: 'Rejected', value: 'rejected', name: 'rejected'},
    ];
    let typeListData = [
        {label: 'Type - All', value: '', name: ''},
        {label: 'Production', value: 'production', name: 'production'},
        {label: 'Beta', value: 'beta', name: 'beta'},
    ];
    let hospitalListData = [
        {label: 'Hospital - All', value: '', name: ''},
        {label: 'Live', value: 'live', name: 'live'},
        {label: 'Testing', value: 'testing', name: 'testing'},
    ];
    // status
    const [status, setStatus] = useState('');
    const [statusList, setStatusList] = useState([]);
    // Type
    const [type, setType] = useState('');
    const [typeList, setTypeList] = useState([]);
    // Hospital filter
    const [hospitalFilter, setHospitalFilter] = useState('');
    const [hospitalFilterList, setHospitalFilterList] = useState([]);


    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [search, setSearch] = useState('');

    // state list which is used in api 
    const [hospitalFormData, setHospitalFormData] = useState({
        "name": '',
        "isBeta": false,
        "isTesting": false,
        "regNo": '',
        "contact": '',
        "countryCode": "+91",
        "emailId": '',
        "address": '',
        "address2": '',
        "city": '',
        "state": '',
        "country": '',
        "pincode": '',
        "latitude": "",
        "longitude": "",
        "plotNo": "",
        "pricingId": "",
        "expiryDate": "",
        "isExpiry": false,
        "usedSMS": 0,
        "noOfSMS": 0,
        "noOfWp": 0,
        "usedWp": 0,
        "isApproved": 0,
        "isDoccineWaterMarkShow": false
    });

    const [FormErrors, setFormErrors] = useState(currentFormErrors);

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton1, setModalButton1] = useState('');
    const [modalButton2, setModalButton2] = useState('');

    const [showBtnLoader, setShowBtnLoader] = useState('');
    // tab active key
    const [activeKey, setActiveKey] = useState("hospitalInfo");
    const resetForm = () => {
        currentFormErrors.name = '';
        currentFormErrors.emailId = '';
        currentFormErrors.contact = '';
        setFormErrors(currentFormErrors);
        setHospitalFormData({
            "name": '',
            "isBeta": false,
            "isTesting": false,
            "regNo": '',
            "contact": '',
            "countryCode": "+91",
            "emailId": '',
            "address": '',
            "address2": '',
            "city": '',
            "state": '',
            "country": '',
            "pincode": '',
            "latitude": "",
            "longitude": "",
            "plotNo": "",
            "pricingId": "",
            "expiryDate": "",
            "isExpiry": false,
            "noOfSMS": 0,
            "noOfWp": 0,
            "isApproved": 0,
            "isDoccineWaterMarkShow": false
        });
    };

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton1('');
        setModalButton2('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                setModalTitle('Add Hospital');
                setModalButton1('Close');
                setModalButton2('Add Hospital');
                break;
            case 'changeQuota':
                setActiveKey('pricingAndSms');
            case 'update':
                setModalTitle('Edit Hospital');
                setModalButton1('Close');
                setModalButton2('Edit Hospital');
                setHospitalId(data._id);
                let changedField = { ...hospitalFormData };
                changedField["name"] = data?.name;
                changedField["isBeta"] = data?.pricingPlanId === "beta" ? true : false;
                changedField["isTesting"] = data?.isTesting || false;
                changedField["isDoccineWaterMarkShow"] = data?.isDoccineWaterMarkShow || false;
                changedField["regNo"] = data?.regNo;
                changedField["countryCode"] = data?.countryCode;
                changedField["contact"] = data?.contact;
                changedField["emailId"] = data?.emailId;
                changedField["pricingId"] = data.pricingId ? data.pricingId._id : "";
                changedField["isApproved"] = data.isApproved ? data.isApproved : 0;
                changedField["isExpiry"] = data.pricingPlanNoExpiry === true ? true : false;
                changedField["expiryDate"] = data.pricingPlanExpireDate ? new Date(data.pricingPlanExpireDate) : "";
                if (data.address) {
                    changedField["city"] = data.address.city ? data.address.city : "";
                    changedField["state"] = data.address.state ? data.address.state : "";
                    changedField["plotNo"] = data.address.plotNo ? data.address.plotNo : "";
                    changedField["country"] = data.address.country ? data.address.country : "";
                    changedField["pincode"] = data.address.pincode ? data.address.pincode : "";
                    changedField["address"] = data.address.line1 ? data.address.line1 : "";
                    changedField["address2"] = data.address.line2 ? data.address.line2 : "";
                    changedField["latitude"] = data.address.latitude ? data.address.latitude : "";
                    changedField["longitude"] = data.address.longitude ? data.address.longitude : "";
                }
                changedField["usedSMS"] = data?.usedSMS ? data.usedSMS : 0;
                changedField["noOfSMS"] = data?.noOfSMS ? data.noOfSMS : 0;
                changedField["usedWp"] = data?.usedWp ? data.usedWp : 0;
                changedField["noOfWp"] = data?.noOfWp ? data.noOfWp : 0;
                changedField['usedBefore'] = data?.usedBefore || [];
                changedField['noOfDoctors'] = data?.noOfDoctors || '';
                changedField['heardAbout'] = data?.heardAbout || '';
                setHospitalFormData({ ...changedField });
                break;
            case 'approve':
                setModalTitle('Hospital Details');
                setModalButton1('Reject Hospital');
                setModalButton2('Approve Hospital');
                setHospitalId(data._id);
                let changeField2 = { ...hospitalFormData };
                changeField2["name"] = data?.name;
                changeField2["isBeta"] = data?.pricingPlanId === "beta" ? true : false;
                changeField2["isTesting"] = data?.isTesting || false;
                changeField2["isDoccineWaterMarkShow"] = data?.isDoccineWaterMarkShow || false;
                changeField2["regNo"] = data?.regNo;
                changeField2["countryCode"] = data?.countryCode;
                changeField2["contact"] = data?.contact;
                changeField2["emailId"] = data?.emailId;
                changeField2["isApproved"] = data.isApproved ? data.isApproved : 0;
                changeField2["pricingId"] = data?.pricingId ? data?.pricingId?._id : "";
                changeField2["isExpiry"] = data?.pricingPlanNoExpiry === true ? true : false;
                changeField2["expiryDate"] = data?.pricingPlanExpireDate ? new Date(data.pricingPlanExpireDate) : "";
                if (data.address) {
                    changeField2["plotNo"] = data.address.plotNo;
                    changeField2["city"] = data.address.city;
                    changeField2["state"] = data.address.state;
                    changeField2["country"] = data.address.country;
                    changeField2["pincode"] = data.address.pincode;
                    changeField2["address"] = data.address.line1;
                    changeField2["address2"] = data.address.line2;
                    changeField2["latitude"] = data.address.latitude;
                    changeField2["longitude"] = data.address.longitude;
                }
                changeField2["usedSMS"] = data?.usedSMS ? data.usedSMS : 0
                changeField2["noOfSMS"] = data?.noOfSMS ? data.noOfSMS : 0;

                changeField2["usedWp"] = data?.usedWp ? data.usedWp : 0;
                changeField2["noOfWp"] = data?.noOfWp ? data.noOfWp : 0;
                setHospitalFormData({ ...changeField2 });
                break;
            //eslint-disable-next-line
            case 'reject':
            //eslint-disable-next-line
            case 'disable':
            //eslint-disable-next-line
            case 'enable':
            //eslint-disable-next-line
            case 'unarchive':
            //eslint-disable-next-line
            case 'delete':
                let changedField1 = { ...hospitalFormData };
                changedField1["name"] = data.name;
                setHospitalFormData({ ...changedField1 });
                setHospitalId(data._id);
                break;
            case 'goToSettings':
                dispatch(goToHospitalSettings(data._id)).then((res) => {
                    window.open(res, '_blank');
                })
                break;
            case 'undoReject':
                let changedField2 = { ...hospitalFormData };
                changedField2["name"] = data.name;
                setHospitalFormData({ ...changedField2 });
                setHospitalId(data._id);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };
    const dispatchAction = (action) => {
        let payload = {
            name: capitalizeFirstLetter(hospitalFormData.name),
            regNo: hospitalFormData.regNo,
            contact: hospitalFormData.contact?.trim(),
            countryCode: hospitalFormData.countryCode,
            emailId: hospitalFormData?.emailId?.trim(),
            address: {
                "plotNo": hospitalFormData.plotNo,
                "line1": hospitalFormData.address,
                "line2": hospitalFormData.address2,
                "city": hospitalFormData.city,
                "state": hospitalFormData.state,
                "country": hospitalFormData.country,
                "pincode": hospitalFormData.pincode,
                "latitude": hospitalFormData.latitude,
                "longitude": hospitalFormData.longitude,
            },
            pricingPlanId: hospitalFormData.isBeta === true ? "beta" : "",
            isTesting: hospitalFormData.isTesting,
            isDoccineWaterMarkShow: hospitalFormData.isDoccineWaterMarkShow,
            noOfSMS: hospitalFormData.noOfSMS,
            noOfWp: hospitalFormData.noOfWp
        };
        if (!emptyField(hospitalFormData.pricingId)) {
            payload.pricingId = hospitalFormData.pricingId;
            payload.pricingPlanNoExpiry = hospitalFormData.isExpiry
            if (hospitalFormData.isExpiry === false && hospitalFormData.expiryDate) {
                payload.pricingPlanExpireDate = getTodayDate(hospitalFormData.expiryDate, 23, 59, 59, 999);
            }
            payload.pricingAddedbyAdmin = auth.id;
        }
        if (emptyField(hospitalFormData.name)) {
            currentFormErrors.name = 'Name is required';
        }
        // if (emptyField(hospitalFormData.emailId)) {
        //     currentFormErrors.emailId = 'Email is required';
        // }
        // if (hospitalFormData.emailId && validEmail(hospitalFormData.emailId)) {
        //     currentFormErrors.emailId = 'Email is invalid';
        // }
        if (emptyField(payload.contact)) {
            currentFormErrors.contact = 'Contact is required';
        }
        if (payload?.contact && payload?.contact?.trim()?.length !== 10) {
            currentFormErrors.contact = 'Contact maximum length 10';
        }
        setFormErrors(currentFormErrors);
        if ((Object.keys(currentFormErrors).length === 0 && (action === 'add' || action === 'update' || action === 'changeQuota')) || (action === 'disable' || action === 'enable' || action === 'delete' || action === 'approve' || action === 'reject' || action === 'undoReject' || action === 'unarchive')) {
            switch (action) {
                case 'add':
                    setShowBtnLoader('show-button-loader');
                    dispatch(addHospital(payload, hospitals)).then(() => { LoadApiCalls(1, pageSize, search, plan, status, type, hospitalFilter); setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'changeQuota':
                case 'update':
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateHospital(hospitalId, payload, hospitals)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'approve':
                    if (emptyField(payload.contact)) {
                        currentFormErrors.contact = 'Contact is required';
                    } else if (payload?.contact && payload?.contact?.trim()?.length !== 10) {
                        currentFormErrors.contact = 'Contact maximum length 10';
                    } else {
                        let data = { "hospitalIds": [hospitalId], "approval": "APPROVE" };
                        setShowBtnLoader('show-button-loader');
                        dispatch(updateHospital(hospitalId, payload, hospitals)).then(() => {
                            dispatch(approvalHospital(hospitalId, data, hospitals)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                        });
                    }

                    break;
                case 'reject':
                    let dataReject = { "hospitalIds": [hospitalId], "approval": "REJECT" }
                    setShowBtnLoader('show-button-loader');
                    dispatch(approvalHospital(hospitalId, dataReject, hospitals)).then(() => { setShowBtnLoader(''); modalClickAction('close') });
                    break;
                case 'undoReject':
                    let undoReject = { "hospitalIds": [hospitalId], "approval": "PENDING" }
                    setShowBtnLoader('show-button-loader');
                    dispatch(approvalHospital(hospitalId, undoReject, hospitals)).then(() => { setShowBtnLoader(''); modalClickAction('close') });
                    notification.success({ description: 'Undo Rejected hospital succesfully' })
                    break;
                case 'disable':
                    let disable = { "hospitalIds": [hospitalId], "block": true }
                    setShowBtnLoader('show-button-loader');
                    dispatch(disableEnableHospital(hospitalId, disable, hospitals)).then(() => { setShowBtnLoader(''); modalClickAction('close') });
                    break;
                case 'enable':
                    setShowBtnLoader('show-button-loader');
                    let enable = { "hospitalIds": [hospitalId], "block": false }
                    dispatch(disableEnableHospital(hospitalId, enable, hospitals)).then(() => { setShowBtnLoader(''); modalClickAction('close') });
                    break;
                case 'delete':
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteHospital(hospitalId, '', hospitals)).then(() => { setShowBtnLoader(''); modalClickAction('close') });
                    break;
                case 'unarchive':
                    setShowBtnLoader('show-button-loader');
                    dispatch(unarchiveHospital(hospitalId,'',hospitals)).then(() => { setShowBtnLoader(''); modalClickAction('close') });
                    break;
                default:
                    throw new Error('Modal action not match');
            }
        }
    };

    const LoadApiCalls = (page, pageSize, search, planId, statusValue, typeValue, hospitalTestValue) => {
        let pagination = { "pageNo": page, "pageSize": pageSize };
        let filter = { 
            "isApproved": ["0", "-1", "1"], 
            "isActive": "all", 
            "isTesting": hospitalTestValue === 'testing' ? true : hospitalTestValue === 'live' ? false :  'all',
            "status": statusValue,
            "pricingPlanId": typeValue === 'beta' ? 'beta' : typeValue === 'production' ? '' : 'all'
        };
        let paramSearch = { value: search };
        // if (isBeta === true) {
        //     filter.pricingPlanId = "beta"
        // }
        if (planId) {
            filter.pricingId = planId
        }
        if (search === "") setLoading(true);
        dispatch(fetchHospitalList(pagination, filter, paramSearch, true)).then(() => {
            removeMainLoaderClass();
            if (search === "") setLoading(false);
        }).catch(() => {
            removeMainLoaderClass();
            if (search === "") setLoading(false);
        });
    }


    useEffect(() => {
        LoadApiCalls(page, pageSize, search, plan, status, type, hospitalFilter);
        //eslint-disable-next-line
    }, [page, pageSize, plan, status, type, hospitalFilter])

    const [list, setList] = useState([]);

    const filtersDataFormatValue = (listData, responseData) => {
        let updatedStatusListData = listData.map((status) => {
            const count = responseData[status.name];
            const labelWithCount = count !== undefined ? `${status.label} - ${count}` : status.label;
            return {
                label: labelWithCount,
                value: status.value,
            };
        });
        return updatedStatusListData;
    }
    useEffect(() => {
        dispatch(hospitalFilters());
    }, []);

    useEffect(() => {
        if (Object.keys(hospitalFilterData)?.length > 0) {
            dispatch(listPricingPlanName({ planType: "PLAN" })).then((res) => {
                if (res.records.length > 0) {
                    let arr = [{ value: '', label: 'Pricing Plan - All' }];
                    res.records.map((v) => {
                        arr.push({ value: v._id, label: v.planName + ' ' + (capitalizeFirstLetter(v.planSize)) });
                    })
                    if (hospitalFilterData.pricing.length > 0) {
                        let updatedArr = arr.map((item) => {
                            const matchingPricing = hospitalFilterData.pricing.find((p) => p.pricingId === item.value);
                            const hospitalCount = matchingPricing ? matchingPricing.hospitalCount : '';
                            return {
                                value: item.value,
                                label: hospitalCount ? `${item.label} - ${hospitalCount}` : `${item.label}`,
                            };
                        });
                        setList([...updatedArr]);
                    } else {
                        setList([...arr]);
                    }
                }
            });
            if(Object.keys(hospitalFilterData?.response)?.length > 0){
                let status = filtersDataFormatValue(statusListData, hospitalFilterData.response )
                setStatusList([...status]);
                let type = filtersDataFormatValue(typeListData, hospitalFilterData.response)
                setTypeList([...type]);
                let hospital = filtersDataFormatValue(hospitalListData, hospitalFilterData.response)
                setHospitalFilterList([...hospital]);
            } else {
                setStatusList([...statusListData]);
                setTypeList([...typeListData]);
                setHospitalFilterList([...hospitalListData]);
            }
        }

    }, [hospitalFilterData])

    const handleSearch = (e, page, pageSize, isBeta, planId, status, type, hospitalFilter) => {
        LoadApiCalls(page, pageSize, e.target.value, planId, status, type, hospitalFilter);
    }

    const handleDebounceSearch = useCallback(debounce(handleSearch, 800), []);

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Manage Hospitals" />
                            <div className="table-header-search">
                                <span>Total Hospitals: {hospitals.count}</span>
                            </div>
                        </div>
                        <div className="adminTableHeader borders no-space">
                            <div className="left-adminHeader">
                                {/* <Formcheckbox
                                inputType="checkbox"
                                id="betaUsers"
                                name="betaUsers"
                                title="Beta Hospitals"
                                onChange={(e) => { setIsBeta(e.target.checked); setSearch('') }}
                                isChecked={isBeta ? "checked" : ""}
                            /> */}
                                <Select
                                    isClearable={false}
                                    isSearchable={false}
                                    required="required"
                                    classNamePrefix="react-select"
                                    options={statusList}
                                    placeholder="Status"
                                    name="statusFilter"
                                    id="statusFilter"
                                    onChange={(e) => { e?.value ? setStatus(e.value) : setStatus(''); setPage(1) }}
                                    value={statusList.filter((option) => {
                                        return option.value === status;
                                    })}
                                />
                                <Select
                                    isClearable={false}
                                    isSearchable={false}
                                    required="required"
                                    classNamePrefix="react-select"
                                    options={typeList}
                                    placeholder="Type"
                                    name="typeFilter"
                                    id="typeFilter"
                                    onChange={(e) => { e?.value ? setType(e.value) : setType(''); setPage(1) }}
                                    value={typeList.filter((option) => {
                                        return option.value === type;
                                    })}
                                />
                                <Select
                                    isClearable={false}
                                    isSearchable={false}
                                    required="required"
                                    classNamePrefix="react-select"
                                    options={list}
                                    placeholder="Pricing Plan"
                                    name="planFilter"
                                    id="planFilter"
                                    onChange={(e) => { e?.value ? setPlan(e.value) : setPlan(''); setPage(1) }}
                                    value={list.filter((option) => {
                                        return option.value === plan;
                                    })}
                                />
                                <Select
                                    isClearable={false}
                                    isSearchable={false}
                                    required="required"
                                    classNamePrefix="react-select"
                                    options={hospitalFilterList}
                                    placeholder="Hospitals"
                                    name="hospitalFilter"
                                    id="hospitalFilter"
                                    onChange={(e) => { e?.value ? setHospitalFilter(e.value) : setHospitalFilter(''); setPage(1) }}
                                    value={hospitalFilterList.filter((option) => {
                                        return option.value === hospitalFilter;
                                    })}
                                />
                            </div>
                            <div className="table-header-search">

                                <div className="no-label table-search">
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); setPage(1); handleDebounceSearch(e, 1, pageSize, isBeta, plan) }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add New"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table hospitals">
                            <HospitalsList
                                hospitals={hospitals}
                                modalClickAction={modalClickAction}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                auth={auth}
                                isHospital={true}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            {/* Delete Modal */}
            <HospitalOptionModal
                showBtnLoader={showBtnLoader}
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                dispatchAction={dispatchAction}
                data={hospitalFormData.name}
            />
            {/* Add/Edit/Approve Modal */}
            <div className="AddNew add-hospital-modal">
                <HospitalAddModal
                    showBtnLoader={showBtnLoader}
                    modal={modal}
                    modalAction={modalAction}
                    modalButton1={modalButton1}
                    modalButton2={modalButton2}
                    modalClickAction={modalClickAction}
                    modalTitle={modalTitle}
                    hospitalFormData={hospitalFormData}
                    setHospitalFormData={setHospitalFormData}
                    FormErrors={FormErrors}
                    setFormErrors={setFormErrors}
                    resetForm={resetForm}
                    dispatchAction={dispatchAction}
                    isHospital={true}
                    list={list}
                    setPage={setPage}
                    setSearch={setSearch}
                    handleDebounceSearch={handleDebounceSearch}
                    hospitalId={hospitalId}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                />
            </div>
        </>
    );
}
