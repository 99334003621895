import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { Skeleton } from "antd";
import { closeModal, openModal } from "../../actions/modal";
import Button from "../../Components/Button";
import TableTitle from "../../Components/TableTitle";
import FormInput from "../../Components/FormInput";
import { fetchUserRoleList } from "../../actions/userRole";
import { adminOnBoardVideoLinkUpsert, adminOnBoardVideoLinkListOne, adminOnBoardVideoLinkDelete } from "../../actions/onBoardVideo";
import OnBoardVideoList from "./OnBoardVideoList";
import DiseaseModals from "../Diseases/DiseaseModals";
import Modal from "../../Components/Modal";
import { removeMainLoaderClass } from "../../common";

export default function OnBoardVideos(props) {

    const { modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton, setModalButton] = useState('');

    const [roleName, setRoleName] = useState([]);
    const [link, setLink] = useState('');
    const [roleNameErr, setRoleNameErr] = useState('');
    const [linkErr, setLinkErr] = useState('');

    const [showBtnLoader, setShowBtnLoader] = useState('');

    const resetForm = () => {
        setRoleName('');
        setLink('');
    }

    const resetModal = () => {
        setModalButton('');
        setModalTitle('');
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case 'add':
                setModalTitle('Add Video Link');
                setModalButton('Add');
                break;
            case 'update':
                setModalTitle('Edit Video Link');
                setModalButton('Edit');
                setRoleName(data.role);
                setLink(data.link);
                break;
            case 'delete':
                setRoleName(data.role);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        switch (action) {
            case 'update':
            case 'add':
                let add = {
                    key: roleName,
                    value: link,
                    group: "ONBOARD_VIDEO"
                }
                if (roleName !== "" && link !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(adminOnBoardVideoLinkUpsert(add)).then((res) => {
                        let newArray = [...mainLinks];
                        let tmp = newArray.findIndex((v) => v.role === res.key);
                        if (tmp >= 0) {
                            newArray[tmp] = { role: res.key, link: res.value }
                        } else {
                            newArray.push({ role: res.key, link: res.value })
                        }
                        setMainLinks([...newArray]);
                        setShowBtnLoader('')
                        modalClickAction('close');
                    }).catch(() => setShowBtnLoader(''));
                } else {
                    if (roleName === "") {
                        setRoleNameErr('Role Name is required')
                    }
                    if (link === "") {
                        setLinkErr('Link is required')
                    }
                }
                break;
            case 'delete':
                if (roleName !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(adminOnBoardVideoLinkDelete(roleName)).then((res) => {
                        setShowBtnLoader('');
                        let newArray = [...mainLinks];
                        let tmp = newArray.findIndex((v) => v.role === roleName);
                        if (tmp >= 0) {
                            newArray.splice(tmp, 1);
                        }
                        setMainLinks([...newArray]);
                        modalClickAction('close');
                    }).catch(() => setShowBtnLoader(''));
                }
                break;
            default:
                throw new Error('Modal action not match');
        }
    }

    const [userRoles, setUserRoles] = useState([]);
    const [mainLinks, setMainLinks] = useState([]);

    useEffect(() => {
        dispatch(fetchUserRoleList()).then((res) => {
            if (res.data && res.data.DEFAULT) {
                let arr = [];
                res.data.DEFAULT.map((v) => {
                    arr.push({ label: v.name, value: v.name })
                })
                setUserRoles([...arr]);
            }
        });
        setLoading(true);
        dispatch(adminOnBoardVideoLinkListOne("ONBOARD_VIDEO")).then((res) => {
            setLoading(false);
            removeMainLoaderClass();
            let linkArr = [];
            if (res) {
                res.map((v) => {
                    linkArr.push({ role: v.key, link: v.value })
                })
                setMainLinks([...linkArr])
            }
        }).catch(() => {
            setLoading(false);
            removeMainLoaderClass();
        })
    }, []);

    const [search, setSearch] = useState('');
    const [speList, setSpeList] = useState([]);

    useEffect(() => {
        if (mainLinks?.length > 0) setSpeList([...mainLinks]);
        else setSpeList([]);
    }, [mainLinks])

    useEffect(() => {
        if (mainLinks?.length > 0) {
            if (search !== "") {
                let arr = [];
                mainLinks?.map((v) => {
                    if (v.role.toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setSpeList([...arr])
            } else {
                setSpeList([...mainLinks])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: speList.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="On-Board Video List" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table onboardvideos">
                            <OnBoardVideoList
                                speList={speList}
                                modalClickAction={modalClickAction}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={roleName}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            {(modalAction === "add" || modalAction === "update") &&
                <Modal
                    ModalTitle={modalTitle}
                    type="center"
                    onClose={() => modalClickAction('close')}
                    Modalclass={(modal && (modalAction === "add" || modalAction === "update")) ? 'show-modal' : ''}
                >
                    <div className="formField">
                        <label><div className="feildRequired">Role<span className="actionField errorBox">*</span></div></label>
                        <Select
                            required="required"
                            classNamePrefix="react-select"
                            options={userRoles}
                            placeholder="Type Role Name"
                            name="roleName"
                            id="roleName"
                            isDisabled={modalAction === 'update' ? true : false}
                            onChange={(e) => { setRoleName(e.value); setRoleNameErr('') }}
                            value={userRoles.filter((option) => {
                                return option.value === roleName;
                            })}
                        />
                        {roleNameErr && <div className="actionField errorBox">{roleNameErr}</div>}
                    </div>
                    <FormInput
                        placeholder="Please Enter Link"
                        inputType="text"
                        title="Link"
                        value={link}
                        onChange={(e) => { setLink(e.target.value); setLinkErr('') }}
                        required="required"
                        errorMessage={linkErr}
                    />
                    <div className="activeButton right">
                        <Button
                            title="Cancel"
                            buttonType="reset"
                            onClick={() => modalClickAction('close')}
                        />
                        <Button
                            loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                            title={modalButton}
                            onClick={(e) => { e.preventDefault(); dispatchAction(modalAction) }}
                        />
                    </div>
                </Modal>
            }
        </>
    )
}