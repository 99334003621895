import FormInput from "../../../../Components/FormInput";
import TextArea from 'antd/lib/input/TextArea';
import Select from "react-select";

export default function SmsTemplateAddModal({ smsData, setSmsData, error, setError, modalAction }) {
    const CATEGORY = [
        { value: 'OWNER', label: 'Owner' },
        { value: 'ADMIN', label: 'Admin' },
    ]
    //on chnage event of form
    const handleChange = (e, name, isSelect) => {
        let change = { ...smsData };
        if (isSelect) {
            const value = e.value;
            change[name] = value;
        } else {
            const value = e.target.value;
            change[name] = value;
        }
        setSmsData({ ...change });
        let err = { ...error };
        if (err[name]) {
            delete err[name];
        }
        setError({ ...err });
    }

    return (
        <>
            <div className="input-fields-templates">
                <FormInput
                    inputType="text"
                    placeholder="Name"
                    name="triggerName"
                    title="Trigger Name"
                    required="required"
                    onChange={(e) => { handleChange(e, 'name', false); }}
                    value={smsData.name}
                    errorMessage={error?.name}
                />
                <FormInput
                    inputType="text"
                    placeholder="Trigger Key"
                    name="triggerKey"
                    title="Trigger Key"
                    required="required"
                    onChange={(e) => { handleChange(e, 'trigger', false); }}
                    value={smsData.trigger}
                    isDisabled={modalAction==="update" ? true : false}
                    errorMessage={error?.trigger}
                />
                <FormInput
                    inputType="text"
                    placeholder="Template Id"
                    name="templateId"
                    title="Template Id"
                    required="required"
                    onChange={(e) => { handleChange(e, 'tempId', false); }}
                    value={smsData.tempId}
                    errorMessage={error?.tempId}
                />
                <div className="formField">
                    <label>
                        <div className="feildRequired">Template Body<span className="actionField errorBox">*</span></div>
                    </label>
                    <TextArea
                        name="templateBody"
                        value={smsData.tempBody}
                        placeholder="Enter Value"
                        onChange={(e) => {handleChange(e, 'tempBody', false);}}
                        required={true}
                    />
                    {error?.tempBody && <div className="actionField errorBox">{error.tempBody}</div>}
                </div>
                <div className="formField add-zindex">
                    <label><div className="feildRequired">Category<span className="actionField errorBox">*</span></div></label>
                    <Select
                        classNamePrefix="react-select"
                        options={CATEGORY}
                        name="category"
                        value={CATEGORY.filter((option) => {
                            return option.value === smsData.category
                        })}
                        onChange={(e) => { handleChange(e, 'category', true); }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                    />
                    {error?.category && <div className="actionField errorBox">{error.category}</div>}
                </div>
            </div>
        </>
    )
}