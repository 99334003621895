import { Skeleton } from 'antd';
import React from 'react'
import { useState } from 'react';
import TableTitle from '../../Components/TableTitle';
import ErrorLogList from './ErrorLogList';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getErrorLogs, updateErrorLogStatus } from '../../actions/errorLog';
import { removeMainLoaderClass } from '../../common';
import ViewErrorLogModal from './ViewErrorLogModal';
import './styles.scss'

const ErrorLog = () => {
    const { errorLogList } = useSelector((state) => state);
    const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1);
  const [viewError, setViewError] = useState(false)
  const [errordetails, setErrorDetails] = useState([])
  

  const dispatchAction = (action, type , row, value) => {
    let payload = {
        id:row?.key,
        data: {
           type: type,
           value:value
        }
    }
    switch (action) {
        case 'resolve':
            dispatch(updateErrorLogStatus(payload)).then(()=>{
                LoadApiCalls(page, pageSize)
             }).catch(() => {
                setLoading(false);
                removeMainLoaderClass();
             })
            break;
        case 'unresolve':
            dispatch(updateErrorLogStatus(payload)).then(()=>{
                LoadApiCalls(page, pageSize)
             }).catch(() => {
                setLoading(false);
                removeMainLoaderClass();
             })
            break;
        case 'visited':
            setViewError(false)
            dispatch(updateErrorLogStatus(payload)).then(()=>{
                LoadApiCalls(page, pageSize)
             }).catch(() => {
                setLoading(false);
                removeMainLoaderClass();
             })
            break;
            default:
    }}
  
  const LoadApiCalls = ( page, pageSize) => {
    let payload = {
        "page": page,
         "limit": pageSize
    }
    setLoading(true);
    dispatch(getErrorLogs(payload)).then(() => {
        setLoading(false);
        removeMainLoaderClass();
    }).catch(() => {
        setLoading(false);
        removeMainLoaderClass();
    })
}

  useEffect(()=>{
    LoadApiCalls(page, pageSize)
  }, [page, pageSize])

  return (
    <>
    <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Error Logs" />
                        </div>
                        <div className="table vitals">
                            <ErrorLogList
                                error={errorLogList}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                                dispatchAction={dispatchAction}
                                setViewError={setViewError}
                                setErrorDetails={setErrorDetails}
                            />
                        </div>
                    </Skeleton>
                </div>
    </div>

    {
        viewError && 
        <div className='error-log'>
        <ViewErrorLogModal
        state={viewError}
        setState={setViewError}
        dispatchAction={dispatchAction}
        errordetails={errordetails}

        />
        </div>
    }
    
    
    </>
  )
}

export default ErrorLog