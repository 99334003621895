import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { LABORATORY_TEST_LIST } from "./types";

//admin lab test data list
export const getAllAdminLabTest = (filter, pagination, extraData, isType) => (dispatch) => {
    let body = { filter, pagination, extraData }
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.laboratoryTests.labTests.get}`,
            data: body
        }).then((response) => {
            resolve(response.data);
            if (!isType) {
                dispatch({
                    type: LABORATORY_TEST_LIST,
                    payload: {
                        count: response.data.count,
                        data: response.data.data,
                    }
                })
            }
        }).catch((e) => {
            reject(e);
        })
    })
};

//approve and reject labtest
export const approvalTest = (testId, data, laboratoryTests) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.laboratoryTests.labTests.approve}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...laboratoryTests.data]
            let index = mainArray?.findIndex(lab => lab._id === testId);
            if (index >= 0) {
                mainArray.splice(index, 1, response.data.find(data => data._id === testId));
            }
            dispatch({
                type: LABORATORY_TEST_LIST,
                payload: {
                    count: laboratoryTests?.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//merge test apis
export const mergeTest = (testId, data, laboratoryTests) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.laboratoryTests.labTests.mergeTest}/${testId}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...laboratoryTests.data]
            dispatch({
                type: LABORATORY_TEST_LIST,
                payload: {
                    count: laboratoryTests?.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        })
    })
}

//add admin lab tests
export const adminAddLabTest = (data, laboratoryTests) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.laboratoryTests.labTests.add}`,
            data: data
        }).then((response) => {
            if (response) {
                resolve(response.data);
                let mainArray = [...laboratoryTests.data];
                response.data.map((v) => {
                    let tmp = mainArray.some((a) => a._id === v._id);
                    if (!tmp) mainArray.push(v);
                })
                dispatch({
                    type: LABORATORY_TEST_LIST,
                    payload: {
                        count: laboratoryTests?.count + response.data.length,
                        data: mainArray,
                    }
                });
            }
        }).catch((e) => {
            reject(e);
        })
    })
};

//labtest update api
export const adminUpdateTest = (testId, data, laboratoryTests) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.laboratoryTests.labTests.update}/${testId}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...laboratoryTests.data];
            response.data.map((v) => {
                let tmp = mainArray.findIndex((a) => a._id === v._id);
                if (tmp >= 0) mainArray.splice(tmp, 1, v);
            })
            dispatch({
                type: LABORATORY_TEST_LIST,
                payload: {
                    count: laboratoryTests?.count,
                    data: mainArray,
                }
            });
        }).catch((e) => {
            reject(e);
        })
    })
};

//remove labtests api
export const adminDeleteLabTest = (testId, laboratoryTests) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.laboratoryTests.labTests.delete}/${testId}`,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...laboratoryTests.data];
            let tmp = mainArray.findIndex((a) => a._id === testId);
            if (tmp >= 0) mainArray.splice(tmp, 1);
            dispatch({
                type: LABORATORY_TEST_LIST,
                payload: {
                    count: laboratoryTests?.count - 1,
                    data: mainArray,
                }
            });
        }).catch((e) => {
            reject(e);
        })
    })
};