import React from "react";
import { capitalizeFirstLetter } from "../../../common";
import Button from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import Reject from '../../../assets/images/reject.svg';
import EnableLogin from '../../../assets/images/enable-login.svg';
import DisableLogin from '../../../assets/images/disabled-login.svg';
import Delete from '../../../assets/images/delete.svg';
import Approve from '../../../assets/images/approve.svg';

export default function ReceptionistOptionModal({ showBtnLoader, modal, modalAction, modalClickAction, receptionistFormData, dispatchAction }) {
    return (
        <>
            {(modalAction === 'disable' || modalAction === 'delete' || modalAction === 'enable' || modalAction === 'approve' || modalAction === 'reject') &&
                <Modal
                    className="removedoc"
                    type="center"
                    onClose={() => modalClickAction('close')}
                    Modalclass={((modal) && (modalAction === 'disable' || modalAction === 'delete' || modalAction === 'enable' || modalAction === 'approve' || modalAction === 'reject')) ? `show-modal` : ``}
                >
                    <span className="modal-action-icon">
                        {modalAction === "disable" && <span className="modal-icon"><img className="" src={DisableLogin} alt={DisableLogin} /></span>}
                        {modalAction === "enable" && <span className="modal-icon"><img src={EnableLogin} alt={EnableLogin} /></span>}
                        {modalAction === "delete" && <span className="modal-icon"><img src={Delete} alt={Delete} /></span>}
                        {modalAction === "reject" && <span className="modal-icon"><img src={Reject} alt={Reject} /></span>}
                        {modalAction === "approve" && <span className="modal-icon"><img src={Approve} alt={Approve} /></span>}
                    </span>
                    <div className="modal-action-info">
                        <p className="text-center">
                            Are you sure you want to
                            <em> {capitalizeFirstLetter(modalAction)} </em>
                            the request of <br />
                            <strong>{receptionistFormData.firstName + ' ' + receptionistFormData.lastName}</strong> ?
                        </p>
                        <div className="activeButton center">
                            <Button
                                loaderClass={["disable", "delete", "enable", "reject", "approve"].includes(modalAction) ? showBtnLoader : ""}
                                title="Confirm"
                                onClick={(e) => { dispatchAction(modalAction, e); }}
                            />
                            <Button
                                btn="no-bg"
                                title="Cancel"
                                onClick={() => modalClickAction('close')}
                            />
                        </div>
                    </div>
                </Modal>
            }
            {
                modalAction === 'hospitalApprove' &&
                <Modal
                    className="removedoc"
                    type="center"
                    onClose={() => modalClickAction('close')}
                    Modalclass={((modal) && (modalAction === 'hospitalApprove')) ? `show-modal` : ``}
                >
                    <span className="modal-action-icon">
                        <span className="modal-icon"><img src={Approve} alt={Approve} /></span>
                    </span>
                    <div className="modal-action-info">
                        <p className="text-center">
                            Are you sure you want to
                            <em> Approve </em>
                            the request of<br />
                            hospital <strong>{capitalizeFirstLetter(receptionistFormData.hospitalName)} </strong>
                            and doctor <strong>{capitalizeFirstLetter(receptionistFormData.firstName)} {capitalizeFirstLetter(receptionistFormData.lastName)}</strong> ?
                        </p>
                        <div className="activeButton center">
                            <Button
                                loaderClass={modalAction === "hospitalApprove" ? showBtnLoader : ""}
                                title="Confirm"
                                onClick={(e) => { dispatchAction(modalAction, e); }}
                            />
                            <Button
                                btn="no-bg"
                                title="Cancel"
                                onClick={() => modalClickAction('close')}
                            />
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}