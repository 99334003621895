import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { ADMIN_LOGIN, ADMIN_LOGOUT } from "./types";

export const adminLogin = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.auth.login}`,
            data: data
        }).then((response) => {
            resolve(response);
            dispatch({
                type: ADMIN_LOGIN,
                payload: {
                    token: response.data.token,
                    role: response.data.role,
                    id: response.data._id,
                    pricingPlanId: response.data.pricingPlanId
                },
            });
        }).catch((error) => {
            reject(error);
        });
    });
};

export const adminLogout = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.auth.logout}`,
        }).then((response) => {
            resolve(response);
            dispatch({
                type: ADMIN_LOGOUT,
                payload: {},
            });
        }).catch((error) => {
            reject(error.message);
        });
    });
};
