import React, { Component } from 'react';
import ComponentHeader from './component-header';
import ComponentLabel from './component-label';
import myxss from './myxss';

class CustomElement extends Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();

  }
  render() {
    const { bare } = this.props.data;
    const props = {};
    props.type = this.props.data.props.is_numeric ? "number" : "text";
    props.name = this.props.formType ? this.props.data.props.inputName : this.props.data.field_name;
    // if( this.props.formType && this.props.data.props.formula ){
    //   props.read_only = this.props.formType ? this.props.data.props.read_only : this.props.data.read_only;
    // }else{
    //   props.read_only = this.props.data.field_name;
    // }
    props.className = `form-control ${this.props.data.key === "CmpVitals" && this.props.is_normal != "undefined" ? this.props.is_normal : ""}`;
    props.key = this.props.formType ? this.props.data.props.inputName + "_" + Math.random() : this.props.data.field_name;
    props.defaultValue = this.props.defaultValue;
    props.onBlur = this.props.handleBlur;
    props.onChange = this.props.handleChange;
    props.onKeyDown = this.props.onKeyDown;
    props.id = this.props.id;

    if (this.props.mutable && this.props.data.forwardRef) {
      props.ref = this.inputField;
    }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }
    if (this.props.readOnly) {
      props.disabled = 'disabled';
    }
    // Return if component is invalid.
    if (!this.props.data.component) {
      return null;
    }

    
    const Element = this.props.data.component;
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }
    return (
      <div className={`${baseClasses} ${this.props.formType ? `frm-${this.props.formType}-${this.props.fieldData}` : ""}`}>
        <ComponentHeader {...this.props} />
        <>
          {bare ?
            <Element data={this.props.data} {...this.props.data.props} {...props} /> :
            this.props.data.key === "CmpVitals" ?
              <div className="form-group">
                <ComponentLabel className="form-label" {...this.props} />
                <input {...props} />
                {this.props.data.props.unitValue && <span>{this.props.data.props.unitValue}</span>}
                {this.props.is_normal && <span className='Abnormal'>
                  {/* ({this.props.is_normal_label}) */}
                {
                (this.props.data.props.multiple && this.props.data.props.multipleOptions) ?
                  <div className='abnormal-tooltip'>
                    {
                      this.props.data.props.multipleOptions.map((item) => {
                        return (<p>
                          <label>{item.label}</label>
                          <span>{item.min}-{item.max}</span>
                        </p>
                        )
                      })}
                  </div>
                  :
                  ""
                }
                
                </span>}
                
              </div>
              :
              <div className="form-group">
                <ComponentLabel className="form-label" {...this.props} />
                <Element data={this.props.data} {...this.props.data.props} {...props} />
              </div>
          }
        </>
      </div>
    );
  }
}

CustomElement.propTypes = {};

export default CustomElement;
