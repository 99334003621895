import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { SYMPTOMS_LIST } from "./types";

//Fetch SymptomsCategoryList 
export const symptomCategoryList = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.symptom.categoryList}`,
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

//Fetch SymptomsList and search 
export const fetchSymptomList = (filter, pagination, extraData = {}, isType) => async (dispatch) => {
    let body = { filter, pagination, extraData };
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            data: body,
            url: `${API_URL.symptom.list}`
        }).then((response) => {
            resolve(response.data)
            if (!isType) {
                dispatch({
                    type: SYMPTOMS_LIST,
                    payload: {
                        count: response.data.approvalCount,
                        data: response.data.data
                    }
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// Symptoms Update
export const updateSymptom = (docs, symptoms) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.symptom.update}`,
            data: docs
        }).then((response) => {
            resolve(response.data);
            let obj = response.data[0];
            let mainArray = [...symptoms.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: SYMPTOMS_LIST,
                payload: {
                    count: symptoms.count,
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Symptoms Approval and Reject
export const approveSymptom = (docs, symptoms) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.symptom.approve}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let obj = response.data[0];
            let mainArray = [...symptoms.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: SYMPTOMS_LIST,
                payload: {
                    count: symptoms.count,
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

// Symptoms Delete
export const isCommonMultipleSymptom = (docs, symptoms) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.symptom.isCommon}`,
            data: docs
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...symptoms.data];
            response.data.map((item) => {
                let index = mainArray.findIndex(v => v._id === item._id);
                if (index >= 0) {
                    mainArray.splice(index, 1, item);
                }
            })
            dispatch({
                type: SYMPTOMS_LIST,
                payload: {
                    count: symptoms.count,
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

// Symptoms Delete
export const deleteSymptom = (docs, symptoms, symptomId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.symptom.delete}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...symptoms.data];
            if (Array.isArray(symptomId)) {
                symptomId.map((item) => {
                    let index = mainArray.findIndex(v => v._id === item);
                    if (index >= 0) mainArray.splice(index, 1);
                })
            } else {
                let index = mainArray.findIndex(v => v._id === symptomId);
                if (index >= 0) mainArray.splice(index, 1);
            }
            dispatch({
                type: SYMPTOMS_LIST,
                payload: {
                    count: symptoms.count - (Array.isArray(symptomId) ? symptomId.length : 1),
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

// Symptoms Merge
export const mergeSymptoms = (docs, symptoms) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.symptom.merge}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let obj = response.data[0];
            let mainArray = [...symptoms.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: SYMPTOMS_LIST,
                payload: {
                    count: symptoms.count,
                    data: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Symptoms Add Data
export const addSymptom = (docs, symptoms) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.symptom.add}`,
            data: docs
        }).then((response) => {
            let obj = response.data[0];
            let mainArray = [...symptoms.data];
            let tmp = mainArray.some(v => v._id === obj._id);
            if (!tmp) {
                mainArray.push(obj);
            }
            dispatch({
                type: SYMPTOMS_LIST,
                payload: {
                    count: symptoms.count,
                    data: mainArray
                }
            });
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}