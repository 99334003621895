import { RECEPTIONIST_LIST } from "../actions/types";

const initialState = {
    userCount: 0,
    userData: []
};

export default function receptionist(state = initialState, action) {
    switch (action.type) {
        case RECEPTIONIST_LIST:
            return {
                userCount: action.payload.userCount,
                userData: action.payload.userData,
            };
        default:
            return state;
    }
};