import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { arraySortingForTable, capitalizeFirstLetter, nameSortingForTable } from "../../../common";
import { CommonActionIconWithTooltipInANTDTable } from "../../Common";

export default function DoctorsList(props) {
    const navigate = useNavigate()
    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Doctor Name", 
            dataIndex: "name", 
            fixed: "left", 
            width: 170, 
            sorter: (a, b) => a.name.localeCompare(b.name),
            // render: (_,{name, data}) =>{
            //     return (
            //         <a onClick={()=> navigate("/hospitalmx/doctorSetting", {state:{id:data._id, allValue:data}}) }>{name}</a>
            //     )
            // }
        },
        { title: "MCI Reg No", dataIndex: "mciRegNumber", width: 150, sorter: (a, b) => nameSortingForTable(a?.mciRegNumber, b?.mciRegNumber) },
        { title: "Contact", dataIndex: "contact", width: 150, sorter: (a, b) => nameSortingForTable(a?.contact, b?.contact) },
        { title: "Email", dataIndex: "emailId", width: 320, sorter: (a, b) => nameSortingForTable(a?.emailId, b?.emailId) },
        { title: "Hospital/Clinic", dataIndex: "hospitals", width: 180, sorter: (a, b) => arraySortingForTable(a?.hospitals, b?.hospitals), render:(_, {hospitals}) => <p >{hospitals}</p> },
        { title: "Speciality", dataIndex: "speciality", width: 300, sorter: (a, b) => arraySortingForTable(a?.speciality, b?.speciality), render: (_,{ speciality }) => <span>{speciality} </span> },
        { title: "Degree", dataIndex: "degree", width: 300, sorter: (a, b) => arraySortingForTable(a?.degree, b?.degree), render: (_,{ degree }) => <span>{degree} </span> },
        { title: "Address", dataIndex: "address", width: 350, sorter: (a, b) => nameSortingForTable(a?.address, b?.address) },
        {
            title: 'Action',
            width: 200,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return CommonActionIconWithTooltipInANTDTable(row, props.modalClickAction,'','',true, false)
            },
        },
    ]

    //onClick navigate doctorSetting module
    const buttonClick = (id, v) =>{
        // navigate("/hospitalmx/doctorSetting", {state:{id:id, allValue:v}})
    }

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (props?.doctors?.userData?.length > 0) {
            props.doctors.userData.map((v, i) => {
                if (v._id) {
                    arr.push({
                        key: v._id,
                        no: i + 1,
                        name: capitalizeFirstLetter(v.firstName) + ' ' + capitalizeFirstLetter(v.lastName),
                        mciRegNumber: v?.mciRegNumber || '-',
                        contact: v?.countryCode && v.contact ? v?.countryCode + ' ' + v.contact : v.contact ? v.contact : "",
                        emailId: v?.emailId || '-',
                        hospitals: v?.hospitals?.length > 0 ? v.hospitals.map((j, i) => 
                            j?.hospitalId?.name?.trim() || '' + `${i == v?.hospitals?.length - 1 ? "" : ", "}`
                        ) : "-",
                        speciality: v?.specialty?.length > 0 ? v.specialty.map((j, i) => 
                            j + `${i == v?.specialty?.length - 1 ? "" : ", "}`
                        ) : "-",
                        degree: (
                            (v?.degree?.length > 0 &&
                              v?.degree.map(
                                (j, i) => j + (i === v?.degree?.length - 1 ? "" : "")
                              )) ||
                            []
                          )
                            .concat(
                              (v?.degreeIds?.length > 0 &&
                                v?.degreeIds.map(
                                  (j, i) =>
                                    j?.name + (i === v?.degreeIds?.length - 1 ? "" : "")
                                )) ||
                                []
                            )
                            .join(", "),
                        address: v?.address?.permanent?.line1 ? v?.address?.permanent?.line1 : '-',
                        data: v,
                    })
                }
            })
        }
        setData([...arr])
    }, [props.doctors.userData])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1500 }} />
            <Pagination
                total={props.doctors.userCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={props.pageSize}
                current={props.page}
                onChange={(page, pageSize) => { props.setPage(page); props.setPageSize(pageSize); }}
            />
        </>
    )
}