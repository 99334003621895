/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { CommonActionIconWithTooltipInANTDTable } from "../../Common";

export default function DiseaseList({ diseases, modalClickAction, page, pageSize, setPage, setPageSize, selectedRowKeys, setSelectedRowKeys, isCategory, isCommon, isHab, isSymp, isMerge, loading }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const [columns, setColumns] = useState([
        { title: "Name", dataIndex: "name", fixed: "left", width: 200, sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: "Added by", dataIndex: "addedBy", width: 130, sorter: (a, b) => a.addedBy.localeCompare(b.addedBy) },
        { title: "Merge Reference", dataIndex: "mergeReference", width: 130, sorter: (a, b) => a.mergeReference.localeCompare(b.mergeReference) },
        {
            title: "Is Common?", dataIndex: "isCommon", width: 100,
            render: (_, { isCommon }) => {
                return (
                    <Tag color={isCommon === "True" ? "green" : "red"} key={isCommon} >
                        {isCommon}
                    </Tag >
                )
            }
        },
        { title: "Category", dataIndex: "category", width: 120 },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return CommonActionIconWithTooltipInANTDTable(row, modalClickAction, true, false, false, isMerge);
            },
        },
    ])

    useEffect(() => {
        let mainField = [...columns];
        if (!isCategory) {
            let tmp = mainField.findIndex(v => v.dataIndex === "category")
            if (tmp >= 0) mainField.splice(tmp, 1);
        }
        if (!isCommon) {
            let tmp = mainField.findIndex(v => v.dataIndex === "isCommon")
            if (tmp >= 0) mainField.splice(tmp, 1);
        }
        if (!isMerge) {
            let tmp = mainField.findIndex(v => v.dataIndex === "mergeReference")
            if (tmp >= 0) mainField.splice(tmp, 1);
        }
        setColumns([...mainField]);
    }, [isCategory, isCommon])

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (diseases?.data?.length > 0) {
            diseases.data.map((v, i) => {
                let obj = {
                    key: v._id,
                    name: v.name,
                    addedBy: v?.addedBy?._id ? "Dr. " + v?.addedBy?.firstName + " " + v?.addedBy?.lastName : "-",
                    mergeReference: v.mergeReference && v.mergeReference.name ? v.mergeReference.name : "-",
                    category: isHab ? (v?.type ? v?.type : '-') : (v?.category?.name ? v?.category?.name : '-'),
                    isCommon: v.isCommon ? "True" : "False",
                    data: v
                }
                if (!isCategory) {
                    delete obj.category;
                }
                if (!isCommon) {
                    delete obj.isCommon;
                }
                if(!isMerge){
                    delete obj.mergeReference;
                }
                arr.push(obj)
                return true;
            })
        }
        setData([...arr])
    }, [diseases.data])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    }

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} rowSelection={rowSelection} loading={loading && loading} />
            <Pagination
                total={diseases.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={pageSize}
                current={page}
                onChange={(page, pageSize) => { setPage(page); setPageSize(pageSize); }}
            />
        </>
    )
}