import { ADMIN_LOGIN, ADMIN_LOGOUT } from "../actions/types";
import { defaultAxios } from "../api/general";
import { checkCookie, getCookie } from "../common";

const initialState = {
    token: localStorage.getItem("token") || '',
    role: localStorage.getItem("role") || [],
    id: localStorage.getItem("id") || [],
}

if (initialState.token) defaultAxios.defaults.headers.common['Authorization'] = "Bearer " + initialState.token;

export default function auth(state = initialState, action) {
    switch (action.type) {
        case ADMIN_LOGIN:
            //when login add token to localstorage and api headers
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("role", action.payload.role);
            localStorage.setItem("id", action.payload.id);
            defaultAxios.defaults.headers.common['Authorization'] = "Bearer " + action.payload.token;
            let PricingCookie = checkCookie('HTTP_SERVER_SELECT');
            if (PricingCookie) {
                getCookie('HTTP_SERVER_SELECT')
                defaultAxios.defaults.headers.Cookies = action?.payload?.pricingPlanId
            } else if (JSON.stringify(action?.payload?.pricingPlanId) === JSON.stringify("beta")) {
                const d = new Date();
                d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
                let expires = "expires=" + d.toUTCString();
                document.cookie = "HTTP_SERVER_SELECT" + "=" + "BETA" + ";" + expires + ";path=/";
                window.location.reload(false)
                defaultAxios.defaults.headers.Cookies = action?.payload?.pricingPlanId;
            }
            return {
                token: action.payload.token,
                role: action.payload.role,
                id: action.payload.id,
            };
        case ADMIN_LOGOUT:
            //when logout remove all localstorage and token from api headers
            let cookieValue;
            let da = document.cookie.split(';');
            da.map((data) => {
                let cookiess = data.split("=")[0].trim() === "HTTP_SERVER_SELECT" ? data.split("=")[1] : undefined;
                if (cookiess !== undefined) {
                    cookieValue = cookiess;
                }
            })
            if (cookieValue === "BETA") {
                const d = new Date();
                d.setTime(d.getTime() - (1 * 24 * 60 * 60 * 1000));
                let expires = "expires=" + d.toUTCString();
                document.cookie = "HTTP_SERVER_SELECT" + "=;" + expires + ";path=/";
                delete defaultAxios.defaults.headers.serverselect;
            }
            localStorage.clear();
            delete defaultAxios.defaults.headers.common['Authorization'];
            return {
                token: '',
                role: [],
            }
        default:
            return state;
    }
}