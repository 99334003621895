import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import TableTitle from "../../Components/TableTitle";
import Button from "../../Components/Button";
import FormInput from "../../Components/FormInput";
import { closeModal, openModal } from "../../actions/modal";
import { adminOnBoardVideoLinkUpsert, adminOnBoardVideoLinkListOne } from "../../actions/onBoardVideo";
import LanguagesList from "./LanguagesList";
import { Skeleton } from "antd";
import DiseaseModals from "../Diseases/DiseaseModals";
import Modal from "../../Components/Modal";
import { removeMainLoaderClass } from "../../common";

export default function Languages(props) {
    const { modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [langList, setLangList] = useState([]);

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [modalAction, setModalAction] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const singleLangs = { "lang": "" }

    const [langs, setLangs] = useState([singleLangs])

    const [editLang, setEditLang] = useState('');
    const [index, setIndex] = useState('');

    const resetForm = () => {
        setLangs([singleLangs]);
        setIndex('');
        setEditLang('');
    }

    const resetModal = () => {
        setModalAction('')
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case "add":
                break;
            case 'delete':
            case 'update':
                setLangs([...langList]);
                setIndex(data.key);
                setEditLang(data.lang);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        switch (action) {
            case 'update':
                let newUpdateArr = [...langs];
                if (editLang.trim() !== "") {
                    // let updateTmp = newUpdateArr.some(v => v.lang === editLang);
                    // if (!updateTmp) {
                        newUpdateArr.splice(index, 1, { lang: editLang });
                        let editPay = {
                            key: "LANGUAGES_LIST",
                            value: newUpdateArr,
                            group: "LANGUAGES_LIST"
                        }
                        setShowBtnLoader('show-button-loader');
                        dispatch(adminOnBoardVideoLinkUpsert(editPay)).then(res => {
                            setShowBtnLoader('');
                            toast.success('Language updated successfully.')
                            modalClickAction('close')
                            if (res.value.length > 0) {
                                setLangList([...res.value]);
                            }
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    // } else {
                    //     toast.error("This language is already added.")
                    // }
                } else {
                    toast.error("Language is required.")
                }
                break;
            case 'delete':
                let newArr = [...langs];
                newArr.splice(index, 1);
                let deletePay = {
                    key: "LANGUAGES_LIST",
                    value: newArr,
                    group: "LANGUAGES_LIST"
                }
                setShowBtnLoader('show-button-loader');
                dispatch(adminOnBoardVideoLinkUpsert(deletePay)).then(res => {
                    setShowBtnLoader('');
                    toast.success('Language deleted successfully.')
                    modalClickAction('close')
                    if (res.value.length > 0) {
                        setLangList([...res.value]);
                    }
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            case 'add':
                let array = [...langList];
                let tmp = array.some(v => v.lang === editLang);
                if (editLang.trim() !== "") {
                    if (!tmp) {
                        array.push({ "lang": editLang })
                        let add = {
                            key: "LANGUAGES_LIST",
                            value: array,
                            group: "LANGUAGES_LIST"
                        }
                        setShowBtnLoader('show-button-loader');
                        dispatch(adminOnBoardVideoLinkUpsert(add)).then(res => {
                            setShowBtnLoader('');
                            modalClickAction('close')
                            toast.success('Languages added successfully.')
                            if (res.value.length > 0) {
                                setLangList([...res.value]);
                            }
                        }).catch(() => {
                            setShowBtnLoader('');
                        })
                    } else {
                        toast.error("This language is already added.")
                    }
                } else {
                    toast.error("Language is required.")
                }
                break;
            default:
        }
    }

    useEffect(() => {
        setLoading(true);
        dispatch(adminOnBoardVideoLinkListOne("LANGUAGES_LIST")).then((res) => {
            if (res.length > 0) {
                setLoading(false);
                removeMainLoaderClass();
                setLangList([...res[0].value]);
            } else {
                removeMainLoaderClass();
                setLoading(false);
                setLangList([]);
            }
        });
    }, [])

    const [search, setSearch] = useState('');
    const [speList, setSpeList] = useState([]);

    useEffect(() => {
        if (langList?.length > 0) setSpeList([...langList]);
        else setSpeList([]);
    }, [langList])

    useEffect(() => {
        if (langList?.length > 0) {
            if (search !== "") {
                let arr = [];
                langList?.map((v) => {
                    if (v.lang.toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setSpeList([...arr])
            } else {
                setSpeList([...langList])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: speList.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Languages" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table payments">
                            <LanguagesList
                                speList={speList}
                                modalClickAction={modalClickAction}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={editLang}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            {
                (modalAction === 'update' || modalAction === "add") &&
                <Modal
                    className="removedoc"
                    ModalTitle={modalAction === "add" ? "Add Language" : "Edit Language"}
                    type="center"
                    onClose={() => modalClickAction('close')}
                    Modalclass={modal && (modalAction === 'update' || modalAction === "add") ? `show-modal` : ``}
                >
                    <FormInput
                        name="lang"
                        value={editLang}
                        title="Language"
                        placeholder="Type Language"
                        onChange={(e) => setEditLang(e.target.value)}
                        required="required"
                    />
                    <div className="activeButton right">
                        <Button
                            title="Cancel"
                            buttonType="reset"
                            onClick={() => modalClickAction('close')}
                        />
                        <Button
                            loaderClass={["update", "add"].includes(modalAction) ? showBtnLoader : ""}
                            title={modalAction === "add" ? "Add" : "Edit"}
                            onClick={(e) => { e.preventDefault(); dispatchAction(modalAction) }}
                        />
                    </div>
                </Modal>
            }
        </>
    )
}