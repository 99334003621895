import { memo, useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";
import { MAP_KEY } from "../../common";
import FormInput from "../FormInput";

//Google address with autocomplete from maps api
//id, reference, formData, setFormData are required
function GoogleAddress({
  id,
  title,
  reference,
  showMap,
  formData,
  setFormData,
  toReference,
  focusOnInput,
}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    libraries: ["places"],
  });

  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  const showPosition = (position) => {
    setLat(position.coords.latitude);
    setLon(position.coords.longitude);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }, []);

  const [searchBox, setBox] = useState({});

  const onLoadAutoComplete = (autocomplete) => {
    setBox(autocomplete);
  };

  const onPlaceChanged = () => {
    if (Object.keys(searchBox).length > 0 && searchBox !== null) {
      const results = searchBox.getPlace();
      const changedField = { ...formData };
      // const placeName = results?.name ? `${results?.name}, ` : "";
      let plotNo = "";
      let complex = "";
      let placeRoute = "";
      let placeLandmark = "";
      let placeSub1 = "";
      let placeSub2 = "";
      let placeAdm3 = "";
      let placeAdm1 = "";

      if (results && results.address_components) {
        for (let i = 0; i < results.address_components.length; i++) {
          if (plotNo === "" && results.address_components[i].types[0] === "subpremise") {
            plotNo = `${results.address_components[i].long_name}, `;
          }
          if (complex === '' && results.address_components[i].types[0] === "premise") { 
            complex = `${results.address_components[i].long_name}, `;
          }
          if (placeRoute === '' && results.address_components[i].types[0] === "route") {
            placeRoute = `${results.address_components[i].long_name}, `;
          }
          if (placeLandmark === "" && results.address_components[i].types[0] === "landmark") {
            placeLandmark = `near ${results.address_components[i].long_name}, `;
          }
          if (placeSub2 === "" && 
            results.address_components[i].types[0] === "sublocality_level_2"
          ) {
            placeSub2 = `${results.address_components[i].long_name}, `;
          }
          if ( placeSub1 === "" && 
            results.address_components[i].types[0] === "sublocality_level_1"
          ) {
            placeSub1 = `${results.address_components[i].long_name}, `;
          }
          if ( placeAdm3 === "" && 
            results.address_components[i].types[0] ===
            "administrative_area_level_3"
          ) {
            placeAdm3 = `${results.address_components[i].long_name}, `;
          }
          if ( changedField.city === "" && results.address_components[i].types[0] === "locality") {
            changedField.city = `${results.address_components[i].long_name}, `;
          }
          if ( placeAdm1 === "" && 
            results.address_components[i].types[0] ===
            "administrative_area_level_1"
          ) {
            changedField.state = results.address_components[i].long_name;
            placeAdm1 = `${results.address_components[i].long_name}, `;
          }
          if ( changedField.country === "" && results.address_components[i].types[0] === "country") {
            changedField.country = results.address_components[i].long_name;
          }
          if ( changedField.pincode === "" && results.address_components[i].types[0] === "postal_code") {
            changedField.pincode = results.address_components[i].long_name;
          }
        }
      }
      changedField.latitude = results?.geometry?.location?.lat();
      changedField.longitude = results?.geometry?.location?.lng();
      // const finalAddress =
      //   plotNo +
      //   placeName +
      //   complex +
      //   placeRoute +
      //   placeLandmark +
      //   placeSub2 +
      //   placeSub1 +
      //   placeAdm3 +
      //   placeAdm1 +
      //   changedField.country;
      changedField.address = results?.formatted_address || reference?.current?.value;   
      setFormData({
        ...changedField,
      });
    }
  };

  if (!isLoaded) {
    return (
      <FormInput
        inputType="text"
        placeholder="Search Your Address"
        ref={reference}
      />
    );
  } else {
    let address = document.getElementById(id);
    if (address) {
      address.value = formData.address ? formData.address : "";
    }
  }

  return (
    <>
      <div id="searchbox">
        <Autocomplete
          bounds={map?.getBounds()}
          onLoad={(autoComplete) => onLoadAutoComplete(autoComplete)}
          onPlaceChanged={() => onPlaceChanged()}
        >
          <FormInput
            title={title}
            inputType="text"
            name={"address"}
            id={id}
            placeholder="Search Your Address"
            ref={reference}
            onKeyDown={(e) => {
              focusOnInput && toReference && focusOnInput(e, toReference);
            }}
          />
        </Autocomplete>
      </div>
      <div className={`${showMap === false ? "HideMap" : ""}`}>
        <GoogleMap
          mapContainerClassName={`${
            showMap === false
              ? "HideMapContainerStyle"
              : "ShowMapContainerStyle"
          }`}
          center={{
            lat:
              formData?.latitude !== "" ? parseFloat(formData?.latitude) : lat,
            lng:
              formData?.longitude !== ""
                ? parseFloat(formData?.longitude)
                : lon,
          }}
          zoom={15}
          onLoad={(map) => {
            setMap(map);
          }}
        >
          <Marker
            position={{
              lat:
                formData?.latitude !== ""
                  ? parseFloat(formData?.latitude)
                  : lat,
              lng:
                formData?.longitude !== ""
                  ? parseFloat(formData?.longitude)
                  : lon,
            }}
          />
        </GoogleMap>
      </div>
    </>
  );
}

export default memo(GoogleAddress);
