import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Modal from '../../../../components/common/Modal';
import UserRoleList from "./UserRoleList";
// import AddUserRole from './addUserRole';
import { emptyField, removeMainLoaderClass } from "../../common";
import { closeModal, openModal } from "../../actions/modal";
import { Skeleton } from "antd";
import TableTitle from "../../Components/TableTitle";
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import { addRole, deleteRole, fetchUserRoleList, updateRole } from "../../actions/userRole";
import { fetchModuleAccessList } from "../../actions/moduleAccess";
import DiseaseModals from "../Diseases/DiseaseModals";
import AddUserRoleModal from "./AddUserRoleModal";
import './style.scss';

export default function UserRoles(props) {
    const { modal, userRoles, moduleAccesses } = useSelector((state) => state);

    //for skeleton
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    let currentFormErrors = [];
    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton2, setModalButton2] = useState('');
    const [moduleAccess, setModuleAccess] = useState({ create: [], view: [], edit: [], delete: [] });
    const [FormRoleData, setFormData] = useState({ "mname": '', "mid": '', "mtype": 'DEFAULT', "moduleAccess": {} });
    const [FormErrors, setFormErrors] = useState(currentFormErrors);

    const [showBtnLoader, setShowBtnLoader] = useState('');

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton2('');
    };

    const resetForm = () => {
        setFormData({
            "mname": '',
            "mid": '',
            "mtype": 'DEFAULT',
            "moduleAccess": {}
        });
        setFormErrors('');
        setModuleAccess({ create: [], view: [], edit: [], delete: [] });
    };
    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        let changedField;
        switch (action) {
            case 'add':
                setModalTitle('Add Role');
                setModalButton2('Add');
                break;
            case 'update':
                setModalTitle('Update Role');
                setModalButton2('Update');
                changedField = { ...FormRoleData };
                changedField["mname"] = data.name;
                changedField["mkey"] = data.key;
                changedField["mid"] = data._id;
                let moduleAccessView = [];
                let moduleAccessCreate = [];
                let moduleAccessDelete = [];
                let moduleAccessEdit = [];
                data.moduleAccess && data.moduleAccess.view && data.moduleAccess.view.forEach((v) => {
                    if (v !== null && v.key) {
                        moduleAccessView.push(v.key);
                    }
                })
                data.moduleAccess && data.moduleAccess.create && data.moduleAccess.create.forEach((v) => {
                    if (v !== null && v.key) {
                        moduleAccessCreate.push(v.key);
                    }
                })
                data.moduleAccess && data.moduleAccess.edit && data.moduleAccess.edit.forEach((v) => {
                    if (v !== null && v.key) {
                        moduleAccessEdit.push(v.key);
                    }
                })
                data.moduleAccess && data.moduleAccess.delete && data.moduleAccess.delete.forEach((v) => {
                    if (v !== null && v.key) {
                        moduleAccessDelete.push(v.key);
                    }
                })
                changedField["moduleAccess"].create = moduleAccessCreate;
                changedField["moduleAccess"].view = moduleAccessView;
                changedField["moduleAccess"].edit = moduleAccessEdit;
                changedField["moduleAccess"].delete = moduleAccessDelete;
                setModuleAccess(changedField["moduleAccess"]);
                setFormData({ ...changedField });
                break;
            case 'delete':
                setModalTitle('Delete Role');
                setModalButton2('Delete');
                changedField = { ...FormRoleData };
                changedField["mname"] = data.name;
                changedField["mid"] = data._id;
                setFormData({ ...changedField });
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action, data) => {
        if (emptyField(FormRoleData.mname)) {
            currentFormErrors.mname = "Payment Name is required";
        }
        let payload;
        setFormErrors(currentFormErrors);
        if ((Object.keys(currentFormErrors).length === 0 && (action === 'add' || action === 'update')) || action === 'delete') {
            switch (action) {
                case 'add':
                    payload = {
                        "name": FormRoleData.mname,
                        "type": FormRoleData.mtype,
                        "moduleAccess": moduleAccess
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(addRole(payload, userRoles)).then(res => {
                        modalClickAction('close');
                        setShowBtnLoader('')
                    }).catch(() => setShowBtnLoader(''));
                    break;
                case 'delete':
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteRole(FormRoleData.mid, userRoles)).then(res => {
                        modalClickAction('close')
                        setShowBtnLoader('')
                    }).catch(() => setShowBtnLoader(''));
                    break;
                case 'update':
                    payload = {
                        "name": FormRoleData.mname,
                        "type": FormRoleData.mtype,
                        "id": FormRoleData.mid,
                        "moduleAccess": moduleAccess
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateRole(payload, FormRoleData.mkey, userRoles)).then(res => {
                        modalClickAction('close')
                        setShowBtnLoader('')
                    }).catch(() => setShowBtnLoader(''));
                    break;
                default:
                    throw new Error('Modal action not match');
            }
        }
    };

    useEffect(() => {
        setLoading(true)
        dispatch(fetchUserRoleList()).then(() => {
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
        dispatch(fetchModuleAccessList());
        removeMainLoaderClass();
        //eslint-disable-next-line
    }, []);

    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (userRoles.length > 0) setUsers([...userRoles]);
        else setUsers([]);
    }, [userRoles])

    useEffect(() => {
        if (userRoles?.length > 0) {
            if (search !== "") {
                let arr = [];
                userRoles?.map((v) => {
                    if (v.name.toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setUsers([...arr])
            } else {
                setUsers([...userRoles])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: users.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="User Roles" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table userroles">
                            <UserRoleList
                                users={users}
                                modalClickAction={modalClickAction}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={FormRoleData.mname}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />

            <AddUserRoleModal
                FormRoleData={FormRoleData}
                setFormData={setFormData}
                moduleAccess={moduleAccess}
                setModuleAccess={setModuleAccess}
                modal={modal}
                modalAction={modalAction}
                modalTitle={modalTitle}
                modalButton2={modalButton2}
                modalClickAction={modalClickAction}
                FormErrors={FormErrors}
                moduleAccessList={moduleAccesses.data}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
            />
        </>
    );
};