import { USER_ROLES_LIST } from "../actions/types";

const initialState = {};

export default function userRoles(state = initialState, action) {

    switch (action.type) {
        case USER_ROLES_LIST:
            return action.payload;
        default:
            return state;
    }
};