// if inNumber true then get month in number like 1,2,3
// else { 
// if type short then Jan, Feb etc
// else January, February etc
// }
import Header1 from "../Components/Templetes/Headers/header1";
import Header2 from "../Components/Templetes/Headers/header2";
import Header3 from "../Components/Templetes/Headers/header3";
import Header4 from "../Components/Templetes/Headers/header4";
import Header5 from "../Components/Templetes/Headers/header5";

export const getMonthFromDate = (date, inNumber, type) => {
    let monthName;
    if (date) {
        if (inNumber) {
            monthName = date.getMonth();
        } else {
            if (type === "short") {
                monthName = date.toLocaleString('default', { month: 'short' });
            } else {
                monthName = date.toLocaleString('default', { month: 'long' });
            }
        }
    }
    return monthName;
}

//get date number from any date ex. 1,2,3 etc
export const getDateFromDate = (date) => {
    let dateNumber;
    if (date) {
        dateNumber = date.getDate();
    }
    return dateNumber;
}

// return 1st, 2nd, 3rd etc
export const ordinalSuffixOf = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return "st";
    }
    if (j === 2 && k !== 12) {
        return "nd";
    }
    if (j === 3 && k !== 13) {
        return "rd";
    }
    return "th";
}

//If name is jenil sid then it gives Jenil Sid
export const capitalizeFirstLetter = (string) => {
    if (string) {
        const arr = string?.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i][0] && arr[i][0].toUpperCase() + arr[i].substr(1);
        }
        const str2 = arr.join(" ");
        return str2;
    } else {
        return "";
    }
}

//formate date gives if isDateFirst true then 31/10/2022 else 10/31/2022
export function formatDate(date, isDateFirst = false) {
    var d = new Date(date),
        month = (d.getMonth() + 1)?.toString(),
        day = '' + d.getDate()?.toString(),
        year = '' + d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (isDateFirst) {
        return [day, month, year].join(`/`);
    } else {
        return [month, day, year].join(`/`);
    }
}

//it fives 11:27 AM time or 09:06 PM
export const getUTCTime = (date) => {
    if (date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
}

//get date and time for finance use
export const getTodayDate = (date, h, m, s, ms) => {
    let startOfDay = new Date(date);
    startOfDay.setHours(h, m, s, ms);
    return startOfDay;
  }

//If input field is empty then it gives true else false
export function emptyField(text) {
    let result = false;
    if (!text) {
        result = true;
    } else {
        result = false;
    }
    return result;
}

//it gives true when email is valid otherwise false
export function validEmail(text) {
    const regex = RegExp(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    return !regex.test(text);
}

//wait to calle= function
export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
}

export const defaultManageSettings = [
    {
      "menu": "symptoms",
      "title": "Symptoms",
      "isDisplay": true,
      "subMenu": "symptomsAndDurations",
      "extraMenu": "symptomsComment",
      "isExtraDisplay": true
    },
    {
      "menu": "pastHistory",
      "title": "Past History",
      "isDisplay": true,
      "subMenu": "historyDurationAndComment",
      "extraMenu": "isBlankPastHistory",
      "isExtraDisplay": false,
      "blankValue": ""
    },
    {
      "menu": "habits",
      "title": "Habits",
      "isDisplay": true,
      "subMenu": "habitsAndDurations",
      "extraMenu": "isBlankHabits",
      "isExtraDisplay": false,
      "blankValue": ""
    },
    {
      "menu": "family",
      "title": "Family",
      "isDisplay": true,
      "subMenu": "diagnosisDurationAndRelation",
      "extraMenu": "isBlankFamily",
      "isExtraDisplay": false,
      "blankValue": ""
    },
    {
      "menu": "generalExamination",
      "title": "General Examination",
      "isDisplay": true,
      "subMenu": "defaultGeneralExamination",
      "extraMenu": "isBlankGeneral",
      "isExtraDisplay": false,
      "blankValue": ""
    },
    {
      "menu": "systematicExamination",
      "title": "Systemic Examination",
      "isDisplay": true,
      "subMenu": "defaultSystematicExamination",
      "extraMenu": "isBlankSystemic",
      "isExtraDisplay": false,
      "blankValue": ""
    },
    {
        "menu": "gynecHistory",
        "title": "Gynec History",
        "isDisplay": true,
        "subMenu": "defaultGynecHistory",
    },
    {
      "menu": "probableDiagnosis",
      "title": "Probable Diagnosis",
      "isDisplay": true,
      "subMenu": "diagnosisAndDurations"
    },
    {
      "menu": "confirmedDiagnosis",
      "title": "Confirmed Diagnosis",
      "isDisplay": true,
      "subMenu": "diagnosisAndDurations"
    },
    {
      "menu": "treatment",
      "title": "Treatment",
      "isDisplay": true,
      "subMenu": "defaultTreatment"
    },
    {
      "menu": "labTests",
      "title": "Lab Tests",
      "isDisplay": true,
      "subMenu": "defaultLabTests"
    },
    {
      "menu": "advise",
      "title": "Advice",
      "isDisplay": true,
      "subMenu": "defaultAdvise"
    },
    {
      "menu": "followUp",
      "title": "Follow Up",
      "isDisplay": true,
      "subMenu": "defaultFollowUp"
    },
    {
      "menu": "referedDoctor",
      "title": "Refer a Doctor",
      "isDisplay": true,
      "subMenu": "defaultReferDoctor"
    },
    {
      "menu": "printFormat",
      "isDisplay": true,
      "subMenu": "singleLineFormat"
    },
    {
        "menu": "privateNote",
        "title": "Private Note",
        "isDisplay": true,
        "subMenu": "defaultPrivateNote"
      },
  ];

/**
 * check cookie
 * @param {*} cname 
 * @returns 
 */
export function checkCookie(cname) {
    let switchUser = getCookie(cname);
    if (switchUser.length === 0) {
        return false;
    } else {
        return true;
    }
}

/**
 * get cookie
 * @param {*} cname 
 * @returns 
 */

export function getCookie(cname) {
    let da = document.cookie.split(';');
    let ca = da[0]?.split('=');
    if (cname === ca[0]) {
        let arr = ca[1];
        let finalArray = JSON.parse(arr);
        return finalArray;
    } else {
        return [];
    }
}

//remove main loader class
export const removeMainLoaderClass = () => {
    document?.getElementById('mainLoader')?.classList?.remove('main-loader-done');
}

//Google map key
export const MAP_KEY = "AIzaSyCMH2G4lznu8c0A5PRHfScM_pVq_py0Mfo";

//s3 bukcet url
export const S3_BASE_URL = `${process.env.REACT_APP_S3_URL}${process.env.REACT_APP_S3_BUCKET}/`;

// for multilingual use
export const MEDICINETIME = [
    { value: "BEFORE_FOOD", label: "Before Food" },
    { value: "AFTER_FOOD", label: "After Food" },
    { value: "WITH_FOOD", label: "With Food" },
    { value: "BEFORE_BREAKFAST", label: "Before Breakfast" },
    { value: "AFTER_BREAKFAST", label: "After Breakfast" },
    { value: "BEFORE_LUNCH", label: "Before Lunch" },
    { value: "AFTER_LUNCH", label: "After Lunch" },
    { value: "BEFORE_DINNER", label: "Before Dinner" },
    { value: "AFTER_DINNER", label: "After Dinner" },
    { value: "EMPTY_STOMACH", label: "Empty Stomach" },
    { value: "BED_TIME", label: "Bed Time" },
    { value: "SOS", label: "SoS" },
]

// for multilingual use
export const MEDICINEFREQUENCY = [
    { value: "DAILY", label: "Daily" },
    { value: "ALTERNATE_DAY", label: "Alternate Day" },
    { value: "WEEKLY", label: "Weekly" },
    { value: "MONTHLY", label: "Monthly" },
    { value: "YEARLY", label: "Yearly" },
]

export const MEDICINEDAYSRANGE = [
    { value: "Day", label: "Day" },
    { value: "Week", label: "Week" },
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" },
    { value: "Days", label: "Days" },
    { value: "Weeks", label: "Weeks" },
    { value: "Months", label: "Months" },
    { value: "Years", label: "Years" },
]

//Add latest modal using category, department, sample, container, ML, homecollection options, timeline
export const CATEGORY = [
    { value: 'Hematology', label: 'Hematology' },
    { value: 'Biochemistry', label: 'Biochemistry' },
    { value: 'Clinical Pathology', label: 'Clinical Pathology' },
    { value: 'Immunology & Serology', label: 'Immunology & Serology' },
    { value: 'Microbiology', label: 'Microbiology' },
    { value: 'Molecular Genetics', label: 'Molecular Genetics' },
    { value: 'Radiology and Imaging', label: 'Radiology and Imaging' },
];
export const DEPARTMENT = [
    { value: 'ICU department', label: 'ICU department' },
    { value: 'OT department', label: 'OT department' },
    { value: 'IPD department', label: 'IPD department' },
    { value: 'Pharmacy', label: 'Pharmacy' }
];
export const SAMPLE = [
    { value: 'Blood', label: 'Blood' },
    { value: 'Serum', label: 'Serum' },
    { value: 'EDTA/SERUM', label: 'EDTA/SERUM' },
    { value: 'Urine', label: 'Urine' },
    { value: 'Plan', label: 'Plan' },
    { value: 'Stool', label: 'Stool' }
];
export const CONATAINER = [
    { value: 'Tube', label: 'Tube' },
    { value: 'Plain', label: 'Plain' },
    { value: 'RED/BLUE', label: 'RED/BLUE' },
    { value: 'RED', label: 'RED' },
    { value: 'Blood', label: 'Blood' },
    { value: 'Serum', label: 'Serum' },
    { value: 'Fluoride', label: 'Fluoride' },
    { value: 'RED/EDTA', label: 'RED/EDTA' },
    { value: 'Plastic', label: 'Plastic' },
];
export const ML = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
];
export const HOMECOLLECTIONOPTIONS = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];
export const TIMELINE = [
    { value: 'Hrs', label: 'Hrs' },
    { value: 'Minutes', label: 'Minutes' },
    { value: 'Seconds', label: 'Seconds' },
];
export const GENDER = [
    { value: 'UniSex', label: 'UniSex' },
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
];
export const DATEFORMAT = [
    { value: 'Days', label: 'Days' },
    { value: 'Months', label: 'Months' },
    { value: 'Years', label: 'Years' }
];
const cube = (id) => {
    return (
        <div>
            10<sup>{id}</sup>
        </div>
    )
}
export const UNITS = [
    { value: '%', label: '%' },
    { value: '/L', label: '/L' },
    { value: 'cP', label: 'cP' },
    { value: 'fL', label: 'fL' },
    { value: 'fmol', label: 'fmol' },
    { value: 'g', label: 'g' },
    { value: 'g/dL', label: 'g/dL' },
    { value: 'g/L', label: 'g/L' },
    { value: 'hr', label: 'hr' },
    { value: 'INR', label: 'INR' },
    { value: 'IU/L', label: 'IU/L' },
    { value: 'IU/mL', label: 'IU/mL' },
    { value: 'kPa', label: 'kPa' },
    { value: 'kU/L', label: 'kU/L' },
    { value: 'Gms%', label: 'Gms%' },
    { value: 'Cells/cu mm', label: 'Cells/cu mm' },
    { value: 'Million Cells/cu mm', label: 'Million Cells/cu mm' },
    { value: 'mm/hour', label: 'mm/hour' },
    { value: 'lakh/cu mm', label: 'lakh/cu mm' },
    { value: 'mcg/24hr', label: 'mcg/24hr' },
    { value: 'g/day', label: 'g/day' },
    { value: 'mL', label: 'mL' },
    { value: 'HPF', label: 'HPF' },
    { value: 'mcg/dL', label: 'mcg/dL' },
    { value: 'mcg/mL', label: 'mcg/mL' },
    { value: 'mckat/h', label: 'mckat/h' },
    { value: 'mckat/L', label: 'mckat/L' },
    { value: 'mcmol/d', label: 'mcmol/d' },
    { value: 'mcmol/L', label: 'mcmol/L' },
    { value: 'mcmol/mol', label: 'mcmol/mol' },
    { value: 'mEq/L', label: 'mEq/L' },
    { value: 'mg/24hr', label: 'mg/24hr' },
    { value: 'mg/dL', label: 'mg/dL' },
    { value: 'mg/g', label: 'mg/g' },
    { value: 'mg/g Cr', label: 'mg/g Cr' },
    { value: 'mg/L', label: 'mg/L' },
    { value: 'Min', label: 'Min' },
    { value: 'mIU/mL', label: 'mIU/mL' },
    { value: 'µg/dL', label: 'µg/dL' },
    { value: 'ml/s/m2', label: 'ml/s/m2' },
    { value: 'mm3', label: 'mm3' },
    { value: 'mmHg', label: 'mmHg' },
    { value: 'mmol/kg', label: 'mmol/kg' },
    { value: 'mmol/L', label: 'mmol/L' },
    { value: 'mOsm/kg', label: 'mOsm/kg' },
    { value: 'ng/dL', label: 'ng/dL' },
    { value: 'ng/L', label: 'ng/L' },
    { value: 'ng/mL', label: 'ng/mL' },
    { value: 'nmol/24hr', label: 'nmol/24hr' },
    { value: 'nmol/L', label: 'nmol/L' },
    { value: 'pg', label: 'pg' },
    { value: 'pg/mL', label: 'pg/mL' },
    { value: 'pmol/L', label: 'pmol/L' },
    { value: 'sec', label: 'sec' },
    { value: 'U/h', label: 'U/h' },
    { value: 'U/L', label: 'U/L' },
    { value: 'U/mL', label: 'U/mL' },
    { value: 'ug/day', label: 'ug/day' },
    { value: 'ug/dL', label: 'ug/dL' },
    { value: 'ug/g', label: 'ug/g' },
    { value: 'mU/L', label: 'mU/L' },
    { value: 'mlU/L', label: 'mlU/L' },
    { value: 'lu/mL', label: 'lu/mL' },
    { value: 'thousand cells/μL', label: 'thousand cells/μL' },
    { value: 'mL/min/1.73m2', label: 'mL/min/1.73m2' },
    { value: 'µU/ml', label: 'µU/ml' },
    { value: 'seconds', label: 'seconds' },
    { value: 'µg/L', label: 'µg/L' },
    { value: 'min', label: 'min' },
    { value: 'million', label: 'million' },
    { value: 'bpm', label: 'bpm' },
    { value: 'cm', label: 'cm' },
    { value: 'kg', label: 'kg' },
    { value: 'F', label: 'F' },
    { value: 'Kg/m2', label: 'Kg/m2' },
    { value: 'ml', label: 'ml' },
    { value: cube(3), label: cube(3) },
    { value: cube(4), label: cube(4) },
    { value: cube(5), label: cube(5) },
];

export const rowArr = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
]

 //set page and pageSize onchange select box
 export function selectedRowFilter (e, setPage, setPageSize) {
    if(e?.value === 10){
        setPage(1)
        setPageSize(10)
    } else if(e?.value === 25) {
        setPage(1)
        setPageSize(25)
    } else if(e?.value === 50) {
        setPage(1)
        setPageSize(50)
    } else if(e?.value === 100) {
        setPage(1)
        setPageSize(100)
    }
}

/**
 * push in array function dynamic
 * @param {*} arr 
 * @param {*} val 
 * @param {*} strict 
 * @returns 
 */
export function pushInArray(arr, val, strict = false) {
    if (strict) {
        if (arr.includes(val)) {
            return arr
        }
    }
    arr.push(val);
    return arr;
}

/**
 * 
 * @param {*} arr 
 * @param {*} val 
 * @returns 
 */
export function popFromArray(arr, val) {
    if (arr.includes(val)) {
        let idx = arr.indexOf(val)
        arr.splice(idx, 1);
    }
    return arr;
}

/**
 * 
 * @param {*} arr 
 * @param {*} val 
 * @returns 
 */
export function findFromArray(arr, val) {
    return arr.includes(val);
}

export function mergeArray(array1, array2) {
    let mergedArray = Object.assign(array1, array2);
    return mergedArray;
  }

  //Array of header
export const headersTab = (opdTime, doctor, hospitalsDetails) => {
    return [
      {
        title: 'Header1', id: 'header1', component:
          <Header1
            hospitalName={hospitalsDetails?.name}
            hospitalAddress={hospitalsDetails?.address?.line1}
            hospitalContact={hospitalsDetails?.countryCode + ' ' + hospitalsDetails?.contact}
            hospitalEmail={hospitalsDetails?.emailId}
            doctorName={(doctor?.firstName || doctor?.lastName) ? 'Dr. ' + doctor?.firstName + ' ' + doctor?.lastName : ''}
            degreeIdsName={doctor?.degreeIds}
            degree={doctor?.degree}
            role={"Doctor"}
            regNo={doctor?.mciRegNumber}
          />
      },
      {
        title: 'Header2', id: 'header2', component:
          <Header2
            hospitalName={hospitalsDetails?.name}
            hospitalContact={hospitalsDetails?.countryCode + ' ' + hospitalsDetails?.contact}
            hospitalEmail={hospitalsDetails?.emailId}
            doctorName={(doctor?.firstName || doctor?.lastName) ? 'Dr. ' + doctor?.firstName + ' ' + doctor?.lastName : ''}
            degreeIdsName={doctor?.degreeIds}
            degree={doctor?.degree}
            opdTime={opdTime}
          />
      },
      {
        title: 'Header3', id: 'header3', component:
          <Header3
            hospitalName={hospitalsDetails?.name}
            hospitalAddress={hospitalsDetails?.address?.line1}
            doctorName={(doctor?.firstName || doctor?.lastName) ? 'Dr. ' + doctor?.firstName + ' ' + doctor?.lastName : ''}
            degreeIdsName={doctor?.degreeIds}
            degree={doctor?.degree}
            opdTime={opdTime}
          />
      },
      {
        title: 'Header4', id: 'header4', component:
          <Header4
            hospitalLogo={hospitalsDetails?.logo}
            hospitalAddress={hospitalsDetails?.address?.line1}
            doctorName={(doctor?.firstName || doctor?.lastName) ? 'Dr. ' + doctor?.firstName + ' ' + doctor?.lastName : ''}
            degreeIdsName={doctor?.degreeIds}
            degree={doctor?.degree}
            opdTime={opdTime}
          />
      },
      {
        title: 'Header5', id: 'header5', component:
          <Header5
            hospitalLogo={hospitalsDetails?.logo}
            hospitalContact={hospitalsDetails?.countryCode + ' ' + hospitalsDetails?.contact}
            doctorName={(doctor?.firstName || doctor?.lastName) ? 'Dr. ' + doctor?.firstName + ' ' + doctor?.lastName : ''}
            degreeIdsName={doctor?.degreeIds}
            degree={doctor?.degree}
            opdTime={opdTime}
          />
      },
    ];
  }

  export const focusOnInput = (e, ref) => {
    if (e.keyCode === 13) {
      ref.current.focus();
    }
  }

  export const qualificationsArr = [
    { value: "Anesthesia", label: "Anesthesia" },
    { value: "Dermatology", label: "Dermatology" },
    { value: "ENT", label: "ENT" },
    { value: "MBBS", label: "MBBS" },
    { value: "BAMS", label: "BAMS" },
    { value: "BHMS", label: "BHMS" },
    { value: "General Surgery", label: "General Surgery" },
    { value: "Ophthalmology", label: "Ophthalmology" },
    { value: "Obstetrics & Gynaecology", label: "Obstetrics & Gynaecology" },
    { value: "Gastroenterology", label: "Gastroenterology" },
    { value: "Haematology", label: "Haematology" },
    { value: "Pulmonology", label: "Pulmonology" },
    { value: "Intensive Care Specialist", label: "Intensive Care Specialist" },
    { value: "Infectious Diseases", label: "Infectious Diseases" },
    { value: "Rheumatology", label: "Rheumatology" },
    { value: "Urology", label: "Urology" },
    { value: "Orthopedics", label: "Orthopedics" },
    { value: "Pediatrician", label: "Pediatrician" },
    { value: "Oncologist", label: "Oncologist" },
    { value: "Nephrologist", label: "Nephrologist" },
    { value: "Neurologist", label: "Neurologist" },
    { value: "Nephro Surgery", label: "Nephro Surgery" },
    { value: "Neuro Surgery", label: "Neuro Surgery" },
    { value: "Onco Surgery", label: "Onco Surgery" },
    { value: "Cardiology", label: "Cardiology" },
    { value: "Cardio-thoracic Surgery", label: "Cardio-thoracic Surgery" },
    { value: "Plastic Surgery", label: "Plastic Surgery" },
  ]

  //country code in 
  export const countryCodes = [
    { value: '+1', label: '+1' },
    { value: '+44', label: '+44' },
    { value: '+61', label: '+61' },
    { value: '+86', label: '+86' },
    { value: '+81', label: '+81' },
    { value: '+49', label: '+49' },
    { value: '+33', label: '+33' },
    { value: '+91', label: '+91' },
    { value: '+7', label: '+7' },
    { value: '+39', label: '+39' },
    { value: '+34', label: '+34' },
    { value: '+82', label: '+82' },
    { value: '+852', label: '+852' },
    { value: '+65', label: '+65' },
    { value: '+971', label: '+971' },
    { value: '+966', label: '+966' },
    { value: '+52', label: '+52' },
    { value: '+1', label: '+1' },
    { value: '+352', label: '+352' },
    { value: '+353', label: '+353' },
    { value: '+354', label: '+354' },
    { value: '+41', label: '+41' },
    { value: '+46', label: '+46' },
    { value: '+47', label: '+47' },
    { value: '+358', label: '+358' },
    { value: '+64', label: '+64' },
    { value: '+351', label: '+351' },
    { value: '+30', label: '+30' },
    { value: '+31', label: '+31' },
    { value: '+90', label: '+90' },
    { value: '+380', label: '+380' },
    { value: '+972', label: '+972' },
    { value: '+63', label: '+63' },
    { value: '+60', label: '+60' },
    { value: '+62', label: '+62' },
    { value: '+92', label: '+92' },
    { value: '+27', label: '+27' },
    { value: '+234', label: '+234' },
    { value: '+254', label: '+254' },
    { value: '+233', label: '+233' },
    { value: '+20', label: '+20' },
    { value: '+213', label: '+213' },
    { value: '+212', label: '+212' },
    { value: '+216', label: '+216' },
    { value: '+255', label: '+255' },
    { value: '+260', label: '+260' },
    { value: '+263', label: '+263' },
  ];

// Table Sorting Common Functions: 
export const dateSortingForTable = (a, b) => {
    return new Date(a) - new Date(b);
}
  
export const nameSortingForTable = (a, b) => {
    return a?.localeCompare(b);
}
  
export const numberSortingForTable = (a, b, withProp) => {
    if(withProp){
        return a?.props?.value - b?.props?.value;
    } else {
        return a - b;
    }
}

export const arraySortingForTable = (a, b) => {
    const methodA = Array.isArray(a) ? a[0] : a;
    const methodB = Array.isArray(b) ? b[0] : b;
    return methodA.localeCompare(methodB); 
}