import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { listDegree } from "../../../actions/degree";
import {
  addDoctor,
  approvalDoctor,
  deleteDoctor,
  disableEnableDoctor,
  fetchDoctorList,
  goToDoctorSettings,
  sendVerificationEmail,
  updateDoctor,
} from "../../../actions/doctors";
import {
  approvalHospital,
  fetchHospitalList,
} from "../../../actions/hospitals";
import { closeModal, openModal } from "../../../actions/modal";
import { fetchModuleAccessList } from "../../../actions/moduleAccess";
import { searchSpecialty } from "../../../actions/specialty";
import { capitalizeFirstLetter, debounce, emptyField } from "../../../common";
import DoctorAddModal from "../../DoctorsMx/DoctorAddModal";
import AddUserRoleModal from "../../UserRoles/AddUserRoleModal";
import HospitalDoctorList from "./HospitalDoctorList";

export default function HospitalDoctorTab({
  modalAction,
  modalClickAction,
  hospitalFormData,
  hospitalId,
}) {
  const {
    doctors = [],
    hospitals = [],
    modal,
    moduleAccesses = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    hospitalDoctorsList(1, pageSize, search, hospitalId );
    dispatch(fetchModuleAccessList());
  }, []);

  const hospitalDoctorsList = async (pageNos, pageSizes, searchs, hsId) => {
    // const token = localStorage.getItem("token");
    // let pagination = { pageNo: "", pageSize: "" };
    // let headers = { Authorization: token ? `Bearer ${token}` : undefined };
    const payload = {
      search: searchs,
      pagination:{
        pageNo: pageNos, 
        pageSize: pageSizes,
      },
      hospitalId: hsId
    }
    await dispatch(
      fetchDoctorList(payload)
    );
  };

  const [doctorFormData, setDoctorFormData] = useState({
    latitude: "",
    longitude: "",
    firstName: "",
    lastName: "",
    mciRegNumber: "",
    contact: "",
    countryCode: "+91",
    emailId: "",
    hospitalIds: "",
    hospitalName: "",
    plotNo: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    degreeIds: [],
    degree: [],
    specialty: [],
    moduleAccess: {}
  });

  let currentFormErrors = [];
  const [doctorId, setDoctorId] = useState("");
  const [FormErrors, setFormErrors] = useState(currentFormErrors);
  const [modalTitle, setModalTitle] = useState("");
  const [modalButton1, setModalButton1] = useState("");
  const [modalButton2, setModalButton2] = useState("");
  const [doctorModalAction, setDoctorModalAction] = useState("");

  const [moduleAccess, setModuleAccess] = useState({
    create: [],
    view: [],
    edit: [],
    delete: [],
  });
  /**
   * for pagination
   */
  const [search, setSearch] = useState("");
  const [showBtnLoader, setShowBtnLoader] = useState("");

  //pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [specialtyList, setSpecialtyList] = useState([]);
  const [degreeList, setDegreeList] = useState([]);
  const [otherList, setOtherList] = useState([]);

  const [roles, setRoles] = useState([]);

  const resetForm = () => {
    setDoctorId("");
    setAddHospitalName("");
    currentFormErrors.firstName = "";
    currentFormErrors.lastName = "";
    currentFormErrors.mciRegNumber = "";
    currentFormErrors.contact = "";
    currentFormErrors.emailId = "";
    setFormErrors(currentFormErrors);
    setDoctorFormData({
      latitude: "",
      longitude: "",
      firstName: "",
      lastName: "",
      mciRegNumber: "",
      countryCode: "+91",
      contact: "",
      emailId: "",
      hospitalIds: "",
      hospitalName: "",
      plotNo: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      degreeIds: [],
      degree: [],
      specialty: [],
    });
    setModuleAccess({ create: [], view: [], edit: [], delete: [] });
    setOtherList([]);
    setRoles([]);
  };

  const resetModal = () => {
    setDoctorModalAction("");
    setModalTitle("");
    setModalButton1("");
    setModalButton2("");
  };

  const doctorModalClickAction = (type, action = "", data = {}) => {
    resetModal();
    resetForm();
    switch (action) {
      case "add":
        setModalTitle("Add Doctor");
        setModalButton1("Reset");
        setModalButton2("Add Doctor");
        break;
      case "update":
        setModalTitle("Edit Doctor");
        setModalButton1(action === "update" ? "Reset" : "Cancel");
        setModalButton2(action === "update" ? "Edit Doctor" : "Approve");
        setDoctorId(data._id);
        let changedField = { ...doctorFormData };
        changedField["firstName"] = capitalizeFirstLetter(data.firstName);
        changedField["lastName"] = capitalizeFirstLetter(data.lastName);
        changedField["mciRegNumber"] = data.mciRegNumber;
        changedField["countryCode"] = data.countryCode;
        changedField["contact"] = data.contact;
        changedField["emailId"] = data.emailId;
        changedField["hospitalIds"] =
          data.hospitals.length > 0 &&
          data.hospitals[0].hospitalId &&
          data.hospitals[0].hospitalId._id &&
          data.hospitals[0].hospitalId._id;
        changedField["hospitalName"] =
          data.hospitals.length > 0 &&
          data.hospitals[0].hospitalId &&
          data.hospitals[0].hospitalId.name &&
          data.hospitals[0].hospitalId.name;
        setAddHospitalName(
          data.hospitals.length > 0 &&
            data.hospitals[0].hospitalId &&
            data.hospitals[0].hospitalId.name &&
            data.hospitals[0].hospitalId.name
        );
        changedField["plotNo"] = data?.address?.permanent?.plotNo;
        changedField["city"] = data?.address?.permanent?.city;
        changedField["country"] = data?.address?.permanent?.country;
        changedField["state"] = data?.address?.permanent?.state;
        changedField["pincode"] = data?.address?.permanent?.pincode;
        changedField["address"] = data?.address?.permanent?.line1;
        changedField["address2"] = data?.address?.permanent?.line2;
        changedField["latitude"] = data?.address?.permanent?.latitude;
        changedField["longitude"] = data?.address?.permanent?.longitude;
        let otherData = [];
        if (data?.degreeIds?.length > 0) {
          data?.degreeIds.map((v) => {
            otherData.push(v._id);
          });
        }
        if (data?.degree?.length > 0) {
          if (
            !data?.degreeIds?.includes("Other") &&
            !data?.degree?.includes("false")
          )
            otherData.push("Other");
        }
        changedField["degreeIds"] = otherData;
        changedField["degree"] =
          data?.degree?.length > 0
            ? data.degree?.filter((v) => v !== "false")
            : [];
        if (data.degree?.length > 0) {
          let arr = [];
          data.degree.map((v) => {
            arr.push({ value: v, label: v });
          });
          setOtherList([...arr]);
        } else {
          setOtherList([]);
        }
        changedField["specialty"] =
          data.specialty?.length > 0 ? data.specialty : [];
        changedField["isVerified"] = data.isVerified;
        changedField["isPasswordSet"] = data.isPasswordSet;
        setDoctorFormData({ ...doctorFormData, ...changedField });
        if (
          data.hospitals.length > 0 &&
          data.hospitals[0].roles &&
          data.hospitals[0].roles.length > 0
        ) {
          let arr = [];
          data.hospitals[0].roles.map((v) => {
            arr.push(v.name);
          });
          setRoles([...arr]);
        }
        let moduleAccessView = [];
        let moduleAccessCreate = [];
        let moduleAccessEdit = [];
        let moduleAccessDelete = [];
        if (
          data.hospitals &&
          data.hospitals[0] &&
          data.hospitals[0].moduleAccess
        ) {
          data.hospitals[0].moduleAccess.view.length > 0 &&
            data.hospitals[0].moduleAccess.view.forEach((v) => {
              moduleAccessView.push(v);
            });
          data.hospitals[0].moduleAccess.create.length > 0 &&
            data.hospitals[0].moduleAccess.create.forEach((v) => {
              moduleAccessCreate.push(v);
            });
          data.hospitals[0].moduleAccess.edit.length > 0 &&
            data.hospitals[0].moduleAccess.edit.forEach((v) => {
              moduleAccessEdit.push(v);
            });
          data.hospitals[0].moduleAccess.delete.length > 0 &&
            data.hospitals[0].moduleAccess.delete.forEach((v) => {
              moduleAccessDelete.push(v);
            });
        }
        setModuleAccess({
          view: moduleAccessView,
          create: moduleAccessCreate,
          edit: moduleAccessEdit,
          delete: moduleAccessDelete,
        });
        break;
      case "moduleAccess":
        setModalTitle("Manage Doctor’s Access");
        setModalButton1("Close");
        setModalButton2("Save Changes");
        setDoctorId(data._id);
        let moduleAccessViews = [];
        let moduleAccessCreates = [];
        let moduleAccessEdits = [];
        let moduleAccessDeletes = [];
        let changedFields = { ...doctorFormData };
        if (
          data.hospitals &&
          data.hospitals[0] &&
          data.hospitals[0].moduleAccess
        ) {
          data.hospitals[0].moduleAccess.view.length > 0 &&
            data.hospitals[0].moduleAccess.view.forEach((v) => {
              moduleAccessViews.push(v);
            });
          data.hospitals[0].moduleAccess.create.length > 0 &&
            data.hospitals[0].moduleAccess.create.forEach((v) => {
              moduleAccessCreates.push(v);
            });
          data.hospitals[0].moduleAccess.edit.length > 0 &&
            data.hospitals[0].moduleAccess.edit.forEach((v) => {
              moduleAccessEdits.push(v);
            });
          data.hospitals[0].moduleAccess.delete.length > 0 &&
            data.hospitals[0].moduleAccess.delete.forEach((v) => {
              moduleAccessDeletes.push(v);
            });
        }
        changedFields['moduleAccess'] = {
          view: moduleAccessViews,
          create: moduleAccessCreates,
          edit: moduleAccessEdits,
          delete: moduleAccessDeletes,
        }
        setDoctorFormData({ ...doctorFormData, ...changedFields });
        setModuleAccess({
          view: moduleAccessViews,
          create: moduleAccessCreates,
          edit: moduleAccessEdits,
          delete: moduleAccessDeletes,
        });
        break;
      case "hospitalAdd":
        setAddHospitalName(data.name);
        break;
      case "approve":
        let filter = { isApproved: ["0", "-1", "1"] };
        let search = {
          field: "_id",
          value:
            data.hospitals.length > 0 &&
            data.hospitals[0].hospitalId &&
            data.hospitals[0].hospitalId._id &&
            data.hospitals[0].hospitalId._id,
          strict: true,
        };
        let paramSearch = search;
        dispatch(fetchHospitalList("", filter, paramSearch, true)).then(
          (response) => {
            if (
              response.data &&
              response.data.data &&
              response.data.data[0] &&
              response.data.data[0].isApproved === "1"
            ) {
              setDoctorId(data._id);
              let changedFielddelete = { ...doctorFormData };
              changedFielddelete["firstName"] = capitalizeFirstLetter(
                data.firstName
              );
              changedFielddelete["lastName"] = capitalizeFirstLetter(
                data.lastName
              );
              setDoctorFormData({ ...changedFielddelete });
              setDoctorModalAction(action);
              if (type === "open") {
                dispatch(openModal());
              } else {
                dispatch(closeModal());
              }
            } else {
              let changedFielddelete = { ...doctorFormData };
              changedFielddelete["hospitalName"] = response.data.data[0].name;
              changedFielddelete["firstName"] = capitalizeFirstLetter(
                data.firstName
              );
              changedFielddelete["lastName"] = capitalizeFirstLetter(
                data.lastName
              );
              changedFielddelete["hospitalIds"] = response.data.data[0]._id;
              setDoctorFormData({ ...changedFielddelete });
              setDoctorModalAction("hospitalApprove");
              setDoctorId(data._id);
              if (type === "open") {
                dispatch(openModal());
              } else {
                dispatch(closeModal());
              }
            }
          }
        );
        break;
      case "reject":
      case "disable":
      case "delete":
      case "enable":
        setDoctorId(data._id);
        let changedFielddelete = { ...doctorFormData };
        changedFielddelete["firstName"] = capitalizeFirstLetter(data.firstName);
        changedFielddelete["lastName"] = capitalizeFirstLetter(data.lastName);
        setDoctorFormData({ ...changedFielddelete });
        dispatchAction(action);
        break;
      case "goToSettings":
        dispatch(goToDoctorSettings(data._id)).then((res) => {
          window.open(res, "_blank");
        });
        break;
      case "changeEmail":
        setDoctorModalAction(action);
        setDoctorId(data._id);
        dispatchAction("changeEmail", data._id);
        break;
      default:
    }
    if (action === "approve" || action === "hospitalApprove") {
    } else {
      setDoctorModalAction(action);
      if (type === "open") {
        // dispatch(openModal());
      } else {
        // dispatch(closeModal());
      }
    }
  };

  const dispatchAction = (action, e) => {
    if (action === "add" || action === "update") {
      // if (emptyField(doctorFormData.firstName)) {
      //   currentFormErrors.firstName = "First Name is required";
      // }
      // if (emptyField(doctorFormData.lastName)) {
      //   currentFormErrors.lastName = "Last Name is required";
      // }
      // if (emptyField(doctorFormData.emailId)) {
      //   currentFormErrors.emailId = "Email Id is required";
      // }
      if (emptyField(doctorFormData.contact.trim())) {
        currentFormErrors.contact = "Contact is required";
      }
      if (
        doctorFormData.hospitalIds === "" &&
        emptyField(doctorFormData.hospitalName)
      ) {
        currentFormErrors.hospitalName = "Hospital is required";
      }
      if (emptyField(doctorFormData.mciRegNumber)) {
        currentFormErrors.mciRegNumber = "MCI Reg. Number is required";
      }
      // if (doctorFormData.emailId && validEmail(doctorFormData.emailId)) {
      //   currentFormErrors.emailId = "EmailId is invalid";
      // }
      if (
        doctorFormData.contact.trim() !== "" &&
        doctorFormData.contact.trim().length !== 10
      ) {
        currentFormErrors.contact = "Contact maximum length 10";
      }
      // if (doctorFormData.degreeIds?.length === 0) {
      //   currentFormErrors.degreeIds = "Degree is required";
      // }
      // if (
      //   doctorFormData.degreeIds.includes("Other") &&
      //   doctorFormData.degree?.length === 0
      // ) {
      //   currentFormErrors.other = "Degree is required";
      // }
      if (doctorFormData.specialty?.length === 0) {
        currentFormErrors.specialty = "Specialty is required";
      }
      setFormErrors(currentFormErrors);
    }
    if (
      (Object.keys(currentFormErrors).length === 0 &&
        (action === "add" || action === "update")) ||
      action === "delete" ||
      action === "hospitalApprove" ||
      action === "disable" ||
      action === "enable" ||
      action === "approve" ||
      action === "reject" ||
      action === "hospitalAdd" ||
      action === "changeEmail" || 
      action === "moduleAccess"
    ) {
      switch (action) {
        case "add":
          let addPayload = {
            firstName: capitalizeFirstLetter(doctorFormData.firstName),
            lastName: capitalizeFirstLetter(doctorFormData.lastName),
            mciRegNumber: doctorFormData.mciRegNumber,
            contact: doctorFormData.contact,
            countryCode: doctorFormData.countryCode,
            emailId: doctorFormData?.emailId?.trim(),
            isApproved: "APPROVE",
            degreeIds: doctorFormData?.degreeIds?.filter((v) => v !== "Other"),
            degree:
              doctorFormData.degree === false ? [] : doctorFormData.degree,
            specialty: doctorFormData.specialty,
            address: {
              permanent: {
                plotNo: doctorFormData.plotNo,
                line1: doctorFormData.address,
                line2: doctorFormData.address2,
                city: doctorFormData.city,
                state: doctorFormData.state,
                country: doctorFormData.country,
                pincode: doctorFormData.pincode,
                latitude: doctorFormData.latitude,
                longitude: doctorFormData.longitude,
              },
            },
          };
          if (doctorFormData.hospitalIds === "") {
            addPayload.hospitalName = capitalizeFirstLetter(
              doctorFormData.hospitalName
            );
            addPayload.roles = ["OWNER", "DOCTOR"];
          } else {
            addPayload.hospitals = [
              {
                hospitalId: doctorFormData.hospitalIds,
                roles: ["DOCTOR"],
                moduleAccess: moduleAccess,
              },
            ];
          }
          setShowBtnLoader("show-button-loader");
          dispatch(addDoctor(addPayload, doctors))
            .then(() => {
              hospitalDoctorsList(1, pageSize, search, doctorFormData.hospitalIds);
              setShowBtnLoader("");
              doctorModalClickAction("close");
            })
            .catch(() => {
              setShowBtnLoader("");
            });
          break;
        case "update":
          let updatePayload = {
            firstName: capitalizeFirstLetter(doctorFormData.firstName),
            lastName: capitalizeFirstLetter(doctorFormData.lastName),
            mciRegNumber: doctorFormData.mciRegNumber,
            contact: doctorFormData.contact,
            countryCode: doctorFormData.countryCode,
            emailId: doctorFormData?.emailId?.trim(),
            degreeIds: doctorFormData?.degreeIds?.filter((v) => v !== "Other"),
            degree:
              doctorFormData.degree === false ? [] : doctorFormData.degree,
            specialty: doctorFormData.specialty,
            address: {
              permanent: {
                plotNo: doctorFormData.plotNo,
                line1: doctorFormData.address,
                line2: doctorFormData.address2,
                city: doctorFormData.city,
                state: doctorFormData.state,
                country: doctorFormData.country,
                pincode: doctorFormData.pincode,
                latitude: doctorFormData.latitude,
                longitude: doctorFormData.longitude,
              },
            },
          };
          if (doctorFormData.hospitalIds === "") {
            updatePayload.hospitalName = capitalizeFirstLetter(
              doctorFormData.hospitalName
            );
            updatePayload.roles = roles;
          } else {
            updatePayload.hospitals = [
              {
                hospitalId: doctorFormData.hospitalIds,
                roles: roles,
                moduleAccess: moduleAccess,
              },
            ];
          }
          setShowBtnLoader("show-button-loader");
          dispatch(updateDoctor(doctorId, updatePayload, doctors))
            .then(() => {
              setShowBtnLoader("");
              doctorModalClickAction("close");
            })
            .catch(() => {
              setShowBtnLoader("");
            });
          break;
        case "approve":
          setShowBtnLoader("show-button-loader");
          const approveData = { userIds: [doctorId], approval: "APPROVE" };
          dispatch(approvalDoctor(doctorId, approveData, doctors))
            .then(() => {
              setShowBtnLoader("");
              doctorModalClickAction("close");
              setShowBtnLoader("");
            })
            .catch(() => setShowBtnLoader(""));
          break;
        case "hospitalApprove":
          let data = {
            hospitalIds: [doctorFormData.hospitalIds],
            approval: "APPROVE",
            noEmail: true,
          };
          setShowBtnLoader("show-button-loader");
          dispatch(
            approvalHospital(doctorFormData.hospitalIds, data, hospitals)
          ).then(() => {
            setShowBtnLoader("");
            const approveData = { userIds: [doctorId], approval: "APPROVE" };
            dispatch(approvalDoctor(doctorId, approveData, doctors))
              .then(() => {
                doctorModalClickAction("close");
                setShowBtnLoader("");
              })
              .catch(() => setShowBtnLoader(""));
          });
          break;
        case "reject":
          setShowBtnLoader("show-button-loader");
          const rejectData = { userIds: [doctorId], approval: "REJECT" };
          dispatch(approvalDoctor(doctorId, rejectData, doctors))
            .then(() => {
              setShowBtnLoader("");
              doctorModalClickAction("close");
            })
            .catch(() => setShowBtnLoader(""));
          break;
        case "disable":
          setShowBtnLoader("show-button-loader");
          const disableData = { userIds: [doctorId], block: true };
          dispatch(disableEnableDoctor(doctorId, disableData, doctors))
            .then(() => {
              setShowBtnLoader("");
              doctorModalClickAction("close");
            })
            .catch(() => setShowBtnLoader(""));
          break;
        case "enable":
          setShowBtnLoader("show-button-loader");
          const enableData = { userIds: [doctorId], block: false };
          dispatch(disableEnableDoctor(doctorId, enableData, doctors))
            .then(() => {
              setShowBtnLoader("");
              doctorModalClickAction("close");
            })
            .catch(() => setShowBtnLoader(""));
          break;
        case "delete":
          setShowBtnLoader("show-button-loader");
          dispatch(deleteDoctor(doctorId, doctors))
            .then(() => {
              setShowBtnLoader("");
              doctorModalClickAction("close");
            })
            .catch(() => setShowBtnLoader(""));
          break;
        case "changeEmail":
          dispatch(sendVerificationEmail(e))
            .then(() => {
              toast.success("Verification email sent successfully.");
            })
            .catch(() => {
              toast.success("Error while sending an email.");
            });
          // toast.success('Verification email sent successfully.');
          break;
        case 'moduleAccess':
          let updatePayloads = {};
          updatePayloads.hospitals = [
            {
              hospitalId: hospitalId,
              moduleAccess: moduleAccess,
            },
          ];
          setShowBtnLoader('show-button-loader');
          dispatch(updateDoctor(doctorId, updatePayloads, doctors))
            .then(() => {
              setShowBtnLoader("");
              doctorModalClickAction("close");
            })
            .catch(() => {
              setShowBtnLoader("");
            });
          break;
        default:
          throw new Error("Modal action not match");
      }
    }
    typeof e !== "string" && e?.preventDefault();
  };

  const handleSelectDegreeAndSpeciality = (res, setState, name) => {
    if (res?.length > 0) {
      let arr = [];
      res.map((v) => {
        arr.push({ value: name === "degree" ? v._id : v.name, label: v.name });
      });
      name === "degree" && arr.push({ value: "Other", label: "Other" });
      setState([...arr]);
    } else {
      setState([]);
    }
  };

  useEffect(() => {
    let filter = { filter: { isApproved: ["1"] } };
    // dispatch(fetchHospitalList({}, filter, "", true));

    dispatch(listDegree()).then((res) => {
      handleSelectDegreeAndSpeciality(res.data.data, setDegreeList, "degree");
    });
    dispatch(searchSpecialty()).then((res) => {
      handleSelectDegreeAndSpeciality(res.data, setSpecialtyList, "specialty");
    });
    //eslint-disable-next-line
  }, []);

  const [addHospitalName, setAddHospitalName] = useState("");
  const searchDebounce = useCallback(debounce(hospitalDoctorsList, 500), [])
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPageNo(1);  
    searchDebounce(1, pageSize, e.target.value, hospitalId);
  };
  const handlePaginationChange = (pageNos, pageSizes) => {
    setPageNo(pageNos);
    setPageSize(pageSizes);
    hospitalDoctorsList(pageNos, pageSizes, search, hospitalId);
  }

  return (
    ["update", "approve","changeQuota"].includes(modalAction) && (
      <>
        <div className="hospitalDoctorList">
          <HospitalDoctorList
            modalClickAction={doctorModalClickAction}
            modalAction={modalAction}
            hospitalFormData={hospitalFormData}
            search={search}
            pageSize={pageSize}
            handleSearchChange={handleSearchChange}
            hospitalId={hospitalId}
            pageNo={pageNo}
            handlePaginationChange={handlePaginationChange}
          />
        </div>
        <div className="AddNew">
          <DoctorAddModal
            showBtnLoader={showBtnLoader}
            modal={modal}
            modalTitle={modalTitle}
            modalAction={doctorModalAction}
            doctorFormData={doctorFormData}
            setDoctorFormData={setDoctorFormData}
            FormErrors={FormErrors}
            setFormErrors={setFormErrors}
            modalClickAction={doctorModalClickAction}
            hospitals={hospitals}
            modalButton1={modalButton1}
            modalButton2={modalButton2}
            resetForm={resetForm}
            dispatchAction={dispatchAction}
            setAddHospitalName={setAddHospitalName}
            addHospitalName={addHospitalName}
            specialtyList={specialtyList}
            degreeList={degreeList}
            otherList={otherList}
            setOtherList={setOtherList}
            isHospitalMember={true}
          />
        </div>
        {["moduleAccess"].includes(doctorModalAction) && (
          <div className="roleModuleAccess">
            <AddUserRoleModal
              FormRoleData={doctorFormData}
              setFormData={setDoctorFormData}
              moduleAccess={moduleAccess}
              setModuleAccess={setModuleAccess}
              modal={modal}
              modalAction={doctorModalAction}
              modalTitle={modalTitle}
              modalButton2={modalButton2}
              modalClickAction={doctorModalClickAction}
              FormErrors={FormErrors}
              moduleAccessList={moduleAccesses.data}
              showBtnLoader={showBtnLoader}
              dispatchAction={dispatchAction}
            />
          </div>
        )}
      </>
    )
  );
}
