import { Pagination, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { S3_BASE_URL } from "../../../common";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';

export default function SpecialtyList({ specialty, modalClickAction, page, setPage, pageSize, setPageSize }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Name", dataIndex: "name", fixed: "left", sorter: (a, b) => a.name.localeCompare(b.name) },
        // {
        //     title: "Aliase", dataIndex: "aliaseSpecialty", render: (row) => {
        //         return (
        //             row.map((r, i) => {
        //                 return (
        //                     <span key={i}>{r.name}</span>
        //                 )
        //             })
        //         )
        //     }
        // },
        // {
        //     title: "Icon", dataIndex: "icon", render: (row) => {
        //         return (
        //             row.map((r) => {
        //                 return (
        //                     findIconImage(r) !== "" &&
        //                     <span className="payment-icon" key={r}>
        //                         <img src={S3_BASE_URL + findIconImage(r)} alt="canvasIcon" />
        //                     </span>
        //                 )
        //             })
        //         )
        //     }
        // },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (specialty?.data?.length > 0) {
            specialty?.data.map((v, i) => {
                let obj = {
                    key: v._id,
                    no: i + 1,
                    name: v.name,
                    // aliaseSpecialty: v.aliaseSpecialty,
                    // icon: v.icon,
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [specialty?.data])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
            <Pagination
                total={specialty.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={pageSize}
                current={page}
                onChange={(page, pageSize) => { setPage(page); setPageSize(pageSize); }}
            />
        </>
    )
}