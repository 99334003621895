import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";

//insert pricing plan
export const addPricingPlanName = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.pricingPlan.add}`,
            data: data
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

//edit pricing plan
export const editPricingPlanName = (data, id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.pricingPlan.update}/${id}`,
            data: data
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

//insert pricing plan
export const deletePricingPlanName = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.pricingPlan.delete}/${id}`,
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

//list pricing plan name 
export const listPricingPlanName = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.pricingPlan.list}`,
            data: data,
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
