import React, { useEffect, useState } from "react";
import Button from "../../Components/Button";
import InfoIcon from "../../assets/images/info-icon.svg";
import { Tooltip, notification } from "antd";
import FormUpload from "../../Components/FormUpload"
import calendericon from '../../assets/images/calendar.svg';
import DatePicker from 'react-datepicker';
import { useDispatch } from "react-redux";
import { HeaderChangeListAPI } from "../../actions/onBoardVideo";
import FormRadio from "../../Components/FormRadio";
import { updateHeader } from "../../actions/payment";
import { removeMainLoaderClass } from "../../common";
import Delete from '../../assets/images/delete.svg'

export default function HeaderChange() {
    const [iconImage, setIconImage] = useState('');
    const [iconImageDelete, setIconImageDelete] = useState('');
    const [deafultHeader, setDeafultHeader] = useState(false);
    const [headerImage, setHeaderImage] = useState('');
    const [headerImageDelete, setHeaderImageDelete] = useState('');
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    const dispatch = useDispatch();

    const handleSubmitHeader = () => {
        let formData = new FormData();
        
        headerImage && setHeaderImageDelete('');
        iconImage && setIconImageDelete('');
        formData.append('logoimage', iconImage);
        formData.append('headerImage', headerImage);
        formData.append('fromDate', fromDate);
        formData.append('deafultHeader', deafultHeader);
        formData.append('iconImageDelete', iconImageDelete);
        formData.append('headerImageDelete', headerImageDelete);
        formData.append('toDate', toDate);
        formData.append('group', "HEADER_LOGO");
        formData.append('key', "HEADER_LOGO");
        dispatch(updateHeader(formData)).then((res) => {
            setIconImage(res.logoimage);
            setHeaderImage(res.headerImage);
            setFromDate(new Date(res.fromDate));
            setToDate(new Date(res.toDate));
            setDeafultHeader(res.deafultHeader);
            notification.success({description: "Header changes has been updated."})
        }).catch(() => { });
    }
    useEffect(() => {
        dispatch(HeaderChangeListAPI('HEADER_LOGO')).then((res) => {
            setIconImage(res[0].value.logoimage);
            setHeaderImage(res[0].value.headerImage);
            setFromDate(new Date(res[0].value.fromDate));
            setToDate(new Date(res[0].value.toDate));
            setDeafultHeader(res[0].value.deafultHeader);
            removeMainLoaderClass();
        }).catch(() => {
            removeMainLoaderClass();
        });
        //eslint-disable-next-line
    }, []);
    return (
        <>
            <div className="tab-white-bg">
                <div className="departmentsSettings">
                    <div className="departmentsoption">
                        <p>Header Change
                            <Tooltip title="Festival changes">
                                <span className="info-icon">
                                    <img src={InfoIcon} alt={InfoIcon} />
                                </span>
                            </Tooltip>
                        </p>
                        <div>
                            <FormRadio
                                inputType="radio"
                                id="refdoctor"
                                name="departmentsSettings"
                                title="OFF"
                                value={false}
                                isChecked={deafultHeader === false && true}
                                onChange={() => setDeafultHeader(false)}
                            />
                            <FormRadio
                                inputType="radio"
                                id="refDept"
                                name="departmentsSettings"
                                title="ON"
                                value={true}
                                isChecked={deafultHeader === true && true}
                                onChange={() => setDeafultHeader(true)}
                            />
                        </div>
                    </div>
                    <div className="action-field right">
                        <Button
                            // loaderClass={props.isKey === false && showBtnLoader ? showBtnLoader : ""}
                            title="Update"
                            onClick={() => { handleSubmitHeader(); }}
                        />
                    </div>
                    <div className="header-setting">
                        <div style={{ display: `${deafultHeader ? "block" : "none"}` }}>
                            <div className="formField">
                                <label><div className="feildRequired">Logo Image<span className="actionField errorBox">*</span></div></label>
                                <FormUpload
                                    logo={iconImage}
                                    setLogo={setIconImage}
                                    message="Drag and Drop image here"
                                    type="logo"
                                />
                                    { iconImage && <span className="delete" onClick={() => {setIconImageDelete('pull'); setIconImage(''); }}>
                                        <img src={Delete} alt={Delete} />
                                    </span> }
                                {/* {
                                    iconImage && onChangePreview  &&
                                    <>
                                        <div className='sign-prev'>
                                            <img src={onChangePreview} alt={onChangePreview} />
                                        </div>
                                    </>
                                } */}
                            </div>
                            <div className="formField">
                                <label><div className="feildRequired">Header Image<span className="actionField errorBox">*</span></div></label>
                                <FormUpload
                                    logo={headerImage}
                                    setLogo={setHeaderImage}
                                    message="Drag and Drop image here"
                                    type="header"
                                />
                                    {headerImage && <span className="delete" onClick={() => {setHeaderImageDelete('pull'); setHeaderImage(''); }}>
                                        <img src={Delete} alt={Delete} />
                                    </span>}
                                {/* {
                                    headerImage && onChangePreviewhHeader &&
                                    <>
                                        <div className='sign-prev'>
                                            <img src={onChangePreviewhHeader} alt={onChangePreviewhHeader} />
                                        </div>
                                    </>
                                } */}
                            </div>
                            <div className="formField calender-field">
                                <label><div className="feildRequired">From Date<span className="errorBox">*</span></div></label>
                                <img src={calendericon} alt={calendericon} />
                                <DatePicker
                                    onChange={(e) => { setFromDate(e); }}
                                    selected={fromDate}
                                    dateFormat="dd-MM-yyyy"
                                    shouldCloseOnSelect={true}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    scrollableYearDropdown={true}
                                    yearDropdownItemNumber={100}
                                />
                                {/* {dateErr && <div className="actionField errorBox">{dateErr}</div>} */}
                            </div>
                            <div className="formField calender-field">
                                <label><div className="feildRequired">To Date<span className="errorBox">*</span></div></label>
                                <img src={calendericon} alt={calendericon} />
                                <DatePicker
                                    onChange={(e) => { setToDate(e); }}
                                    selected={toDate}
                                    dateFormat="dd-MM-yyyy"
                                    shouldCloseOnSelect={true}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    scrollableYearDropdown={true}
                                    yearDropdownItemNumber={100}
                                />
                                {/* {dateErr && <div className="actionField errorBox">{dateErr}</div>} */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}