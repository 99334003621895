import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { LAB_LIST } from "./types";

export const addLab = (data, labs) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.lab.add}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...labs.data];
            let tmp = mainArray.some(v => v._id === response.data._id);
            if (!tmp) {
                mainArray.push(response.data);
            }
            dispatch({
                type: LAB_LIST,
                payload: {
                    count: labs.count + 1,
                    data: mainArray
                },
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//list of lab api
export const fetchLabList = (pagination = {}, filter = {}, search = {}, sort) => async (dispatch) => {
    let body = { pagination, filter, search, sort };
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'post',
            url: `${API_URL.lab.get}`,
            data: { ...body },
        }).then((response) => {
            resolve(response)
            dispatch({
                type: LAB_LIST,
                payload: {
                    count: response.data.count,
                    data: response.data.data
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update lab api
export const updateLab = (labId, data, labs) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.lab.update}/${labId}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...labs.data]
            let tmp = mainArray.findIndex(hospital => hospital._id === labId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data)
            }
            dispatch({
                type: LAB_LIST,
                payload: {
                    count: labs.count,
                    data: mainArray
                },
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete lab api
export const deleteLab = (labId, labs) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "DELETE",
            url: `${API_URL.lab.delete}/${labId}`,
        }).then((response) => {
            resolve(response);
            let mainArray = [...labs.data];
            let tmp = mainArray.findIndex(hospital => hospital._id === labId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1);
            }
            dispatch({
                type: LAB_LIST,
                payload: {
                    count: labs.count,
                    data: mainArray
                },
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//approve and reject lab api
export const approvalLab = (labId, data, labs) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.lab.approval}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...labs.data];
            let tmp = mainArray.findIndex(hospital => hospital._id === labId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data[0])
            }
            dispatch({
                type: LAB_LIST,
                payload: {
                    count: labs.count,
                    data: mainArray
                },
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//enable and disable lab module
export const enableLab = (labId, data, labs) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.lab.blocking}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...labs.data];
            let tmp = mainArray.findIndex(v => v._id === labId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data[0]);
            }
            dispatch({
                type: LAB_LIST,
                payload: {
                    count: labs.count,
                    data: mainArray
                },
            });
        }).catch((error) => {
            reject(error);
        });
    });
}