import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { USER_ROLES_LIST } from "./types";

//list of user roles 
export const fetchUserRoleList = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.userRole.list}`,
        }).then((response) => {
            resolve(response);
            dispatch({
                type: USER_ROLES_LIST,
                payload: response.data.DEFAULT
            })
        }).catch((error) => {
            reject(error);
        });
    });
}

//  add user role and add data in reducer
export const addRole = (data, userRoles) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.userRole.add}`,
            data: data
        }).then((response) => {
            resolve(response.data)
            let obj = response.data;
            let mainArray = [...userRoles];
            let tmp = mainArray.some(v => v._id === obj._id);
            if (!tmp) {
                mainArray.unshift(obj);
            }
            dispatch({
                type: USER_ROLES_LIST,
                payload: mainArray
            })
        }).catch((error) => {
            reject(error);
        });
    });
}

//  update user role and update data in reducer
export const updateRole = (data, key, userRoles) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.userRole.update}`,
            data
        }).then((response) => {
            resolve(response.data)
            let obj = response.data;
            let mainArray = [...userRoles];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: USER_ROLES_LIST,
                payload: mainArray
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//  delete role
export const deleteRole = (roleId, userRoles) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.userRole.delete}/${roleId}`,
        }).then((response) => {
            resolve(response)
            let mainArray = [...userRoles];
            let index = mainArray.findIndex(v => v._id === roleId);
            if (index >= 0) mainArray.splice(index, 1);
            dispatch({
                type: USER_ROLES_LIST,
                payload: mainArray
            });
        }).catch((error) => {
            reject(error);
        });
    });
}