import { Progress, Switch } from "antd";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Button from "../../../Components/Button";
import FormInput from "../../../Components/FormInput";
import Formcheckbox from "../../../Components/Formcheckbox";

export default function PricingAndSms({
  modalAction,
  hospitalFormData,
  isHospital,
  list,
  handleFormChange,
  handleCheckBox,
  handleSelectChange,
  handleDateChange,
  handleSwitchChange,
}) {
  const smsBarCal = (hospitalFormData.usedSMS / hospitalFormData.noOfSMS) * 100;
  const wpBarCal = (hospitalFormData.usedWp / hospitalFormData.noOfWp) * 100;

  const [addSms, setAddSms] = useState(0);
  const [addWp, setAddWp] = useState(0);

  const handlePricingChange = (e,name) => {
    const {value} = e.target;
    const reg = /^-?\d*(\d*)?$/;
      if (reg.test(value) || value === "") {
        if(name === 'sms'){
          setAddSms(value);
        }else if(name === 'whatsapp'){
          setAddWp(value);
        }
      }
  }

  return (
    ["add", "update", "approve", "changeQuota"].includes(modalAction) && (
      <>
        <div className="pricing-title">
          <h4>Pricing Plan & SMS Usage</h4>
        </div>
        <div className="doccine-watermark">
          <h6>Doccine Watermark</h6>
          <p>Remove “Powered by Doccine” watermark from every prints</p>
          <div className="switch-btn">
            <Switch
              checked={hospitalFormData.isDoccineWaterMarkShow}
              onChange={(checked) =>
                handleSwitchChange(checked, "isDoccineWaterMarkShow")
              }
            />
          </div>
        </div>
        <div className="pricing-plan">
          {isHospital && (
            <div className="formField">
              <label>
                <div className="feildRequired">Pricing Plan</div>
              </label>
              <Select
                isSearchable={false}
                isClearable={true}
                required="required"
                classNamePrefix="react-select"
                options={list}
                placeholder="Select value"
                onChange={(e) => handleSelectChange(e, "pricingId")}
                value={list.filter(
                  (option) => hospitalFormData.pricingId === option.value
                )}
              />
            </div>
          )}
          {isHospital && hospitalFormData.pricingId && (
            <Formcheckbox
              inputType="checkbox"
              id="isExpiry"
              name="isExpiry"
              title="Has Plan no expiry?"
              onChange={(e) => handleCheckBox(e)}
              isChecked={hospitalFormData.isExpiry === true ? "checked" : ""}
            />
          )}
          {isHospital &&
            hospitalFormData.pricingId &&
            !hospitalFormData.isExpiry && (
              <div className="formField">
                <label>
                  <div className="feildRequired">Plan Expiry Date</div>
                </label>
                <DatePicker
                  onChange={(e) => handleDateChange(e)}
                  selected={hospitalFormData.expiryDate}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  shouldCloseOnSelect={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={50}
                  // disabled={true}
                />
              </div>
            )}
          <div className="pruchase-date-notchange">
            <span>
              Purchase Date : <b>{moment(new Date()).format("DD MMM,YYYY")} </b>
            </span>

            {hospitalFormData.expiryDate && (
              <span>
                Expiry Date :{" "}
                <b>
                  {moment(hospitalFormData.expiryDate).format("DD MMM,YYYY")}{" "}
                </b>
              </span>
            )}
          </div>
        </div>
        <div className="balance-credit">
          <div className="balance-box">
            <div className="balance-title">
              <h4>SMS Credit</h4>
              <span>
                {hospitalFormData?.usedSMS || 0} of{" "}
                {hospitalFormData?.noOfSMS || 0} Used
              </span>
            </div>
            <Progress
              percent={smsBarCal}
              showInfo={false}
              status={smsBarCal >= 80 ? "exception" : ""}
            />
            <div className="purchase-btn">
              <FormInput
                placeholder="Enter no of SMS"
                title="No Of SMS"
                name="noOfSMS"
                onChange={(e) => {  
                  handlePricingChange(e,'sms');                  
                }}
                value={addSms}
              />
              <Button
                isDisabled={Number(addSms) === 0 ? true : false}
                title={"Add SMS"}
                onClick={() =>
                  handleFormChange({
                    target: {
                      value: Number(addSms) + Number(hospitalFormData.noOfSMS),
                      name: "noOfSMS",
                    },
                  })
                }
              />
            </div>
            <div className="pruchase-date-notchange">
              <span>
                Purchase Date :{" "}
                <b>{moment(new Date()).format("DD MMM,YYYY")} </b>
              </span>
              {hospitalFormData.expiryDate && (
                <span>
                  Expiry Date :{" "}
                  <b>
                    {moment(hospitalFormData.expiryDate).format("DD MMM,YYYY")}{" "}
                  </b>
                </span>
              )}
            </div>
          </div>

          <div className="balance-box">
            <div className="balance-title">
              <h4>WhatsApp Credit</h4>
              <span>
                {hospitalFormData?.usedWp || 0} of{" "}
                {hospitalFormData?.noOfWp || 0} Used
              </span>
            </div>
            <Progress
              percent={wpBarCal}
              showInfo={false}
              status={wpBarCal >= 80 ? "exception" : ""}
            />
            <div className="purchase-btn">
              <FormInput
                placeholder="Enter no of WhatsApp"
                title="No Of WhatsApp"
                name="noOfWp"
                onChange={(e) => {
                  handlePricingChange(e,'whatsapp'); 
                }}
                value={addWp}
              />
              <Button
                isDisabled={Number(addWp) === 0 ? true : false}
                title={"Add Credit"}
                onClick={() =>
                  handleFormChange({
                    target: {
                      value: Number(addWp) + Number(hospitalFormData.noOfWp),
                      name: "noOfWp",
                    },
                  })
                }
              />
            </div>
            <div className="pruchase-date-notchange">
              <span>
                Purchase Date : {moment(new Date()).format("DD MMM,YYYY")}{" "}
              </span>
              {hospitalFormData.expiryDate && (
                <span>
                  Expiry Date :{" "}
                  {moment(hospitalFormData.expiryDate).format("DD MMM,YYYY")}{" "}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* {modalAction !== "add" && (
          <FormInput
            inputType="number"
            placeholder="Used SMS"
            title="Used SMS"
            name="usedSMS"
            onChange={(e) => {
              handleFormChange(e);
            }}
            value={hospitalFormData.usedSMS}
            isDisabled={true}
          />
        )} */}

        {/* {modalAction !== "add" && (
          <FormInput
            inputType="number"
            placeholder="Used WhatsApp"
            title="Used WhatsApp"
            name="usedWp"
            onChange={(e) => {
              handleFormChange(e);
            }}
            value={hospitalFormData.usedWp}
            isDisabled={true}
          />
        )} */}
      </>
    )
  );
}
