import React, { useState } from 'react';
import EmailTemplate from './EmailTemplate';
import SmsTemplate from './SmsTemplate';
import WPTemplate from './WPTemplate';
import './style.scss';

export default function Notifications() {

    const [activeSubMenu, setActiveSubMenu] = useState('EMAIL');

    const renderComponentSubMenu = (activeSubMenu) => {
        switch (activeSubMenu) {
            case "EMAIL":
                return <EmailTemplate activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            case "SMS":
                return <SmsTemplate activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            case "WHATSUP":
                return <WPTemplate activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            default:
                break;
        }
    }

    return (
        <>
            {renderComponentSubMenu(activeSubMenu)}
        </>
    )
}