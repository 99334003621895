import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { Skeleton } from "antd";
import TableTitle from "../../../Components/TableTitle";
import { ExtraSubMenuLabTest, ListApisForDataMx } from "../../Common";
import FormInput from "../../../Components/FormInput";
import Button from "../../../Components/Button";
import { closeModal, openModal } from "../../../actions/modal";
import { getAllAdminLabTest, adminAddReportTemplate, adminUpdateReportTemplate, getAllAdminReportTemplates, deleteAdminReportTemplates } from "../../../actions/template";
import TemplateList from "./TemplatesList";
import { debounce, removeMainLoaderClass } from "../../../common";
import PanelAddModal from "../Panel/PanelAddModal";
import { toast } from "react-toastify";


export default function Templates({ activeSubMenu, setActiveSubMenu }) {
    const { modal, templates = [] } = useSelector(state => state);

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState('');
    const [searchData, setsSearchData] = useState({ templateList: [], templateCount: 0 });
    const [flag, setFlag] = useState(false);
    const [allTemplates, setAllTemplates] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const dispatch = useDispatch();

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(25)

    const initArray = {
        reportName: "",
        details: []
    };
    const [record, setRecord] = useState(initArray);
    const [templateId, setTemplateId] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const [modalAction, setModalAction] = useState('');

    const resetModal = () => {
        setModalAction('');
    };

    const resetForm = () => {
        setRecord(initArray)
        setTemplateId('');
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                break;
            case 'update':
                setTemplateId(data._id);
                let changeRecord = { ...record }
                changeRecord['reportName'] = data.reportName
                let arr = [];
                data.details.map(v => {
                    let subArr = [];
                    v.testList.map((ele) => {
                        const payload = {
                            testName: ele?.testName ? ele?.testName : '',
                            category: ele?.category ? ele?.category : '',
                            department: ele?.department ? ele?.department : '',
                            testCode: ele?.testCode ? ele?.testCode : '',
                            sample: ele?.sample ? ele?.sample : '',
                            container: ele?.container ? ele?.container : '',
                            size: ele?.sampleSize?.size ? ele?.sampleSize?.size : '',
                            ml: ele?.sampleSize?.ml ? ele?.sampleSize?.ml : '',
                            price: ele?.price ? ele?.price : '',
                            reportSize: ele?.report?.size ? ele?.report?.size : '',
                            timeline: ele?.report?.timeline ? ele?.report?.timeline : '',
                            homeCollection: ele?.homeCollection ? ele?.homeCollection : '',
                            referenceValues: ele?.referenceValues ? ele?.referenceValues : [],
                            notes: ele?.notes ? ele?.notes : '',
                        };
                        subArr.push({ ...payload });
                    })
                    let obj = { headingName: v.headingName ? v.headingName : '', testList: [...subArr] }
                    arr.push(obj);
                })
                changeRecord['type'] = data.type
                changeRecord['details'] = [...arr]
                changeRecord["isApproved"] = data.isApproved
                setRecord({ ...changeRecord })
                break;
            case 'delete':
                dispatchAction("delete",data._id)
                break;
            case 'approve':
                dispatchAction("approve",data._id)
                break;
            case 'reject':
                dispatchAction("reject",data._id)
                break;
            case "deleteMultiple":
                dispatchAction("deleteMultiple")
                break;
            // case 'merge':
                // console.log("merge")
                // setModalTitle('Merge Disease');
                // setModalButton1('Cancel');
                // setModalButton2('Merge Disease');
                // setTemplateId(data._id);
                // setName(data.name);
                // break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action, id=false) => {
        const payload = {
            "reportName": record.reportName,
            "details": record.details,
            "type": record.type
        }
        switch (action) {
            case "add":
                if (payload.reportName && payload.reportName.trim() !== "" && payload.type && payload.type.trim() !== "") {
                    payload.isApproved = "1"
                    setShowBtnLoader('show-button-loader');
                    dispatch(adminAddReportTemplate(payload, templates)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('')
                    }).catch(() => {
                        setShowBtnLoader('')
                    })
                } else {
                    if(!payload.reportName){
                        toast.error('Report name is required.');
                    }
                    if(!payload.type){
                        toast.error('Report type is required.');
                    }
                }
                break;
            case "update":
                if (payload.reportName && payload.reportName.trim() !== "" && (templateId) && payload.type && payload.type.trim() !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(adminUpdateReportTemplate(templateId, payload, templates)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('')
                    }).catch(() => {
                        setShowBtnLoader('')
                    })
                } else {
                    if(!payload.reportName){
                        toast.error('Report name is required.');
                    }
                    if(!payload.type){
                        toast.error('Report type is required.');
                    }
                }
                break;
            case "approve":
                if (id) {
                    dispatch(adminUpdateReportTemplate(id, {isApproved: "1"}, templates)).then(() => {
                        setShowBtnLoader('')
                        ListApisForDataMx(activeSubMenu, page, pageSize, search, getAllAdminLabTest, false, dispatch, setLoading);
                        toast.success('Report Approved Successfully.');
                    }).catch(() => {
                        setShowBtnLoader('')
                    })
                }
                break;
            case "delete":
                if (id) {
                    dispatch(adminUpdateReportTemplate(id, {isDeleted: true}, templates)).then(() => {
                        setShowBtnLoader('')
                        payload = {}
                        toast.success('Report Deleted Successfully.');
                    }).catch(() => {
                        setShowBtnLoader('')
                    })
                }
                break;
            case "reject":
                if (id) {
                    dispatch(adminUpdateReportTemplate(id, {isApproved: "-1"}, templates)).then(() => {
                        setShowBtnLoader('')
                        toast.success('Report Rejected Successfully.');
                    }).catch(() => {
                        setShowBtnLoader('')
                    })
                }
                break;
            case 'deleteMultiple':
                if(selectedRowKeys.length>0){
                    const deleteMultiplePayload = {
                        "ids": selectedRowKeys
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteAdminReportTemplates(deleteMultiplePayload, templates, selectedRowKeys)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                        setSelectedRowKeys([]);
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                }
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        debounce(ListApisForDataMx(activeSubMenu, page, pageSize, search, getAllAdminLabTest, false, dispatch, setLoading),300)
    }, [activeSubMenu, page, pageSize])

    useEffect(()=>{
        dispatch(getAllAdminLabTest(null, null, null)).then((res)=>{
            setAllTemplates(res.data)
        })
    },[])

    const handleSearchTemplates = async (e) => {
        ListApisForDataMx(activeSubMenu, page, pageSize, e.target.value, getAllAdminLabTest, false, dispatch, setLoading);
    };

    const handler = useCallback(debounce(handleSearchTemplates, 800), []);

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Templates List" />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenuLabTest}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setSearch('');setPage(1);}}
                                        placeholder="Select Status"
                                        value={ExtraSubMenuLabTest.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { handleSearchTemplates(e); setSearch(e.target.value);}}
                                        value={search}
                                    />
                                </div>
                                {
                                    activeSubMenu === "approved" &&
                                    <div className="add-btn">
                                        <Button
                                            title="Add"
                                            onClick={() => modalClickAction('open', 'add')}
                                        />
                                    </div>
                                }
                                {
                                    selectedRowKeys?.length > 0 &&
                                    <div className="delete-btn">
                                        <Button
                                            btn="btn-outline-primary"
                                            title="Delete"
                                            onClick={() => modalClickAction('open', 'deleteMultiple')}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table labtest-templates">
                            <TemplateList
                                templates={templates}
                                modalClickAction={modalClickAction}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <div className="add-panel-modal Add-LabTest-modal">
                <div className="language-table">
                    <PanelAddModal
                        dispatch={dispatch}
                        modal={modal}
                        modalAction={modalAction}
                        modalClickAction={modalClickAction}
                        showBtnLoader={showBtnLoader}
                        resetForm={resetForm}
                        dispatchAction={dispatchAction}
                        panel={allTemplates}
                        record={record}
                        setRecord={setRecord}
                        panelId={templateId}
                        setPanelId={setTemplateId}
                        isPanel={false}
                    />
                </div>
            </div>
        </>
    )
};
