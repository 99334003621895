import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";

//Doccine University list
export const fetchDoccineUniversityList = (body) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            data: body,
            url: `${API_URL.doccienUniversity.list}`
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

//Doccine University category list
export const fetchDoccineUniversityCategoryList = () => async () => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.doccienUniversity.category}`
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

//Doccine University add video
export const addDoccineUniversityVideo = (body) => async () => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            data: body,
            url: `${API_URL.doccienUniversity.add}`
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

//Doccine University update video
export const updateDoccineUniversityVideo = (body, id) => async () => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            data: body,
            url: `${API_URL.doccienUniversity.update}/${id}`
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

//Doccine University delete video
export const deleteDoccineUniversityVideo = (id) => async () => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.doccienUniversity.delete}/${id}`
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

