import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import deleteicon from '../../assets/images/close-icon.png';
import calendericon from '../../assets/images/calendar.svg';
import { Skeleton } from "antd";
import TableTitle from "../../Components/TableTitle";
import WhatsNewList from "./WhatsNewList";
import Button from "../../Components/Button";
import { closeModal, openModal } from "../../actions/modal";
import FormInput from "../../Components/FormInput";
import { addWhatsnewImages, deleteWhatsnewImages, ListWhatsnewImages, updateWhatsnewImages } from "../../actions/whatsNew";
import { fetchUserRoleList } from "../../actions/userRole";
import DiseaseModals from "../Diseases/DiseaseModals";
import { formatDate, removeMainLoaderClass, S3_BASE_URL } from "../../common";
import Modal from "../../Components/Modal";
import './style.scss';

export default function WhatsNew(props) {

    const { modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [modalAction, setModalAction] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');
    const [newImages, setNewImages] = useState([]);
    const [newImageId, setNewImageId] = useState('');
    const [roleList, setRoleList] = useState([]);
    const [role, setRole] = useState('');
    const [roleErr, setRoleErr] = useState('');
    const [date, setDate] = useState(new Date())
    const [dateErr, setDateErr] = useState('')
    const [qrImage, setQrImage] = useState([]);
    const [qrImageErr, setQrImageErr] = useState('');
    const [preview, setPreview] = useState([]);

    const [name, setName] = useState('')

    const resetForm = () => {
        setName('')
        setDate(new Date());
        setDateErr('');
        setQrImage([]);
        setQrImageErr('')
        setPreview([]);
        setRole('');
        setRoleErr('');
        setNewImageId('');
    }

    const resetModal = () => {
        setModalAction('');
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case "addImages":
                break;
            case 'delete':
            case "updateImage":
                setNewImageId(data._id);
                let arr = [];
                data.roles.map(v => {
                    arr.push(v._id);
                })
                setRole([...arr]);
                setDate(new Date(data.date));
                let prev = [];
                data.images.map((v) => {
                    prev.push({ url: v, isAdd: true })
                })
                setPreview([...prev]);
                setName(formatDate(new Date(data.date)))
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        switch (action) {
            case "addImages":
                let formData = new FormData();
                formData.append('roles', JSON.stringify(role));
                formData.append('date', date);
                if (qrImage) {
                    for (var x = 0; x < qrImage.length; x++) {
                        formData.append("images", qrImage[x]);
                    }
                }
                if (role === "") setRoleErr('Role is required.')
                if (date === "") setDateErr('Date is required.')
                if (qrImage.length === 0) setQrImageErr('Image is required.')
                if (role !== "" && date !== "" && qrImage.length !== 0 && !dateErr && !qrImageErr && !roleErr) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(addWhatsnewImages(formData)).then((res) => {
                        if (res.data) {
                            let changeImage = [...newImages];
                            let tmp = changeImage.some(v => v._id === res.data._id);
                            if (!tmp) {
                                changeImage.unshift(res.data);
                            }
                            setNewImages([...changeImage])
                        }
                        setShowBtnLoader('');
                        modalClickAction('close');
                        toast.success('Image is added successfully.')
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            case 'updateImage':
                let formUpdateData = new FormData();
                formUpdateData.append('roles', JSON.stringify(role));
                formUpdateData.append('date', date);
                if (qrImage) {
                    for (var x = 0; x < qrImage.length; x++) {
                        formUpdateData.append("images", qrImage[x]);
                    }
                }
                let oldUrls = [];
                preview.map((v) => {
                    if (v.isAdd === true) {
                        oldUrls.push(v.url);
                    }
                })
                if (oldUrls.length > 0) formUpdateData.append("images_url", JSON.stringify(oldUrls));
                else formUpdateData.append("images_url", JSON.stringify([]));
                if (preview.length === 0) setQrImageErr('Image is required.')
                if (role === "") setRoleErr('Role is required.')
                if (date === "") setDateErr('Date is required.')
                if (role !== "" && date !== "" && preview.length !== 0 && !dateErr && !roleErr && newImageId) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateWhatsnewImages(formUpdateData, newImageId)).then((res) => {
                        if (res.data) {
                            let changeImage = [...newImages];
                            let tmp = changeImage.findIndex(v => v._id === res.data._id);
                            if (tmp >= 0) {
                                changeImage.splice(tmp, 1, res.data);
                            }
                            setNewImages([...changeImage])
                        }
                        setShowBtnLoader('');
                        modalClickAction('close');
                        toast.success('Image is upadated successfully.')
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            case 'delete':
                if (newImageId) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteWhatsnewImages(newImageId)).then(() => {
                        let changeImage = [...newImages];
                        let tmp = changeImage.findIndex(v => v._id === newImageId);
                        if (tmp >= 0) {
                            changeImage.splice(tmp, 1);
                        }
                        setNewImages([...changeImage]);
                        setShowBtnLoader('');
                        modalClickAction('close');
                        toast.success('Image is deleted successfully.')
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            default:
        }
    }

    const handleMultipleImages = (evnt) => {
        const selectedFIles = [];
        const saveFIles = [];
        const targetFiles = evnt.target.files;
        const targetFilesObject = [...targetFiles]
        targetFilesObject.map((file) => {
            let arrFile = file.name.split('.');
            if (["jpg", "jpeg", "png", "gif"].includes(arrFile[arrFile.length - 1]?.toLowerCase())) {
                saveFIles.push(file)
                selectedFIles.push(window.URL.createObjectURL(file))
            } else {
                toast.error('Please upload an Image with ".jpg", ".jpeg", ".png", ".gif" formats.')
            }
        });
        let exists = [];
        selectedFIles.map((v) => {
            exists.push({ url: v, isAdd: false });
        })
        setPreview([...preview, ...exists]);
        setQrImage([...qrImage, ...saveFIles]);
        setQrImageErr("")
    }

    useEffect(() => {
        setLoading(true);
        dispatch(ListWhatsnewImages()).then((res) => {
            if (res.data.length > 0) setNewImages(res.data);
            else setNewImages([]);
            removeMainLoaderClass();
            setLoading(false);
        }).catch(() => {
            removeMainLoaderClass();
            setLoading(false);
        })
        dispatch(fetchUserRoleList()).then((res) => {
            if (res?.data?.DEFAULT?.length > 0) {
                let arr = [];
                res.data.DEFAULT.map((v) => {
                    arr.push({ value: v._id, label: v.name })
                })
                setRoleList([...arr]);
            }
        });
    }, []);

    const handleMultipleRole = (e) => {
        let selectedValue = [];
        e.forEach((v) => {
            selectedValue.push(v.value);
        })
        setRole([...selectedValue])
    }

    const handleDeleteImage = (i) => {
        let changePreview = [...preview];
        changePreview.splice(i, 1);
        if(changePreview.length===0){
            setQrImage([])
        }
        setPreview([...changePreview]);
    }

    const [search, setSearch] = useState('');
    const [speList, setSpeList] = useState([]);

    useEffect(() => {
        if (newImages?.length > 0) setSpeList([...newImages]);
        else setSpeList([]);
    }, [newImages])

    useEffect(() => {
        if (newImages?.length > 0) {
            if (search !== "") {
                let arr = [];
                newImages?.map((v) => {
                    v.roles.map((a) => {
                        if (a.name.toLowerCase().includes(search?.toLowerCase())) {
                            arr.push(v)
                        }
                    })
                });
                setSpeList([...arr])
            } else {
                setSpeList([...newImages])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: speList.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="What's New" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'addImages')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table whatsnew">
                            <WhatsNewList
                                speList={speList}
                                modalClickAction={modalClickAction}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            {(modalAction === "addImages" || modalAction === "updateImage") &&
                <div className="whats-new-modal">
                    <Modal
                        ModalTitle={modalAction === "addImages" ? "Add Image" : "Update Image"}
                        type="center"
                        onClose={() => modalClickAction('close')}
                        Modalclass={(modal && (modalAction === "addImages" || modalAction === "updateImage")) ? 'show-modal' : ''}
                    >
                        <div>
                            <div className="twoColsField">
                                <div className="formField">
                                    <label><div className="feildRequired">Role<span className="actionField errorBox">*</span></div></label>
                                    <Select
                                        isMulti={true}
                                        required="required"
                                        classNamePrefix="react-select"
                                        options={roleList}
                                        placeholder="Select Group"
                                        name="role"
                                        id="role"
                                        onChange={(e) => { handleMultipleRole(e); setRoleErr('') }}
                                        value={roleList.filter((option) => {
                                            return (role.includes(option.value));
                                        })}
                                    />
                                    {roleErr && <div className="actionField errorBox">{roleErr}</div>}
                                </div>

                                <div className="formField calender-field">
                                    <label><div className="feildRequired">Date<span className="errorBox">*</span></div></label>
                                    <img src={calendericon} alt={calendericon} />
                                    <DatePicker
                                        onChange={(e) => { setDate(e); setDateErr('') }}
                                        selected={date}
                                        dateFormat="dd-MM-yyyy"
                                        shouldCloseOnSelect={true}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        scrollableYearDropdown={true}
                                        yearDropdownItemNumber={100}
                                    />
                                    {dateErr && <div className="actionField errorBox">{dateErr}</div>}
                                </div>
                            </div>
                            <FormInput
                                inputType="file"
                                name="image"
                                title={"Images"}
                                onChange={handleMultipleImages}
                                multiple
                                isMulti={true}
                                required="required"
                                errorMessage={qrImageErr}
                            />
                            <div className="upload-img-show">
                                {
                                    preview && preview.map((a, i) => {
                                        return (
                                            <span key={i}>
                                                <img src={a.isAdd === true ? S3_BASE_URL + a.url : a.url} alt="" />
                                                <span className="delete" onClick={() => handleDeleteImage(i)}>
                                                    <img alt={deleteicon} src={deleteicon} />
                                                </span>
                                            </span>
                                        )
                                    })
                                }
                            </div>
                            <div className="activeButton right">
                                <Button
                                    title="Cancel"
                                    buttonType="reset"
                                    onClick={() => modalClickAction('close')}
                                />
                                <Button
                                    loaderClass={["addImages", "updateImage"].includes(modalAction) ? showBtnLoader : ""}
                                    title={modalAction === "addImages" ? "Add" : "Update"}
                                    onClick={(e) => { e.preventDefault(); dispatchAction(modalAction) }}
                                />
                            </div>
                        </div>
                    </Modal>
                </div>
            }
        </>
    )
}