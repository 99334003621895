import React, { useState } from "react";
import './style.scss';

function ForwardFormInput({ onFocus, isMulti, checkError, key, className, id, title, value, required, defaultValue, inputType, placeholder, isDisabled, isReadonly, onChange, onKeyUp, onKeyDown, onKeyPress, autoFocus, onPaste, name, errorMessage, min, max, onBlur, style = {}, onClick }, ref) {
    const [hasFocus, setFocus] = useState(false);
    return (
        <div key={key} className={`formField ${className ? className : ""} ${hasFocus ? ' active' : ''}`}>
            {
                title &&
                <label htmlFor={id}>
                    <div className="feildRequired">{title}{required === "required" &&
                        <span className="actionField errorBox">*</span>}</div>
                </label>
            }
            <input className="formControl" key={key}
                onFocus={() => { setFocus(true); onFocus && onFocus() }}
                value={(defaultValue ? defaultValue : value)}
                placeholder={placeholder}
                type={inputType}
                disabled={isDisabled}
                readOnly={isReadonly}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                autoFocus={autoFocus}
                onPaste={onPaste}
                onKeyPress={onKeyPress}
                onClick={onClick}
                name={name}
                id={id}
                min={min}
                max={max}
                ref={ref}
                autoComplete="off"
                required
                style={{ ...style }}
                multiple={isMulti}
                onBlur={() => { setFocus(false); onBlur && onBlur(); }}
            />
            {errorMessage && <div className="actionField errorBox"> {errorMessage}</div>}
        </div>
    );
}
const FormInput = React.forwardRef(ForwardFormInput);
export default FormInput;