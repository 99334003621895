/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';
import View from '../../../assets/images/eye-outline-blue.svg';
import { nameSortingForTable, numberSortingForTable } from "../../../common";

export default function AddOnsList({ records, modalClickAction, getLabelFromValue }) {

    const [data, setData] = useState([]);
    const columns = [
        { title: "No", dataIndex: "no", width: 50, sorter: (a, b) => numberSortingForTable(a?.no, b?.no) },
        { title: "Name", dataIndex: "name", sorter: (a, b) => nameSortingForTable(a?.name, b?.name) },
        { title: "Type", dataIndex: "type", sorter: (a, b) => nameSortingForTable(a?.type, b?.type) },
        { title: "Extra Value", dataIndex: "value", sorter: (a, b) => nameSortingForTable(a?.value, b?.value)},
        { title: "Price(₹)", dataIndex: "price", sorter: (a, b) => numberSortingForTable(a?.price, b?.price)},
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="View">
                            <span className="view" onClick={() => modalClickAction('open', "viewAddOns", row.data)}>
                                <img src={View} alt={View} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', "updateAddOns", row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (records?.length > 0) {
            records.map((v, i) => {
                let obj = {
                    no: i + 1,
                    key: v._id,
                    name: v.planName,
                    value: v.planDetail.value ? v.planDetail.value + ' ' + (v.planDetail.type === "EXTRA_GBS" ? "GB" : "SMS") : '-',
                    price: v.charges ? v.charges : '-',
                    type: v.planDetail.type ? getLabelFromValue(v.planDetail.type) : '-',
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [records])

    return (
        <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
    )
}