import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../actions/modal";
import { ExtraSubMenu, ListApisForDataMx } from "../Common";
import { addSymptom, approveSymptom, deleteSymptom, fetchSymptomList, isCommonMultipleSymptom, mergeSymptoms, symptomCategoryList, updateSymptom } from "../../actions/symptom";
import TableTitle from "../../Components/TableTitle";
import Select from "react-select";
import Button from "../../Components/Button";
import FormInput from "../../Components/FormInput";
import { debounce, emptyField } from "../../common";
import DiseaseList from "../Diseases/DiseaseList";
import DiseaseModals from "../Diseases/DiseaseModals";
import { Skeleton } from 'antd';

export default function Symptoms(props) {

    //for skeleton
    const [loading, setLoading] = useState(false);

    const { symptoms = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [symptomId, setSymptomId] = useState('');
    const [mergeSymptomId, setMergeSymptomId] = useState('');

    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [isCommon, setIsCommon] = useState(false);
    const [categories, setCategories] = useState([]);

    const [nameError, setNameError] = useState('');
    const [mergeError, setMergeError] = useState('');
    const [categoryError, setCategoryError] = useState('');

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton1, setModalButton1] = useState('');
    const [modalButton2, setModalButton2] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const [symptomSearch, setSymptomSearch] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const [activeSubMenu, setActiveSubMenu] = useState('inapporval');

    //For multiple delete options
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const resetForm = () => {
        setName('');
        setCategory('');
        setSymptomSearch('');
        setSymptomId('');
        setIsCommon(false);
        setMergeSymptomId('');
        setNameError('');
        setMergeError('');
        setCategoryError('');
    };

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton1('');
        setModalButton2('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                setModalTitle('Add Symptom');
                setModalButton1('Reset');
                setModalButton2('Add');
                break;
            case 'update':
                setModalTitle('Edit Symptom');
                setModalButton1('Reset');
                setModalButton2('Edit Symptom');
                setIsCommon(data.isCommon);
                setSymptomId(data._id);
                setName(data.name);
                setCategory(data?.category?._id);
                break;
            case 'approve':
                setModalTitle('Confirm Symptoms');
                setModalButton1('Reset');
                setModalButton2('Confirm Symptoms');
                setSymptomId(data._id);
                setName(data.name);
                setCategory(data?.category?._id);
                setIsCommon(data.isCommon)
                break;
            case 'reject':
                setSymptomId(data._id);
                setName(data.name);
                setCategory(data.category);
                break;
            case 'disable':
            case 'merge':
                setModalTitle('Merge Symptom');
                setModalButton1('Cancel');
                setModalButton2('Merge Symptom');
                setIsCommon(data.isCommon)
                setSymptomId(data._id);
                setName(data.name);
                break;
            case 'delete':
                setSymptomId(data._id);
                setName(data.name);
                break;
            case 'deleteMultiple':
                setName("Selected Symptoms");
                break;
            case 'isCommonMultiple':
                setName("Selected Symptoms");
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        if (action === "add" || action === "update" || action === "merge" || action === "approve") {
            if (emptyField(name)) {
                setNameError("Symptom is Required")
            }
            if (emptyField(category)) {
                setCategoryError("Category is Required");
            }
            if (emptyField(mergeSymptomId)) {
                setMergeError("Merge Symptom is Required");
            }
        }
        const updatePayload = {
            _id: symptomId,
            name: name,
            category: category,
            isCommon: isCommon
        }
        const deleteMultiplePayload = {
            "ids": selectedRowKeys
        }
        switch (action) {
            case 'add':
                const payload = {
                    docs: [{ name: name, category: category, isCommon: isCommon }]
                };
                if (payload.name !== "" && payload.category !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(addSymptom(payload, symptoms)).then(() => {
                        ListApisForDataMx(activeSubMenu, 1, pageSize, symptomSearch, fetchSymptomList, setSelectedRowKeys, dispatch, setLoading);
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    })
                }
                break;
            case 'update':
                if (updatePayload.name !== "" && updatePayload.category && updatePayload.category !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateSymptom(updatePayload, symptoms)).then(() => { modalClickAction('close'); setShowBtnLoader(''); }).catch(() => { setShowBtnLoader(''); })
                }
                break;
            case 'approve':
                if (updatePayload.name !== "" && updatePayload.category && updatePayload.category !== "") {
                    const ApprovePayload = {
                        "ids": [symptomId],
                        "approval": "APPROVE"
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateSymptom(updatePayload, symptoms, "approved")).then(() => {
                        dispatch(approveSymptom(ApprovePayload, symptoms)).then(() => {
                            modalClickAction('close')
                            ListApisForDataMx(activeSubMenu, 1, pageSize, symptomSearch, fetchSymptomList, setSelectedRowKeys, dispatch, setLoading);
                            setShowBtnLoader('');
                        }).catch(() => setShowBtnLoader(''));
                    });
                }
                break;
            case 'reject':
                const rejectPayload = {
                    "ids": [symptomId],
                    "approval": "REJECT"
                }
                setShowBtnLoader('show-button-loader');
                dispatch(approveSymptom(rejectPayload, symptoms)).then(() => { modalClickAction('close'); setShowBtnLoader(''); }).catch(() => { setShowBtnLoader(''); })
                break;
            case 'merge':
                const mergePayload = {
                    "id": symptomId,
                    "withId": mergeSymptomId
                }
                if (mergePayload.id !== "" && mergePayload.withId !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(mergeSymptoms(mergePayload, symptoms)).then(() => { modalClickAction('close'); setShowBtnLoader(''); }).catch(() => { setShowBtnLoader(''); })
                }
                break;
            case 'delete':
                const deletePayload = {
                    "ids": [symptomId]
                }
                setShowBtnLoader('show-button-loader');
                dispatch(deleteSymptom(deletePayload, symptoms, symptomId)).then(() => { modalClickAction('close'); setShowBtnLoader(''); }).catch(() => { setShowBtnLoader(''); })
                break;
            case 'deleteMultiple':
                setShowBtnLoader('show-button-loader');
                dispatch(deleteSymptom(deleteMultiplePayload, symptoms, selectedRowKeys)).then(() => { modalClickAction('close'); setShowBtnLoader(''); }).catch(() => { setShowBtnLoader(''); })
                setSelectedRowKeys([])
                break;
            case 'isCommonMultiple':
                setShowBtnLoader('show-button-loader');
                dispatch(isCommonMultipleSymptom(deleteMultiplePayload, symptoms)).then(() => { modalClickAction('close'); setShowBtnLoader(''); setSelectedRowKeys([]) }).catch(() => { setShowBtnLoader(''); })
                break;
            default:
                throw new Error('Modal action not match');
        }
    };

    const updateSymptomArr = (array, setState, name) => {
        let arr = [];
        array && array.map((v) => {
            if (v._id && v.name && v.name !== name && !v.mergeReference) {
                arr.push({ value: v._id, label: v.name });
            }
        });
        setState(arr);
    }

    useEffect(() => {
        dispatch(symptomCategoryList()).then((res) => {
            if (res.data) {
                let arr = [];
                res.data.forEach((v) => {
                    if (v._id && v.name) {
                        arr.push({ value: v._id, label: v.name });
                    }
                });
                setCategories([...arr]);
            }
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        debounce(ListApisForDataMx(activeSubMenu, page, pageSize, symptomSearch, fetchSymptomList, setSelectedRowKeys, dispatch, setLoading),300)
    }, [symptomSearch, activeSubMenu, page, pageSize])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Symptoms List" />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenu}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setPage(1); setSymptomSearch('') }}
                                        placeholder="Select Status"
                                        value={ExtraSubMenu.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSymptomSearch(e.target.value); }}
                                        value={symptomSearch}
                                    />
                                </div>
                                {
                                    selectedRowKeys?.length > 0 &&
                                    <div className="common-btn">
                                        <Button
                                            title="IsCommon True"
                                            onClick={() => modalClickAction('open', 'isCommonMultiple')}
                                        />
                                    </div>

                                }
                                {
                                    activeSubMenu === "approved" &&
                                    <div className="add-btn">
                                        <Button
                                            title="Add"
                                            onClick={() => modalClickAction('open', 'add')}
                                        />
                                    </div>
                                }
                                {
                                    selectedRowKeys?.length > 0 &&
                                    <div className="delete-btn">
                                        <Button
                                            btn="btn-outline-primary"
                                            title="Delete"
                                            onClick={() => modalClickAction('open', 'deleteMultiple')}
                                        />
                                    </div>
                                }

                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table symptoms">
                            <DiseaseList
                                diseases={symptoms}
                                modalClickAction={modalClickAction}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                isCategory={true}
                                isCommon={true}
                                isSymp={true}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            {/* Symptoms modal import from disease */}
            <DiseaseModals
                api={fetchSymptomList}
                dispatch={dispatch}
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                setName={setName}
                nameError={nameError}
                setNameError={setNameError}
                mergeDiseaseId={mergeSymptomId}
                setMergeDiseaseId={setMergeSymptomId}
                mergeError={mergeError}
                setMergeError={setMergeError}
                modalButton1={modalButton1}
                modalButton2={modalButton2}
                modalTitle={modalTitle}
                isCommon={isCommon}
                setIsCommon={setIsCommon}
                resetForm={resetForm}
                isCommonMultiple={true}
                categories={categories}
                category={category}
                setCategory={setCategory}
                categoryError={categoryError}
                setCategoryError={setCategoryError}
                isCatAvailable={true}
                isComAvailable={true}
                isDelete={true}
                isMerge={true}
                isAdd={true}
            />
        </>
    );
};