/* eslint-disable array-callback-return */
import { Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { S3_BASE_URL } from "../../../common";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';

export default function UserRoleList({ users, modalClickAction }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Name", dataIndex: "name", fixed: "left", sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: "Type", dataIndex: "type", sorter: (a, b) => a?.type.localeCompare(b?.type)  },
        {
            title: "Module Access",width: 450, dataIndex: "icon", render: (_, { icon }) => {
                return (
                    icon.map((r, i) => {
                        return (
                            <Tooltip title={r.key} key={i}>
                                <span className="access-icon" key={i}>
                                    <img src={S3_BASE_URL + r.icon} alt="moduleAccess" />
                                </span>
                            </Tooltip>
                        )
                    })
                )
            }
        },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (users?.length > 0) {
            users.map((v, i) => {
                let moduleAccessAllList = [];
                v.moduleAccess && v.moduleAccess.view && v.moduleAccess.view.forEach((p) => {
                    let tmp = moduleAccessAllList.some(v => v.key === p.key);
                    if (!tmp) moduleAccessAllList.push(p);
                })
                v.moduleAccess && v.moduleAccess.create && v.moduleAccess.create.forEach((p) => {
                    let tmp = moduleAccessAllList.some(v => v.key === p.key);
                    if (!tmp) moduleAccessAllList.push(p);
                })
                v.moduleAccess && v.moduleAccess.edit && v.moduleAccess.edit.forEach((p) => {
                    let tmp = moduleAccessAllList.some(v => v.key === p.key);
                    if (!tmp) moduleAccessAllList.push(p);
                })
                v.moduleAccess && v.moduleAccess.delete && v.moduleAccess.delete.forEach((p) => {
                    let tmp = moduleAccessAllList.some(v => v.key === p.key);
                    if (!tmp) moduleAccessAllList.push(p);
                })
                let obj = {
                    no: i + 1,
                    key: v._id,
                    name: v.name,
                    type: "DEFAULT",
                    icon: moduleAccessAllList,
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [users])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
        </>
    )
}