import { Pagination, Table, Tooltip } from 'antd';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Eye from '../../../assets/images/eye-outline-blue.svg'
import Resolve from '../../../assets/images/tick-circle.svg';
import ResolveFill from '../../../assets/images/tick-circle-fill.svg';
import { nameSortingForTable } from '../../../common';

const ErrorLogList = ({page, pageSize, setPage, setPageSize, error, dispatchAction, setViewError, setErrorDetails}) => {
  const [data, setData] = useState([]);

  const viewAction = (row)=>{
    setViewError(true)
    setErrorDetails(row)
  }

  const columns =[
    { title: "No", dataIndex: "no", width: 50 },
    { title: "Name", dataIndex: "name", width: 80, sorter:(a, b) => nameSortingForTable(a?.name, b?.name)},
    { title: "Date/Time", dataIndex: "time", width: 80, sorter:(a, b) => nameSortingForTable(a?.time, b?.time)},
    { title: "Message", dataIndex: "message", width: 100, sorter:(a, b) => nameSortingForTable(a?.message , b?.message)},
    { title: "Status", dataIndex: "status", width: 50, render: (_, { status }) => {
      return (
        <strong>
          <span>{status ? <span style={{color: "green"}}> Resolved </span>: <span style={{color: "red"}}> Un Resolved </span>}</span>
        </strong>
          
      )
      }, sorter:(a, b) => nameSortingForTable(a?.status ? 'Resolved' : 'Un Resolved', b?.status ? 'Resolved' : 'Un Resolved')
    },
    {
      title: 'Action',
      width: 50,
      dataIndex: '',
      key: 'x',
      fixed: "right",
      render: (row) => {
          return (
              <div className="action">
                  <Tooltip title="View">
                      <span className="edit" onClick={()=> viewAction(row)}>
                          <img src={Eye} alt={Eye} />
                      </span>
                  </Tooltip>
                  {
                    row?.status ? 
                    <Tooltip title="Un Resolve">
                      <span className="delete" onClick={()=> dispatchAction("unresolve", "archived", row, false)}>
                          <img src={ResolveFill} alt={ResolveFill} />
                      </span>
                  </Tooltip>
                   : 
                   <Tooltip title="Resolve">
                      <span className="delete" onClick={()=> dispatchAction("resolve", "archived", row, true)}>
                          <img src={Resolve} alt={Resolve} />
                      </span>
                  </Tooltip>

                  }
              
              </div>
          )
      },
    }, 
  ]

  useEffect(() => {
    let arr = [];
    if (error?.data?.length > 0) {
        error.data.map((v, i) => {
            let obj = {
                key:v?._id,
                no: i + 1,
                name: v?.name,
                time: v?.time,
                message: v?.message?.message,
                status: v?.archived,
                visited: v?.visited,
                data:v
            }
            arr.push(obj)
        })
    }
    setData([...arr])
}, [error])
  return (
    <>
    <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }}   rowClassName={(record) => record.visited ? "visited-error" : "new-error"}/>
            <Pagination
                total={error.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={pageSize}
                current={page}
                onChange={(page, pageSize) => { setPage(page); setPageSize(pageSize); }}
    />
    </>

  )
}

export default ErrorLogList