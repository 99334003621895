import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { DOCTOR_LIST } from "./types";

//doctor list api
export const fetchDoctorList = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'post',
            url: `${API_URL.doctors.get}`,
            data,
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: DOCTOR_LIST,
                payload: {
                    userCount: response.count,
                    userData: response.data
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//add doctor api
export const addDoctor = (data, doctors) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.doctors.add}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...doctors.userData];
            let tmp = mainArray.some(v => v._id === response.data._id);
            if (!tmp) {
                mainArray.push(response.data);
            }
            dispatch({
                type: DOCTOR_LIST,
                payload: {
                    userCount: doctors.userCount + 1,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update doctor api
export const updateDoctor = (doctorId, body, doctors) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.doctors.update}/${doctorId}`,
            data: body,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...doctors.userData]
            let tmp = mainArray.findIndex(v => v._id === doctorId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data)
            }
            dispatch({
                type: DOCTOR_LIST,
                payload: {
                    userCount: doctors.userCount,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete doctor api
export const deleteDoctor = (doctorId, doctors) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "DELETE",
            url: `${API_URL.doctors.delete}`,
            data: { "userIds": [doctorId] },
        }).then((response) => {
            resolve(response)
            let mainArray = [...doctors.userData];
            let tmp = mainArray.findIndex(v => v._id === doctorId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1);
            }
            dispatch({
                type: DOCTOR_LIST,
                payload: {
                    userCount: doctors.userCount - 1,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//doctors analytics data
export const doctorAnalytics = async () => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.doctors.analytics}`,
        }).then((response) => {
            resolve(response.data)
            return response.data;
        }).catch((error) => {
            reject(error);
        });
    });
}

//approve and reject doctor api
export const approvalDoctor = (doctorId, data, doctors) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.doctors.approval}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...doctors.userData];
            let tmp = mainArray.findIndex(v => v._id === doctorId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data[0])
            }
            dispatch({
                type: DOCTOR_LIST,
                payload: {
                    userCount: doctors.userCount,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//disable and enable doctor api
export const disableEnableDoctor = (doctorId, data, doctors) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.doctors.blocking}`,
            data: data,
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...doctors.userData];
            let tmp = mainArray.findIndex(v => v._id === doctorId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data[0]);
            }
            dispatch({
                type: DOCTOR_LIST,
                payload: {
                    userCount: doctors.userCount,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

// export const getDoctorList = () => async (dispatch) => {
//     return new Promise((resolve, reject) => {
//         apiClient({
//             method: 'GET',
//             url: `${API_URL.doctor.getDoctorList}`,
//         }).then((response) => {
//             resolve(response.data);
//             dispatch({
//                 type: FINANCE_DOCTOR_LIST,
//                 payload: {
//                     financeDoctorList: response.data,
//                 }
//             });
//         })
//             .catch((error) => {
//                 reject(error);
//             });
//     });
// }

//disable and enable doctor api
export const goToDoctorSettings = (doctorId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "GET",
            url: `${API_URL.doctors.gotoSetting}/${doctorId}`,
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

//send verification email on email change
export const sendVerificationEmail = (userId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.doctors.verificationEmail}/${userId}`,
        }).then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}