import { Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { S3_BASE_URL, nameSortingForTable } from "../../../common";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';

export default function FilesCategory({ filesCategory, modalClickAction }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Type", dataIndex: "type", sorter: (a, b) => nameSortingForTable(a?.type, b?.type) },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]
    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (filesCategory?.length > 0) {
            filesCategory.map((v, i) => {
                let obj = {
                    no: i + 1,
                    type: v?.name,
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [filesCategory])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
        </>
    )
}