import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { MODULE_ACCESS_LIST } from "./types";

//module access list api
export const fetchModuleAccessList = (pagination, search) => async (dispatch) => {
    let body = { pagination, search }
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.moduleAccess.list}`,
            data: body
        }).then((response) => {
            resolve(response)
            dispatch({
                type: MODULE_ACCESS_LIST,
                payload: {
                    count: response.data.count,
                    data: response.data.data,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//  Update and add module access and Update and Add data from module access list reducer
export const updateModule = (data, key, moduleAccesses, action) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.moduleAccess.update}`,
            data
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...moduleAccesses?.data];
            let obj = response.data;
            if (action === "update") {
                let index = mainArray.findIndex(v => v._id === key);
                if (index >= 0) {
                    mainArray.splice(index, 1, obj)
                }

            } else if (action === "add") {
                let tmp = mainArray.some(v => v._id === key);
                if (!tmp) {
                    mainArray.push(obj);
                }
            }
            dispatch({
                type: MODULE_ACCESS_LIST,
                payload: {
                    count: moduleAccesses.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//  delete module access and remove from module access list reducer
export const deleteModule = (key, moduleAccesses) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.moduleAccess.delete}/${key}`,
        }).then((response) => {
            resolve(response)
            let mainArray = [...moduleAccesses?.data];
            let tmp = mainArray.findIndex(paymentl => paymentl.key === key);
            if (tmp >= 0) mainArray.splice(tmp, 1);
            dispatch({
                type: MODULE_ACCESS_LIST,
                payload: {
                    count: moduleAccesses.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}