import { Pagination, Table, Tag } from 'antd';
import 'antd/dist/antd.css';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../../common";

export default function DoctorsList(props) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Doctor Name", dataIndex: "drName", fixed: "left", sorter: (a, b) => a.drName.localeCompare(b.drName) },
        { title: "Hospital Name", dataIndex: "hosName", fixed: "left", sorter: (a, b) => a.hosName.localeCompare(b.hosName) },
        { title: "Last Login Time", dataIndex: "lastLoginTime", sorter: (a, b) => new Date(a?.data?.lastLoginTime) - new Date(b?.data?.lastLoginTime) },
        { title: "Login Count", dataIndex: "loginCount", sorter: (a, b) => a.loginCount - b.loginCount },
        {
            title: "Appointments(Doc)", dataIndex: "aptCount", sorter: (a, b) => a?.aptCount?.TOTAL - b?.aptCount?.TOTAL, render: (_, { aptCount }) => {
                return (
                    <div className="appointment-type">
                        <Tag color="green">
                            Total: {aptCount.TOTAL}
                        </Tag>
                        <Tag color="green">
                            New Case: {aptCount.NEW_CASE}
                        </Tag>
                        <Tag color="green">
                            Follow Up: {aptCount.FOLLOW_UP}
                        </Tag>
                    </div>
                )
            }
        },
        { title: "Bills(Doc)s", dataIndex: "billCount", sorter: (a, b) => a.billCount - b.billCount },
        { title: "Prescriptions(Doc)", dataIndex: "presCount", sorter: (a, b) => a.presCount - b.presCount },
        { title: "Prescriptions(Hosp)", dataIndex: "totalPresCount", sorter: (a, b) => a.totalPresCount - b.totalPresCount },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (props.doctors.length > 0) {
            props.doctors.map((v, i) => {
                arr.push({
                    key: i,
                    no: i + 1,
                    drName: capitalizeFirstLetter(v.doctorName),
                    hosName: capitalizeFirstLetter(v.hospitalName),
                    lastLoginTime: v.lastLoginTime ? moment(v.lastLoginTime).format("DD-MM-YYYY hh:mm A") : '-',
                    loginCount: v?.loginCount ? v.loginCount : 0,
                    aptCount: v?.apt,
                    billCount: v?.billCount ? v.billCount : 0,
                    presCount: v?.presCount ? v.presCount : 0,
                    totalPresCount: v?.hospitalCount ? v.hospitalCount : 0,
                    data: v,
                })
                return true;
            })
        }
        setData([...arr])
    }, [props.doctors])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
            <Pagination
                total={props.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={props.pageSize}
                current={props.page}
                onChange={(page, pageSize) => { props.getApiList(page, pageSize, props.fromDate, props.toDate, props.search, props.filterLog) }}
            />
        </>
    )
}