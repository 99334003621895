/* eslint-disable array-callback-return */
import { Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import Edit from '../../../assets/images/Edit.svg';
import Delete from '../../../assets/images/delete.svg';
import { S3_BASE_URL, dateSortingForTable } from "../../../common";

export default function WhatsNewList({ speList, modalClickAction }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", width: 50 },
        {
            title: "Images", dataIndex: "images", width: 450, render: (row) => {
                return (
                    row.map((r, i) => {
                        return (
                            <span className="whatsnew-img" key={i}>
                                <img src={S3_BASE_URL + r} alt="whatsnew" />
                            </span>
                        )
                    })
                )
            }
        },
        {
            title: "Role", dataIndex: "roles", render: (row) => {
                return (
                    row.map((r, i) => {
                        return (
                            i === (row.length - 1) ?
                                <span key={r.name}>{r.name}</span>
                                :
                                <span key={r.name}>{r.name}, </span>
                        )
                    })
                )
            }
        },
        { title: "Date", dataIndex: "date", sorter: (a, b) => dateSortingForTable(a?.date, b?.date) },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'updateImage', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (speList?.length > 0) {
            speList.map((v, i) => {
                let obj = {
                    no: i + 1,
                    key: v._id,
                    roles: v.roles,
                    images: v.images,
                    date: v?.date,
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [speList])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
        </>
    )
}