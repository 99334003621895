import { Skeleton } from "antd";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { defaultManageSettings, removeMainLoaderClass } from "../../common";
import Button from "../../Components/Button";
import Formcheckbox from "../../Components/Formcheckbox";
import ManageSettingCheckBox from "../../Components/ManageSettingCheckBox";
import ManageSettingRadio from "../../Components/ManageSettingRadio";
import TableTitle from "../../Components/TableTitle";
import All3SymptomsDuraionIcon from "../../assets/mangeSettingImg/History-Duration-Comment.svg";
import SymptomsOnlyIcon from "../../assets/mangeSettingImg/Symptoms.svg";
import SymptomsDuraionIcon from "../../assets/mangeSettingImg/Symptoms-Duration.svg";
import SymptomsCommentIcon from "../../assets/mangeSettingImg/History-Comment.svg";
import All3HistoryDurationIcon from "../../assets/mangeSettingImg/History-Duration-Comment.svg";
import HistoryOnlyIcon from "../../assets/mangeSettingImg/History.svg";
import HistoryDurationIcon from "../../assets/mangeSettingImg/History-Duration.svg";
import HistoryCommentIcon from "../../assets/mangeSettingImg/History-Comment.svg";
import DefaultTreatment from "../../assets/mangeSettingImg/Default-Treatment.svg";
import SingleLineFormat from "../../assets/mangeSettingImg/singleLineFormat.svg";
import LineByLineFormat from "../../assets/mangeSettingImg/lineByLine.svg";
import tick from "../../assets/images/Tick-icon.svg";
import Edit from "../../assets/images/Edit-grey.svg";
import { useDispatch } from "react-redux";
import { adminOnBoardVideoLinkListOne, adminOnBoardVideoLinkUpsert } from "../../actions/onBoardVideo";
import { toast } from "react-toastify";
import './style.scss';
import FormInput from "../../Components/FormInput";

export default function ManageSettings() {

    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);
    //button loader
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const [activeMenu, setActiveMenu] = useState('symptoms');

    const [mainMenu, setMainMenu] = useState([
        { menu: "Symptoms", defaultTitle: "Symptoms", value: "symptoms", extraValue: "symptomsComment", isEdit: false },
        { menu: "Past History", defaultTitle: "Past History", value: "pastHistory", extraValue: "isBlankPastHistory", blankValue: "blankHistory", isEdit: false },
        { menu: "Habits", defaultTitle: "Habits", value: "habits", extraValue: "isBlankHabits", blankValue: "blankHabits", isEdit: false },
        { menu: "Family", defaultTitle: "Family", value: "family", extraValue: "isBlankFamily", blankValue: "blankFamily", isEdit: false },
        { menu: "General Examination", defaultTitle: "General Examination", value: "generalExamination", extraValue: "isBlankGeneral", blankValue: "blankGeneral", isEdit: false },
        { menu: "Systemic Examination", defaultTitle: "Systemic Examination", value: "systematicExamination", extraValue: "isBlankSystemic", blankValue: "blankSystemic", isEdit: false },
        { menu: "Gynec History", defaultTitle: "Gynec History", value: "gynecHistory", isEdit: false },
        { menu: "Probable Diagnosis", defaultTitle: "Probable Diagnosis", value: "probableDiagnosis", isEdit: false },
        { menu: "Confirmed Diagnosis", defaultTitle: "Confirmed Diagnosis", value: "confirmedDiagnosis", isEdit: false },
        { menu: "Treatment", defaultTitle: "Treatment", value: "treatment", isEdit: false },
        { menu: "Lab Tests", defaultTitle: "Lab Tests", value: "labTests", isEdit: false },
        { menu: "Advise", defaultTitle: "Advise", value: "advise", isEdit: false },
        { menu: "Follow Up", defaultTitle: "Follow Up", value: "followUp", isEdit: false },
        { menu: "Refer a Doctor", defaultTitle: "Refer a Doctor", value: "referedDoctor", isEdit: false },
        { menu: "Print Format", value: "printFormat" },
        { menu: "Private Note", defaultTitle: "Private Note", value: "privateNote", isEdit: false},
    ]);

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (position) => {
        dragItem.current = position;
    };

    const dragEnter = (position) => {
        dragOverItem.current = position;
    };

    const drop = () => {
        const copyListItems = [...mainMenu];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setMainMenu([...copyListItems]);
    };

    const [menus, setMenus] = useState({
        symptoms: true,
        symptomsComment: true,
        pastHistory: true,
        isBlankPastHistory: false,
        blankHistory: "",
        habits: true,
        isBlankHabits: false,
        blankHabits: "",
        family: true,
        isBlankFamily: false,
        blankFamily: "",
        generalExamination: true,
        isBlankGeneral: false,
        blankGeneral: "",
        systematicExamination: true,
        isBlankSystemic: false,
        gynecHistory: true,
        probableDiagnosis: true,
        confirmedDiagnosis: true,
        treatment: true,
        labTests: true,
        advise: true,
        followUp: true,
        referedDoctor: true,
        printFormatssss: true,
        privateNote: true,
    })

    const [subMenus, setSubMenus] = useState({
        symptoms: "symptomsAndDurations",
        pastHistory: "historyDurationAndComment",
        habits: "habitsAndDurations",
        family: "diagnosisDurationAndRelation",
        generalExamination: "defaultGeneralExamination",
        systematicExamination: "defaultSystematicExamination",
        probableDiagnosis: "diagnosisAndDurations",
        confirmedDiagnosis: "diagnosisAndDurations",
        treatment: "defaultTreatment",
        labTests: "defaultLabTests",
        advise: "defaultAdvise",
        followUp: "defaultFollowUp",
        gynecHistory: "defaultGynecHistory",
        referedDoctor: "defaultReferDoctor",
        printFormat: "singleLineFormat",
        privateNote: "defaultPrivateNote",
    })

    const compareArrays = (arr1, arr2) => {
        const uniqueObjects = [];
      
        if (arr1 && arr1.length > 0) {
          for (const obj1 of arr1) {
            if (!arr2 || arr2.length === 0) {
              uniqueObjects.push(obj1);
            } else {
              const found = arr2.some((obj2) => obj2.menu === obj1.menu);
      
              if (!found) {
                uniqueObjects.push(obj1);
              }
            }
          }
        }
      
        if (arr2 && arr2.length > 0) {
          for (const obj2 of arr2) {
            if (!arr1 || arr1.length === 0) {
              uniqueObjects.push(obj2);
            } else {
              const found = arr1.some((obj1) => obj1.menu === obj2.menu);
      
              if (!found) {
                uniqueObjects.push(obj2);
              }
            }
          }
        }
      
        return uniqueObjects;
    };
      
      
      
      //set menu, submenu and mainmenu
    const setStateInManageSetting = (prescriptionSetting, defaultManageSettings) => {
        let arr = [];
        let changeMenu = { ...menus };
        let changeSubMenu = { ...subMenus };
          
        prescriptionSetting.map((v, i) => {
            let obj = mainMenu.find(men => men.value === v.menu);
            // if (v.title) {
            //     obj.menu = v.title
            // }
            obj.isEdit = false;
            obj.isDisplay = v.isDisplay;
                  
            arr.push({ ...obj });
            if (v.extraMenu) {
              changeMenu[v.extraMenu] = v.isExtraDisplay;
            }
            if (v.blankValue) {
                changeMenu[obj.blankValue] = v.blankValue;
            }
            changeMenu[v.menu] = v.isDisplay;
            changeMenu["isDisplay"] = v.isDisplay;
                    
            changeSubMenu[v.menu] = v.subMenu;
            if (i === 0) setActiveMenu(v.menu);
        });
        setMenus({ ...changeMenu });
        setSubMenus({ ...changeSubMenu });
        if(prescriptionSetting.length > 0 && defaultManageSettings.length > 0){
            const uniqueObjects = compareArrays(prescriptionSetting, defaultManageSettings);
            setMainMenu([...arr, ...uniqueObjects]);
        }
        else{
            setMainMenu([...arr])
        }

    }

    const mainSubMenus = [
        {
            menus: [
                {
                  subMenuName: 'Symptoms + Duration + Comment',
                  subValue: 'symptomsDurationAndComment',
                  image: All3SymptomsDuraionIcon
                },
                { subMenuName: 'Symptoms Only', subValue: 'symptomsOnly', image: SymptomsOnlyIcon },
                { subMenuName: 'Symptoms + Duration', subValue: 'symptomsAndDurations', image: SymptomsDuraionIcon },
                { subMenuName: 'Symptoms + Comment', subValue: 'symptomsAndComment', image: SymptomsCommentIcon },
            ], value: "symptoms",
            extraMenu: { extraMenuName: "Do you want comments fields with Symptoms?", extraValue: "symptomsComment" }
        },
        {
            menus: [
                { subMenuName: "History + Duration + Comment", subValue: "historyDurationAndComment", image: All3HistoryDurationIcon },
                { subMenuName: "History Only", subValue: "historyOnly", image: HistoryOnlyIcon },
                { subMenuName: "History + Duration", subValue: "historyAndDuration", image: HistoryDurationIcon },
                { subMenuName: "History + Comment", subValue: "historyAndComment", image: HistoryCommentIcon },
            ], value: "pastHistory",
            extraMenu: { extraMenuName: "Custom text when left blank", extraValue: "isBlankPastHistory", blankValue: "blankHistory" }
        },
        {
            menus: [
                { subMenuName: "Habits + Duration", subValue: "habitsAndDurations", image: HistoryDurationIcon },
                { subMenuName: "Habits Only", subValue: "habitsOnly", image: HistoryOnlyIcon }
            ], value: "habits",
            extraMenu: { extraMenuName: "Custom text when left blank", extraValue: "isBlankHabits", blankValue: "blankHabits" }
        },
        {
            menus: [
                { subMenuName: "Diagnosis + Duration + Relation", subValue: "diagnosisDurationAndRelation", image: All3HistoryDurationIcon },
                { subMenuName: "Diagnosis + Relation", subValue: "diagnosisAndRealtion", image: HistoryCommentIcon },
            ], value: "family",
            extraMenu: { extraMenuName: "Custom text when left blank", extraValue: "isBlankFamily", blankValue: "blankFamily" }
        },
        {
            menus: [
                { subMenuName: "Default General Examination", subValue: "defaultGeneralExamination", image: HistoryOnlyIcon },
            ], value: "generalExamination",
            extraMenu: { extraMenuName: "Custom text when left blank", extraValue: "isBlankGeneral", blankValue: "blankGeneral" }
        },
        {
            menus: [
                { subMenuName: "Default Systemic Examination", subValue: "defaultSystematicExamination", image: HistoryOnlyIcon },
            ], value: "systematicExamination",
            extraMenu: { extraMenuName: "Custom text when left blank", extraValue: "isBlankSystemic", blankValue: "blankSystemic" }
        },
        {
            menus: [
                { subMenuName: "Diagnosis + Duration", subValue: "diagnosisAndDurations", image: HistoryDurationIcon },
                { subMenuName: "Diagnosis Only", subValue: "diagnosisOnly", image: HistoryOnlyIcon }
            ], value: "probableDiagnosis"
        },
        {
            menus: [
                { subMenuName: "Diagnosis + Duration", subValue: "diagnosisAndDurations", image: HistoryDurationIcon },
                { subMenuName: "Diagnosis Only", subValue: "diagnosisOnly", image: HistoryOnlyIcon }
            ], value: "confirmedDiagnosis"
        },
        {
            menus: [
                { subMenuName: "Default Gynec History", subValue: "defaultGynecHistory", image: HistoryOnlyIcon },
            ], value: "gynecHistory"
        },
        {
            menus: [
                { subMenuName: "Default Treatment", subValue: "defaultTreatment", image: DefaultTreatment },
            ], value: "treatment"
        },
        {
            menus: [
                { subMenuName: "Default Lab Tests", subValue: "defaultLabTests", image: HistoryOnlyIcon },
            ], value: "labTests"
        },
        {
            menus: [
                { subMenuName: "Default Advise", subValue: "defaultAdvise", image: HistoryOnlyIcon },
            ], value: "advise"
        },
        {
            menus: [
                { subMenuName: "Default Follow Up", subValue: "defaultFollowUp", image: HistoryOnlyIcon },
            ], value: "followUp"
        },
        {
            menus: [
                { subMenuName: "Default Refer a Doctor", subValue: "defaultReferDoctor", image: HistoryOnlyIcon },
            ], value: "referedDoctor"
        },
        {
            menus: [
                { subMenuName: "Default Single line Prescription", subValue: "singleLineFormat", image: SingleLineFormat },
                { subMenuName: "Right Align Breakline Prescription ", subValue: "lineByLineFormat", image: LineByLineFormat },
            ], value: "printFormat"
        },
        {
            menus : [
                {subMenuName : "Default Private Note", subValue: "defaultPrivateNote", image: HistoryOnlyIcon},
            ], value: "privateNote"
        },
    ]

    //handle input change
    const handleInputChange = (e) => {
        const { value, name } = e.target;
        let changeFields = { ...menus };
        changeFields[name] = value;
        setMenus({ ...changeFields })
    }

    //handle checkbox
    const handleCheckBox = (name) => {
        let changeFields = { ...menus };
        changeFields[name] = !changeFields[name];
        setMenus({ ...changeFields })
    }

    //handle radio change
    const handleRadioChange = (name, value) => {
        let changeFields = { ...subMenus };
        changeFields[name] = value
        setSubMenus({ ...changeFields })
    }

    //when is edit field change
    const handleEditChange = (isBool, i, isChange) => {
        let changeFields = [...mainMenu];
        changeFields = changeFields.map((v, index) => {
            if (index !== i && isChange) {
                v.isEdit = !isBool
            } else {
                v.isEdit = isBool
            }
            return v;
        })
        setMainMenu([...changeFields]);
    }

    //when is edit input field change
    const handleEditInputChange = (e, i) => {
        let changeFields = [...mainMenu];
        changeFields[i]['menu'] = e.target.value;
        setMainMenu([...changeFields]);
    }

    //when input is empty then set default as title
    const handleEmptyInput = (i) => {
        let changeFields = [...mainMenu];
        if (changeFields[i]['menu']?.trim() === "") {
            changeFields[i]['menu'] = changeFields[i]['defaultTitle'];
            setMainMenu([...changeFields]);
        }
    }

    //on save button click 
    const saveManageSetting = () => {
        let arr = []
        mainMenu.map((v) => {
            let obj = {}
            if (v.extraValue) {
                obj = { menu: v.value, title: v.menu, isDisplay: menus[v.value], subMenu: subMenus[v.value], extraMenu: v.extraValue, isExtraDisplay: menus[v.extraValue] };
                if (v.blankValue) {
                    obj.blankValue = menus[v.blankValue];
                }
            } else {
                obj = { menu: v.value, title: v.menu, isDisplay: menus[v.value], subMenu: subMenus[v.value] };
            }
            if (obj.isDisplay === true) obj.isDefault = true;
            else obj.isDefault = false;
            arr.push(obj);
        });
        let defaultArr = arr.filter(a => a.isDisplay === true);
        let nonDefaultArr = arr.filter(a => a.isDisplay === false);
        arr = [...defaultArr, ...nonDefaultArr];
        let add = {
            key: "DEFAULT_MANAGE_SETTINGS",
            value: arr,
            group: "DEFAULT_MANAGE_SETTINGS"
        }
        setShowBtnLoader('show-button-loader');
        dispatch(adminOnBoardVideoLinkUpsert(add)).then((res) => {
            setShowBtnLoader('');
            toast.success('Default Manage setting updated successfully.')
        }).catch(() => {
            setShowBtnLoader('');
        })
    }

    //list of default manage setting
    useEffect(() => {
        setLoading(true);
        dispatch(adminOnBoardVideoLinkListOne("DEFAULT_MANAGE_SETTINGS")).then((res) => {
            if (res[0]?.value?.length > 0 ) {
                setLoading(false);
                removeMainLoaderClass();
                setStateInManageSetting(res[0].value, defaultManageSettings);
            } else {
                removeMainLoaderClass();
                setLoading(false);
                setStateInManageSetting(defaultManageSettings, res[0].value);
            }
        });
    }, [])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <div className="adminTableHeader">
                        <TableTitle title="Manage Settings" />
                    </div>
                    <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                        <div className="manage-setttings-prescriptions">
                            <ul className="manage-settings-main-menus">
                                {
                                    mainMenu.length > 0 && mainMenu.map((menu, i) => {
                                        return (
                                            menu.value !== "printFormat" ?
                                                <li key={i} className={menu.value === activeMenu ? 'active' : ''} onClick={() => setActiveMenu(menu.value)}
                                                    onDragStart={() => dragStart(i)} onDragEnter={() => dragEnter(i)}
                                                    onDragEnd={() => drop()} draggable={true}
                                                >
                                                    {
                                                        menu.isEdit ?
                                                            <div className="manage-settings-inputs">
                                                                <FormInput
                                                                    inputType="text"
                                                                    name={menu.value}
                                                                    value={menu.menu}
                                                                    onChange={(e) => handleEditInputChange(e, i)}
                                                                    placeholder={menu.value}
                                                                    onBlur={() => handleEmptyInput(i)}
                                                                />
                                                                <span className="closeIcon" onClick={() => handleEditChange(false, i)}>
                                                                    <img src={tick} alt={tick} />
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="manage-settings-checkboxes">
                                                                <ManageSettingCheckBox
                                                                    inputType="checkbox"
                                                                    id={menu.value}
                                                                    name={menu.value}
                                                                    title={menu.menu}
                                                                    onChange={() => handleCheckBox(menu.value)}
                                                                    isChecked={menus[menu.value] ? "checked" : ""}
                                                                />
                                                                <span className="editIcon" onClick={() => handleEditChange(true, i, true)}>
                                                                    <img src={Edit} alt={Edit} />
                                                                </span>
                                                            </div>
                                                    }
                                                    <span className="Drapicon">
                                                        <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_91_1060)">
                                                                <path d="M1.66667 3.33333C2.58714 3.33333 3.33333 2.58714 3.33333 1.66667C3.33333 0.746192 2.58714 0 1.66667 0C0.746192 0 0 0.746192 0 1.66667C0 2.58714 0.746192 3.33333 1.66667 3.33333Z" fill="#D9D9D9" />
                                                                <path d="M1.66667 11.6693C2.58714 11.6693 3.33333 10.9231 3.33333 10.0026C3.33333 9.08213 2.58714 8.33594 1.66667 8.33594C0.746192 8.33594 0 9.08213 0 10.0026C0 10.9231 0.746192 11.6693 1.66667 11.6693Z" fill="#D9D9D9" />
                                                                <path d="M1.66667 19.9974C2.58714 19.9974 3.33333 19.2512 3.33333 18.3307C3.33333 17.4103 2.58714 16.6641 1.66667 16.6641C0.746192 16.6641 0 17.4103 0 18.3307C0 19.2512 0.746192 19.9974 1.66667 19.9974Z" fill="#D9D9D9" />
                                                                <path d="M8.33073 3.33333C9.2512 3.33333 9.9974 2.58714 9.9974 1.66667C9.9974 0.746192 9.2512 0 8.33073 0C7.41025 0 6.66406 0.746192 6.66406 1.66667C6.66406 2.58714 7.41025 3.33333 8.33073 3.33333Z" fill="#D9D9D9" />
                                                                <path d="M8.33073 11.6693C9.2512 11.6693 9.9974 10.9231 9.9974 10.0026C9.9974 9.08213 9.2512 8.33594 8.33073 8.33594C7.41025 8.33594 6.66406 9.08213 6.66406 10.0026C6.66406 10.9231 7.41025 11.6693 8.33073 11.6693Z" fill="#D9D9D9" />
                                                                <path d="M8.33073 19.9974C9.2512 19.9974 9.9974 19.2512 9.9974 18.3307C9.9974 17.4103 9.2512 16.6641 8.33073 16.6641C7.41025 16.6641 6.66406 17.4103 6.66406 18.3307C6.66406 19.2512 7.41025 19.9974 8.33073 19.9974Z" fill="#D9D9D9" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_91_1060">
                                                                    <rect width="10" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                </li>
                                                :
                                                <li key={i} className={menu.value === activeMenu ? 'active' : ''} onClick={() => setActiveMenu(menu.value)}>
                                                    <ManageSettingCheckBox
                                                        inputType="checkbox"
                                                        id={menu.value}
                                                        name={menu.value}
                                                        title={menu.menu}
                                                        onChange={() => handleCheckBox(menu.value)}
                                                        isChecked={menus[menu.value] ? "checked" : ""}
                                                    />
                                                </li>
                                        )
                                    })
                                }
                            </ul>
                            <div className="manage-settings-submenus">
                                {
                                    mainSubMenus.length > 0 && mainSubMenus.map((subMenu, i) => {
                                        return (
                                            subMenu.value === activeMenu &&
                                            <div key={i} className="d-flex">
                                                {
                                                    subMenu?.menus?.length > 0 && subMenu?.menus?.map((sub, j) => {
                                                        return (
                                                            <div key={j}>
                                                                <ManageSettingRadio
                                                                    inputType="radio"
                                                                    title={sub.subMenuName}
                                                                    id={sub.subValue}
                                                                    name={subMenu.value}
                                                                    value={sub.subValue}
                                                                    settingimg={sub?.image}
                                                                    onChange={() => handleRadioChange(subMenu.value, sub.subValue)}
                                                                    isChecked={sub?.subValue === subMenus[subMenu.value] && true}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="checkbox-with-form-input">
                                                    {
                                                        subMenu?.extraMenu &&
                                                        <Formcheckbox
                                                            inputType="checkbox"
                                                            id={subMenu?.extraMenu?.extraValue}
                                                            name={subMenu?.extraMenu?.extraValue}
                                                            title={subMenu?.extraMenu?.extraMenuName}
                                                            onChange={() => handleCheckBox(subMenu?.extraMenu?.extraValue)}
                                                            isChecked={menus[subMenu?.extraMenu?.extraValue] ? "checked" : ""}
                                                        />
                                                    }
                                                    {
                                                        menus[subMenu?.extraMenu?.extraValue] && subMenu.value !== "symptoms" &&
                                                        <FormInput
                                                            inputType="text"
                                                            name={subMenu?.extraMenu?.blankValue}
                                                            value={menus[subMenu?.extraMenu?.blankValue]}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Write here"
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="activeButton right">
                            <Button
                                buttonType="reset"
                                title="Reset"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setStateInManageSetting(defaultManageSettings)
                                }} />
                            <Button
                                loaderClass={showBtnLoader ? showBtnLoader : ""}
                                btn="no-print"
                                title="Save"
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveManageSetting();
                                }}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
        </>
    )
}