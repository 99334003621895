/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Skeleton, Table, Collapse, Switch, Checkbox } from "antd";
import { fetchAdminAptFields, updateAdminAptFields } from "../../actions/AptCustomForm";
import TableTitle from "../../Components/TableTitle";
// import Formcheckbox from "../../Components/Formcheckbox";
import { removeMainLoaderClass } from "../../common";
import './style.scss';

export default function AptCustomForm(props) {
    const dispatch = useDispatch();
    const { Panel } = Collapse;

    const [loading, setLoading] = useState(false);
    const [aptFieldList, setAptFieldList] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        dispatch(fetchAdminAptFields(
            { "groupByCategory": true }
        )).then((res) => {
            setAptFieldList(res)
            removeMainLoaderClass();
            setLoading(false);
        }).catch(() => {
            removeMainLoaderClass();
            setLoading(false);
        });
        //eslint-disable-next-line
    }, []);

    const onChange = (checked, j, id, key, i) => {
        if (key === "isActive") {
            dispatch(updateAdminAptFields(id, { isActive: checked })).then((res) => {
                let updatedata = [...aptFieldList];
                updatedata[i].entries[j].isActive = res.isActive;
                setAptFieldList([...updatedata]);
            });
        } else {
            dispatch(updateAdminAptFields(id, { isMandatory: checked })).then((res) => {
                let updatedata = [...aptFieldList];
                updatedata[i].entries[j].isMandatory = res.isMandatory;
                setAptFieldList(updatedata);
            })
        }
    }

    useEffect(() => {
        let arr = [];
        if (aptFieldList?.length > 0) {
            aptFieldList.map((v, i) => {
                let entries = [];
                v.entries.map((e, j) => {
                    let obj = {
                        i: i,
                        j: j,
                        key: e.name,
                        isFixed: e.isFixed,
                        isMandatory: e.isMandatory,
                        isActive: e.isActive,
                        data: e
                    }
                    entries.push(obj);
                })
                arr.push(entries)

            })
        }
        setData([...arr]);

    }, [aptFieldList])

    let columns = [
        { title: "Name", dataIndex: "key", fixed: "left" },
        {
            title: "Mandatory",
            dataIndex: "isMandatory",
            fixed: "left",
            render: (_, { data, i, j }) => {
                return (
                    <>
                        <Checkbox
                            title=""
                            inputType="checkbox"
                            checked={data.isMandatory ? true : false}
                            onChange={(e) => onChange(e.target.checked, j, data._id, "isMandatory", i)}
                            defaultChecked
                            disabled={data.isFixed ? true : false}
                        />
                    </>
                )
            },
        },
        {
            title: 'Action',
            width: 150,
            dataIndex: 'isActive',
            key: 'x',
            fixed: "right",
            render: (_, { data, i, j }) => {
                return (
                    <Switch
                        checked={data.isActive ? true : false}
                        onChange={(checked) => onChange(checked, j, data._id, "isActive", i)}
                        disabled={data.isFixed ? true : false}
                    />
                )
            },
        },
    ]

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: aptFieldList.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Appointment Custom Form" />
                        </div>
                        {/* Table List */}
                        <div className="table AptCustomForm">
                            {
                                aptFieldList && aptFieldList.length > 0 && aptFieldList.map((af, i) => {
                                    return (
                                        <>
                                            <Collapse defaultActiveKey={i}>
                                                <Panel header={<><span>{af._id}</span><span className="mandatory">Mandatory?</span> <span className="actions">Actions</span></>} key={i}>
                                                    <Table showHeader={false} columns={columns} dataSource={data[i]} pagination={false} sticky={true} scroll={{ x: 1000 }} />
                                                </Panel>
                                            </Collapse>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </Skeleton>
                </div>
            </div>
        </>
    );
};