import React, { useState, useEffect } from "react";
import DoctorImage from '../../assets/images/Doctor-img.png';
import { useSelector, useDispatch } from "react-redux";
import { adminLogin } from "../../actions/auth";
import './style.scss';
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/Logo.svg';
import { CONSTANT_ROUTES } from "../../config/constantRoutes";
import { removeMainLoaderClass } from "../../common";
import { notification } from "antd";

export default function Login(props) {

    const { auth = [] } = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    /* Field states */
    const [emailId, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [showBtnLoader, setShowBtnLoader] = useState('');

    useEffect(() => {
        removeMainLoaderClass();
    }, [])

    useEffect(() => {
        if (auth.role.includes('ADMIN') && auth.token) {
            navigate(`${CONSTANT_ROUTES.admin.dashboard}`)
        }
        //eslint-disable-next-line
    }, [auth]);

    const onChangeEmail = (e) => {
        setEmail(e.currentTarget.value);
    };
    const onChangePassword = (e) => {
        setPassword(e.currentTarget.value);
    };
    const onLoginSubmit = async (e) => {
        e.preventDefault();
        setShowBtnLoader('show-button-loader');
        dispatch(adminLogin({ emailId: emailId, password: password })).then(() => setShowBtnLoader('')).catch((err) => {
            setShowBtnLoader('')
            if(err.response?.data?.message){
                notification.error({description: err.response?.data?.message})
            }
        });
    };

    const passwordShow = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div className="main-wrapper">
            <div className="OnBoarding">
                <div className="bottomImg">
                    <img alt="" src={DoctorImage} />
                </div>
                <div className="setPasswordBox">
                    <div className="login-logo">
                        <img src={logo} alt={logo} />
                    </div>
                    <div className="whiteBox">
                        <div className="box-heading">
                            <h3>Welcome Back</h3>
                            <p>Enter your Credentials to access your account</p>
                        </div>
                        <form className="remove-label" onSubmit={onLoginSubmit}>
                            <div className="email-action-field">
                                <FormInput
                                    inputType="text"
                                    placeholder="Type your email."
                                    onChange={onChangeEmail}
                                    title="Email "
                                    required="required"
                                />
                            </div>
                            <div className="password-action-field">
                                <FormInput
                                    inputType={showPassword ? 'text' : 'password'}
                                    placeholder="Type your password."
                                    onChange={onChangePassword}
                                    title="Password "
                                    required="required"
                                />
                                <div className={`passwordeyeicon ${password && (showPassword ? 'hidePassword' : 'showPassword')}`} onClick={() => password && passwordShow()}>
                                    <i className={password && (showPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}></i>
                                </div>
                            </div>
                            <div className="actionField center">
                                <Button
                                    loaderClass={showBtnLoader ? showBtnLoader : ""}
                                    title="Continue"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            <div className="bottomFooter">
                <Footer />
            </div>
        </div >
    )
}
