import { Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { DATEFORMAT, GENDER, UNITS } from "../../../../common";
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import FormInput from "../../../../Components/FormInput";
import deleteicon from "../../../../assets/images/delete.svg";
import addicon from "../../../../assets/images/add-icon.svg";
import TableTitle from "../../../../Components/TableTitle";

export default function LabTestAddReferenceModal({ referenceValues, index, setRefValues, setSelectClass }) {

    const [data, setData] = useState([]);

    const [unitsArr, setUnitsArr] = useState(UNITS);
    const handleUnitsCreate = useCallback((inputValue) => {
        const newValue = { value: inputValue, label: inputValue };
        setUnitsArr([...unitsArr, newValue]);
    })
    const initialReferenceData = {
        gender: '',
        minimumAge: '',
        maximumAge: '',
        age: '',
        lowerValue: '',
        upperValue: '',
        units: '',
        refNotes: ''
    };
    const [referenceData, setReferenceData] = useState([initialReferenceData]);

    useEffect(() => {
        if (referenceValues.length > 0) {
            let arr = [...unitsArr]
            referenceValues.map((v) => {
                let tmp = arr.some((a) => a.value === v.units);
                if (!tmp) {
                    arr.push({ value: v.units, label: v.units });
                }
            })
            setUnitsArr([...arr])
            setReferenceData([...referenceValues])
        } else {
            setReferenceData([initialReferenceData]);
        }
    }, [referenceValues])

    const [error, setError] = useState({
        lowerError: '',
        upperError: '',
        maxError: '',
        minError: ''
    });

    const handleRefereneceChange = (key, e, i) => {
        const keyArray = ['gender', 'age', 'units'];
        if ((key !== '') && (keyArray.includes(key))) {
            let newArr = [...referenceData];
            newArr[i] = { ...newArr[i], [key]: e.value };
            setReferenceData(newArr);
            setRefValues(newArr, index)
        } else {
            const { name, value } = e.target;
            let newArrs = [...referenceData];
            newArrs[i][name] = value
            newArrs[i] = { ...newArrs[i], [name]: value };
            let upperValue = name === 'upperValue' ? value : newArrs[i].upperValue
            let lowerValue = name === 'lowerValue' ? value : newArrs[i].lowerValue
            let minimumAge = name === 'minimumAge' ? value : newArrs[i].minimumAge
            let maximumAge = name === 'maximumAge' ? value : newArrs[i].maximumAge
            if (name === 'upperValue' && parseInt(lowerValue) > parseInt(upperValue === "" ? 0 : upperValue)) {
                setError({ ...error, upperError: 'Enter Greater Then Lower Value', })
            } else if (name === 'lowerValue' && parseInt(lowerValue === "" ? 0 : lowerValue) > parseInt(upperValue)) {
                setError({ ...error, lowerError: 'Enter Less Then Lower Value' })
            } else if (name === 'upperValue' && parseInt(lowerValue) < parseInt(upperValue === "" ? 0 : upperValue)) {
                setError({ ...error, lowerError: '', upperError: '' })
            } else if (name === 'lowerValue' && parseInt(lowerValue === "" ? 0 : lowerValue) < parseInt(upperValue)) {
                setError({ ...error, lowerError: '', upperError: '' })
            } else if (name === 'minimumAge' && parseInt(minimumAge === "" ? 0 : minimumAge) > parseInt(maximumAge)) {
                setError({ ...error, minError: 'Enter Less Then Lower Value' })
            } else if (name === 'maximumAge' && parseInt(minimumAge) > parseInt(maximumAge === "" ? 0 : maximumAge)) {
                setError({ ...error, maxError: 'Enter Greater Then Lower Value' })
            } else if (name === 'maximumAge' && parseInt(minimumAge) < parseInt(maximumAge === "" ? 0 : maximumAge)) {
                setError({ ...error, minError: '', maxError: '' })
            } else if (name === 'minimumAge' && parseInt(minimumAge === "" ? 0 : minimumAge) < parseInt(maximumAge)) {
                setError({ ...error, minError: '', maxError: '' })
            } else {
                if (error.upperError === '' && error.lowerError === '' && error.maxError === '' && error.minError === '') {
                    setError({ upperError: '', lowerError: '', minError: '', maxError: '' })
                }
            }
            setReferenceData([...newArrs]);
            setRefValues([...newArrs], index)
        }
    };

    const handleGroupAdd = () => {
        let changeValues = [...referenceData];
        let tmp = changeValues.some(v => JSON.stringify(v) === JSON.stringify(initialReferenceData));
        if (!tmp) {
            changeValues.push(initialReferenceData);
        }
        setReferenceData([...changeValues]);
    }

    const handleGroupRemove = (i) => {
        let changeLang = [...referenceData];
        if (changeLang.length > 1) {
            changeLang.splice(i, 1);
            setReferenceData([...changeLang]);
        } else {
            changeLang = { ...initialReferenceData };
            setReferenceData([changeLang]);
        }
    }

    const columns = [
        {
            title: "Gender", dataIndex: "gender", render: (_, { gender, index }) => {
                return (
                    <Select
                        classNamePrefix="react-select"
                        options={GENDER}
                        name="gender"
                        value={GENDER.filter((option) => {
                            return option.value === gender
                        })}
                        onChange={(e) => { handleRefereneceChange('gender', e, index); }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-inner-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Minimum Age", dataIndex: "minimumAge", render: (_, { minimumAge, index }) => {
                return (
                    <FormInput
                        inputType="number"
                        placeholder="Minimum Age."
                        name="minimumAge"
                        onChange={(e) => { handleRefereneceChange('', e, index); }}
                        value={minimumAge}
                        errorMessage={error.minError}
                    />
                )
            }
        },
        {
            title: "Maximum Age", dataIndex: "maximumAge", render: (_, { maximumAge, index }) => {
                return (
                    <FormInput
                        inputType="number"
                        placeholder="Maximum Age."
                        name="maximumAge"
                        onChange={(e) => { handleRefereneceChange('', e, index); }}
                        value={maximumAge}
                        errorMessage={error.maxError}
                    />
                )
            }
        },
        {
            title: "Age", dataIndex: "age", render: (_, { age, index }) => {
                return (
                    <Select
                        classNamePrefix="react-select"
                        options={DATEFORMAT}
                        name="age"
                        value={DATEFORMAT.filter((option) => {
                            return option.value === age
                        })}
                        onChange={(e) => { handleRefereneceChange('age', e, index); }}
                        placeholder="Years"
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-inner-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Lower Value", dataIndex: "lowerValue", render: (_, { lowerValue, index }) => {
                return (
                    <FormInput
                        inputType="number"
                        placeholder="Lower Value"
                        name="lowerValue"
                        onChange={(e) => { handleRefereneceChange('', e, index); }}
                        value={lowerValue}
                        errorMessage={error.lowerError}
                    />
                )
            }
        },
        {
            title: "Upper Value", dataIndex: "upperValue", render: (_, { upperValue, index }) => {
                return (
                    <FormInput
                        testPage={false}
                        inputType="number"
                        placeholder="Upper Value"
                        name="upperValue"
                        onChange={(e) => { handleRefereneceChange('', e, index); }}
                        value={upperValue}
                        errorMessage={error.upperError}
                    />
                )
            }
        },
        {
            title: "Units", dataIndex: "units", render: (_, { units, index }) => {
                return (
                    <Creatable
                        classNamePrefix="react-select"
                        options={unitsArr}
                        name="units"
                        value={unitsArr.filter((option) => {
                            return option.value === units
                        })}
                        onChange={(e) => { handleRefereneceChange('units', e, index); }}
                        placeholder="Select"
                        onCreateOption={handleUnitsCreate}
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-inner-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Notes", dataIndex: "refNotes", render: (_, { refNotes, index }) => {
                return (
                    <FormInput
                        inputType="text"
                        placeholder="Note"
                        name="refNotes"
                        onChange={(e) => { handleRefereneceChange('', e, index); }}
                        value={refNotes}
                    />
                )
            }
        },
        {
            title: 'Action', dataIndex: '', width: 100, fixed: "right", render: (_, { index }) => {
                return (
                    <div className="action">
                        {
                            ((data.length - 1) === index) &&
                            <span className="add-group" onClick={() => handleGroupAdd()}>
                                <img alt={addicon} src={addicon} />
                            </span>
                        }
                        <span className="remove-lang" onClick={() => { handleGroupRemove(index); setError({ upperError: '', lowerError: '', minError: '', maxError: '' }) }}>
                            <img alt={deleteicon} src={deleteicon} />
                        </span>
                    </div >
                )
            },
        },
    ]

    useEffect(() => {
        let arr = [];
        if (referenceData.length > 0) {
            referenceData.map((v, i) => {
                let obj = {
                    index: i,
                    key: i,
                    gender: v.gender,
                    minimumAge: v.minimumAge,
                    maximumAge: v.maximumAge,
                    age: v.age,
                    lowerValue: v.lowerValue,
                    upperValue: v.upperValue,
                    units: v.units,
                    refNotes: v.refNotes,
                    data: v,
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [referenceData])

    return (
        <>
            <TableTitle title="Reference Values" />
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} />
        </>
    )
}