import { Switch, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { dateSortingForTable, formatDate, getUTCTime, nameSortingForTable } from "../../../../common";
import Edit from '../../../../assets/images/Edit.svg';
import Delete from '../../../../assets/images/delete.svg';
import { updateEmailTemplates } from "../../../../actions/emailTemplate";

export default function EmailTemplateList({ temps, modalClickAction, getValueFromLabelTriggerType, triggerType, dispatch }) {

    const [data, setData] = useState([]);

    const onChange = (checked, i, id) => {
        let change = [...data];
        change[i]['status'] = checked
        setData([...change]);
        dispatch(updateEmailTemplates(id, { isActive: checked }, temps));
    }

    // add columns which you wonna add in table
    const columns = [
        { title: "No", dataIndex: "no", width: 50 },
        { title: "Template Name", dataIndex: "name", sorter: (a, b) => nameSortingForTable(a?.name, b?.name) },
        { title: "Trigger", dataIndex: "trigger", sorter: (a, b) => nameSortingForTable(a?.trigger, b?.trigger) },
        { title: "Category", dataIndex: "category", sorter: (a, b) => nameSortingForTable(a?.category, b?.category) },
        { title: "Last Modified", dataIndex: "updatedAt", sorter: (a, b) => dateSortingForTable(a?.updatedAt, b?.updatedAt), render : (_,{updatedAt} ) => formatDate(updatedAt, true) + ' ' + getUTCTime(new Date(updatedAt))},
        {
            title: "Status", dataIndex: "status", render: (_, { status, index, key }) => {
                return (
                    <Switch
                        checked={status ? true : false}
                        onChange={(checked) => onChange(checked, index, key)}
                    />
                )
            }
        },
        {
            title: 'Action',
            width: 200,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (temps?.length > 0) {
            temps.map((v, i) => {
                let obj = {
                    index: i,
                    key: v._id,
                    no: i + 1,
                    name: v.name ? v.name : '-',
                    trigger: v.trigger ? getValueFromLabelTriggerType(v.trigger, triggerType) : '-',
                    category: v.category ? v.category : '-',
                    status: v.isActive,
                    updatedAt: v?.updatedAt ||'-',
                    data: v
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [temps, triggerType])

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} />
        </>
    )
}