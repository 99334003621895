import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { HABITS_LIST } from "./types";

//mergeHabbits Action
export const mergeHabbits = (data, habits) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.habit.merge}`,
            data: data
        }).then((response) => {
            resolve(response.data)
            let obj = response.data;
            let mainArray = [...habits.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: HABITS_LIST,
                payload: {
                    count: habits.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Search, Merge list And Fetch list Habbits 
export const searchHabbitsList = (filter, pagination, extraData = {}, isType) => async (dispatch) => {
    let body = { filter, pagination, extraData }
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.habit.search}`,
            data: body,
        }).then((response) => {
            resolve(response.data)
            if (!isType) {
                dispatch({
                    type: HABITS_LIST,
                    payload: {
                        count: response.data.length,
                        data: response.data,
                    }
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

//Add Multiple Habbits Action
export const InsertManyHabbits = (body, habits) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.habit.add}`,
            data: { ...body },
        }).then((response) => {
            resolve(response);
            let mainArray = [...habits.data];
            response?.data?.map((a) => {
                let tmp = mainArray.some(v => v._id === a._id);
                if (!tmp) {
                    mainArray.push(a);
                }
            })
            dispatch({
                type: HABITS_LIST,
                payload: {
                    count: habits.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error)
        });
    });
}

//Update Habbits Action
export const updateHabbits = (docs, habits) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.habit.update}`,
            data: docs
        }).then((response) => {
            resolve(response.data);
            let obj = response.data;
            let mainArray = [...habits.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: HABITS_LIST,
                payload: {
                    count: habits.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Delete Habbits Action
export const deleteHabbits = (docs, habits, hId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.habit.delete}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...habits.data]
            if (Array.isArray(hId)) {
                hId.map((item) => {
                    let index = mainArray.findIndex(v => v._id === item);
                    mainArray.splice(index, 1);
                })
            } else {
                let index = mainArray.findIndex(v => v._id === hId);
                mainArray.splice(index, 1);
            }
            dispatch({
                type: HABITS_LIST,
                payload: {
                    count: habits.count - (Array.isArray(hId) ? hId.length : 1),
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//Approve And Reject Habbits Action
export const approveHabbits = (docs, habits) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.habit.approve}`,
            data: docs
        }).then((response) => {
            resolve(response.data)
            let obj = response.data[0];
            let mainArray = [...habits.data];
            let index = mainArray.findIndex(v => v._id === obj._id);
            if (index >= 0) {
                mainArray.splice(index, 1, obj)
            }
            dispatch({
                type: HABITS_LIST,
                payload: {
                    count: habits.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}