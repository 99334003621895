import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { ERROR_LOG_LIST } from "./types";


export const getErrorLogs = (search) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
        method: 'PUT',
        url: `${API_URL.notification.errorLog.list}`,
        data: {"page": search.page, 
                "limit": search.limit
              }
    }).then((response) => {
        resolve(response);
        dispatch({
            type: ERROR_LOG_LIST,
            payload: {
                count: response.data.totalCount,
                data: response.data.records,
            }
        });
        return response;
    }).catch((error) => {
        reject(error);
    });
});

}

export const updateErrorLogStatus =(data) => async (dispatch) =>{
    return new Promise((resolve, reject) =>{
        apiClient({
            method:'PUT',
            url:`${API_URL.notification.errorLog.update}/${data.id}`,
            data : data?.data?.type === "archived" ? {archived : data?.data?.value} : {visited: true}
        }).then((response) => {
            resolve(response);

            return response;
        }).catch((error) => {
            reject(error);
        });
    })
}