import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, emptyField } from "../../common";
import { closeModal, openModal } from "../../actions/modal";
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import Select from "react-select";
import Modal from "../../Components/Modal";
import { ExtraSubMenu, ListApisForDataMx } from "../Common";
import { Skeleton } from "antd";
import TableTitle from "../../Components/TableTitle";
import { approveMedicine, deleteMedicines, fetchManufacturerList, fetchMedicineList, fetchSaltCompositionList, fetchtherapeuticClassList, fetchtherapeuticSubClassList, InsertManyMedicine, medicineTypeList, mergeMedicines, updateMedicine } from "../../actions/medicine";
import MedicineList from "./MedicineList";
import Formcheckbox from "../../Components/Formcheckbox";

export default function Medicines(props) {
    const {
        medicines = [],
        modal,
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);
    const [isCommon, setIsCommon] = useState(false);

    const [medicineId, setMedicineId] = useState('');
    const [typeError, setTypeError] = useState('');
    const [nameError, setNameError] = useState('');
    const [mergeMedId, setMergeMedId] = useState('');
    const [mergeError, setMergeError] = useState('');

    const [medicineFormData, setMedicineFormData] = useState({
        medicineName: "", type: "", therapeuticClass: "", therapeuticSubClass: "", saltComposition: "", manufacturer: "", description: "", currency: "", regular_price: "", sale_price: ""
    });

    const [name, setName] = useState('');

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton1, setModalButton1] = useState('');
    const [modalButton2, setModalButton2] = useState('');
    const [search, setSearch] = useState('');

    const [showBtnLoader, setShowBtnLoader] = useState('');

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [activeSubMenu, setActiveSubMenu] = useState('inapporval');

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    const resetForm = () => {
        setName('');
        setMedicineId('');
        setMergeMedId('');
        setMergeError('');
        setNameError('');
        setTypeError('');
        setMedicineFormData({
            medicineName: "", type: "", therapeuticClass: "", therapeuticSubClass: "", saltComposition: "", manufacturer: "", description: "", currency: "", regular_price: "", sale_price: ""
        });
        setIsCommon(false);
    };

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton1('');
        setModalButton2('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        resetModal();
        switch (action) {
            case "addMedicine":
                setModalTitle('Add Medicine');
                setModalButton1('Reset');
                setModalButton2('Add');
                break;
            case 'update':
                setModalTitle('Edit Medicine');
                setModalButton1('Reset');
                setModalButton2('Edit');
                setMedicineId(data._id);
                let updateField = { ...medicineFormData };
                updateField["medicineName"] = data.name;
                updateField["therapeuticClass"] = data?.therapeuticClass
                updateField["type"] = data?.type
                updateField["therapeuticSubClass"] = data?.therapeuticSubClass
                updateField["saltComposition"] = data?.saltComposition
                updateField["manufacturer"] = data?.manufacturer
                updateField["description"] = data?.description;
                updateField["currency"] = data?.currency;
                updateField["regular_price"] = data?.regular_price;
                updateField["sale_price"] = data?.sale_price;
                setMedicineFormData({ ...updateField });
                setIsCommon(data.isCommon);
                break;
            case 'deleteMultiple':
                setName("Selected Medicines");
                break;
            case 'delete':
            case 'reject':
            case 'approve':
                setModalTitle('Confirm Medicines');
                setModalButton1("Reset");
                setModalButton2('Comfirm');
                setMedicineId(data._id);
                let apField = { ...medicineFormData };
                apField["medicineName"] = data.name;
                apField["therapeuticClass"] = data?.therapeuticClass
                apField["type"] = data?.type
                apField["therapeuticSubClass"] = data?.therapeuticSubClass
                apField["saltComposition"] = data?.saltComposition
                apField["manufacturer"] = data?.manufacturer
                apField["description"] = data?.description;
                apField["currency"] = data?.currency;
                apField["regular_price"] = data?.regular_price;
                apField["sale_price"] = data?.sale_price;
                setMedicineFormData({ ...apField })
                setName(data.name)
                setIsCommon(data.isCommon);
                break;
            case 'merge':
                setModalTitle('Merge Medicine');
                setModalButton1('Cancel');
                setModalButton2('Merge');
                setMedicineId(data._id);
                let oldField = { ...medicineFormData };
                oldField["medicineName"] = data.name;
                setMedicineFormData({ ...oldField });
                setName(data.name);
                setIsCommon(data.isCommon);
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        if (action === "merge") {
            if (emptyField(mergeMedId)) {
                setMergeError("Merge Medicine is Required");
            }
        }
        const updatePayload = {
            _id: medicineId,
            name: medicineFormData.medicineName,
            therapeuticClass: medicineFormData.therapeuticClass,
            type: medicineFormData.type,
            therapeuticSubClass: medicineFormData.therapeuticSubClass,
            saltComposition: medicineFormData.saltComposition,
            manufacturer: medicineFormData.manufacturer,
            description: medicineFormData.description,
            currency: medicineFormData.currency,
            regular_price: medicineFormData.regular_price,
            sale_price: medicineFormData.sale_price,
            isCommon: isCommon,
        }
        switch (action) {
            case "addMedicine":
                let addPayload = {}
                let obj = {
                    "name": medicineFormData.medicineName,
                    "type": medicineFormData.type,
                    "isCommon": isCommon,
                }
                if (!emptyField(medicineFormData.therapeuticClass)) obj.therapeuticClass = medicineFormData.therapeuticClass
                if (!emptyField(medicineFormData.therapeuticSubClass)) obj.therapeuticSubClass = medicineFormData.therapeuticSubClass
                if (!emptyField(medicineFormData.saltComposition)) obj.saltComposition = medicineFormData.saltComposition
                if (!emptyField(medicineFormData.manufacturer)) obj.manufacturer = medicineFormData.manufacturer
                if (!emptyField(medicineFormData.description)) obj.description = medicineFormData.description
                if (!emptyField(medicineFormData.currency)) obj.currency = medicineFormData.currency
                if (!emptyField(medicineFormData.regular_price)) obj.regular_price = medicineFormData.regular_price
                if (!emptyField(medicineFormData.sale_price)) obj.sale_price = medicineFormData.sale_price
                addPayload.docs = [obj];
                if (!emptyField(obj.name) && !emptyField(obj.type)) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(InsertManyMedicine(addPayload, medicines)).then(() => {
                        ListApisForDataMx(activeSubMenu, 1, pageSize, search, fetchMedicineList, setSelectedRowKeys, dispatch, setLoading);
                        modalClickAction('close')
                        setShowBtnLoader('');
                    }).catch(() => setShowBtnLoader(''))
                } else {
                    if (emptyField(obj.name)) {
                        setNameError("Medicine name is required")
                    } else {
                        setTypeError("Medicine type is required")
                    }
                }
                break;
            case 'update':
                if (!emptyField(updatePayload.name)) {
                    if (!emptyField(updatePayload.type)) {
                        setShowBtnLoader('show-button-loader');
                        dispatch(updateMedicine(updatePayload, medicines)).then(() => {
                            modalClickAction('close')
                            setShowBtnLoader('');
                        }).catch(() => setShowBtnLoader(''))
                    } else {
                        setTypeError("Medicine type is required")
                    }
                } else {
                    setNameError("Medicine name is required")
                }
                break;
            case 'approve':
                const ApprovePayload = {
                    "ids": [medicineId],
                    "approval": "APPROVE"
                }
                if (!emptyField(updatePayload.name)) {
                    if (!emptyField(updatePayload.type)) {
                        setShowBtnLoader('show-button-loader');
                        dispatch(updateMedicine(updatePayload, medicines)).then(() => {
                            dispatch(approveMedicine(ApprovePayload, medicines)).then(() => {
                                modalClickAction('close')
                                ListApisForDataMx(activeSubMenu, 1, pageSize, search, fetchMedicineList, setSelectedRowKeys, dispatch, setLoading);
                                setShowBtnLoader('');
                            }).catch(() => setShowBtnLoader(''))
                        });
                    } else {
                        setTypeError("Medicine type is required")
                    }
                } else {
                    setNameError("Medicine name is required")
                }
                break;
            case 'reject':
                const rejectPayload = {
                    "ids": [medicineId],
                    "approval": "REJECT"
                }
                setShowBtnLoader('show-button-loader');
                dispatch(approveMedicine(rejectPayload, medicines)).then(() => {
                    modalClickAction('close')
                    setShowBtnLoader('');
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            case 'merge':
                const mergePayload = {
                    "id": medicineId,
                    "withId": mergeMedId
                }
                if (mergePayload.id && mergePayload.withId) {
                    setShowBtnLoader('show-button-loader');
                    dispatch(mergeMedicines(mergePayload, medicines)).then(() => {
                        modalClickAction('close')
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                }
                break;
            case 'delete':
                const deletePayload = {
                    "ids": [medicineId]
                }
                setShowBtnLoader('show-button-loader');
                dispatch(deleteMedicines(deletePayload, medicines, medicineId)).then(() => {
                    modalClickAction('close');
                    setShowBtnLoader('');
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            case 'deleteMultiple':
                const deleteMultiplePayload = {
                    "ids": selectedRowKeys
                }
                setShowBtnLoader('show-button-loader');
                dispatch(deleteMedicines(deleteMultiplePayload, medicines, selectedRowKeys)).then(() => {
                    modalClickAction('close');
                    setShowBtnLoader('');
                    setSelectedRowKeys([]);
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            default:
                throw new Error('Modal action not match');
        }
    };

    const [therapeuticClass, setTherapeuticClass] = useState([]);
    const [type, setType] = useState([])
    const [therapeuticSubClass, setTherapeuticSubClass] = useState([]);
    const [saltComposition, setSaltComposition] = useState([]);
    const [manufacturer, setManufacturer] = useState([]);

    const resultOfMedicineApiCalls = (res, setState) => {
        if (res.data) {
            let arr = []
            res.data.forEach((tc) => {
                let tmp = arr.some(v => v.value === tc._id);
                if (!tmp) arr.push({ value: tc._id, label: tc.name });
            });
            setState([...arr])
        }
    }

    useEffect(() => {
        dispatch(fetchtherapeuticClassList()).then((res) => {
            resultOfMedicineApiCalls(res, setTherapeuticClass);
        })
        dispatch(fetchtherapeuticSubClassList()).then((res) => {
            resultOfMedicineApiCalls(res, setTherapeuticSubClass);
        })
        dispatch(fetchSaltCompositionList()).then((res) => {
            resultOfMedicineApiCalls(res, setSaltComposition);
        })
        dispatch(fetchManufacturerList()).then((res) => {
            resultOfMedicineApiCalls(res, setManufacturer);
        })
        dispatch(medicineTypeList()).then((res) => {
            if (res.data) {
                let arr = [];
                res.data.map((v) => {
                    if (v !== "") {
                        let tmp = arr.some(v => v.value === v);
                        if (!tmp) arr.push({ value: v, label: v })
                    }
                })
                setType([...arr])
            }
        })
    }, [])

    useEffect(() => {
        debounce(ListApisForDataMx(activeSubMenu, page, pageSize, search, fetchMedicineList, setSelectedRowKeys, dispatch, setLoading),300)
    }, [search, activeSubMenu, page, pageSize])

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        let list = { ...medicineFormData };
        list[name] = value;
        setMedicineFormData({ ...list });
    }

    const handleSelectChange = (field, e) => {
        let changedField = { ...medicineFormData };
        changedField[field] = e.value;
        setMedicineFormData({ ...changedField });
    };

    const currency = [
        { value: 'INR', label: 'INR' },
        { value: 'USD', label: 'USD' }
    ];

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Medicine List " />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenu}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setPage(1); setSearch('') }}
                                        placeholder="Select Status"
                                        value={ExtraSubMenu.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                {
                                    activeSubMenu === "approved" &&
                                    <div className="add-btn">
                                        <Button
                                            title="Add"
                                            onClick={() => modalClickAction('open', 'addMedicine')}
                                        />
                                    </div>
                                }
                                {
                                    selectedRowKeys?.length > 0 &&
                                    <div className="delete-btn">
                                        <Button
                                            btn="btn-outline-primary"
                                            title="Delete"
                                            onClick={() => modalClickAction('open', 'deleteMultiple')}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table medicinesmx">
                            <MedicineList
                                medicines={medicines}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                therapeuticClass={therapeuticClass}
                                therapeuticSubClass={therapeuticSubClass}
                                saltComposition={saltComposition}
                                manufacturer={manufacturer}
                                modalClickAction={modalClickAction}
                                isCommon={true}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            {/* <DiseaseModals
                api={fetchMedicineList}
                dispatch={dispatch}
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                mergeDiseaseId={mergeMedId}
                setMergeDiseaseId={setMergeMedId}
                mergeError={mergeError}
                setMergeError={setMergeError}
                modalButton1={modalButton1}
                modalButton2={modalButton2}
                modalTitle={modalTitle}
                isCommon={isCommon}
                setIsCommon={setIsCommon}
                resetForm={resetForm}
                isComAvailable={true}
                isDelete={true}
                isMerge={true}
                isAdd={true}
            /> */}
            {/* Add, Update And Approve Modal */}
            <div className="AddNew">
                {['update', 'approve', "addMedicine"].includes(modalAction) &&
                    <Modal
                        ModalTitle={modalTitle}
                        onClose={() => modalClickAction('close')}
                        Modalclass={(modal && ['update', 'approve', "addMedicine"].includes(modalAction)) ? 'show-modal' : ''}
                    >
                        {
                            <Formcheckbox
                                inputType="checkbox"
                                id="isCommon"
                                name="isCommon"
                                title="Is Name Common?"
                                onChange={() => setIsCommon(!isCommon)}
                                isChecked={isCommon ? "checked" : ""}
                            />
                        }
                        <FormInput
                            inputType="text"
                            title='Medicine Name'
                            placeholder='Medicine Name'
                            name="medicineName"
                            onChange={(e) => { handleFormChange(e); setNameError('') }}
                            value={medicineFormData?.medicineName}
                            required="required"
                            errorMessage={nameError}
                        />
                        <div className="formField">
                            <label><div className="feildRequired">Medicine Type<span className="errorBox">*</span></div></label>
                            <Select
                                classNamePrefix="react-select"
                                options={type}
                                name="type"
                                placeholder="Select"
                                value={type.filter((option) => {
                                    return option.value === medicineFormData.type;
                                })}
                                onChange={(e) => { handleSelectChange("type", e); setTypeError(''); }}
                            />
                            {typeError && <div className="actionField errorBox"> {typeError}</div>}
                        </div>
                        <div className="formField">
                            <label><div className="feildRequired">Therapeutic Class</div></label>
                            <Select
                                classNamePrefix="react-select"
                                options={therapeuticClass}
                                name="therapeuticClass"
                                placeholder="Select"
                                value={therapeuticClass.filter((option) => {
                                    return option.value === medicineFormData.therapeuticClass;
                                })}
                                onChange={(e) => handleSelectChange("therapeuticClass", e)}
                            />
                        </div>
                        <div className="formField">
                            <label><div className="feildRequired">Therapeutic SubClass</div></label>
                            <Select
                                classNamePrefix="react-select"
                                options={therapeuticSubClass}
                                name="therapeuticSubClass"
                                placeholder="Select"
                                value={therapeuticSubClass.filter((option) => {
                                    return option.value === medicineFormData.therapeuticSubClass;
                                })}
                                onChange={(e) => handleSelectChange("therapeuticSubClass", e)}
                            />
                            {/* {FormErrors && <div className="actionField errorBox"> {FormErrors.therapeuticSubClass}</div>} */}
                        </div>
                        <div className="formField">
                            <label><div className="feildRequired">Salt Composition</div></label>
                            <Select
                                classNamePrefix="react-select"
                                options={saltComposition}
                                name="saltComposition"
                                placeholder="Select"
                                value={saltComposition.filter((option) => {
                                    return option.value === medicineFormData.saltComposition;
                                })}
                                onChange={(e) => handleSelectChange("saltComposition", e)}
                            />
                            {/* {FormErrors && <div className="actionField errorBox"> {FormErrors.saltComposition}</div>} */}
                        </div>
                        <div className="formField">
                            <label><div className="feildRequired">Manufacturer</div></label>
                            <Select
                                classNamePrefix="react-select"
                                options={manufacturer}
                                name="manufacturer"
                                placeholder="Select"
                                value={manufacturer.filter((option) => {
                                    return option.value === medicineFormData.manufacturer;
                                })}
                                onChange={(e) => handleSelectChange("manufacturer", e)}
                            />
                            {/* {FormErrors && <div className="actionField errorBox"> {FormErrors.manufacturer}</div>} */}
                        </div>
                        <FormInput
                            inputType="text"
                            title='Description'
                            placeholder='Description'
                            name="description"
                            onChange={(e) => handleFormChange(e)}
                            value={medicineFormData.description}
                        />
                        <div className="formField">
                            <label><div className="feildRequired">Currency</div></label>
                            <Select
                                classNamePrefix="react-select"
                                options={currency}
                                name="currency"
                                placeholder="Select"
                                value={currency.filter((option) => {
                                    return option.value === medicineFormData.currency;
                                })}
                                onChange={(e) => handleSelectChange("currency", e)}
                            />
                            {/* {FormErrors && <div className="actionField errorBox"> {FormErrors.currency}</div>} */}
                        </div>
                        <FormInput
                            inputType="text"
                            title='MRP'
                            placeholder='MRP'
                            name="regular_price"
                            onChange={(e) => handleFormChange(e)}
                            value={medicineFormData.regular_price}
                        // errorMessage={FormErrors && FormErrors.regular_price}
                        />
                        <FormInput
                            inputType="text"
                            title='Sales Price'
                            placeholder='Sales Price'
                            name="sale_price"
                            onChange={(e) => handleFormChange(e)}
                            value={medicineFormData.sale_price}
                        // errorMessage={FormErrors && FormErrors.sale_price}
                        />
                        <div className="activeButton right">
                            <Button
                                title={modalButton1}
                                buttonType="reset"
                                onClick={() => { resetForm(); }}
                            />
                            <Button
                                loaderClass={['update', 'approve', "addMedicine"].includes(modalAction) ? showBtnLoader : ""}
                                title={modalButton2}
                                onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                            />
                        </div>
                    </Modal>
                }
            </div>
        </>
    );
}
