import './style.scss';
import React, { useState, useEffect, useCallback } from "react";
import DoctorAddModal from './DoctorAddModal';
import DoctorsList from './DoctorsList';
import DoctorOptionModal from './DoctorOptionModal';
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, debounce, emptyField, removeMainLoaderClass, validEmail } from '../../common';
import { closeModal, openModal } from '../../actions/modal';
import FormInput from '../../Components/FormInput';
import Button from '../../Components/Button';
import TableTitle from '../../Components/TableTitle';
import AdminCounter from '../../Components/AdminCounter';
import { fetchHospitalList, approvalHospital } from '../../actions/hospitals';
import { addDoctor, approvalDoctor, deleteDoctor, disableEnableDoctor, doctorAnalytics, fetchDoctorList, goToDoctorSettings, updateDoctor, sendVerificationEmail } from '../../actions/doctors';
import totaldoc from '../../assets/images/Total-doc.svg';
import activedoc from '../../assets/images/active.svg';
import notIndoc from '../../assets/images/notIn.svg';
import leadsdoc from '../../assets/images/leads.svg';
import { searchSpecialty } from '../../actions/specialty';
import { Skeleton } from 'antd';
import { listDegree } from '../../actions/degree';
import { toast } from "react-toastify";

export default function DoctorMx(props) {

    //for skeleton
    const [loading, setLoading] = useState(false);

    const { doctors = [], hospitals = [], modal } = useSelector((state) => state);

    const dispatch = useDispatch();
    /***
     * state used for set data for api
     */
    const [doctorAnalyticsData, setDoctorAnalytics] = useState({ totalDoctors: 0, leads: 0, notInYet: 0, todayActive: 0 });

    const [doctorFormData, setDoctorFormData] = useState({
        "latitude": "", "longitude": "", "firstName": '', "lastName": '', "mciRegNumber": '', "contact": '', "countryCode": "+91", "emailId": '', "hospitalIds": '', "hospitalName": '', "plotNo": "", "address": '', "address2": '', "city": '', "state": '', "country": '', "pincode": '', "degreeIds": [], "degree": [], "specialty": [],
    });

    let currentFormErrors = [];
    const [doctorId, setDoctorId] = useState('');
    const [FormErrors, setFormErrors] = useState(currentFormErrors);
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton1, setModalButton1] = useState('');
    const [modalButton2, setModalButton2] = useState('');
    const [modalAction, setModalAction] = useState('');

    const [moduleAccess, setModuleAccess] = useState({ create: [], view: [], edit: [], delete: [] });
    /**
     * for pagination
     */
    const [search, setSearch] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const [specialtyList, setSpecialtyList] = useState([])
    const [degreeList, setDegreeList] = useState([])
    const [otherList, setOtherList] = useState([])

    const [roles, setRoles] = useState([])

    const resetForm = () => {
        setDoctorId('');
        setAddHospitalName('');
        currentFormErrors.firstName = ''
        currentFormErrors.lastName = ''
        currentFormErrors.mciRegNumber = ''
        currentFormErrors.contact = ''
        currentFormErrors.emailId = ''
        setFormErrors(currentFormErrors);
        setDoctorFormData({
            "latitude": "",
            "longitude": "", "firstName": '', "lastName": '', "mciRegNumber": '', "countryCode": "+91", "contact": '', "emailId": '', "hospitalIds": '', "hospitalName": '', "plotNo": "", "address": '', "address2": '', "city": '', "state": '', "country": '', "pincode": '', "degreeIds": [], "degree": [], "specialty": [],
        });
        setModuleAccess({ create: [], view: [], edit: [], delete: [] })
        setOtherList([]);
        setRoles([])
    };

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton1('');
        setModalButton2('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                setModalTitle('Add Doctor');
                setModalButton1('Reset');
                setModalButton2('Add Doctor');

                break;
            case 'update':
                setModalTitle('Edit Doctor');
                setModalButton1((action === 'update') ? 'Reset' : 'Cancel');
                setModalButton2((action === 'update') ? 'Edit Doctor' : 'Approve');
                setDoctorId(data._id);
                let changedField = { ...doctorFormData };
                changedField["firstName"] = capitalizeFirstLetter(data.firstName);
                changedField["lastName"] = capitalizeFirstLetter(data.lastName);
                changedField["mciRegNumber"] = data.mciRegNumber;
                changedField["countryCode"] = data.countryCode;
                changedField["contact"] = data.contact;
                changedField["emailId"] = data.emailId;
                changedField["hospitalIds"] = data.hospitals.length > 0 && data.hospitals[0].hospitalId && data.hospitals[0].hospitalId._id && data.hospitals[0].hospitalId._id;
                changedField["hospitalName"] = data.hospitals.length > 0 && data.hospitals[0].hospitalId && data.hospitals[0].hospitalId.name && data.hospitals[0].hospitalId.name;
                setAddHospitalName(data.hospitals.length > 0 && data.hospitals[0].hospitalId && data.hospitals[0].hospitalId.name && data.hospitals[0].hospitalId.name);
                changedField["plotNo"] = data?.address?.permanent?.plotNo;
                changedField["city"] = data?.address?.permanent?.city;
                changedField["country"] = data?.address?.permanent?.country;
                changedField["state"] = data?.address?.permanent?.state;
                changedField["pincode"] = data?.address?.permanent?.pincode;
                changedField["address"] = data?.address?.permanent?.line1;
                changedField["address2"] = data?.address?.permanent?.line2;
                changedField["latitude"] = data?.address?.permanent?.latitude;
                changedField["longitude"] = data?.address?.permanent?.longitude;
                let otherData = []
                if (data?.degreeIds?.length > 0) {
                    data?.degreeIds.map((v) => {
                        otherData.push(v._id)
                    })
                }
                if (data?.degree?.length > 0) {
                    if (!data?.degreeIds?.includes("Other") && !data?.degree?.includes("false")) otherData.push("Other")
                }
                changedField['degreeIds'] = otherData
                changedField['degree'] = data?.degree?.length > 0 ? data.degree?.filter((v) => v !== "false") : []
                if (data.degree?.length > 0) {
                    let arr = []
                    data.degree.map((v) => {
                        arr.push({ value: v, label: v })
                    })
                    setOtherList([...arr])
                } else {
                    setOtherList([])
                }
                changedField['specialty'] = data.specialty?.length > 0 ? data.specialty : []
                changedField["isVerified"] = data.isVerified;
                changedField["isPasswordSet"] = data.isPasswordSet;
                setDoctorFormData({ ...doctorFormData, ...changedField });
                if (data.hospitals.length > 0 && data.hospitals[0].roles && data.hospitals[0].roles.length > 0) {
                    let arr = [];
                    data.hospitals[0].roles.map((v) => {
                        arr.push(v.name)
                    })
                    setRoles([...arr]);
                }
                let moduleAccessView = [];
                let moduleAccessCreate = [];
                let moduleAccessEdit = [];
                let moduleAccessDelete = [];
                if (data.hospitals && data.hospitals[0] && data.hospitals[0].moduleAccess) {
                    data.hospitals[0].moduleAccess.view.length > 0 && data.hospitals[0].moduleAccess.view.forEach((v) => {
                        moduleAccessView.push(v);
                    })
                    data.hospitals[0].moduleAccess.create.length > 0 && data.hospitals[0].moduleAccess.create.forEach((v) => {
                        moduleAccessCreate.push(v);
                    })
                    data.hospitals[0].moduleAccess.edit.length > 0 && data.hospitals[0].moduleAccess.edit.forEach((v) => {
                        moduleAccessEdit.push(v);
                    })
                    data.hospitals[0].moduleAccess.delete.length > 0 && data.hospitals[0].moduleAccess.delete.forEach((v) => {
                        moduleAccessDelete.push(v);
                    })
                }
                setModuleAccess({ view: moduleAccessView, create: moduleAccessCreate, edit: moduleAccessEdit, delete: moduleAccessDelete });
                break;
            case "hospitalAdd":
                setAddHospitalName(data.name);
                break;
            case 'approve':
                let filter = { "isApproved": ["0", "-1", "1"] };
                let search = {
                    "field": "_id",
                    "value": data.hospitals.length > 0 && data.hospitals[0].hospitalId && data.hospitals[0].hospitalId._id && data.hospitals[0].hospitalId._id,
                    "strict": true
                };
                let paramSearch = search;
                dispatch(fetchHospitalList('', filter, paramSearch, true)).then(response => {
                    if (response.data && response.data.data && response.data.data[0] && response.data.data[0].isApproved === "1") {
                        setDoctorId(data._id);
                        let changedFielddelete = { ...doctorFormData };
                        changedFielddelete["firstName"] = capitalizeFirstLetter(data.firstName);
                        changedFielddelete["lastName"] = capitalizeFirstLetter(data.lastName);
                        setDoctorFormData({ ...changedFielddelete });
                        setModalAction(action);
                        if (type === 'open') {
                            dispatch(openModal());
                        } else {
                            dispatch(closeModal());
                        }
                    } else {
                        let changedFielddelete = { ...doctorFormData };
                        changedFielddelete["hospitalName"] = response.data.data[0].name;
                        changedFielddelete["firstName"] = capitalizeFirstLetter(data.firstName);
                        changedFielddelete["lastName"] = capitalizeFirstLetter(data.lastName);
                        changedFielddelete["hospitalIds"] = response.data.data[0]._id;
                        setDoctorFormData({ ...changedFielddelete });
                        setModalAction('hospitalApprove');
                        setDoctorId(data._id);
                        if (type === 'open') {
                            dispatch(openModal());
                        } else {
                            dispatch(closeModal());
                        }
                    }

                });
                break;
            case 'reject':
            case 'disable':
            case 'delete':
            case 'enable':
                setDoctorId(data._id);
                let changedFielddelete = { ...doctorFormData };
                changedFielddelete["firstName"] = capitalizeFirstLetter(data.firstName);
                changedFielddelete["lastName"] = capitalizeFirstLetter(data.lastName);
                setDoctorFormData({ ...changedFielddelete });
                break;
            case 'goToSettings':
                dispatch(goToDoctorSettings(data._id)).then((res) => {
                    window.open(res, '_blank');
                })
                break;
            case 'changeEmail':
                setModalAction(action);
                setDoctorId(data._id)
                dispatchAction("changeEmail",data._id)
                break;
            default:
        }
        if (action === "approve" || action === "hospitalApprove") {

        } else {
            setModalAction(action);
            if (type === 'open') {
                dispatch(openModal());
            } else {
                dispatch(closeModal());
            }
        }

    };

    const dispatchAction = (action, e) => {
        if ((action === 'add' || action === 'update')) {
            if (emptyField(doctorFormData.firstName)) {
                currentFormErrors.firstName = "First Name is required";
            }
            if (emptyField(doctorFormData.lastName)) {
                currentFormErrors.lastName = "Last Name is required";
            }
            // if (emptyField(doctorFormData.emailId)) {
            //     currentFormErrors.emailId = "Email Id is required";
            // }
            if (emptyField(doctorFormData.contact.trim())) {
                currentFormErrors.contact = "Contact is required";
            }
            if (doctorFormData.hospitalIds === '' && emptyField(doctorFormData.hospitalName)) {
                currentFormErrors.hospitalName = "Hospital is required";
            }
            if (emptyField(doctorFormData.mciRegNumber)) {
                currentFormErrors.mciRegNumber = "MCI Reg. Number is required";
            }
            if (doctorFormData.emailId && validEmail(doctorFormData.emailId)) {
                currentFormErrors.emailId = "EmailId is invalid";
            }
            if (doctorFormData.contact.trim() !== "" && doctorFormData.contact.trim().length !== 10) {
                currentFormErrors.contact = "Contact maximum length 10";
            }
            if (doctorFormData.degreeIds?.length === 0) {
                currentFormErrors.degreeIds = "Degree is required";
            }
            if (doctorFormData.degreeIds.includes('Other') && doctorFormData.degree?.length === 0) {
                currentFormErrors.other = "Degree is required";
            }
            if (doctorFormData.specialty?.length === 0) {
                currentFormErrors.specialty = "Specialty is required";
            }
            setFormErrors(currentFormErrors);
        }
        if ((Object.keys(currentFormErrors).length === 0 && (action === 'add' || action === 'update')) || action === 'delete' || action === 'hospitalApprove' || action === 'disable' || action === 'enable' || action === 'approve' || action === 'reject' || action === 'hospitalAdd' || action==="changeEmail") {
            switch (action) {
                case 'add':
                    let addPayload = {
                        firstName: capitalizeFirstLetter(doctorFormData.firstName),
                        lastName: capitalizeFirstLetter(doctorFormData.lastName),
                        mciRegNumber: doctorFormData.mciRegNumber,
                        contact: doctorFormData.contact,
                        countryCode: doctorFormData.countryCode,
                        emailId: doctorFormData?.emailId?.trim(),
                        degreeIds: doctorFormData?.degreeIds?.filter((v) => v !== "Other"),
                        degree: doctorFormData.degree === false ? [] : doctorFormData.degree,
                        specialty: doctorFormData.specialty,
                        isApproved: "APPROVE",
                        "address": {
                            "permanent": {
                                "plotNo": doctorFormData.plotNo,
                                "line1": doctorFormData.address,
                                "line2": doctorFormData.address2,
                                "city": doctorFormData.city,
                                "state": doctorFormData.state,
                                "country": doctorFormData.country,
                                "pincode": doctorFormData.pincode,
                                "latitude": doctorFormData.latitude,
                                "longitude": doctorFormData.longitude,
                            }
                        },
                    }
                    if (doctorFormData.hospitalIds === "") {
                        addPayload.hospitalName = capitalizeFirstLetter(doctorFormData.hospitalName);
                        addPayload.roles = ["OWNER", "DOCTOR"]
                    } else {
                        addPayload.hospitals = [{
                            hospitalId: doctorFormData.hospitalIds,
                            roles: ["DOCTOR"],
                            moduleAccess: moduleAccess,
                        }];
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(addDoctor(addPayload, doctors)).then(() => {
                        LoadApiCalls(1, pageSize, search);
                        setShowBtnLoader('');
                        modalClickAction('close');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                    break;
                case 'update':
                    let updatePayload = {
                        firstName: capitalizeFirstLetter(doctorFormData.firstName),
                        lastName: capitalizeFirstLetter(doctorFormData.lastName),
                        mciRegNumber: doctorFormData.mciRegNumber,
                        contact: doctorFormData.contact,
                        countryCode: doctorFormData.countryCode,
                        emailId: doctorFormData?.emailId?.trim(),
                        degreeIds: doctorFormData?.degreeIds?.filter((v) => v !== "Other"),
                        degree: doctorFormData.degree === false ? [] : doctorFormData.degree,
                        specialty: doctorFormData.specialty,
                        "address": {
                            "permanent": {
                                "plotNo": doctorFormData.plotNo,
                                "line1": doctorFormData.address,
                                "line2": doctorFormData.address2,
                                "city": doctorFormData.city,
                                "state": doctorFormData.state,
                                "country": doctorFormData.country,
                                "pincode": doctorFormData.pincode,
                                "latitude": doctorFormData.latitude,
                                "longitude": doctorFormData.longitude,
                            }
                        },
                    }
                    if (doctorFormData.hospitalIds === "") {
                        updatePayload.hospitalName = capitalizeFirstLetter(doctorFormData.hospitalName);
                        updatePayload.roles = roles;
                    } else {
                        updatePayload.hospitals = [{
                            hospitalId: doctorFormData.hospitalIds,
                            roles: roles,
                            moduleAccess: moduleAccess,
                        }];
                    }
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateDoctor(doctorId, updatePayload, doctors)).then(() => {
                        setShowBtnLoader('');
                        modalClickAction('close');
                    }).catch(() => {
                        setShowBtnLoader('')
                    });
                    break;
                case 'approve':
                    setShowBtnLoader('show-button-loader');
                    const approveData = { "userIds": [doctorId], "approval": "APPROVE" }
                    dispatch(approvalDoctor(doctorId, approveData, doctors)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'hospitalApprove':
                    let data = { "hospitalIds": [doctorFormData.hospitalIds], "approval": "APPROVE", "noEmail": true };
                    setShowBtnLoader('show-button-loader');
                    dispatch(approvalHospital(doctorFormData.hospitalIds, data, hospitals)).then(() => {
                        setShowBtnLoader('');
                        const approveData = { "userIds": [doctorId], "approval": "APPROVE" }
                        dispatch(approvalDoctor(doctorId, approveData, doctors)).then(() => { modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    });
                    break;
                case 'reject':
                    setShowBtnLoader('show-button-loader');
                    const rejectData = { "userIds": [doctorId], "approval": "REJECT" }
                    dispatch(approvalDoctor(doctorId, rejectData, doctors)).then(() => { setShowBtnLoader(''); modalClickAction('close') }).catch(() => setShowBtnLoader(''));
                    break;
                case 'disable':
                    setShowBtnLoader('show-button-loader');
                    const disableData = { "userIds": [doctorId], "block": true }
                    dispatch(disableEnableDoctor(doctorId, disableData, doctors)).then(() => { setShowBtnLoader(''); modalClickAction('close') }).catch(() => setShowBtnLoader(''));
                    break;
                case 'enable':
                    setShowBtnLoader('show-button-loader');
                    const enableData = { "userIds": [doctorId], "block": false }
                    dispatch(disableEnableDoctor(doctorId, enableData, doctors)).then(() => { setShowBtnLoader(''); modalClickAction('close') }).catch(() => setShowBtnLoader(''));
                    break;
                case 'delete':
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteDoctor(doctorId, doctors)).then(() => { setShowBtnLoader(''); modalClickAction('close') }).catch(() => setShowBtnLoader(''));
                    break;
                case 'changeEmail':
                    dispatch(sendVerificationEmail(e)).then(() => { toast.success('Verification email sent successfully.') }).catch(() => {toast.success('Error while sending an email.')});
                    // toast.success('Verification email sent successfully.');
                    break;
                default:
                    throw new Error('Modal action not match');
            }
        }
        typeof(e)!=="string" && e?.preventDefault();
    };

    const fetchDoctorAnalytics = async () => {
        const data = await doctorAnalytics();
        if (data) {
            let changeField = { ...doctorAnalyticsData }
            changeField["totalDoctors"] = data?.totalDoctors;
            changeField["leads"] = data?.leads;
            changeField["notInYet"] = data?.notInYet;
            changeField["todayActive"] = data?.todayActive;
            setDoctorAnalytics({ ...changeField });
        }
    }

    const handleSelectDegreeAndSpeciality = (res, setState, name) => {
        if (res?.length > 0) {
            let arr = []
            res.map((v) => {
                arr.push({ value: name === "degree" ? v._id : v.name, label: v.name });
            })
            name === "degree" && arr.push({ value: "Other", label: "Other" });
            setState([...arr])
        } else {
            setState([])
        }
    }

    useEffect(() => {
        let filter = {  "isApproved": ["1"]  };
        dispatch(fetchHospitalList({}, filter, '', true));

        dispatch(listDegree()).then((res) => {
            handleSelectDegreeAndSpeciality(res.data.data, setDegreeList, "degree")
        })
        dispatch(searchSpecialty()).then((res) => {
            handleSelectDegreeAndSpeciality(res.data, setSpecialtyList, "specialty")
        })
        //eslint-disable-next-line
    }, []);

    const [addHospitalName, setAddHospitalName] = useState('');

    const LoadApiCalls = (page, pageSize, search) => {
        const payload = {
            search: search,
            pagination:{ "pageNo": page, "pageSize": pageSize },
          }
        if (search === "") setLoading(true);
        dispatch(fetchDoctorList(payload)).then(() => {
            fetchDoctorAnalytics();
            removeMainLoaderClass();
            if (search === "") setLoading(false);
        }).catch(() => {
            removeMainLoaderClass();
            if (search === "") setLoading(false);
        });
    }

    useEffect(() => {
        LoadApiCalls(page, pageSize, search);
        //eslint-disable-next-line
    }, [page, pageSize]);

    //on change search function
    const handleSearch = (e, page, pageSize) => {
        if (e.target.value === "") {
            LoadApiCalls(page, pageSize, "");
        } else {
            LoadApiCalls(page, pageSize, e.target.value);
        }
    }

    //debounce call function for search
    const debounceHandleSearch = useCallback(debounce(handleSearch, 800), []);

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="counter">
                    <Skeleton active loading={loading}>
                        <AdminCounter number={doctorAnalyticsData.totalDoctors} title="Total Doctors" img={totaldoc} />
                        <AdminCounter number={doctorAnalyticsData.leads} title="Leads" img={leadsdoc} />
                        <AdminCounter number={doctorAnalyticsData.notInYet} title="Not In Yet" img={notIndoc} />
                        <AdminCounter number={doctorAnalyticsData.todayActive} title="Today's Active" img={activedoc} />
                    </Skeleton>
                </div>
                <div className="adminTable">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Doctors List" />
                            <div className="table-header-search">
                                <div className="no-label table-search">
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); setPage(1); debounceHandleSearch(e, 1, pageSize) }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add New"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table doctors-table">
                            <DoctorsList
                                doctors={doctors}
                                modalClickAction={modalClickAction}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            {/* Add/Edit/Approve Modal */}
            <div className="AddNew">
                <DoctorAddModal showBtnLoader={showBtnLoader} modal={modal} modalTitle={modalTitle} modalAction={modalAction} doctorFormData={doctorFormData}
                    setDoctorFormData={setDoctorFormData} FormErrors={FormErrors} setFormErrors={setFormErrors}
                    modalClickAction={modalClickAction} hospitals={hospitals} modalButton1={modalButton1} modalButton2={modalButton2}
                    resetForm={resetForm} dispatchAction={dispatchAction} setAddHospitalName={setAddHospitalName}
                    addHospitalName={addHospitalName}
                    specialtyList={specialtyList} degreeList={degreeList}
                    otherList={otherList} setOtherList={setOtherList}
                />
            </div>
            {/* Delete Modal */}
            <DoctorOptionModal showBtnLoader={showBtnLoader} modal={modal} modalAction={modalAction} modalClickAction={modalClickAction} doctorFormData={doctorFormData}
                dispatchAction={dispatchAction}
            />
        </>
    );
}
