/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Modal from "../../../Components/Modal";
import Button from "../../../Components/Button";
import Delete from '../../../assets/images/delete.svg';
import Reject from '../../../assets/images/reject.svg';
import IsCommon from '../../../assets/images/iscommon.svg';
import Approve from '../../../assets/images/approve.svg';
import FormInput from "../../../Components/FormInput";
import Select from 'react-select';
import Formcheckbox from "../../../Components/Formcheckbox";
import { capitalizeFirstLetter, debounce } from "../../../common";
import { useCallback } from "react";

export default function DiseaseModals({ api, dispatch, modal, modalAction, modalClickAction, showBtnLoader, dispatchAction,
    name, setName, nameError, setNameError, mergeDiseaseId, setMergeDiseaseId, mergeError, setMergeError, modalButton1,
    modalButton2, modalTitle, isCommon, setIsCommon, resetForm, isCommonMultiple, categories, category, setCategory,
    categoryError, setCategoryError, isCatAvailable, isComAvailable, isDelete, isMerge, isAdd, isApprove }) {

    const [approvedListData, setApprovedListData] = useState([])

    const handleKeyDown = async (e) => {
        const value = e?.target?.value || '';
        let filter = { "isApproved": "1", "isDeleted": false };
        let pagination = { "pageNo": 1, "pageSize": 50 };
        let extraData = { "search": value }
        // if (value?.length >= 2) {
            if(api){
                let arr = [];
                await dispatch(api(filter, pagination, extraData, true)).then((res) => {
                    if (res?.data) {
                        if (res?.data?.length > 0) {
                            res.data.map((v) => {
                                if (v._id && v.name && v.name !== name) {
                                    arr.push({ value: v._id, label: v.name })
                                } else if (v._id && v.testName && v.testName !== name) {
                                    arr.push({ value: v._id, label: v.testName })
                                }
                            })
                            setApprovedListData([...arr])
                        }
                    }else if (res) {
                        if (res?.length > 0) {
                            res.map((v) => {
                                if (v._id && v.name && v.name !== name) {
                                    arr.push({ value: v._id, label: v.name })
                                } else if (v._id && v.testName && v.testName !== name) {
                                    arr.push({ value: v._id, label: v.testName })
                                }
                            })
                            setApprovedListData([...arr])
                        }
                    }
                })
            }
        // } else {
        //     setApprovedListData([])
        // }
    }
    useEffect(() => {
        handleKeyDown();
    },[])

    const debounceHandleKeyDown = useCallback(debounce(handleKeyDown, 800), []);

    return (
        <>
            {/* Delete/Approve/Reject Disease Modal */}
            {
                isDelete && (modalAction === 'delete' || modalAction === 'deleteMultiple' || modalAction === 'reject' || (isCommonMultiple && modalAction === 'isCommonMultiple') || (isApprove && modalAction === 'approve')) &&
                <Modal
                    type="center"
                    className="removedoc"
                    onClose={() => modalClickAction('close')}
                    Modalclass={(modal && (modalAction === 'delete' || modalAction === 'deleteMultiple' || modalAction === 'reject' || (isCommonMultiple && modalAction === 'isCommonMultiple') || (isApprove && modalAction === 'approve'))) ? `show-modal` : ``}
                >
                    <span className="modal-action-icon">
                        {(modalAction === "delete" || modalAction === "deleteMultiple") && <span className="modal-icon"><img className="" src={Delete} alt={Delete} /></span>}
                        {modalAction === "reject" && <span className="modal-icon"><img src={Reject} alt={Reject} /></span>}
                        {(isCommonMultiple && modalAction === 'isCommonMultiple') && <span className="modal-icon"><img src={IsCommon} alt={IsCommon} /></span>}
                        {(isApprove && modalAction === 'approve') && <span className="modal-icon"><img src={Approve} alt={Approve} /></span>}
                    </span>
                    <div className="modal-action-info">
                        <p className="text-center">
                            Are you sure you want to
                            <em> {modalAction === 'deleteMultiple' ? "Delete" : (isCommonMultiple && modalAction === 'isCommonMultiple') ? "do IsCommon True of" : capitalizeFirstLetter(modalAction)} </em> <br />
                            <strong>{name}</strong> ?
                        </p>
                        <div className="activeButton center">
                            <Button
                                btn="no-bg"
                                title="Cancel"
                                onClick={() => modalClickAction('close')}
                            />
                            <Button
                                loaderClass={['delete', 'deleteMultiple', 'reject', isCommonMultiple && 'isCommonMultiple', isApprove && 'approve'].includes(modalAction) ? showBtnLoader : ""}
                                title="Confirm"
                                onClick={() => dispatchAction(modalAction)}
                            />
                        </div>
                    </div>
                </Modal>
            }
            {/* Merge Disease Modal */}
            {
                isMerge && ['merge'].includes(modalAction) &&
                <Modal
                    type="center"
                    ModalTitle={modalTitle}
                    onClose={() => { modalClickAction('close'); setApprovedListData([]) }}
                    Modalclass={(modal && ['merge'].includes(modalAction)) ? 'show-modal' : ''}
                >
                    <FormInput
                        inputType="text"
                        placeholder="Enter Name"
                        title="Name"
                        name="name"
                        required="required"
                        isReadonly={true}
                        value={name}
                    />
                    <div className="formField">
                        <label><div className="feildRequired">Merge Name<span className="actionField errorBox">*</span></div></label>
                        <Select
                            required
                            classNamePrefix="react-select"
                            options={approvedListData}
                            name="mergeDisease"
                            placeholder="Select name"
                            onChange={(e) => { setMergeDiseaseId(e.value); setMergeError('') }}
                            value={approvedListData.filter((option) => {
                                return option.value === mergeDiseaseId;
                            })}
                            onKeyDown={(e) => debounceHandleKeyDown(e)}
                        />
                        {mergeError && <div className="actionField errorBox"> {mergeError}</div>}
                    </div>
                    <div className="activeButton right">
                        <Button
                            buttonType="reset"
                            title={modalButton1}
                            onClick={() => modalClickAction('close')}
                        />
                        <Button
                            loaderClass={modalAction === "merge" ? showBtnLoader : ""}
                            title={modalButton2}
                            onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                        />
                    </div>
                </Modal>
            }
            {
                isAdd && ['add', 'update', 'approve'].includes(modalAction) &&
                <Modal
                    ModalTitle={modalTitle}
                    onClose={() => modalClickAction('close')}
                    Modalclass={(modal && ['add', 'update', 'approve'].includes(modalAction)) ? 'show-modal' : ''}
                >
                    {
                        isComAvailable &&
                        <Formcheckbox
                            inputType="checkbox"
                            id="isCommon"
                            name="isCommon"
                            title="Is Name Common?"
                            onChange={() => setIsCommon(!isCommon)}
                            isChecked={isCommon ? "checked" : ""}
                        />
                    }
                    <FormInput
                        inputType="text"
                        placeholder="Enter Name"
                        title="Name"
                        name="name"
                        required="required"
                        onChange={(e) => { setName(e.target.value); setNameError('') }}
                        value={name}
                        errorMessage={nameError}
                    />
                    {
                        isCatAvailable &&
                        <div className="formField">
                            <label><div className="feildRequired">Category<span className="actionField errorBox">*</span></div></label>
                            <Select
                                required
                                classNamePrefix="react-select"
                                options={categories}
                                placeholder="Select Category"
                                onChange={(e) => { setCategory(e.value); setCategoryError('') }}
                                value={categories.filter((option) => {
                                    return option.value === category;
                                })}
                            />
                            {categoryError && <div className="actionField errorBox">{categoryError}</div>}
                        </div>
                    }
                    <div className="activeButton right">
                        <Button
                            title={modalButton1}
                            buttonType="reset"
                            onClick={() => resetForm()}
                        />
                        <Button
                            loaderClass={['add', 'update', 'approve'].includes(modalAction) ? showBtnLoader : ""}
                            title={modalButton2}
                            onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                        />
                    </div>
                </Modal>
            }
        </>
    )
}