/* eslint-disable react-hooks/exhaustive-deps */
import TableTitle from "../../Components/TableTitle";
import Select from "react-select";
import FormInput from "../../Components/FormInput";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDisease, approveDisease, deleteDisease, fetchDiseaseList, mergeDisease, updateDisease } from "../../actions/disease";
import DiseaseList from "./DiseaseList";
import { closeModal, openModal } from "../../actions/modal";
import DiseaseModals from "./DiseaseModals";
import Button from "../../Components/Button";
import { debounce, emptyField } from "../../common";
import { ExtraSubMenu, ListApisForDataMx } from "../Common";
import { Skeleton } from 'antd';

export default function Diseases(props) {

    //for skeleton
    const [loading, setLoading] = useState(false);

    const { diseases = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');

    //For multiple delete options
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    const [activeSubMenu, setActiveSubMenu] = useState('inapporval');

    //form state
    const [name, setName] = useState('');
    const [diseaseId, setDiseaseId] = useState('');
    const [isCommon, setIsCommon] = useState(false);
    const [mergeDiseaseId, setMergeDiseaseId] = useState('');
    const [nameError, setNameError] = useState('');
    const [mergeError, setMergeError] = useState('');

    const [showBtnLoader, setShowBtnLoader] = useState('');

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton1, setModalButton1] = useState('');
    const [modalButton2, setModalButton2] = useState('');

    const resetForm = () => {
        setName('');
        setDiseaseId('');
        setIsCommon(false);
        setMergeDiseaseId('');
        setNameError('');
        setMergeError('');
    };

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton1('');
        setModalButton2('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                setModalTitle('Add Disease');
                setModalButton1('Reset');
                setModalButton2('Add Disease');
                break;
            case 'update':
                setModalTitle('Edit Disease');
                setModalButton1('Reset');
                setModalButton2('Edit Disease');
                setDiseaseId(data._id);
                setName(data.name);
                setIsCommon(data.isCommon);
                break;
            case 'approve':
                setModalTitle('Confirm Disease');
                setModalButton1('Reset');
                setModalButton2('Confirm Disease');
                setDiseaseId(data._id);
                setName(data.name);
                setIsCommon(data.isCommon);
                break;
            case 'reject':
                setDiseaseId(data._id);
                setName(data.name);
                break;
            case 'merge':
                setModalTitle('Merge Disease');
                setModalButton1('Cancel');
                setModalButton2('Merge Disease');
                setDiseaseId(data._id);
                setName(data.name);
                break;
            case 'delete':
                setDiseaseId(data._id);
                setName(data.name);
                break;
            case 'deleteMultiple':
                setName("Selected Diseases");
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action) => {
        const updateData = {
            "_id": diseaseId,
            "name": name,
            "isCommon": isCommon,
        }
        if (action === "update" || action === "merge") {
            if (emptyField(name)) {
                setNameError("Disease is Required");
            } else if (emptyField(mergeDiseaseId)) {
                setMergeError("MergeDisease name is required");
            }
        }
        switch (action) {
            case 'add':
                const addData = {
                    "name": name,
                    "isCommon": isCommon,
                }
                if (addData.name !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(addDisease({ docs: [addData] }, diseases)).then(() => {
                        ListApisForDataMx(activeSubMenu, 1, pageSize, search, fetchDiseaseList, setSelectedRowKeys, dispatch, setLoading);
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                }
                break;
            case 'update':
                if (updateData._id !== "" && updateData.name !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateDisease(updateData, diseases)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                }
                break;
            case 'approve':
                const ApprovePayload = {
                    "ids": [diseaseId],
                    "approval": "APPROVE"
                }
                setShowBtnLoader('show-button-loader');
                dispatch(updateDisease(updateData, diseases)).then(() => {
                    dispatch(approveDisease(ApprovePayload, diseases)).then(() => {
                        modalClickAction('close');
                        ListApisForDataMx(activeSubMenu, 1, pageSize, search, fetchDiseaseList, setSelectedRowKeys, dispatch, setLoading);
                        setShowBtnLoader('');
                    }).catch(() => setShowBtnLoader(''));
                });
                break;
            case 'merge':
                const mergePayload = {
                    "id": diseaseId,
                    "withId": mergeDiseaseId,
                }
                if (mergePayload.id !== "" && mergePayload.withId !== "") {
                    setShowBtnLoader('show-button-loader');
                    dispatch(mergeDisease(mergePayload, diseases)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                }
                break;
            case 'reject':
                const rejectPayload = {
                    "ids": [diseaseId],
                    "approval": "REJECT"
                }
                setShowBtnLoader('show-button-loader');
                dispatch(approveDisease(rejectPayload, diseases)).then(() => {
                    modalClickAction('close');
                    setShowBtnLoader('');
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            case 'delete':
                const deletePayload = {
                    "ids": [diseaseId]
                }
                setShowBtnLoader('show-button-loader');
                dispatch(deleteDisease(deletePayload, diseases, diseaseId)).then(() => {
                    modalClickAction('close');
                    setShowBtnLoader('');
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            case 'deleteMultiple':
                const deleteMultiplePayload = {
                    "ids": selectedRowKeys
                }
                setShowBtnLoader('show-button-loader');
                dispatch(deleteDisease(deleteMultiplePayload, diseases, selectedRowKeys)).then(() => {
                    modalClickAction('close');
                    setShowBtnLoader('');
                    setSelectedRowKeys([]);
                }).catch(() => {
                    setShowBtnLoader('');
                });
                break;
            default:
        }
    };

    useEffect(() => {
        debounce(ListApisForDataMx(activeSubMenu, page, pageSize, search, fetchDiseaseList, setSelectedRowKeys, dispatch, setLoading), 300 )
    }, [search, activeSubMenu, page, pageSize])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Disease List " />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenu}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setPage(1); setSearch(''); }}
                                        placeholder="Select Status"
                                        value={ExtraSubMenu.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                {
                                    activeSubMenu === "approved" &&
                                    <div className="add-btn">
                                        <Button
                                            title="Add"
                                            onClick={() => modalClickAction('open', 'add')}
                                        />
                                    </div>
                                }
                                {
                                    selectedRowKeys?.length > 0 &&
                                    <div className="delete-btn">
                                        <Button
                                            btn="btn-outline-primary"
                                            title="Delete"
                                            onClick={() => modalClickAction('open', 'deleteMultiple')}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table diseases">
                            <DiseaseList
                                diseases={diseases}
                                modalClickAction={modalClickAction}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                isCategory={false}
                                isCommon={true}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                api={fetchDiseaseList}
                dispatch={dispatch}
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                setName={setName}
                nameError={nameError}
                setNameError={setNameError}
                mergeDiseaseId={mergeDiseaseId}
                setMergeDiseaseId={setMergeDiseaseId}
                mergeError={mergeError}
                setMergeError={setMergeError}
                modalButton1={modalButton1}
                modalButton2={modalButton2}
                modalTitle={modalTitle}
                isCommon={isCommon}
                setIsCommon={setIsCommon}
                resetForm={resetForm}
                isCatAvailable={false}
                isComAvailable={true}
                isDelete={true}
                isMerge={true}
                isAdd={true}
            />
        </>
    )
}