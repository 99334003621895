import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { TEMPLATES_LIST } from "./types";

//template list with pagination isType true otherwise all data fetch
export const getAllAdminReportTemplates = (data, isType) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.laboratoryTests.reportTemplate.put}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            if (isType) {
                dispatch({
                    type: TEMPLATES_LIST,
                    payload: {
                        count: response.data.totalRecord,
                        data: response.data.data,
                    }
                });
            }
        }).catch((e) => {
            reject(e);
        })
    })
};

//admin lab test data list
export const getAllAdminLabTest = (filter, pagination, extraData) => (dispatch) => {
    
    let body = {}
    if(filter){
        body.filter = filter
    }
    if(pagination){
        body.pagination = pagination
    }
    if(extraData){
        body.extraData = extraData
    }

    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.laboratoryTests.reportTemplate.search}`,
            data: body
        }).then((response) => {
            resolve(response.data);
            if(Object.keys(body).length>0){
                dispatch({
                    type: TEMPLATES_LIST,
                    payload: {
                        count: response.count,
                        data: response.data,
                    }
                })
            }
        }).catch((e) => {
            reject(e);
        })
    })
};

//add laboratory test templates api
export const adminAddReportTemplate = (data, templates) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.laboratoryTests.reportTemplate.add}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let obj = response.data[0]
            let mainArray = [...templates.data];
            let tmp =  mainArray.filter(labPanel => labPanel._id === obj._id);
            if (tmp.length===0) {
                mainArray.push(obj);
            }
            dispatch({
                type: TEMPLATES_LIST,
                payload: {
                    count: templates.count,
                    data: mainArray,
                }
            });
        }).catch((e) => {
            reject(e);
        })
    })
};

//laboratory test templates update api
export const adminUpdateReportTemplate = (templateId, data, templates) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.laboratoryTests.reportTemplate.update}/${templateId}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...templates.data];
            mainArray = mainArray.map((labPanel) => {
                if(labPanel._id === templateId){
                    return {
                        ...labPanel,
                        ...response.data
                    }
                }else{
                    return labPanel
                }
            });
            dispatch({
                type: TEMPLATES_LIST,
                payload: {
                    count: templates.count,
                    data: mainArray,
                }
            });
        }).catch((e) => {
            reject(e);
        })
    })
};

//template list with pagination isType true otherwise all data fetch
export const deleteAdminReportTemplates = (docs, templates, keys) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.laboratoryTests.reportTemplate.delete}`,
            data: docs
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...templates.data]
            keys.map((item) => {
                mainArray = mainArray.filter(v => v._id !== item);
            })
            dispatch({
                type: TEMPLATES_LIST,
                payload: {
                    count: templates.count - keys.length,
                    data: mainArray,
                }
            });
        }).catch((e) => {
            reject(e);
        })
    })
};
