import auth from "./auth";
import modal from "./modal";
import hospitals from "./hospitals";
import doctors from "./doctors";
import receptionist from "./receptionist";
import labs from "./labs";
import diseases from "./diseases";
import symptoms from "./symptoms";
import laboratoryTests from "./laboratoryTests";
import habits from "./habits";
import pastHistory from "./pastHistory";
import medicines from "./medicines";
import payments from "./payments";
import fileCategory from "./fileCategory";
import userRoles from "./userRoles";
import moduleAccesses from "./moduleAccesses";
import canvasIcon from "./canvasIcon";
import specialty from "./specialty";
import vitals from "./vitals";
import panel from "./panel";
import templates from "./templates";
import emailTemplates from "./emailTemplates";
import smsTemplates from "./smsTemplates";
import wpTemplates from "./wpTemplates";
import degree from "./degree";
import patientUhids from "./patientUhids";
import aptPrefixSuffix from "./aptPrefixSuffix";
import patientBill from "./patientBill";
import errorLogList from "./errorLogList"; 
import hospitalFilterData from "./hospitalFilter";
import menstrualType from "./menstrualType";

export default {
    auth,
    modal,
    hospitals,
    doctors,
    labs,
    diseases,
    symptoms,
    laboratoryTests,
    habits,
    pastHistory,
    medicines,
    payments,
    fileCategory,
    userRoles,
    moduleAccesses,
    canvasIcon,
    specialty,
    vitals,
    panel,
    templates,
    emailTemplates,
    smsTemplates,
    wpTemplates,
    degree,
    patientUhids,
    aptPrefixSuffix,
    patientBill,
    receptionist,
    errorLogList,
    hospitalFilterData,
    menstrualType
}