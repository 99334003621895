import { Skeleton, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Components/Button";
import FormInput from "../../Components/FormInput";
import Modal from "../../Components/Modal";
import TableTitle from "../../Components/TableTitle";
import { openModal } from "../../actions/modal";
import { VITALS_LIST } from "../../actions/types";
import { addVitals, deleteVitals } from "../../actions/vitals";
import { API_URL } from "../../api/apiRoutes";
import { apiClient } from "../../api/general";
import { removeMainLoaderClass } from "../../common";
import DiseaseModals from "../Diseases/DiseaseModals";
import AddVital from "./AddVital";
import VitalList from "./VitalList";
import './style.scss';

export default function Vitals(props) {
    const { modal, vitals } = useSelector((state) => state);

    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton2, setModalButton2] = useState('');
    const [builderData, setBuilderData] = useState('');
    const [FormVitalData, setFormVitalsData] = useState({});
    const [vitalsOptions, setVitalsOptions] = useState({});
    const [FormErrors, setFormErrors] = useState({});

    const [name, setName] = useState('')

    const [showBtnLoader, setShowBtnLoader] = useState('');

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25)

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton2('');
        setName('')
    };

    const resetForm = () => {
        setFormVitalsData({
            "name": '',
            "key": '',
            "shortForm": "",
            "type": "",
            "isSpecial": false,
            "isDefault": false,
            "vitalImage": "",
            "formulaFields": [],
            "formula": "",
            "fields": []
        });
        setFormErrors('');
    };

    const modalClickAction = (type, action = '', vdata = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                setModalTitle('Add Vital');
                setModalButton2('Add');
                break;
            case 'update':
                setModalTitle('Update Vital');
                setModalButton2('Update');
                let updateChangeField = {};
                updateChangeField = FormVitalData;
                setBuilderData({ task_data: vdata.fields });
                updateChangeField["name"] = vdata.name;
                updateChangeField["key"] = vdata.key;
                updateChangeField["type"] = vdata.type;
                updateChangeField["formula"] = vdata.formula ? vdata.formula : "";
                updateChangeField["formulaFields"] = vdata.formulaFields ? vdata.formulaFields : "";
                updateChangeField["isDefault"] = vdata.isDefault !== undefined ? vdata.isDefault : false;
                updateChangeField["isSpecial"] = vdata.isSpecial ? vdata.isSpecial : false;
                updateChangeField["vitalImage"] = vdata.vitalImage ? vdata.vitalImage : "";
                updateChangeField["shortForm"] = vdata.shortForm ? vdata.shortForm : "";
                updateChangeField["fields"] = vdata.fields !== "undefined" ? vdata.fields : null;
                setFormVitalsData({ ...FormVitalData, ...updateChangeField });
                setName(vdata.name);
                break;
            case 'delete':
                let changedField = { ...FormVitalData };
                changedField["name"] = vdata.name;
                changedField["key"] = vdata.key;
                setFormVitalsData({ ...changedField });
                setName(vdata.name)
                break;
            default:
                break;
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            document.body.classList.remove('popup-open');
        }
    };

    const dispatchAction = (action, options) => {
        switch (action) {
            case 'add':

                if( FormVitalData.name === "" ){
                    notification.error({
                        description:
                            "Please add vital name",
                    });
                    return false;
                }
                if( FormVitalData.key === "" ){
                    notification.error({
                        description:
                            "Please add vital key",
                    });
                    return false;
                }
                if( FormVitalData.shortForm === "" ){
                    notification.error({
                        description:
                            "Please add vital shortForm",
                    });
                    return false;
                }
                if( options.task_data === undefined || options.task_data.length === 0 ){
                    notification.error({
                        description:
                            "Please drop one type from the toolbox.",
                    });
                    return false;
                }
                
                let formData = new FormData();
                formData.append("name", FormVitalData.name);
                formData.append("key", FormVitalData.key);
                formData.append("shortForm", FormVitalData.shortForm);
                formData.append("type", FormVitalData.type);
                formData.append("formula", FormVitalData.formula);
                formData.append("vitalImage", FormVitalData.vitalImage);
                formData.append("formulaFields", JSON.stringify(FormVitalData.formulaFields));
                formData.append("isSpecial", FormVitalData.isSpecial);
                formData.append("isDefault", FormVitalData.isDefault);
                formData.append("fields", JSON.stringify(options.task_data));
                setShowBtnLoader('show-button-loader');
                dispatch(addVitals(formData, FormVitalData.key)).then(res => {
                    LoadApiCalls(search, 1, pageSize);
                    setPage(1);
                    setShowBtnLoader('');
                    modalClickAction('close');
                }).catch(() => setShowBtnLoader(''));
                break;
            case 'delete':
                setShowBtnLoader('show-button-loader');
                dispatch(deleteVitals(FormVitalData.key)).then(() => {
                    LoadApiCalls(search, page, pageSize);
                    modalClickAction('close');
                    setShowBtnLoader('');
                }).catch(() => setShowBtnLoader(''));
                break;
            case 'update':
                if( options.task_data === undefined ){
                    notification.error({
                        description:
                            "Please drop one type from the toolbox.",
                    });
                    return false;
                }
                let updateFormData = new FormData();
                updateFormData.append("name", FormVitalData.name);
                updateFormData.append("key", FormVitalData.key);
                updateFormData.append("shortForm", FormVitalData.shortForm);
                updateFormData.append("type", FormVitalData.type);
                updateFormData.append("formula", FormVitalData.formula);
                updateFormData.append("vitalImage", FormVitalData.vitalImage);
                updateFormData.append("formulaFields", JSON.stringify(FormVitalData.formulaFields));
                updateFormData.append("isSpecial", FormVitalData.isSpecial);
                updateFormData.append("isDefault", FormVitalData.isDefault);
                updateFormData.append("fields", JSON.stringify(options.task_data));
                setShowBtnLoader('show-button-loader');
                //add this code here because of some error
                return new Promise((resolve, reject) => {
                    apiClient({
                        method: 'PUT',
                        url: `${API_URL.vitals.change}/${FormVitalData.key}`,
                        data: updateFormData
                    }).then((response) => {
                        LoadApiCalls(search, page, pageSize);
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch((error) => {
                        reject(error);
                        setShowBtnLoader('')
                    });
                });
                // dispatch(updateVitals([...updateFormData], FormVitalData.key)).then((res) => {
                //     LoadApiCalls(search, page, pageSize);
                //     modalClickAction('close');
                //     setShowBtnLoader('');
                // }).catch((err) => {
                //     setShowBtnLoader('')
                // });
                break;
            default:
                throw new Error('Modal action not match');
        }
    };

    const [search, setSearch] = useState('');

    const LoadApiCalls = (search, page, pageSize) => {
        const payload = {
            "pagination": { "pageNo": page, "pageSize": pageSize },
            "search": search
        }
        if (search === "") setLoading(true);
        //state mutation in dispatch issue solved to direct api call
        return new Promise((resolve, reject) => {
            apiClient({
                method: 'POST',
                url: `${API_URL.vitals.findMany}`,
                data: payload
            }).then((response) => {
                resolve(response);
                dispatch({
                    type: VITALS_LIST,
                    payload: {
                        count: response.data.count,
                        data: response.data.data,
                    }
                });
                if (search === "") setLoading(false);
                removeMainLoaderClass();
                let vitalsOps = [];
                response.data.data.forEach((v) => {
                    vitalsOps.push({ "label": v.name, "value": v.key });
                })
                setVitalsOptions(vitalsOps);
            }).catch((error) => {
                reject(error);
                if (search === "") setLoading(false);
                removeMainLoaderClass();
            });
        });
        // dispatch(getVitalsData(payload)).then(res => {
        // }).catch(() => {
        // });
    }

    useEffect(() => {
        LoadApiCalls(search, page, pageSize);
    }, [search, page, pageSize]);

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Vitals List" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table vitals">
                            <VitalList
                                vitals={vitals}
                                modalClickAction={modalClickAction}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            {
                (['add', 'update']).includes(modalAction) &&
                <Modal
                    ModalTitle={modalTitle}
                    onClose={() => modalClickAction('close')}
                    type="vitals_add"
                    Modalclass={((modal) && (['add', 'update']).includes(modalAction)) ? 'show-modal' : ''}
                >
                    <div className="AddNew">
                        <AddVital
                            showBtnLoader={showBtnLoader}
                            FormVitalData={FormVitalData}
                            setFormVitalsData={setFormVitalsData}
                            FormErrors={FormErrors}
                            modalAction={modalAction}
                            vitalsOptions={vitalsOptions}
                            modalClickAction={modalClickAction}
                            dispatchAction={dispatchAction}
                            modalButton2={modalButton2}
                            setBuilderData={setBuilderData}
                            builderData={builderData}
                        />
                    </div>
                </Modal>
            }
        </>
    );
};