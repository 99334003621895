import { Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import FormInput from "../../../../Components/FormInput";
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import { CATEGORY, CONATAINER, debounce, DEPARTMENT, HOMECOLLECTIONOPTIONS, ML, SAMPLE, TIMELINE } from "../../../../common";
import deleteicon from "../../../../assets/images/delete.svg";
import addicon from "../../../../assets/images/add-icon.svg";
import LabTestAddReferenceModal from "../LabTestAddReferenceModal";

export default function LabTestAddModal({ addLabTest, modalAction, isSingleArray, setAddDataValues, index, creatableLabTest, isAdd, setSelectClass }) {

    //reference value add
    const initialState = {
        testName: "",
        category: "",
        department: "",
        testCode: "",
        sample: "",
        container: "",
        size: "",
        ml: "",
        price: "",
        homeCollection: "",
        reportSize: "",
        timeline: "",
        referenceValues: [],
        notes: ""
    };

    const [tests, setTests] = useState([]);
    useEffect(() => {
        let arr = [];
        if (creatableLabTest.length > 0) {
            creatableLabTest.map((test) => {
                arr.push({ value: test.testName, label: test.testName, allValue: test })
            })
        }
        setTests([...arr]);
    }, [creatableLabTest])

    const [addData, setAddData] = useState([initialState])
    useEffect(() => {
        if (addLabTest?.length > 0) {
            let arr = [...tests];
            let caArr = [...categories];
            addLabTest.map((v) => {
                let tmp = arr.some(a => a.value === v.testName);
                if (!tmp) {
                    arr.push({ value: v.testName, label: v.testName, allValue: v })
                }
                let catmp = arr.some(a => a.value === v.category);
                if (!catmp) {
                    caArr.push({ value: v.category, label: v.category })
                }
            })
            setTests([...arr]);
            setCategories([...caArr]);
            setAddData([...addLabTest]);
        } else {
            setAddData([initialState]);
        }
    }, [addLabTest])

    const [fillTable, setFillTable] = useState([]);
    //show category
    const [categories, setCategories] = useState(CATEGORY);
    //when creatable category add new value in the array
    const handleCreate = useCallback((inputValue) => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        setCategories([...categories, newValue]);
    })

    const handleChange = (key, e, i) => {
        const keyArray = ['category', 'department', 'sample', 'container', 'ml', 'homeCollection', 'timeline'];
        let newArrs = [...addData];
        if ((key !== '') && (keyArray.includes(key))) {
            newArrs[i][key] = e.value;
        } else {
            const { name, value } = e.target;
            newArrs[i][name] = value;
        }
        if (isSingleArray) {
            setAddDataValues([...newArrs])
        } else {
            setAddDataValues([...newArrs], index)
        }
        setAddData([...newArrs])
    };

    const handleLabChange = (e, i) => {
        let newArrs = [...addData];
        if (e?.__isNew__) {
            const newValue = { value: e.value, label: e.value, allValue: { ...initialState } };
            setTests([...tests, newValue]);
            newArrs[i] = saveAllValueInTest(initialState, e.value);
        } else {
            newArrs[i] = saveAllValueInTest(e.allValue);
        }
        if (isSingleArray) {
            setAddDataValues([...newArrs])
        } else {
            setAddDataValues([...newArrs], index)
        }
        setAddData([...newArrs]);
    }

    const saveAllValueInTest = (ele, testName) => {
        const payload = {
            testName: testName ? testName : (ele?.testName ? ele?.testName : ''),
            category: ele?.category ? ele?.category : '',
            department: ele?.department ? ele?.department : '',
            testCode: ele?.testCode ? ele?.testCode : '',
            sample: ele?.sample ? ele?.sample : '',
            container: ele?.container ? ele?.container : '',
            size: ele?.sampleSize?.size ? ele?.sampleSize?.size : '',
            ml: ele?.sampleSize?.ml ? ele?.sampleSize?.ml : '',
            price: ele?.price ? ele?.price : '',
            reportSize: ele?.report?.size ? ele?.report?.size : '',
            timeline: ele?.report?.timeline ? ele?.report?.timeline : '',
            homeCollection: ele?.homeCollection ? ele?.homeCollection : '',
            referenceValues: ele?.referenceValues ? ele?.referenceValues : [],
            notes: ele?.notes ? ele?.notes : '',
        };
        return payload;
    }

    const handleGroupAdd = () => {
        let changeValues = [...addData];
        let tmp = changeValues.some(v => JSON.stringify(v) === JSON.stringify(initialState));
        if (!tmp) {
            changeValues.push(initialState);
        }
        setAddData([...changeValues]);
    }

    const handleGroupRemove = (i) => {
        let changeLang = [...addData];
        if (changeLang.length > 1) {
            changeLang.splice(i, 1);
            setAddData([...changeLang]);
        } else {
            changeLang = { ...initialState };
            setAddData([changeLang]);
        }
    }

    const columns = [
        Table.EXPAND_COLUMN,
        {
            title: "Name", dataIndex: "testName", render: (_, { testName, index }) => {
                return (
                    <>
                        {
                            isAdd ?
                                <FormInput
                                    inputType="text"
                                    placeholder="Name"
                                    name="testName"
                                    onChange={(e) => { handleChange('', e, index); }}
                                    value={testName}
                                />
                                :
                                <Creatable
                                    classNamePrefix="react-select"
                                    options={tests}
                                    name="testName"
                                    value={tests.filter((option) => {
                                        return option.value === testName
                                    })}
                                    onChange={(e) => { handleLabChange(e, index); }}
                                    placeholder="Select"
                                    openMenuOnFocus={true}
                                    onMenuOpen={() => setSelectClass('select-menu-open')}
                                    onMenuClose={() => setSelectClass('')}
                                />
                        }
                    </>
                )
            }
        },
        {
            title: "Category", dataIndex: "category", render: (_, { category, index }) => {
                return (
                    <Creatable
                        classNamePrefix="react-select"
                        options={categories}
                        name="category"
                        value={categories.filter((option) => {
                            return option.value === category
                        })}
                        onChange={(e) => { handleChange('category', e, index); }}
                        placeholder="Select"
                        onCreateOption={handleCreate}
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Department", dataIndex: "department", render: (_, { department, index }) => {
                return (
                    <Select
                        classNamePrefix="react-select"
                        options={DEPARTMENT}
                        name="department"
                        value={DEPARTMENT.filter((option) => {
                            return option.value === department
                        })}
                        onChange={(e) => {
                            handleChange('department', e, index);
                        }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "TestCode", dataIndex: "testCode", render: (_, { testCode, index }) => {
                return (
                    <FormInput
                        inputType="text"
                        placeholder="Test Code"
                        name="testCode"
                        onChange={(e) => { handleChange('', e, index); }}
                        value={testCode}
                    />
                )
            }
        },
        {
            title: "Sample", dataIndex: "sample", render: (_, { sample, index }) => {
                return (
                    <Select
                        classNamePrefix="react-select"
                        options={SAMPLE}
                        name="sample"
                        value={SAMPLE.filter((option) => {
                            return option.value === sample
                        })}
                        onChange={(e) => { handleChange('sample', e, index); }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Container", dataIndex: "container", render: (_, { container, index }) => {
                return (
                    <Select
                        classNamePrefix="react-select"
                        options={CONATAINER}
                        name="container"
                        value={
                            CONATAINER.filter((option) => {
                                return option.value === container
                            })
                        }
                        onChange={(e) => {
                            handleChange('container', e, index);
                        }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Size", dataIndex: "size", render: (_, { size, index }) => {
                return (
                    <FormInput
                        inputType="number"
                        placeholder="Size"
                        name="size"
                        onChange={(e) => { handleChange('', e, index); }}
                        value={size}
                    />
                )
            }
        },
        {
            title: "ML", dataIndex: "ml", render: (_, { ml, index }) => {
                return (
                    <Select
                        classNamePrefix="react-select"
                        options={ML}
                        name="ml"
                        value={ML.filter((option) => {
                            return option.value === ml
                        })}
                        onChange={(e) => { handleChange('ml', e, index); }}
                        placeholder="ml"
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Price", dataIndex: "price", render: (_, { price, index }) => {
                return (
                    <FormInput
                        inputType="number"
                        placeholder="Enter Price"
                        name="price"
                        onChange={(e) => { handleChange('', e, index); }}
                        value={price}
                    />
                )
            }
        },
        {
            title: "Home Collection", dataIndex: "homeCollection", render: (_, { homeCollection, index }) => {
                return (
                    <Select
                        classNamePrefix="react-select"
                        options={HOMECOLLECTIONOPTIONS}
                        name="homeCollection"
                        value={HOMECOLLECTIONOPTIONS.filter((option) => {
                            return option.value === homeCollection
                        })}
                        onChange={(e) => { handleChange('homeCollection', e, index); }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Report Size", dataIndex: "reportSize", render: (_, { reportSize, index }) => {
                return (
                    <FormInput
                        inputType="number"
                        placeholder="Count"
                        name="reportSize"
                        onChange={(e) => { handleChange('', e, index); }}
                        value={reportSize}
                    />
                )
            }
        },
        {
            title: "Timeline", dataIndex: "timeline", render: (_, { timeline, index }) => {
                return (
                    <Select
                        classNamePrefix="react-select"
                        options={TIMELINE}
                        name="timeline"
                        value={TIMELINE.filter((option) => {
                            return option.value === timeline
                        })}
                        onChange={(e) => { handleChange('timeline', e, index); }}
                        placeholder="Time"
                        openMenuOnFocus={true}
                        onMenuOpen={() => setSelectClass('select-menu-open')}
                        onMenuClose={() => setSelectClass('')}
                    />
                )
            }
        },
        {
            title: "Notes", dataIndex: "notes", render: (_, { notes, index }) => {
                return (
                    <FormInput
                        inputType="text"
                        placeholder="Note"
                        name="notes"
                        onChange={(e) => { handleChange('', e, index); }}
                        value={notes}
                    />
                )
            }
        },
        {
            title: 'Action', dataIndex: 'action', width: 100, fixed: "right", render: (_, { index }) => {
                return (
                    <div className="action">
                        {
                            (modalAction === "add" && (fillTable.length - 1 === index)) &&
                            <span className="add-group" onClick={() => handleGroupAdd()}>
                                <img alt={addicon} src={addicon} />
                            </span>
                        }
                        <span className="remove-lang" onClick={() => handleGroupRemove(index, addData, setAddData, initialState)}>
                            <img alt={deleteicon} src={deleteicon} />
                        </span>
                    </div>
                )
            },
        },
    ];

    useEffect(() => {
        let arr = [];
        if (addData.length > 0) {
            addData.map((v, i) => {
                let obj = {
                    index: i,
                    key: i,
                    testName: v.testName,
                    category: v.category,
                    department: v.department,
                    testCode: v.testCode,
                    sample: v.sample,
                    container: v.container,
                    size: v.size,
                    ml: v.ml,
                    price: v.price,
                    homeCollection: v.homeCollection,
                    reportSize: v.reportSize,
                    timeline: v.timeline,
                    referenceValues: v.referenceValues,
                    notes: v.notes,
                    data: v,
                }
                arr.push(obj)
            })
        }
        setFillTable([...arr])
    }, [addData]);

    const setRefValues = (refData, index) => {
        let arr = [...addData];
        let pushArr = []
        refData.forEach(v => {
            pushArr.push(v);
        })
        arr[index] = {...arr[index], ['referenceValues'] : pushArr};
        setAddData([...arr]);
    }

    return (
        <Table
            columns={columns}
            dataSource={fillTable}
            pagination={false}
            sticky={true}
            scroll={{ x: 2350 }}
            expandable={{
                expandedRowRender: (record) => {
                    return (
                        <LabTestAddReferenceModal
                            referenceValues={record.referenceValues}
                            index={record.index}
                            setRefValues={setRefValues}
                            setSelectClass={setSelectClass}
                        />
                    )
                }
            }}
            rowClassName={(record) => {
                return record.index < 3 ? "select-menu-down" : "";
            }}
        />
    )
}