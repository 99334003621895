import { LAB_LIST } from "../actions/types";

const initialState = {
    count: 0,
    data: []
}

export default function labs(state = initialState, action) {
    switch (action.type) {
        case LAB_LIST:
            return {
                count: action.payload.count,
                data: action.payload.data
            }
        default:
            return state;
    }
};