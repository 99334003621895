import React from "react";
import Select from 'react-select';
import FormInput from "../../../Components/FormInput";
import Formcheckbox from "../../../Components/Formcheckbox";
import Button from "../../../Components/Button";
import Builder from "../../../Components/Builder";
import { useState } from "react";
import { useEffect } from "react";

export default function AddVital({ showBtnLoader, FormVitalData, setFormVitalsData, FormErrors, modalAction, vitalsOptions, dispatchAction, modalButton2, setBuilderData, builderData }) {

    const [fields, setFields] = useState([]);

    useEffect(() => {
        if (FormVitalData?.fields?.length > 0) {
            setFields(FormVitalData.fields);
        } else {
            setFields([])
        }
    }, [FormVitalData.fields])

    const handleFormChange = (e) => {
        let changedField = FormVitalData;
        changedField[e.target.name] = e.target.value;
        setFormVitalsData({ ...FormVitalData, ...changedField });
    };

    const handleFileChange = (e) => {
        let changedField = FormVitalData;
        changedField[e.target.name] = e.target.files[0];
        setFormVitalsData({ ...FormVitalData, ...changedField });
    };

    const handleFormChecked = (e) => {
        let changedField = FormVitalData;
        changedField[e.target.name] = e.target.checked;
        setFormVitalsData({ ...FormVitalData, ...changedField });
    };

    const vitalsType = [
        { value: "single", label: "Single" },
        { value: "duol", label: "Duol" },
        { value: "double", label: "Double" },
        { value: "multiple", label: "Multiple" },
    ];

    const handleSelectChange = (field, e, multi) => {
        let changedField = FormVitalData;
        if (multi) {
            let selectedValue = [];
            e.forEach((v) => {
                selectedValue.push(v.value);
            })
            changedField[field] = Array.isArray(e) ? e.map(x => x.value) : [];
        } else {
            changedField[field] = e.value;
        }
        setFormVitalsData({ ...FormVitalData, ...changedField });

    };

    const handleBuilderChange = (data) => {
        let arr = []
        data?.task_data?.map((v) => {
            arr.push(v)
        })
        setFields([...arr])
    }

    return (
        <form className="AddNew-Doctor">
            <div className="twoColsField">
                <FormInput
                    inputType="text"
                    placeholder="Enter your text"
                    title="Vital Name"
                    name="name"
                    required="required"
                    onChange={(e) => { handleFormChange(e); }}
                    value={FormVitalData.name}
                    errorMessage={FormErrors.name}
                />
                <FormInput
                    inputType="text"
                    placeholder="Enter your text"
                    title="Vitals Short Form"
                    name="shortForm"
                    required="required"
                    onChange={(e) => { handleFormChange(e); }}
                    value={FormVitalData.shortForm}
                    errorMessage={FormErrors.shortForm}
                />
            </div>
            <div className="twoColsField">
                <FormInput
                    inputType="text"
                    placeholder="Enter your text"
                    title="Key Name"
                    name="key"
                    required="required"
                    isReadonly={modalAction === 'update' ? true : false}
                    onChange={(e) => { handleFormChange(e); }}
                    value={FormVitalData.key}
                    errorMessage={FormErrors.key}
                />
                <FormInput
                    inputType="file"
                    placeholder="Enter your text"
                    title="Vitals Image"
                    name="vitalImage"
                    onChange={(e) => { handleFileChange(e); }}
                />
            </div>
            <div className="vitals-checkboxes">
                <Formcheckbox
                    inputType="checkbox"
                    id="isSpecial"
                    name="isSpecial"
                    title="Is vital Special?"
                    required="required"
                    isChecked={FormVitalData.isSpecial ? "checked" : ""}
                    onChange={(e) => { handleFormChecked(e); }}
                />
                <Formcheckbox
                    inputType="checkbox"
                    id="isDefault"
                    name="isDefault"
                    title="Is vital Default?"
                    required="required"
                    isChecked={FormVitalData.isDefault ? "checked" : ""}
                    onChange={(e) => { handleFormChecked(e); }}
                />
            </div>
            {FormVitalData.isSpecial &&
                <>
                    <div className="formField">
                        <label>Vitals Fields</label>
                        <Select
                            classNamePrefix="react-select"
                            options={vitalsOptions}
                            placeholder="Select Vitals"
                            name="type"
                            value={FormVitalData.formulaFields && (vitalsOptions.filter(obj => FormVitalData.formulaFields.includes(obj.value)))}
                            // value={ FormVitalData.formulaFields}
                            isMulti={true}
                            onChange={(e) => { handleSelectChange("formulaFields", e, true) }}
                        />
                    </div>
                    <div className="formField">
                        <label>Vitals Fields Key</label>
                        {FormVitalData.formulaFields && FormVitalData.formulaFields.map((obj, index) =>
                            FormVitalData.formulaFields.length === index + 1 ?
                                <span>{obj}</span>
                                :
                                FormVitalData.formulaFields.length >= index + 1 &&
                                <span>{obj}, </span>
                            // FormVitalData.formulaFields.includes(obj.value)
                        )}
                    </div>
                    <FormInput
                        inputType="text"
                        placeholder="Enter your text"
                        title="Formula"
                        name="formula"
                        required="required"
                        onChange={(e) => { handleFormChange(e); }}
                        value={FormVitalData.formula}
                        errorMessage={FormErrors.formula}
                    />
                </>
            }
            <div className="formField">
                <label>Type</label>
                <Select
                    classNamePrefix="react-select"
                    options={vitalsType}
                    placeholder="Enter Your Text"
                    name="type"
                    value={
                        vitalsType.filter(option =>
                            option.value === FormVitalData.type)
                    }
                    onChange={(e) => { handleSelectChange("type", e); }}
                />
            </div>
            <Builder fields={FormVitalData.fields} setBuilderData={setBuilderData}/>
            <div className="activeButton right">
                <Button
                    loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                    title={modalButton2}
                    onClick={(e) => { e.preventDefault(); dispatchAction(modalAction, builderData); }}
                />
            </div>
        </form>
    );
};