import FormInput from "../../../../Components/FormInput";
import TextArea from 'antd/lib/input/TextArea';
import FormUpload from "../../../../Components/FormUpload";
import Select from "react-select";


export default function WPTemplateAddModal({ WPData, setWPData, image, setImage, error, setError, modalAction }) {
    const CATEGORY = [
        { value: 'OWNER', label: 'Owner' },
        { value: 'ADMIN', label: 'Admin' },
    ]
    //on chnage event of form
    const handleChange = (e, name, isSelect) => {
        let change = { ...WPData };
        // console.log(change,"change", e,name,isSelect)
        if (isSelect) {
            const value = e.value;
            change[name] = value;
        } else {
            const value = e.target.value;
            change[name] = value;
        }
        setWPData({ ...change });
        let err = { ...error };
        if (err[name]) {
            delete err[name];
        }
        setError({ ...err });
    }

    return (
        <>
            <div className="input-fields-templates">
                <FormInput
                    inputType="text"
                    placeholder="Name"
                    name="triggerName"
                    title="Trigger Name"
                    required="required"
                    onChange={(e) => { handleChange(e, 'name', false); }}
                    value={WPData.name}
                    errorMessage={error?.name}
                />
                <FormInput
                    inputType="text"
                    placeholder="Trigger Key"
                    name="triggerKey"
                    title="Trigger Key"
                    required="required"
                    onChange={(e) => { handleChange(e, 'trigger', false); }}
                    value={WPData.trigger}
                    isDisabled={modalAction==="update" ? true : false}
                    errorMessage={error?.trigger}
                />
                
                <div className="formField add-zindex">
                    <label><div className="feildRequired">Category<span className="actionField errorBox">*</span></div></label>
                    <Select
                        classNamePrefix="react-select"
                        options={CATEGORY}
                        name="category"
                        value={CATEGORY.filter((option) => {
                            return option.value === WPData.category
                        })}
                        onChange={(e) => { handleChange(e, 'category', true); }}
                        placeholder="Select"
                        openMenuOnFocus={true}
                    />
                    {error?.category && <div className="actionField errorBox">{error.category}</div>}
                </div>
                    
                
                <div className="formField">
                    <label><div className="feildRequired"> Image</div></label>
                    <FormUpload
                        logo={image}
                        setLogo={setImage}
                        message="Drag and Drop image here"
                    />
                    {/* {error.image && <div className="actionField errorBox">{error.image}</div>} */}
                </div>
                <FormInput
                    inputType="text"
                    placeholder="Whatsapp Template Name"
                    name="templateid"
                    title="Whatsapp Template Name"
                    required="required"
                    onChange={(e) => { handleChange(e, 'templateid', false); }}
                    value={WPData.templateid}
                    errorMessage={error?.templateid}
                />
                {/* <FormInput
                    inputType="file"
                    placeholder="image"
                    name="image"
                    title="Image"
                    required="required"
                    onChange={(e) => { handleChange(e, 'image', false); }}
                    value={WPData.image}
                    errorMessage={error?.image}
                /> */}
                
            </div>
        </>
    )
}