import {
    BILL_UPDATE_PREFIX_N_SUFFIX
} from "../actions/types";

const initialState = {};

export default function patientBill(state = initialState, action) {
    switch (action.type) {
        case BILL_UPDATE_PREFIX_N_SUFFIX:
            return action.payload;
        default:
            return state;
    }
};