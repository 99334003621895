import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";

// from options table using as well as languages list api
export const adminOnBoardVideoLinkListOne = (groups) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.onBoardVideo.list}`,
            data: {
                "groups": groups
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        })
    })
};

export const HeaderChangeListAPI = (groups) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.onBoardVideo.list}`,
            data: {
                "groups": groups
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        })
    })
};

//insert and update data of groups in options table add and edit in languages as well
export const adminOnBoardVideoLinkUpsert = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.onBoardVideo.upsert}`,
            data: data
        }).then((response) => {
            if (response) {
                resolve(response.data);
            }
        }).catch((e) => {
            reject(e);
        })
    })
};

//on board video link delete using in groups delete in onboard video link delete
export const adminOnBoardVideoLinkDelete = (key) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.onBoardVideo.delete}/${key}`,
        }).then((response) => {
            if (response) {
                resolve(response.data);
            }
        }).catch((e) => {
            reject(e);
        })
    })
};