import { Pagination, Table, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import Edit from '../../../../assets/images/Edit.svg';
import Delete from '../../../../assets/images/delete.svg';
import Reject from '../../../../assets/images/reject.svg';
import Approve from '../../../../assets/images/approve.svg';

export default function TemplateList({ templates, modalClickAction, page, setPage, pageSize, setPageSize, selectedRowKeys, setSelectedRowKeys }) {

    const [data, setData] = useState([]);
    // add columns which you wonna add in table
    const columns = [
        { title: "Name", dataIndex: "reportName" },
        { title: "Type", dataIndex: "type" },
        {
            title: "Test Include", dataIndex: "testName",
            render: (_, { testName }) => {
                testName = testName.filter((e)=>e!=="")
                return (
                    testName.map((test) => {
                        return (
                            <Tag color={"green"} key={test} >
                                {test}
                            </Tag>
                        )
                    })
                )
            }
        },
        {
            title: 'Action',
            width: 200,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        {
                            (row.data.isApproved === "0") &&
                            <Tooltip title="Approve">
                                <span className="approve" onClick={() => modalClickAction('open', 'approve', row.data)}>
                                    <img src={Approve} alt={Approve} />
                                </span>
                            </Tooltip>
                        }
                        {
                            (row.data.isApproved === "0") &&
                            <Tooltip title="Reject">
                                <span className="reject" onClick={() => modalClickAction('open', 'reject', row.data)}>
                                    <img src={Reject} alt={Reject} />
                                </span>
                            </Tooltip>
                        }
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        {
                            row.data.isApproved !== "1" &&
                            <Tooltip title="Delete">
                                <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                    <img src={Delete} alt={Delete} />
                                </span>
                            </Tooltip>
                        }
                    </div>
                )
            },
        },
    ]

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (templates?.data?.length > 0) {
            templates.data.map((v, i) => {
                let testArr = [];
                v.details && v.details.map((ele) => {
                    ele.testList && ele.testList.map((test) => {
                        testArr.push(test.testName);
                    })
                })
                let obj = {
                    index: i,
                    key: v._id,
                    reportName: v.reportName ? v.reportName : '-',
                    testName: testArr,
                    data: v,
                    type: v.type ? v.type : "-"
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [templates.data])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    }

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} sticky={true} rowSelection={rowSelection} />
            <Pagination
                total={templates.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={pageSize}
                current={page}
                onChange={(page, pageSize) => { setPage(page); setPageSize(pageSize); }}
            />
        </>
    )
}