import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { SPECIALTY_LIST } from "./types";

//search and list specialty
export const searchSpecialty = (search) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.specialty.search}`,
            data: search
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: SPECIALTY_LIST,
                payload: {
                    count: response.data.count,
                    data: response.data.data,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//add specialty
export const addSpecialty = (data, specialty) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.specialty.change}`,
            data: data,
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...specialty.data];
            let temp = mainArray.some(v => v._id === response.data._id);
            if (!temp) {
                mainArray.push(response.data);
            } else {
                let index = mainArray.findIndex(v => v._id === response.data._id);
                mainArray[index] = response.data
            }
            dispatch({
                type: SPECIALTY_LIST,
                payload: {
                    count: specialty.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete specialty
export const deleteSpecialty = (data, specialty) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.specialty.delete}`,
            data: data,
        }).then((response) => {
            let mainArray = [...specialty.data];
            let index = mainArray?.findIndex(v => v._id === response.data._id);
            if (index >= 0) {
                mainArray?.splice(index, 1);
            }
            resolve(response.data)
            dispatch({
                type: SPECIALTY_LIST,
                payload: {
                    count: specialty.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//fetch list aliaseSpecialty
export const fetchAliaseSpeciality = (body) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.specialty.get}`,
            data: { ...body },
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error)
        });
    });
}