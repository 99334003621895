import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import TableTitle from '../../../Components/TableTitle';
import FormInput from '../../../Components/FormInput';
import Button from '../../../Components/Button';
import { ExtraSubMenuNotifications } from '../../Common';
import SmsTemplateList from './SmsTemplateList';
import { addSmsTemplates, deleteSmsTemplates, fetchSmsTemplatesList, updateSmsTemplates } from '../../../actions/smsTemplate';
import { closeModal, openModal } from '../../../actions/modal';
import SmsTemplateAddModal from './SmsTemplateAddModal';
import { emptyField, removeMainLoaderClass } from '../../../common';
import Modal from '../../../Components/Modal';
import DiseaseModals from '../../Diseases/DiseaseModals';
import { toast } from 'react-toastify';

export default function SmsTemplate({ activeSubMenu, setActiveSubMenu }) {

    const { smsTemplates = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [modalAction, setModalAction] = useState('');
    const [templateId, setTemplateId] = useState(null);
    const [showBtnLoader, setShowBtnLoader] = useState('');
    const [smsData, setSmsData] = useState({
        "name": '',
        "trigger": '',
        "tempId": '',
        "category":"",
        "tempBody": '',
    });
    const [error, setError] = useState({});

    const resetForm = () => {
        setSmsData({
            "name": '',
            "trigger": '',
            "tempId": '',        
            "category":"",
            "tempBody": '',
        });
        setError({});
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        switch (action) {
            case 'add':
                break;
            case 'update':
                setTemplateId(data._id);
                let changedField = { ...smsData };
                changedField['name'] = data.name ? data.name : '';
                changedField['trigger'] = data.key_alias ? data.key_alias : '';
                changedField['tempId'] = data.templateid ? data.templateid : '';
                changedField['tempBody'] = data.templatebody ? data.templatebody : '';
                changedField['category'] = data.category ? data.category : '';
                setSmsData({ ...changedField });
                break;
            case 'delete':
                setTemplateId(data._id);
                let changed = { ...smsData };
                changed['name'] = data.name ? data.name : '';
                setSmsData({ ...changed });
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    }

    // Dispatching the action
    const dispatchAction = (action) => {
        let payload = {
            name: smsData.name,
            key_alias: smsData.trigger,
            templateid: smsData.tempId,            
            category: smsData.category,
            templatebody: smsData.tempBody,
        };
        let customError = {}
        if (action === "add" || action === "update") {
            if (emptyField(payload.name)) {
                customError.name = "Template name is required";
            }
            if (emptyField(payload.key_alias)) {
                customError.trigger  = "Trigger key is required";
            }
            if (emptyField(payload.templateid)) {
                customError.tempId = "Template Id is required";
            }
            if (emptyField(payload.category)) {
                customError.category  = "Trigger category is required";
            }
            if (emptyField(payload.templatebody)) {
                customError.tempBody = "Template Body is required";
            }
        }
        setError({ ...customError })
        if (Object.keys(customError).length === 0) {
            switch (action) {
                case 'add':
                    payload = {
                        docs: [
                            payload
                        ]
                    }
                    setShowBtnLoader('show-button-loader')
                    dispatch(addSmsTemplates(payload, smsTemplates)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                        toast.success('Template has been added.')
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                    break;
                case 'update':
                    if (templateId) {
                        setShowBtnLoader('show-button-loader')
                        dispatch(updateSmsTemplates(templateId, payload, smsTemplates)).then(() => {
                            setShowBtnLoader('');
                            modalClickAction('close');
                            toast.success('Template has been updated.')
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    }
                    break;
                case 'delete':
                    if (templateId) {
                        setShowBtnLoader('show-button-loader')
                        dispatch(deleteSmsTemplates(templateId, smsTemplates)).then(() => {
                            setShowBtnLoader('');
                            modalClickAction('close');
                            toast.success('Template has been deleted.')
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    }
                    break;
                default:
            }
        }
    };

    useEffect(() => {
        if (activeSubMenu) {
            setLoading(true);
            dispatch(fetchSmsTemplatesList()).then(() => {
                setLoading(false);
                removeMainLoaderClass();
            }).catch(() => {
                setLoading(false);
                removeMainLoaderClass();
            });
        }
    }, [activeSubMenu])

    const [temps, setTemps] = useState([]);
    useEffect(() => {
        if (smsTemplates.length > 0) {
            setTemps([...smsTemplates]);
        } else {
            setTemps([]);
        }
    }, [smsTemplates])

    useEffect(() => {
        if (smsTemplates?.length > 0) {
            if (search !== "") {
                let arr = [];
                smsTemplates?.forEach((v) => {
                    if (v.name.toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setTemps([...arr])
            } else {
                setTemps([...smsTemplates])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                        <div className="adminTableHeader">
                            <TableTitle title={"SMS Template List"} />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenuNotifications}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setSearch(''); }}
                                        placeholder="Select Status"
                                        value={ExtraSubMenuNotifications.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table notifications">
                            <SmsTemplateList
                                temps={temps}
                                modalClickAction={modalClickAction}
                                dispatch={dispatch}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <div className="medium-modal add-template-editor">
                {
                    ['add', 'update'].includes(modalAction) &&
                    <Modal
                        type="center"
                        ModalTitle={modalAction === "add" ? "Add SMS Template" : "Edit SMS Template"}
                        onClose={() => modalClickAction('close')}
                        Modalclass={(modal && ['add', 'update'].includes(modalAction)) ? 'show-modal' : ''}
                    >
                        <SmsTemplateAddModal
                            smsData={smsData}
                            setSmsData={setSmsData}
                            error={error}
                            setError={setError}
                            activeSubMenu={activeSubMenu}
                            modal={modal}
                            modalAction={modalAction}
                            modalClickAction={modalClickAction}
                            resetForm={resetForm}
                            showBtnLoader={showBtnLoader}
                            dispatchAction={dispatchAction}
                        />
                        <div className="activeButton right">
                            <Button
                                title="Reset"
                                buttonType="reset"
                                onClick={() => resetForm()}
                            />
                            <Button
                                loaderClass={['add', 'update'].includes(modalAction) && showBtnLoader ? showBtnLoader : ""}
                                title={modalAction === "add" ? "Add" : "Edit"}
                                onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                            />
                        </div>
                    </Modal>
                }
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={smsData.name}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
        </>
    )
}