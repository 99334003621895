import React, { useEffect } from "react";
import './style.scss';
import logo from '../../assets/images/Logo.svg';
import smalllogo from '../../assets/images/doccineLogo.png';
import profileicon from '../../assets/images/profile-img.svg';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONSTANT_ROUTES } from "../../config/constantRoutes";
import { adminLogout } from "../../actions/auth";

export default function Header({ leftClick, LeftArrowClick }) {

    const { auth = [] } = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickLogout = () => {
        dispatch(adminLogout());
    }

    useEffect(() => {
        if (auth?.role?.includes('ADMIN') && auth?.token) {
        } else navigate(CONSTANT_ROUTES.admin.login)
        //eslint-disable-next-line
    }, [auth])

    return (
        <div className="adminHeader">
            <div className="logo-arrow">
                <div className="logo">
                    <img className="big-logo" src={logo} alt={logo} />
                    <img className="small-logo" src={smalllogo} alt={logo} />
                </div>
                <div className="slide-in-arrow" onClick={() => leftClick()}>
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="menu-fold" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 000 13.8z"></path>
                    </svg>
                </div>
            </div>

            <div className="logout">
                <div className="profile-drop">
                    <span className="profile-img">
                        <img src={profileicon} alt="img" />
                    </span>
                    <div className="dropdown-menu">
                        <div className="dropdown-item">
                            <div className="avatar avatar-md avatar-indicators avatar-online">
                                <img alt="avatar" src={profileicon} className="rounded-circle" />
                            </div>
                            <div className="profile-detail">
                                <h5>Herin Shah</h5>
                                <p title="Herin@kamaldhari.com">Herin@kamaldhari.com</p>
                            </div>
                        </div>
                        <div className="dropdown-menu-list">
                            <ul className="list-unstyled">
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        Profile
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                        Settings
                                    </span>
                                </li>
                                <li>
                                    <span className="logout-click" onClick={() => onClickLogout()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-power"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>
                                        Logout
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
