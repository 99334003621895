import { Skeleton, TimePicker } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { adminOnBoardVideoLinkListOne, adminOnBoardVideoLinkUpsert } from "../../actions/onBoardVideo";
import { removeMainLoaderClass } from "../../common";
import Button from "../../Components/Button";
import FormInput from "../../Components/FormInput";
import TableTitle from "../../Components/TableTitle";
import './style.scss';
import Select from "react-select";
import { listPricingPlanName } from "../../actions/pricingPlan";

export default function PricingPlanSettings() {

    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [showBtnLoader, setShowBtnLoader] = useState('');
    const [showBtnLoader2, setShowBtnLoader2] = useState('');
    const [showBtnLoader3, setShowBtnLoader3] = useState('');
    const [showBtnLoader4, setShowBtnLoader4] = useState('');

    //expire plan days left
    const [days, setDays] = useState("");
    const [daysInt, setDaysInt] = useState("");
    const [timeInt, setTimeInt] = useState("07:00 AM");

    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [percentage, setPercentage] = useState("");
    const [percentageInt, setPercentageInt] = useState("");    

    const [planId, setPlanId] = useState('');
    const [planIdError, setPlanIdError] = useState('');
    const [list, setList] = useState([]);
    const [noOfDoctor, setNoOfDoctor] = useState(0);
    const [noOfDays, setNoOfDays] = useState(0);

    //tax details state
    const [cgst, setCgst] = useState('');
    const [sgst, setSgst] = useState('');
    const [igst, setIgst] = useState('');

    useEffect(() => {
        setLoading(true);
        dispatch(adminOnBoardVideoLinkListOne("PRICING_PLAN_INVOICE_DETAILS")).then((res) => {
            setLoading(false);
            if (res && res[0] && res[0].value) {
                setName(res[0].value.name)
                setContact(res[0].value.contact)
            }
        }).catch(() => {
            setLoading(false);
        });
        setLoading2(true);
        dispatch(adminOnBoardVideoLinkListOne("PRICING_PLAN_TRIGGER_POINT")).then((res) => {
            setLoading2(false);
            if (res && res[0] && res[0].value) {
                setPercentage(res[0]?.value?.trigger ? res[0]?.value?.trigger : "");
                setPercentageInt(res[0]?.value?.interval ? res[0]?.value?.interval : "");
            }
        }).catch(() => {
            setLoading2(false);
        });
        setLoading3(true);
        dispatch(adminOnBoardVideoLinkListOne("PRICING_PLAN_TAX_LIST")).then((res) => {
            setLoading3(false);
            if (res.length > 0) {
                if (res[0].value) {
                    setSgst(res[0].value.SGST);
                    setCgst(res[0].value.CGST);
                    setIgst(res[0].value.IGST);
                } else {
                    setSgst('');
                    setCgst('');
                    setIgst('');
                }
            }
        }).catch(() => {
            setLoading3(false);
        });
        //expire plan days left for notifications
        setLoading4(true);
        dispatch(adminOnBoardVideoLinkListOne("PLAN_EXPIRES_TRIGGER_NOTIFICATIONS")).then((res) => {
            setLoading4(false);
            if (res && res[0] && res[0].value) {
                setDays(res[0]?.value?.remaining ? res[0]?.value?.remaining : "")
                setDaysInt(res[0]?.value?.interval ? res[0]?.value?.interval : "")
                setTimeInt(res[0]?.value?.time ? res[0]?.value?.time : "")
            }
        }).catch(() => {
            setLoading4(false);
        });
        removeMainLoaderClass();
    }, [])

    //submit the trigger number
    const updateTriggerPoint = () => {
        let payload = {
            key: "PRICING_PLAN_TRIGGER_POINT",
            value: { trigger: percentage, interval: percentageInt },
            group: "PRICING_PLAN_TRIGGER_POINT"
        }
        setShowBtnLoader2('show-button-loader');
        dispatch(adminOnBoardVideoLinkUpsert(payload)).then(() => {
            setShowBtnLoader2('');
            toast.success("Quota Remaining Trigger updated.")
        }).catch(() => {
            setShowBtnLoader2('')
        })
    }

    //submit invoice details
    const updateInvoiceDetails = () => {
        let payload = {
            key: "PRICING_PLAN_INVOICE_DETAILS",
            value: {
                name: name,
                contact: contact,
            },
            group: "PRICING_PLAN_INVOICE_DETAILS"
        }
        setShowBtnLoader('show-button-loader');
        dispatch(adminOnBoardVideoLinkUpsert(payload)).then(() => {
            setShowBtnLoader('');
            toast.success("Invoice details updated.")
        }).catch(() => {
            setShowBtnLoader('')
        })
    }

    //tax update fcuntion
    const updatePricingPlanTax = () => {
        let payload = {
            key: "PRICING_PLAN_TAX_LIST",
            value: {
                CGST: cgst,
                SGST: sgst,
                IGST: igst
            },
            group: "PRICING_PLAN_TAX_LIST"
        }
        setShowBtnLoader3('show-button-loader');
        dispatch(adminOnBoardVideoLinkUpsert(payload)).then(() => {
            setShowBtnLoader3('');
            toast.success("Tax list updated.")
        }).catch(() => {
            setShowBtnLoader3('')
        })
    }

    //submit the expires days left
    const updatePlanExpireDaysLeft = () => {
        let payload = {
            key: "PLAN_EXPIRES_TRIGGER_NOTIFICATIONS",
            value: { remaining: days, interval: daysInt, time: timeInt },
            group: "PLAN_EXPIRES_TRIGGER_NOTIFICATIONS"
        }
        setShowBtnLoader4('show-button-loader');
        dispatch(adminOnBoardVideoLinkUpsert(payload)).then(() => {
            setShowBtnLoader4('');
            toast.success("Plan expirining days left updated.");
        }).catch(() => {
            setShowBtnLoader4('')
        })
    }

    const listPricingList = () => {
        setLoading(true);
        dispatch(listPricingPlanName({ planType: "PLAN" })).then((res) => {
            setLoading(false);
            console.log(res);
            let arrPlan = [];
            res.records.map((p) => {
                arrPlan.push({value: p._id, label: p.planName });
            })
           console.log(arrPlan,"arrPlan")
            setList(arrPlan);
            removeMainLoaderClass();
        }).catch(() => {
            removeMainLoaderClass();
        })
    }

    useEffect(() => {
        setLoading(true);
        dispatch(adminOnBoardVideoLinkListOne("DEFAULT_PRICING_PLAN")).then((res) => {
            setLoading(false);
            removeMainLoaderClass();
            if (res && res[0] && res[0].value) {
                setPlanId(res[0].value.planId)
                setNoOfDoctor(res[0].value.noOfDoctor)
                setNoOfDays(res[0]?.value?.noOfDays !== "" ? res[0].value.noOfDays : noOfDays)
            }
        });
    }, [])

    const updateDeafultPricingPlan = () => {
        if (noOfDays && noOfDays && planId) {
            let payload = {
                key: "DEFAULT_PRICING_PLAN",
                value: {
                    planId: planId,
                    noOfDoctor: noOfDoctor,
                    noOfDays: noOfDays,
                },
                group: "DEFAULT_PRICING_PLAN"
            }
            setShowBtnLoader('show-button-loader');
            dispatch(adminOnBoardVideoLinkUpsert(payload)).then(() => {
                setShowBtnLoader('');
            }).catch(() => {
                setShowBtnLoader('')
            })
        }
    }

    //listing api
    useEffect(() => {
        listPricingList();
    }, [])

    return (
        <>
            <div className="pricing-setting">
                <div className="adminTable">
                    <Skeleton active loading={loading} paragraph={{ rows: 2 }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Invoice Details" />
                        </div>
                        <FormInput
                            inputType="text"
                            placeholder="Enter value"
                            title="Name"
                            name="name"
                            onChange={(e) => { setName(e.target.value); }}
                            value={name}
                        />
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">Contact</div></label>
                            </div>
                            <div className="usernumber formField">
                                <PatternFormat
                                    format="##########"
                                    mask="_"
                                    name="contact"
                                    placeholder="Enter value"
                                    id="contact"
                                    onChange={(e) => setContact(e.target.value)}
                                    value={contact}
                                />
                            </div>
                        </div>
                        <div className="activeButton right">
                            <Button
                                loaderClass={showBtnLoader ? showBtnLoader : ""}
                                title="Update"
                                onClick={(e) => { e.preventDefault(); updateInvoiceDetails() }}
                            />
                        </div>
                    </Skeleton>
                </div>
                <div className="adminTable">
                    <Skeleton active loading={loading2} paragraph={{ rows: 1 }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Quota Remaining Triggers Notifications" />
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">Quota Finished Trigger (in %)</div></label>
                            </div>
                            <div className="usernumber formField">
                                <NumericFormat
                                    name="percentage"
                                    placeholder="Enter value"
                                    id="percentage"
                                    onChange={(e) => setPercentage(e.target.value)}
                                    value={percentage}
                                />
                            </div>
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">Interval (in Days)</div></label>
                            </div>
                            <div className="usernumber formField">
                                <NumericFormat
                                    name="percentageInt"
                                    placeholder="Enter value"
                                    id="percentageInt"
                                    onChange={(e) => setPercentageInt(e.target.value)}
                                    value={percentageInt}
                                />
                            </div>
                        </div>
                        <div className="activeButton right">
                            <Button
                                loaderClass={showBtnLoader2 ? showBtnLoader2 : ""}
                                title="Update"
                                onClick={(e) => { e.preventDefault(); updateTriggerPoint() }}
                            />
                        </div>
                    </Skeleton>
                </div>
                <div className="adminTable">
                    <Skeleton active loading={loading3} paragraph={{ rows: 3 }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Tax" />
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">CGST (in %)</div></label>
                            </div>
                            <div className="usernumber formField">
                                <PatternFormat
                                    format="##"
                                    name="percentage"
                                    placeholder="Enter value"
                                    id="percentage"
                                    onChange={(e) => setCgst(e.target.value)}
                                    value={cgst}
                                />
                            </div>
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">SGST (in %)</div></label>
                            </div>
                            <div className="usernumber formField">
                                <PatternFormat
                                    format="##"
                                    name="percentage"
                                    placeholder="Enter value"
                                    id="percentage"
                                    onChange={(e) => setSgst(e.target.value)}
                                    value={sgst}
                                />
                            </div>
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">IGST (in %)</div></label>
                            </div>
                            <div className="usernumber formField">
                                <PatternFormat
                                    format="##"
                                    name="percentage"
                                    placeholder="Enter value"
                                    id="percentage"
                                    onChange={(e) => setIgst(e.target.value)}
                                    value={igst}
                                />
                            </div>
                        </div>
                        <div className="activeButton right">
                            <Button
                                loaderClass={showBtnLoader3 ? showBtnLoader3 : ""}
                                title="Update"
                                onClick={(e) => { e.preventDefault(); updatePricingPlanTax() }}
                            />
                        </div>
                    </Skeleton>
                </div>
                <div className="adminTable">
                    <Skeleton active loading={loading4} paragraph={{ rows: 2 }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Plan Expiring Notifications" />
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">Before Expired (in days)</div></label>
                            </div>
                            <div className="usernumber formField">
                                <NumericFormat
                                    name="days"
                                    placeholder="Enter value"
                                    id="days"
                                    onChange={(e) => setDays(e.target.value)}
                                    value={days}
                                />
                            </div>
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">Interval (in Days)</div></label>
                            </div>
                            <div className="usernumber formField">
                                <NumericFormat
                                    name="daysInt"
                                    placeholder="Enter value"
                                    id="daysInt"
                                    onChange={(e) => setDaysInt(e.target.value)}
                                    value={daysInt}
                                />
                            </div>
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">Time</div></label>
                            </div>
                            <div className="usernumber formField">
                                <TimePicker
                                    clearIcon={false}
                                    value={moment(timeInt, "hh:mm A")}
                                    format="hh:mm A"
                                    onChange={(time, timeString) => setTimeInt(timeString)}
                                />
                            </div>
                        </div>
                        <div className="activeButton right">
                            <Button
                                loaderClass={showBtnLoader4 ? showBtnLoader4 : ""}
                                title="Update"
                                onClick={(e) => { e.preventDefault(); updatePlanExpireDaysLeft() }}
                            />
                        </div>
                    </Skeleton>
                </div>
                <div className="adminTable">
                    <Skeleton active loading={loading4} paragraph={{ rows: 2 }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Default Plan" />
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">Default trial plan</div></label>
                            </div>
                            <div className="usernumber formField">
                                <Select
                                    isSearchable={false}
                                    isClearable={false}
                                    required="required"
                                    classNamePrefix="react-select"
                                    options={list}
                                    placeholder="Select value"
                                    onChange={(e) => { setPlanId(e.value); setPlanIdError('') }}
                                    value={list.length > 0 && list.filter((option) => planId === option.value)}
                                />
                                {planIdError && <div className="actionField errorBox">{planIdError}</div>}
                            </div>
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">No. of trial days</div></label>
                            </div>
                            <div className="usernumber formField">
                                <NumericFormat
                                    name="noOfDays"
                                    placeholder="Enter value"
                                    id="noOfDays"
                                    onChange={(e) => setNoOfDays(e.target.value)}
                                    value={noOfDays}
                                />
                            </div>
                        </div>
                        <div className="contactformField">
                            <div className="ex-label">
                                <label><div className="feildRequired">No of doctors</div></label>
                            </div>
                            <div className="usernumber formField">
                                <NumericFormat
                                    name="noOfDoctor"
                                    placeholder="Enter value"
                                    id="noOfDoctor"
                                    onChange={(e) => setNoOfDoctor(e.target.value)}
                                    value={noOfDoctor}
                                />
                            </div>
                        </div>
                        <div className="activeButton right">
                            <Button
                                loaderClass={showBtnLoader4 ? showBtnLoader4 : ""}
                                title="Update"
                                onClick={(e) => { e.preventDefault(); updateDeafultPricingPlan(); }}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
        </>
    )
}