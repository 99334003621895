import React from "react";
import './style.scss';
import pagenotfound from '../../assets/images/pagenotfound.png';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { removeMainLoaderClass } from "../../common";

export default function PageNotFound(props) {

    const navigate = useNavigate();

    useEffect(() => {
        removeMainLoaderClass();
    }, [])

    return (
        <div className="main-wrapper">
            <div className="PageNotFound">
                <div className="pagenot-img">
                    <img src={pagenotfound} alt={pagenotfound} />
                </div>
                <div className="pagenot-content">
                    <h4>We couldn't find the page you looking for.</h4>
                    <span className="gotohome" onClick={() => navigate(-1)}>Go Back</span>
                </div>
            </div>
        </div>
    )
}

