import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { DEGREE_LIST } from "./types";

//list of degree
export const listDegree = (search, pagination = {}) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.degree.list}`,
            data: {
                search: search,
                pagination
            },
        }).then((response) => {
            resolve(response)
            dispatch({
                type: DEGREE_LIST,
                payload: {
                    count: response.data.count,
                    data: response.data.data
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//add Degree
export const addDegree = (data, degree) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.degree.add}`,
            data: data,
        }).then((response) => {
            resolve(response.data[0])
            let mainArray = [...degree.data];
            let tmp = mainArray.some(v => v._id === response.data[0]._id);
            if (!tmp) {
                mainArray.unshift(response.data[0]);
            }
            dispatch({
                type: DEGREE_LIST,
                payload: {
                    count: degree.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update Degree
export const updateDegree = (data, degree) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.degree.update}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...degree.data]
            let index = mainArray?.findIndex(v => v._id === response.data._id);
            if (index >= 0) {
                mainArray.splice(index, 1, response.data)
            }
            dispatch({
                type: DEGREE_LIST,
                payload: {
                    count: degree.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete degree
export const deleteDegree = (id, degree) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.degree.delete}/${id}`,
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...degree.data]
            let index = mainArray?.findIndex(v => v._id === response.data._id);
            if (index >= 0) {
                mainArray.splice(index, 1);
            }
            dispatch({
                type: DEGREE_LIST,
                payload: {
                    count: degree.count,
                    data: mainArray,
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

