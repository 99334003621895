import React from "react";
import './style.scss';

export default function ManageSettingRadio({ id, isChecked, settingimg, title, value, defaultValue, inputType, isDisabled, isReadonly, onChange, onKeyUp, onKeyDown, name }) {
	return (
		<div className="MamageSettingRadio">
			<div className="formField radio">
				<input className="formControl"
					value={(value ? value : defaultValue)}
					type={inputType}
					disabled={isDisabled}
					readOnly={isReadonly}
					onChange={onChange}
					onKeyUp={onKeyUp}
					onKeyDown={onKeyDown}
					name={name}
					checked={isChecked}
					id={id}
					required
				/>
				<label htmlFor={id}>
					<span className="setting-img">
						<img src={settingimg} alt ='settingimg' />
					</span>
					<span className="title">{title}</span>
					{
						isChecked === true ?
							<div className="select">
								<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6.26784 12.4441L0.957838 7.13409C0.804746 6.98089 0.71875 6.77317 0.71875 6.55659C0.71875 6.34001 0.804746 6.13229 0.957838 5.97909L2.11284 4.82309C2.26604 4.67 2.47376 4.584 2.69034 4.584C2.90692 4.584 3.11464 4.67 3.26784 4.82309L6.84484 8.40009L14.5058 0.739088C14.659 0.585996 14.8668 0.5 15.0833 0.5C15.2999 0.5 15.5076 0.585996 15.6608 0.739088L16.8188 1.89509C16.9719 2.04829 17.0579 2.25601 17.0579 2.47259C17.0579 2.68917 16.9719 2.89689 16.8188 3.05009L7.41884 12.4441C7.2658 12.596 7.05894 12.6812 6.84334 12.6812C6.62773 12.6812 6.42087 12.596 6.26784 12.4441Z" fill="#17C403" />
								</svg>
								Selected
							</div>
							:
							<div className="select">
								Select
							</div>
					}
				</label>
			</div>
		</div>
	);
}