import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AdminSidebar from "../Components/AdminSidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CONSTANT_ROUTES } from "../config/constantRoutes";
import { useSelector } from "react-redux";
import { MENUITEMS } from "../Components/AdminSidebar/menu";
import './style.scss';

export default function DefaultLayout({ children, sidebar, isFooter, noNavbar, noFooter }) {

    const { auth = [] } = useSelector((state) => state);
    const location = useLocation();

    const [active, setActive] = useState([]);

    useEffect(() => {
        let arrLoca = location?.pathname?.split('/');
        if (arrLoca && arrLoca[1]) {
            let obj = MENUITEMS.find(v => v.subMenu && v.path.split('/')[1] === arrLoca[1]);
            if (obj) {
                setActive([...obj.subMenu]);
            } else {
                setActive([]);
            }
        }
    }, [location])

    const navigate = useNavigate();
    const [LeftArrowClick, setLeftArrowClick] = useState(false);
    const leftClick = () => {
        setLeftArrowClick(!LeftArrowClick)
    }

    useEffect(() => {
        if (auth?.role?.includes('ADMIN') && auth?.token) {
        } else navigate(CONSTANT_ROUTES.admin.login)
        //eslint-disable-next-line
    }, [auth])

    return (
        <>
            <div className={`adminDashboard  ${LeftArrowClick ? 'leftHide' : ''} ${!sidebar && 'hide-sidebar'}`}>
                <Header leftClick={leftClick} LeftArrowClick={LeftArrowClick} />
                {sidebar &&
                    <AdminSidebar />
                }
                <div className={`header-mainscreen ${active.length > 0 ? "submenu-available" : ""}`}>
                    {
                        active.length > 0 &&
                        <ul className="submenu-tabination">
                            {
                                active.map((v, i) => {
                                    return (
                                        <li key={i} className={location?.pathname?.split('/')[2] === v.path.split('/')[2] ? "active" : ""}>
                                            <Link to={v.path}>
                                                <span>{v.title}</span>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }
                    {children}
                    <Footer />
                </div>

                {/* {isFooter && <Footer />} */}
            </div>
        </>
    );
};

