import { Tooltip } from "antd";
import React from "react";
import InfoIcon from "../../assets/images/info-icon.svg"
import './style.scss';

export default function TableTitle({ title, subtitle, className, OPDmessage, otherMessage, ...attrs }) {
  return (
    <div className="tableTitle">
      <h2>{title}
        {
          OPDmessage &&
          <Tooltip title={OPDmessage}>
            <span className="info-icon">
              <img src={InfoIcon} alt={InfoIcon} />
            </span>
          </Tooltip>
        }
        {
          otherMessage &&
          <Tooltip title={otherMessage}>
            <span className="info-icon">
              <img src={InfoIcon} alt={InfoIcon} />
            </span>
          </Tooltip>
        }
      </h2>
    </div>
  )
};

