import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";
import { RECEPTIONIST_LIST } from "./types";

//receptionist list api
export const fetchReceptionistList = (pagination = {}, filter = {}, search = {}, projection = [], expandSearch = true) => async (dispatch) => {
    let body = { pagination, filter, projection, expandSearch: expandSearch, search };
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'post',
            url: `${API_URL.receptionist.get}`,
            data: { ...body },
        }).then((response) => {
            resolve(response.data)
            dispatch({
                type: RECEPTIONIST_LIST,
                payload: {
                    userCount: response.count,
                    userData: response.data
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

// add receptionist api
export const addReceptionist = (data, receptionist) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.receptionist.add}`,
            data: data
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...receptionist.userData];
            let tmp = mainArray.some(v => v._id === response.data._id);
            if (!tmp) {
                mainArray.push(response.data);
            }
            dispatch({
                type: RECEPTIONIST_LIST,
                payload: {
                    userCount: receptionist.userCount + 1,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//update receptionist api
export const updateReceptionist = (receptionistId, body, receptionist) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.receptionist.update}/${receptionistId}`,
            data: body,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...receptionist.userData]
            let tmp = mainArray.findIndex(v => v._id === receptionistId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data)
            }
            dispatch({
                type: RECEPTIONIST_LIST,
                payload: {
                    userCount: receptionist.userCount,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete receptionist api
export const deleteReceptionist = (receptionistId, receptionist) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "DELETE",
            url: `${API_URL.receptionist.delete}`,
            data: { "userIds": [receptionistId] },
        }).then((response) => {
            resolve(response)
            let mainArray = [...receptionist.userData];
            let tmp = mainArray.findIndex(v => v._id === receptionistId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1);
            }
            dispatch({
                type: RECEPTIONIST_LIST,
                payload: {
                    userCount: receptionist.userCount - 1,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//receptionists analytics data
export const receptionistAnalytics = async () => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.receptionist.analytics}`,
        }).then((response) => {
            resolve(response.data)
            return response.data;
        }).catch((error) => {
            reject(error);
        });
    });
}

//approve and reject receptionist api
export const approvalReceptionist = (receptionistId, data, receptionist) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.receptionist.approval}`,
            data: data,
        }).then((response) => {
            resolve(response.data);
            let mainArray = [...receptionist.userData];
            let tmp = mainArray.findIndex(v => v._id === receptionistId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data[0])
            }
            dispatch({
                type: RECEPTIONIST_LIST,
                payload: {
                    userCount: receptionist.userCount,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//disable and enable receptionist api
export const disableEnableReceptionist = (receptionistId, data, receptionist) => (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: "PUT",
            url: `${API_URL.receptionist.blocking}`,
            data: data,
        }).then((response) => {
            resolve(response.data)
            let mainArray = [...receptionist.userData];
            let tmp = mainArray.findIndex(v => v._id === receptionistId);
            if (tmp >= 0) {
                mainArray.splice(tmp, 1, response.data[0]);
            }
            dispatch({
                type: RECEPTIONIST_LIST,
                payload: {
                    userCount: receptionist.userCount,
                    userData: mainArray
                }
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

//send verification email on email change
export const sendVerificationEmail = (userId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.receptionist.verificationEmail}/${userId}`,
        }).then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

//disable and enable receptionist api
// export const goToreceptionistsettings = (receptionistId) => (dispatch) => {
//     return new Promise((resolve, reject) => {
//         apiClient({
//             method: "GET",
//             url: `${API_URL.receptionist.gotoSetting}/${receptionistId}`,
//         }).then((response) => {
//             resolve(response.data)
//         }).catch((error) => {
//             reject(error);
//         });
//     });
// }
