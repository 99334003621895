import { API_URL } from "../api/apiRoutes";
import { apiClient } from "../api/general";

//add whatsnew images
export const addWhatsnewImages = (body) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'POST',
            url: `${API_URL.whatsNew.insert}`,
            data: body
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error);
        });
    });
}

//update whatsnew images
export const updateWhatsnewImages = (body, id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'PUT',
            url: `${API_URL.whatsNew.update}/${id}`,
            data: body
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error);
        });
    });
}

//list whatsnew images
export const ListWhatsnewImages = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'GET',
            url: `${API_URL.whatsNew.list}`,
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error);
        });
    });
}

//delete whatsnew images
export const deleteWhatsnewImages = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        apiClient({
            method: 'DELETE',
            url: `${API_URL.whatsNew.delete}/${id}`,
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error);
        });
    });
}

// export const findOneWhatsnewImage = () => async (dispatch) => {
//     return new Promise((resolve, reject) => {
//         apiClient({
//             method: 'GET',
//             url: `${API_URL.whatsNew.findone}`,
//         }).then((response) => {
//             resolve(response);
//             dispatch({
//                 type: WHATS_NEW_FIND_ONE,
//                 payload: response.data
//             })
//         }).catch((error) => {
//             reject(error);
//             dispatch(setMessage(error.message));
//         });
//     });
// }