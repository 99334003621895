import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import TableTitle from '../../../Components/TableTitle';
import FormInput from '../../../Components/FormInput';
import Button from '../../../Components/Button';
import { ExtraSubMenuNotifications } from '../../Common';
import EmailTemplateList from './EmailTemplateList';
import { addEmailTemplates, deleteEmailTemplates, fetchEmailTemplatesList, getAllTriggerTypes, updateEmailTemplates } from '../../../actions/emailTemplate';
import { closeModal, openModal } from '../../../actions/modal';
import EmailTemplateAddModal from './EmailTemplateAddModal';
import { emptyField, removeMainLoaderClass } from '../../../common';
import Modal from '../../../Components/Modal';
import DiseaseModals from '../../Diseases/DiseaseModals';
import { toast } from 'react-toastify';

export default function EmailTemplate({ activeSubMenu, setActiveSubMenu }) {

    const { emailTemplates = [], modal } = useSelector((state) => state);
    const dispatch = useDispatch();

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState('');

    const [modalAction, setModalAction] = useState('');

    const [templateId, setTemplateId] = useState(null);
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const [triggerType, setTriggerType] = useState([]);

    const [mailData, setMailData] = useState({
        "name": '',
        "triggerType": '',
        "category": '',
        "subject": '',
        "type": '',
    });
    const [editor, setEditor] = useState('');

    const [error, setError] = useState({});

    const resetForm = () => {
        setMailData({
            "name": '',
            "triggerType": '',
            "category": '',
            "subject": '',
            "type": '',
        });
        setEditor('');
        setError({});
    }

    const modalClickAction = (type, action = '', data = {}) => {
        resetForm();
        switch (action) {
            case 'add':
                break;
            case 'update':
                setTemplateId(data._id);
                let changedField = { ...mailData };
                changedField['name'] = data.name ? data.name : '';
                changedField['triggerType'] = data.trigger ? data.trigger : '';
                if (activeSubMenu === "EMAIL") {
                    changedField['subject'] = data?.body?.subject ? data.body.subject : '';
                }
                changedField['category'] = data?.category ? data.category : '';
                changedField['type'] = data?.type ? data.type : '';
                setEditor(data?.body?.body ? data?.body?.body : '');
                setMailData({ ...changedField });
                break;
            case 'delete':
                setTemplateId(data._id);
                let changed = { ...mailData };
                changed['name'] = data.name ? data.name : '';
                setMailData({ ...changed });
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    }

    // Dispatching the action
    const dispatchAction = (action) => {
        const payload = {
            name: mailData.name,
            body: {
                subject: mailData.subject,
                body: editor,
            },
            type: activeSubMenu,
            trigger: mailData.triggerType,
            category: mailData.category,
        };
        if (activeSubMenu === "SMS") {
            delete payload.body.subject;
        }
        let customError = {}
        if (action === "add" || action === "update") {
            if (emptyField(payload.name)) {
                customError.name = "Template name is required";
            }
            if (emptyField(payload.trigger)) {
                customError.triggerType = "Trigger type is required";
            }
            if (emptyField(payload.category)) {
                customError.category = "Category is required";
            }
        }
        setError({ ...customError })
        if (Object.keys(customError).length === 0) {
            switch (action) {
                case 'add':
                    setShowBtnLoader('show-button-loader')
                    dispatch(addEmailTemplates(payload, emailTemplates)).then(() => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                        toast.success('Template has been added.')
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                    break;
                case 'update':
                    if (templateId) {
                        setShowBtnLoader('show-button-loader')
                        dispatch(updateEmailTemplates(templateId, payload, emailTemplates)).then(() => {
                            setShowBtnLoader('');
                            modalClickAction('close');
                            toast.success('Template has been updated.')
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    }
                    break;
                case 'delete':
                    if (templateId) {
                        setShowBtnLoader('show-button-loader')
                        dispatch(deleteEmailTemplates(templateId, emailTemplates)).then(() => {
                            setShowBtnLoader('');
                            modalClickAction('close');
                            toast.success('Template has been deleted.')
                        }).catch(() => {
                            setShowBtnLoader('');
                        });
                    }
                default:
            }
        }
    };

    useEffect(() => {
        if (activeSubMenu) {
            setLoading(true);
            dispatch(fetchEmailTemplatesList({ type: activeSubMenu })).then(() => {
                dispatch(getAllTriggerTypes()).then((res) => {
                    if (res.length > 0) {
                        let arr = []
                        res.map((v) => {
                            arr.push({ value: v._id, label: v.name, allValue: v })
                        })
                        setTriggerType([...arr]);
                    }
                })
                setLoading(false);
                removeMainLoaderClass();
            }).catch(() => {
                setLoading(false);
                removeMainLoaderClass();
            });
        }
    }, [activeSubMenu])

    const getValueFromLabelTriggerType = (id, triggerType) => {
        if (triggerType.length > 0) {
            let obj = triggerType?.find(v => v.value === id);
            if (obj?.label) {
                return obj.label;
            } else {
                return id;
            }
        } else {
            return id;
        }
    }

    const [temps, setTemps] = useState([]);

    useEffect(() => {
        if (emailTemplates.length > 0) {
            setTemps([...emailTemplates]);
        } else {
            setTemps([]);
        }
    }, [emailTemplates])

    useEffect(() => {
        if (emailTemplates?.length > 0) {
            if (search !== "") {
                let arr = [];
                emailTemplates?.map((v) => {
                    if (v.name.toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setTemps([...arr])
            } else {
                setTemps([...emailTemplates])
            }
        }
    }, [search])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                        <div className="adminTableHeader">
                            <TableTitle title={activeSubMenu === "EMAIL" ? "Email Template List" : "SMS Template List"} />
                            <div className="table-header-search">
                                <div className="table-header-dropDown">
                                    <Select
                                        classNamePrefix="react-select"
                                        options={ExtraSubMenuNotifications}
                                        name="status"
                                        onChange={(e) => { setActiveSubMenu(e.value); setSearch(''); }}
                                        placeholder="Select Status"
                                        value={ExtraSubMenuNotifications.filter((v) => v.value === activeSubMenu)}
                                    />
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table notifications">
                            <EmailTemplateList
                                temps={temps}
                                modalClickAction={modalClickAction}
                                getValueFromLabelTriggerType={getValueFromLabelTriggerType}
                                triggerType={triggerType}
                                dispatch={dispatch}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <div className="medium-modal add-template-editor">
                {
                    ['add', 'update'].includes(modalAction) &&
                    <Modal
                        type="center"
                        ModalTitle={modalAction === "add" ? (activeSubMenu === "EMAIL" ? "Add Email Template" : "Add SMS Template") : (activeSubMenu === "EMAIL" ? "Edit Email Template" : "Edit SMS Template")}
                        onClose={() => modalClickAction('close')}
                        Modalclass={(modal && ['add', 'update'].includes(modalAction)) ? 'show-modal' : ''}
                    >
                        <EmailTemplateAddModal
                            mailData={mailData}
                            setMailData={setMailData}
                            editor={editor}
                            setEditor={setEditor}
                            error={error}
                            setError={setError}
                            activeSubMenu={activeSubMenu}
                            triggerType={triggerType}
                            modal={modal}
                            modalAction={modalAction}
                            modalClickAction={modalClickAction}
                            resetForm={resetForm}
                            showBtnLoader={showBtnLoader}
                            dispatchAction={dispatchAction}
                        />
                        <div className="activeButton right">
                            <Button
                                title="Reset"
                                buttonType="reset"
                                onClick={() => resetForm()}
                            />
                            <Button
                                loaderClass={['add', 'update'].includes(modalAction) && showBtnLoader ? showBtnLoader : ""}
                                title={modalAction === "add" ? "Add" : "Edit"}
                                onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                            />
                        </div>
                    </Modal>
                }
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={mailData.name}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
        </>
    )
}