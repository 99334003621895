import { Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/Edit.svg';

export default function MultiLingualList({ speList, modalClickAction, list, groups, findValueFromArray }) {

    const [data, setData] = useState([]);

    // add columns which you wonna add in table
    const [columns, setColumns] = useState([
        { title: "No", dataIndex: "no", fixed: "left", width: 50 },
        { title: "Value", dataIndex: "value", fixed: "left", sorter: (a, b) => a.value.localeCompare(b.value) },
        {
            title: 'Action',
            width: 150,
            dataIndex: '',
            key: 'x',
            fixed: "right",
            render: (row) => {
                return (
                    <div className="action">
                        <Tooltip title="Edit">
                            <span className="edit" onClick={() => modalClickAction('open', 'update', row.data)}>
                                <img src={Edit} alt={Edit} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="delete" onClick={() => modalClickAction('open', 'delete', row.data)}>
                                <img src={Delete} alt={Delete} />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ])

    useEffect(() => {
        let arr = [...columns];
        if (list.length > 0) {
            list.map(a => {
                let length = arr.length;
                let tmp = arr.some(v => v.dataIndex === a.value);
                if (!tmp) arr.splice(length - 1, 0, { title: a.value, dataIndex: a.value })
            })
        }
        setColumns([...arr])
    }, [list])

    // set data for table which object keys come from columns dataIndex
    useEffect(() => {
        let arr = [];
        if (speList?.length > 0) {
            speList.map((v, i) => {
                let obj = {
                    no: i + 1,
                    key: v._id,
                    value: findValueFromArray(groups, v.group),
                    data: v
                }
                v.value.map((a) => {
                    obj[a.lang] = a.value ? a.value : "-";
                })
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [speList])

    return (
        <Table columns={columns} dataSource={data} pagination={false} sticky={true} scroll={{ x: 1000 }} />
    )
}