import './style.scss';
import React, { useState, useEffect, useCallback } from "react";
import ReceptionistAddModal from './ReceptionistAddModal';
import ReceptionistList from './ReceptionistList';
import ReceptionistOptionModal from './ReceptionistOptionModal';
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, debounce, emptyField, removeMainLoaderClass, validEmail } from '../../common';
import { closeModal, openModal } from '../../actions/modal';
import FormInput from '../../Components/FormInput';
import Button from '../../Components/Button';
import TableTitle from '../../Components/TableTitle';
import AdminCounter from '../../Components/AdminCounter';
import { fetchHospitalList, approvalHospital } from '../../actions/hospitals';
import { fetchReceptionistList, addReceptionist, updateReceptionist, approvalReceptionist, deleteReceptionist, disableEnableReceptionist, receptionistAnalytics, sendVerificationEmail } from '../../actions/receptionist';
import totaldoc from '../../assets/images/Total-doc.svg';
import activedoc from '../../assets/images/active.svg';
import notIndoc from '../../assets/images/notIn.svg';
import { Skeleton } from 'antd';
import { toast } from "react-toastify";

export default function ReceptionistMx(props) {

    //for skeleton
    const [loading, setLoading] = useState(false);

    const { hospitals = [], receptionist = [], modal } = useSelector((state) => state);

    const dispatch = useDispatch();
    /***
     * state used for set data for api
     */
    const [receptionistAnalyticsData, setReceptionistAnalytics] = useState({ totalReceptionist: 0, leads: 0, notInYet: 0, todayActive: 0 });

    const [receptionistFormData, setReceptionistFormData] = useState({
        "latitude": "", "longitude": "", "firstName": '', "lastName": '', "contact": '', "countryCode": "+91", "emailId": '', "hospitalIds": '', "hospitalName": '', "plotNo": "", "address": '', "address2": '', "city": '', "state": '', "country": '', "pincode": '' });

    let currentFormErrors = [];
    const [receptionistId, setReceptionistId] = useState('');
    const [FormErrors, setFormErrors] = useState(currentFormErrors);
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton1, setModalButton1] = useState('');
    const [modalButton2, setModalButton2] = useState('');
    const [modalAction, setModalAction] = useState('');

    const [moduleAccess, setModuleAccess] = useState({ create: [], view: [], edit: [], delete: [] });
    /**
     * for pagination
     */
    const [search, setSearch] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const [roles, setRoles] = useState([])

    const resetForm = () => {
        setReceptionistId('');
        setAddHospitalName('');
        currentFormErrors.firstName = ''
        currentFormErrors.lastName = ''
        currentFormErrors.contact = ''
        currentFormErrors.emailId = ''
        setFormErrors(currentFormErrors);
        setReceptionistFormData({
            "latitude": "",
            "longitude": "", "firstName": '', "lastName": '', "countryCode": "+91", "contact": '', "emailId": '', "hospitalIds": '', "hospitalName": '', "plotNo": "", "address": '', "address2": '', "city": '', "state": '', "country": '', "pincode": ''
        });
        setModuleAccess({ create: [], view: [], edit: [], delete: [] })
        setRoles([])
    };

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton1('');
        setModalButton2('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        switch (action) {
            case 'add':
                setModalTitle('Add Receptionist');
                setModalButton1('Reset');
                setModalButton2('Add Receptionist');

                break;
            case 'update':
                setModalTitle('Edit Receptionist');
                setModalButton1((action === 'update') ? 'Reset' : 'Cancel');
                setModalButton2((action === 'update') ? 'Edit Receptionist' : 'Approve');
                setReceptionistId(data._id);
                let changedField = { ...receptionistFormData };
                changedField["firstName"] = capitalizeFirstLetter(data.firstName);
                changedField["lastName"] = capitalizeFirstLetter(data.lastName);
                changedField["countryCode"] = data.countryCode;
                changedField["contact"] = data.contact;
                changedField["emailId"] = data.emailId;
                changedField["hospitalIds"] = data.hospitals.length > 0 && data.hospitals[0].hospitalId && data.hospitals[0].hospitalId._id && data.hospitals[0].hospitalId._id;
                changedField["hospitalName"] = data.hospitals.length > 0 && data.hospitals[0].hospitalId && data.hospitals[0].hospitalId.name && data.hospitals[0].hospitalId.name;
                setAddHospitalName(data.hospitals.length > 0 && data.hospitals[0].hospitalId && data.hospitals[0].hospitalId.name && data.hospitals[0].hospitalId.name);
                changedField["plotNo"] = data?.address?.permanent?.plotNo;
                changedField["city"] = data?.address?.permanent?.city;
                changedField["country"] = data?.address?.permanent?.country;
                changedField["state"] = data?.address?.permanent?.state;
                changedField["pincode"] = data?.address?.permanent?.pincode;
                changedField["address"] = data?.address?.permanent?.line1;
                changedField["address2"] = data?.address?.permanent?.line2;
                changedField["latitude"] = data?.address?.permanent?.latitude;
                changedField["longitude"] = data?.address?.permanent?.longitude;
                changedField["isVerified"] = data?.isVerified;
                changedField["isPasswordSet"] = data?.isPasswordSet;
                setReceptionistFormData({ ...receptionistFormData, ...changedField });
                if (data.hospitals.length > 0 && data.hospitals[0].roles && data.hospitals[0].roles.length > 0) {
                    let arr = [];
                    data.hospitals[0].roles.map((v) => {
                        arr.push(v.name)
                    })
                    setRoles([...arr]);
                }
                let moduleAccessView = [];
                let moduleAccessCreate = [];
                let moduleAccessEdit = [];
                let moduleAccessDelete = [];
                if (data.hospitals && data.hospitals[0] && data.hospitals[0].moduleAccess) {
                    data.hospitals[0].moduleAccess.view.length > 0 && data.hospitals[0].moduleAccess.view.forEach((v) => {
                        moduleAccessView.push(v);
                    })
                    data.hospitals[0].moduleAccess.create.length > 0 && data.hospitals[0].moduleAccess.create.forEach((v) => {
                        moduleAccessCreate.push(v);
                    })
                    data.hospitals[0].moduleAccess.edit.length > 0 && data.hospitals[0].moduleAccess.edit.forEach((v) => {
                        moduleAccessEdit.push(v);
                    })
                    data.hospitals[0].moduleAccess.delete.length > 0 && data.hospitals[0].moduleAccess.delete.forEach((v) => {
                        moduleAccessDelete.push(v);
                    })
                }
                setModuleAccess({ view: moduleAccessView, create: moduleAccessCreate, edit: moduleAccessEdit, delete: moduleAccessDelete });
                break;
            case "hospitalAdd":
                setAddHospitalName(data.name);
                break;
            case 'approve':
                let filter = { "isApproved": ["0", "-1", "1"] };
                let search = {
                    "field": "_id",
                    "value": data.hospitals.length > 0 && data.hospitals[0].hospitalId && data.hospitals[0].hospitalId._id && data.hospitals[0].hospitalId._id,
                    "strict": true
                };
                let paramSearch = search;
                dispatch(fetchHospitalList('', filter, paramSearch, true)).then(response => {
                    if (response.data && response.data.data && response.data.data[0] && response.data.data[0].isApproved === "1") {
                        setReceptionistId(data._id);
                        let changedFielddelete = { ...receptionistFormData };
                        changedFielddelete["firstName"] = capitalizeFirstLetter(data.firstName);
                        changedFielddelete["lastName"] = capitalizeFirstLetter(data.lastName);
                        setReceptionistFormData({ ...changedFielddelete });
                        setModalAction(action);
                        if (type === 'open') {
                            dispatch(openModal());
                        } else {
                            dispatch(closeModal());
                        }
                    } else {
                        let changedFielddelete = { ...receptionistFormData };
                        changedFielddelete["hospitalName"] = response.data.data[0].name;
                        changedFielddelete["firstName"] = capitalizeFirstLetter(data.firstName);
                        changedFielddelete["lastName"] = capitalizeFirstLetter(data.lastName);
                        changedFielddelete["hospitalIds"] = response.data.data[0]._id;
                        setReceptionistFormData({ ...changedFielddelete });
                        setModalAction('hospitalApprove');
                        setReceptionistId(data._id);
                        if (type === 'open') {
                            dispatch(openModal());
                        } else {
                            dispatch(closeModal());
                        }
                    }

                });
                break;
            case 'reject':
            case 'disable':
            case 'delete':
            case 'enable':
                setReceptionistId(data._id);
                let changedFielddelete = { ...receptionistFormData };
                changedFielddelete["firstName"] = capitalizeFirstLetter(data.firstName);
                changedFielddelete["lastName"] = capitalizeFirstLetter(data.lastName);
                setReceptionistFormData({ ...changedFielddelete });
                break;
            case 'changeEmail':
                setModalAction(action);
                setReceptionistId(data._id)
                dispatchAction("changeEmail",data._id)
                break;
            default:
        }
        if (action === "approve" || action === "hospitalApprove") {

        } else {
            setModalAction(action);
            if (type === 'open') {
                dispatch(openModal());
            } else {
                dispatch(closeModal());
            }
        }

    };

    const dispatchAction = (action, e) => {
        if ((action === 'add' || action === 'update')) {
            if (emptyField(receptionistFormData.firstName)) {
                currentFormErrors.firstName = "First Name is required";
            }
            if (emptyField(receptionistFormData.lastName)) {
                currentFormErrors.lastName = "Last Name is required";
            }
            // if (emptyField(receptionistFormData.emailId)) {
            //     currentFormErrors.emailId = "Email Id is required";
            // }
            if (emptyField(receptionistFormData.contact.trim())) {
                currentFormErrors.contact = "Contact is required";
            }
            if (receptionistFormData.hospitalIds === "") {
                currentFormErrors.hospitalName = "Hospital is not present";
            }
            if (receptionistFormData.hospitalIds === "" && emptyField(receptionistFormData.hospitalName)) {
                currentFormErrors.hospitalName = "Hospital is required";
            }
            if (receptionistFormData.emailId && validEmail(receptionistFormData.emailId)) {
                currentFormErrors.emailId = "EmailId is invalid";
            }
            if (receptionistFormData.contact.trim() !== "" && receptionistFormData.contact.trim().length !== 10) {
                currentFormErrors.contact = "Contact maximum length 10";
            }
            setFormErrors(currentFormErrors);
        }
        if ((Object.keys(currentFormErrors).length === 0 && (action === 'add' || action === 'update')) || action === 'delete' || action === 'hospitalApprove' || action === 'disable' || action === 'enable' || action === 'approve' || action === 'reject' || action === 'hospitalAdd' || action==="changeEmail") {
            switch (action) {
                case 'add':
                    let addPayload = {
                        firstName: capitalizeFirstLetter(receptionistFormData.firstName),
                        lastName: capitalizeFirstLetter(receptionistFormData.lastName),
                        contact: receptionistFormData.contact,
                        countryCode: receptionistFormData.countryCode,
                        emailId: receptionistFormData?.emailId?.trim(),
                        isApproved: "APPROVE",
                        "address": {
                            "permanent": {
                                "plotNo": receptionistFormData.plotNo,
                                "line1": receptionistFormData.address,
                                "line2": receptionistFormData.address2,
                                "city": receptionistFormData.city,
                                "state": receptionistFormData.state,
                                "country": receptionistFormData.country,
                                "pincode": receptionistFormData.pincode,
                                "latitude": receptionistFormData.latitude,
                                "longitude": receptionistFormData.longitude,
                            }
                        },
                    }
                    addPayload.hospitals = [{
                        hospitalId: receptionistFormData.hospitalIds,
                        roles: ["RECEPTIONIST"],
                        moduleAccess: moduleAccess,
                    }];
                    setShowBtnLoader('show-button-loader');
                    dispatch(addReceptionist(addPayload, receptionist)).then(() => {
                        LoadApiCalls(1, pageSize, search);
                        setShowBtnLoader('');
                        modalClickAction('close');
                    }).catch(() => {
                        setShowBtnLoader('');
                    });
                    break;
                case 'update':
                    let updatePayload = {
                        firstName: capitalizeFirstLetter(receptionistFormData.firstName),
                        lastName: capitalizeFirstLetter(receptionistFormData.lastName),
                        contact: receptionistFormData.contact,
                        countryCode: receptionistFormData.countryCode,
                        emailId: receptionistFormData?.emailId?.trim(),
                        "address": {
                            "permanent": {
                                "plotNo": receptionistFormData.plotNo,
                                "line1": receptionistFormData.address,
                                "line2": receptionistFormData.address2,
                                "city": receptionistFormData.city,
                                "state": receptionistFormData.state,
                                "country": receptionistFormData.country,
                                "pincode": receptionistFormData.pincode,
                                "latitude": receptionistFormData.latitude,
                                "longitude": receptionistFormData.longitude,
                            }
                        },
                    }
                    updatePayload.hospitals = [{
                        hospitalId: receptionistFormData.hospitalIds,
                        roles: roles,
                        moduleAccess: moduleAccess,
                    }];
                    setShowBtnLoader('show-button-loader');
                    dispatch(updateReceptionist(receptionistId, updatePayload, receptionist)).then(() => {
                        setShowBtnLoader('');
                        modalClickAction('close');
                    }).catch(() => {
                        setShowBtnLoader('')
                    });
                    break;
                case 'approve':
                    setShowBtnLoader('show-button-loader');
                    const approveData = { "userIds": [receptionistId], "approval": "APPROVE" }
                    dispatch(approvalReceptionist(receptionistId, approveData, receptionist)).then(() => { setShowBtnLoader(''); modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    break;
                case 'hospitalApprove':
                    let data = { "hospitalIds": [receptionistFormData.hospitalIds], "approval": "APPROVE", "noEmail": true };
                    setShowBtnLoader('show-button-loader');
                    dispatch(approvalHospital(receptionistFormData.hospitalIds, data, hospitals)).then(() => {
                        setShowBtnLoader('');
                        const approveData = { "userIds": [receptionistId], "approval": "APPROVE" }
                        dispatch(approvalReceptionist(receptionistId, approveData, receptionist)).then(() => { modalClickAction('close'); setShowBtnLoader(''); }).catch(() => setShowBtnLoader(''));
                    });
                    break;
                case 'reject':
                    setShowBtnLoader('show-button-loader');
                    const rejectData = { "userIds": [receptionistId], "approval": "REJECT" }
                    dispatch(approvalReceptionist(receptionistId, rejectData, receptionist)).then(() => { setShowBtnLoader(''); modalClickAction('close') }).catch(() => setShowBtnLoader(''));
                    break;
                case 'disable':
                    setShowBtnLoader('show-button-loader');
                    const disableData = { "userIds": [receptionistId], "block": true }
                    dispatch(disableEnableReceptionist(receptionistId, disableData, receptionist)).then(() => { setShowBtnLoader(''); modalClickAction('close') }).catch(() => setShowBtnLoader(''));
                    break;
                case 'enable':
                    setShowBtnLoader('show-button-loader');
                    const enableData = { "userIds": [receptionistId], "block": false }
                    dispatch(disableEnableReceptionist(receptionistId, enableData, receptionist)).then(() => { setShowBtnLoader(''); modalClickAction('close') }).catch(() => setShowBtnLoader(''));
                    break;
                case 'delete':
                    setShowBtnLoader('show-button-loader');
                    dispatch(deleteReceptionist(receptionistId, receptionist)).then(() => { setShowBtnLoader(''); modalClickAction('close') }).catch(() => setShowBtnLoader(''));
                    break;
                case 'changeEmail':
                    dispatch(sendVerificationEmail(e)).then(() => { toast.success('Verification email sent successfully.') }).catch(() => {toast.success('Error while sending an email.')});
                    // toast.success('Verification email sent successfully.');
                    break;
                default:
                    throw new Error('Modal action not match');
            }
        }
        typeof(e)!=="string" && e?.preventDefault();
    };

    const fetchReceptionistAnalytics = async () => {
        const data = await receptionistAnalytics();
        if (data) {
            let changeField = { ...receptionistAnalyticsData }
            changeField["totalReceptionist"] = data?.totalReceptionist;
            changeField["notInYet"] = data?.notInYet;
            changeField["todayActive"] = data?.todayActive;
            setReceptionistAnalytics({ ...changeField });
        }
    }

    useEffect(() => {
        let filter = { "filter": { "isApproved": ["1"] } };
        dispatch(fetchHospitalList({}, filter, '', true));
        //eslint-disable-next-line
    }, []);

    const [addHospitalName, setAddHospitalName] = useState('');

    const LoadApiCalls = (page, pageSize, search) => {
        let pagination = { "pageNo": page, "pageSize": pageSize };
        let filter = { "isVerified": "all", "isApproved": ["0", "-1", "1"], "isDeleted": false, "roles": ["RECEPTIONIST"] };
        let value = search;
        let paramSearch = { value };
        if (search === "") setLoading(true);
        dispatch(fetchReceptionistList(pagination, filter, paramSearch)).then(() => {
            fetchReceptionistAnalytics();
            removeMainLoaderClass();
            if (search === "") setLoading(false);
        }).catch(() => {
            removeMainLoaderClass();
            if (search === "") setLoading(false);
        });
    }

    useEffect(() => {
        LoadApiCalls(page, pageSize, search);
        //eslint-disable-next-line
    }, [page, pageSize]);

    //on change search function
    const handleSearch = (e, page, pageSize) => {
        if (e.target.value === "") {
            LoadApiCalls(page, pageSize, "");
        } else {
            LoadApiCalls(page, pageSize, e.target.value);
        }
    }

    //debounce call function for search
    const debounceHandleSearch = useCallback(debounce(handleSearch, 800), []);

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="counter">
                    <Skeleton active loading={loading}>
                        <AdminCounter number={receptionistAnalyticsData.totalReceptionist} title="Total Receptionist" img={totaldoc} />
                        <AdminCounter number={receptionistAnalyticsData.notInYet} title="Not In Yet" img={notIndoc} />
                        <AdminCounter number={receptionistAnalyticsData.todayActive} title="Today's Active" img={activedoc} />
                    </Skeleton>
                </div>
                <div className="adminTable">
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Receptionist List" />
                            <div className="table-header-search">
                                <div className="no-label table-search">
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); setPage(1); debounceHandleSearch(e, 1, pageSize) }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add New"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table doctors-table">
                            <ReceptionistList
                                receptionist={receptionist}
                                modalClickAction={modalClickAction}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            {/* Add/Edit/Approve Modal */}
            <div className="AddNew">
                <ReceptionistAddModal showBtnLoader={showBtnLoader} modal={modal} modalTitle={modalTitle} modalAction={modalAction} receptionistFormData={receptionistFormData}
                    setReceptionistFormData={setReceptionistFormData} FormErrors={FormErrors} setFormErrors={setFormErrors}
                    modalClickAction={modalClickAction} hospitals={hospitals} modalButton1={modalButton1} modalButton2={modalButton2}
                    resetForm={resetForm} dispatchAction={dispatchAction} setAddHospitalName={setAddHospitalName}
                    addHospitalName={addHospitalName}
                />
            </div>
            {/* Delete Modal */}
            <ReceptionistOptionModal showBtnLoader={showBtnLoader} modal={modal} modalAction={modalAction} modalClickAction={modalClickAction} receptionistFormData={receptionistFormData}
                dispatchAction={dispatchAction}
            />
        </>
    );
}
