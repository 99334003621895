/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { pushInArray } from "../../../common";
import Modal from "../../../Components/Modal";
import FormInput from "../../../Components/FormInput";
import Formcheckbox from "../../../Components/Formcheckbox";
import Button from "../../../Components/Button";
import { Table } from "antd";

export default function AddUserRoleModal({ FormRoleData, setFormData, moduleAccess, setModuleAccess, modal, modalAction,
    modalTitle, modalButton2, modalClickAction, FormErrors, moduleAccessList, showBtnLoader, dispatchAction }) {

    const [data, setData] = useState([]);

    const columns = [
        { title: "", width: 500, dataIndex: "name" },
        {
            title: "View", dataIndex: "", render: (_, { key, index }) => {
                return (
                    <Formcheckbox
                        inputType="checkbox"
                        title=" "
                        id={`view-${key}`}
                        name="moduleAccess"
                        isChecked={
                            FormRoleData.moduleAccess && FormRoleData.moduleAccess.view
                                && FormRoleData.moduleAccess.view.find((v) => v === key) ? true : false
                        }
                        value={key}
                        onChange={(e) => { handleChange(e, 'view') }}
                    />
                )
            }
        },
        {
            title: "Create", dataIndex: "", render: (_, { key, index }) => {
                return (
                    <Formcheckbox
                        inputType="checkbox"
                        title=" "
                        id={`create-${key}`}
                        name="moduleAccess"
                        isChecked={
                            FormRoleData.moduleAccess && FormRoleData.moduleAccess.create
                                && FormRoleData.moduleAccess.create.find((v) => v === key) ? true : false
                        }
                        value={key}
                        onChange={(e) => { handleChange(e, 'create') }}
                    />
                )
            }
        },
        {
            title: "Edit", dataIndex: "", render: (_, { key, index }) => {
                return (
                    <Formcheckbox
                        inputType="checkbox"
                        title=" "
                        id={`edit-${key}`}
                        name="moduleAccess"
                        isChecked={
                            FormRoleData.moduleAccess && FormRoleData.moduleAccess.edit
                                && FormRoleData.moduleAccess.edit.find((v) => v === key) ? true : false
                        }
                        value={key}
                        onChange={(e) => { handleChange(e, 'edit') }}
                    />
                )
            }
        },
        {
            title: "Delete", dataIndex: "", render: (_, { key, index }) => {
                return (
                    <Formcheckbox
                        inputType="checkbox"
                        title=" "
                        id={`delete-${key}`}
                        name="moduleAccess"
                        isChecked={
                            FormRoleData.moduleAccess && FormRoleData.moduleAccess.delete
                                && FormRoleData.moduleAccess.delete.find((v) => v === key) ? true : false
                        }
                        value={key}
                        onChange={(e) => { handleChange(e, 'delete') }}
                    />
                )
            }
        },
    ]

    useEffect(() => {
        let arr = [];
        if (moduleAccessList.length > 0) {
            moduleAccessList.map((v, i) => {
                let obj = {
                    index: i,
                    key: v.key,
                    name: v.name,
                    data: v,
                }
                arr.push(obj)
            })
        }
        setData([...arr])
    }, [moduleAccessList])

    const handleFormChange = (e) => {
        let changedField = { ...FormRoleData };
        changedField[e.target.name] = e.target.value;
        setFormData({ ...changedField });
    };

    function handleChange(e, type) {
        let isOptionChecked = e.target.checked;
        let checkedValue = e.target.value;
        let existModuleAccess = { ...moduleAccess };
        if (type === 'view') {
            if (isOptionChecked) {
                pushInArray(existModuleAccess.view, checkedValue, true)
            } else {
                let view = existModuleAccess.view.filter((ele) => ele !== e.target.value);
                existModuleAccess.view = view;
            }
        } else if (type === 'create') {
            if (isOptionChecked) {
                pushInArray(existModuleAccess.create, checkedValue, true)
            } else {
                let create = existModuleAccess.create.filter((ele) => ele !== e.target.value);
                existModuleAccess.create = create;
            }
        } else if (type === 'edit') {
            if (isOptionChecked) {
                pushInArray(existModuleAccess.edit, checkedValue, true)
            } else {
                let edit = existModuleAccess.edit.filter((ele) => ele !== e.target.value);
                existModuleAccess.edit = edit;
            }
        } else if (type === 'delete') {
            if (isOptionChecked) {
                pushInArray(existModuleAccess.delete, checkedValue, true)
            } else {
                let dele = existModuleAccess.delete.filter((ele) => ele !== e.target.value);
                existModuleAccess.delete = dele;
            }
        }
        setModuleAccess({ ...existModuleAccess });
        let changedField = { ...FormRoleData };
        changedField["moduleAccess"] = existModuleAccess;
        setFormData({ ...changedField });
    }

    return (
        (['add', 'update']).includes(modalAction) ?
        <Modal
            type="center"
            ModalTitle={modalTitle}
            onClose={() => modalClickAction('close')}
            Modalclass={((modal) && (['add', 'update']).includes(modalAction)) ? 'show-modal' : ''}
        >
            <FormInput
                inputType="text"
                placeholder="Enter your name"
                title="Name"
                name="mname"
                required="required"
                onChange={(e) => { handleFormChange(e); }}
                value={FormRoleData.mname}
                errorMessage={FormErrors.mname}
            />
            {/* <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="Type"
                name="mtype"
                required="required"
                onChange={(e) => { handleFormChange(e); }}
                value={FormRoleData.mtype}
                isReadonly={true}
                errorMessage={FormErrors.mtype}
            /> */}
            <div className="user-role-moduleaccess">
                <div className="adminTable">
                    <div className="table">
                        <Table columns={columns} dataSource={data} pagination={false} sticky={true} />
                    </div>
                </div>
            </div>
            <div className="activeButton right">
                <Button
                    loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                    title={modalButton2}
                    onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                />
            </div>
        </Modal>
        :
         (['moduleAccess']).includes(modalAction) && 
         <Modal
            type="center"
            ModalTitle={modalTitle}
            onClose={() => modalClickAction('close')}
            Modalclass={((modal) && (['moduleAccess']).includes(modalAction)) ? 'show-modal' : ''}
        >
            <div className="user-role-moduleaccess">
                <div className="adminTable">
                    <div className="table">
                        <Table columns={columns} dataSource={data} pagination={false} sticky={true} />
                    </div>
                </div>
            </div>
            <div className="activeButton right">
                <Button
                    btn="no-bg"
                    loaderClass={['moduleAccess'].includes(modalAction) ? showBtnLoader : ""}
                    title={'close'}
                    onClick={(e) => { e.preventDefault(); modalClickAction('close') }}
                />
                <Button
                    loaderClass={['moduleAccess'].includes(modalAction) ? showBtnLoader : ""}
                    title={modalButton2}
                    onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                />
            </div>
        </Modal>
    );
};