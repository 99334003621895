/* eslint-disable react-hooks/exhaustive-deps */
import './style.scss';
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { DateRangePicker } from 'react-date-range';
import DoctorsList from './DoctorsList';
import { useDispatch, useSelector } from "react-redux";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { ordinalSuffixOf, getMonthFromDate, getDateFromDate, removeMainLoaderClass, getTodayDate, debounce } from '../../common';
import { adminDashboardUserList } from '../../actions/dashboard';
import FormInput from '../../Components/FormInput';
import TableTitle from '../../Components/TableTitle';
import AdminCounter from '../../Components/AdminCounter';
import Modal from '../../Components/Modal';
import Button from '../../Components/Button';
import totaldoc from '../../assets/images/Total-doc.svg';
import totalactive from '../../assets/images/Total-act.svg';
import totalinact from '../../assets/images/Total-inact.svg';
import totalpre from '../../assets/images/Total-totalpre.svg';
import appointmentTotal from '../../assets/images/apt-dashboard.svg';
import followUpdashboard from '../../assets/images/followup-dashboard.svg';
import billtotal from '../../assets/images/bill-dashbboard.svg';
import newcase from '../../assets/images/new-case-dashboard.svg';
import calendericon from '../../assets/images/calendar.svg';
import { Skeleton } from 'antd';
import moment from 'moment';
import { useCallback } from 'react';

export default function Dashboard() {
    const { message } = useSelector((state) => state);
    const LogOptions = [{ value: 'LoggedIn', label: 'Logged In' }, { value: 'NotLoggedIn', label: 'Not Logged In' }]

    const dispatch = useDispatch();
    /***
     * state used for set data for api
     */

    //for skeleton
    const [loading, setLoading] = useState(false);

    const [activeDoctor, setActiveDoctor] = useState({});
    const [count, setCount] = useState('')
    const [search, setSearch] = useState('');
    const [headerActive, setHeaderActive] = useState('All');
    const [showCustomDate, setShowCustomDate] = useState(false)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('');
    const [filterLog, setFilterLog] = useState('LoggedIn');
    const [doctorAnalyticsData, setDoctorAnalytics] = useState({});

    //pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    //last weeek
    var lastFromWeek = moment().subtract(1, 'weeks').startOf('week');
    var lastToWeek = moment().subtract(1, 'weeks').endOf('week');

    //this week
    var currentFromWeek = moment().startOf('week').toDate();
    var currentToWeek = moment().endOf('week').toDate();

    //last month
    var lastFromMonth = moment().subtract(1, 'months').startOf('month').toDate();
    var lastToMonth = moment().subtract(1, 'months').endOf('month').toDate();

    //current month
    var currentFromMonth = moment().startOf('month').toDate();
    var currentToMonth = moment().endOf('month').toDate();

    const headerFinance = [
        { value: "TODAY", label: "Today", fromDate: getTodayDate(new Date(), 0, 0, 0, 0), todate: getTodayDate(new Date(), 23, 59, 59, 999) },
        { value: "YESTERDAY", label: "Yesterday", fromDate: getTodayDate(new Date(new Date().getTime() - 24 * 60 * 60 * 1000), 0, 0, 0, 0), todate: getTodayDate(new Date(new Date().getTime() - 24 * 60 * 60 * 1000), 23, 59, 59, 999) },
        { value: "THIS_WEEK", label: "This Week", fromDate: getTodayDate(currentFromWeek, 0, 0, 0, 0), todate: getTodayDate(currentToWeek, 23, 59, 59, 999) },
        { value: "LAST_WEEK", label: "Last Week", fromDate: getTodayDate(lastFromWeek, 0, 0, 0, 0), todate: getTodayDate(lastToWeek, 23, 59, 59, 999) },
        { value: "THIS_MONTH", label: "This Month", fromDate: getTodayDate(currentFromMonth, 0, 0, 0, 0), todate: getTodayDate(currentToMonth, 23, 59, 59, 999) },
        { value: "LAST_MONTH", label: "Last Month", fromDate: getTodayDate(lastFromMonth, 0, 0, 0, 0), todate: getTodayDate(lastToMonth, 23, 59, 59, 999) },
        { value: "All", label: "All" },
    ]

    const [stateDate, setStateDate] = useState([
        {
            startDate: "",
            endDate: "",
            key: 'selection'
        }
    ]);

    //when header active dropdown changes
    const handleHeaderActiveChange = (e) => {
        let obj = headerFinance.find(v => v.value === e.value);
        setFromDate(obj.fromDate);
        setToDate(obj.todate);
        setStateDate({
            startDate: "",
            endDate: "",
            key: 'selection'
        })
        getApiList(1, pageSize, obj.fromDate, obj.todate, search, filterLog)
    }

    //handle custom date range changes
    const handleCustomChange = (item) => {
        setStateDate([item.selection]);
    }

    //when custom date range modal button click
    const customDateChange = () => {
        let startFormDate = stateDate[0].startDate
        let endToDate = stateDate[0].endDate
        getApiList(1, pageSize, startFormDate, endToDate, search, filterLog)
        setFromDate(startFormDate);
        setToDate(endToDate);
        setHeaderActive("");
        setShowCustomDate(!showCustomDate)
    }

    //when logged in or not value change
    const logFilter = (e) => {
        setFilterLog(e.value);
        getApiList(1, pageSize, fromDate, toDate, search, e.value)
    }

    //common api call function of list
    const getApiList = (page, pageSize, fromDate, toDate, search, filterLog) => {
        if (search === "") setLoading(true);
        setPage(page)
        setPageSize(pageSize)
        let payload = {
            "pagination": { "pageNo": page, "pageSize": pageSize },
            "search": search,
            "filter": {
                "notLogin": filterLog === "NotLoggedIn" ? true : false,
                "fromDate": fromDate,
                "toDate": getTodayDate(new Date(toDate), 23, 59, 59, 999)
            },
        };
        dispatch(adminDashboardUserList(payload)).then((res) => {
            if (search === "") setLoading(false);
            removeMainLoaderClass();
            setActiveDoctor(res.data.data)
            setCount(res.data.totalUserCount)
            setDoctorAnalytics({
                totalUserCount: res.data.totalUserCount,
                activeUserCount: res.data.activeUserCount,
                inactiveUserCount: res.data.inactiveUserCount,
                totalAptCount: res.data.totalAptCount,
                totalNewAptCount: res.data.totalNewAptCount,
                totalFollowUpAptCount: res.data.totalFollowUpAptCount,
                totalPresCount: res.data.totalPresCount,
                totalBillCount: res.data.totalBillCount,
            })
        }).catch(() => {
            if (search === "") setLoading(false);
            removeMainLoaderClass();
        })
    }

    //search handler
    const searchHandler = useCallback(debounce(getApiList, 800), [])

    useEffect(() => {
        getApiList(1, pageSize, fromDate, toDate, search, filterLog);
    }, [])

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="finance-white-board">
                    <div className="counter">
                        <Skeleton active loading={loading}>
                            <AdminCounter number={doctorAnalyticsData?.totalUserCount ? doctorAnalyticsData?.totalUserCount : 0} title="Total Doctors" img={totaldoc} />
                            <AdminCounter number={doctorAnalyticsData?.activeUserCount ? doctorAnalyticsData?.activeUserCount : 0} title="Active" img={totalactive} />
                            <AdminCounter number={doctorAnalyticsData?.inactiveUserCount ? doctorAnalyticsData?.inactiveUserCount : 0} title="Inactive" img={totalinact} />
                            <AdminCounter number={doctorAnalyticsData?.totalAptCount ? doctorAnalyticsData?.totalAptCount : 0} title="Total Appointments" img={appointmentTotal} />
                            <AdminCounter number={doctorAnalyticsData?.totalNewAptCount ? doctorAnalyticsData?.totalNewAptCount : 0} title="New Case" img={newcase} />
                            <AdminCounter number={doctorAnalyticsData?.totalFollowUpAptCount ? doctorAnalyticsData?.totalFollowUpAptCount : 0} title="Follow Up" img={followUpdashboard} />
                            <AdminCounter number={doctorAnalyticsData?.totalBillCount ? doctorAnalyticsData?.totalBillCount : 0} title="Total Bills" img={billtotal} />
                            <AdminCounter number={doctorAnalyticsData?.totalPresCount ? doctorAnalyticsData?.totalPresCount : 0} title="Total Prescriptions" img={totalpre} />
                        </Skeleton>
                    </div>
                </div>
                <div className="adminTable" style={{ width: '100%' }}>
                    <Skeleton active loading={loading} paragraph={{ rows: pageSize }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Doctor's Activities " />
                            <div className="table-header-search">
                                <div className="finance-header-filter">
                                    <div className="finance-filter-header">
                                        <div className="finance-date-rangebox">
                                            <span
                                                className={`rangebox-btn ${stateDate && stateDate[0] && stateDate[0].startDate ? "active" : ""}`}
                                                onClick={() => { setShowCustomDate(!showCustomDate); }} >
                                                <img src={calendericon} alt={calendericon} /> Custom
                                            </span>
                                            <p>
                                                {stateDate && stateDate[0] && stateDate[0].startDate ? <> {getDateFromDate(new Date(stateDate[0].startDate))} <sup>{ordinalSuffixOf(new Date(stateDate[0].startDate).getDate())}</sup> {getMonthFromDate(new Date(stateDate[0].startDate), false, 'short')} </> : ""}
                                                {stateDate && stateDate[0] && stateDate[0].endDate ? <> <b>to</b> {getDateFromDate(new Date(stateDate[0].endDate))} <sup>{ordinalSuffixOf(new Date(stateDate[0].endDate).getDate())}</sup> {getMonthFromDate(new Date(stateDate[0].endDate), false, 'short')} </> : ""}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <Select
                                    classNamePrefix="react-select"
                                    options={headerFinance}
                                    placeholder="Select Row"
                                    onChange={(e) => { setHeaderActive(e.value); handleHeaderActiveChange(e); }}
                                    value={headerFinance.filter((v) => headerActive === v.value)}
                                />
                                <div className="table-header-dropDown">
                                    {
                                        headerActive !== "All" &&
                                        <Select
                                            classNamePrefix="react-select"
                                            options={LogOptions}
                                            name="status"
                                            onChange={(e) => { logFilter(e) }}
                                            placeholder="Select Status"
                                            value={LogOptions.filter((v) => filterLog === v.value)}
                                        />
                                    }
                                </div>
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search..."
                                        onChange={(e) => { setSearch(e.target.value); searchHandler(1, pageSize, fromDate, toDate, e.target.value, filterLog); }}
                                        value={search}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table medicines">
                            <DoctorsList filterLog={filterLog} search={search} message={message} doctors={activeDoctor} fromDate={fromDate} toDate={toDate} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} getApiList={getApiList} count={count} />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <div className='custom-range-modal'>
                {showCustomDate &&
                    <Modal
                        ModalTitle="Custom Range"
                        className="removedoc"
                        onClose={() => setShowCustomDate(!showCustomDate)}
                        type="center"
                        Modalclass={showCustomDate ? `show-modal` : ``}
                    >
                        <>
                            <DateRangePicker
                                onChange={item => handleCustomChange(item)}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={stateDate}
                                direction="horizontal"
                            />
                            <div className="activeButton right">
                                <Button title="Save" btn="btn-sm" onClick={(e) => customDateChange()} />
                            </div>
                        </>
                    </Modal>
                }
            </div>
        </>
    );
}
