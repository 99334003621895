import React from "react";
import './style.scss';

export default function Footer() {
    return (
        <div className="footer">
            <p>All Rights Reserved by Admin.</p>
        </div>
    );
};
