import React from "react";
import Button from "../../../Components/Button";
import FormInput from "../../../Components/FormInput";
import FormUpload from "../../../Components/FormUpload";
import Modal from "../../../Components/Modal";

export default function AddModuleAccessModal({ showBtnLoader, FormModuleData, setFormData, FormErrors, modalAction, modalTitle, modal, modalClickAction, dispatchAction, modalButton2, iconImage, setIconImage }) {
    const handleFormChange = (e) => {
        let changedField = { ...FormModuleData };
        changedField[e.target.name] = e.target.value;
        setFormData({ ...changedField });
    };

    const handleFileChange = (e) => {
        let changedField = { ...FormModuleData };
        changedField[e.target.name] = e.target.files[0];
        setFormData({ ...changedField });
    };

    return (
        (['add', 'update']).includes(modalAction) &&
        <Modal
            ModalTitle={modalTitle}
            onClose={() => modalClickAction('close')}
            Modalclass={((modal) && (['add', 'update']).includes(modalAction)) ? 'show-modal' : ''}
        >
            <FormInput
                inputType="text"
                placeholder="Enter your key"
                title="Key"
                name="pkey"
                isReadonly={modalAction === 'update' ? true : false}
                required="required"
                onChange={(e) => { handleFormChange(e); }}
                value={FormModuleData.pkey}
                errorMessage={FormErrors.pkey}
            />
            <FormInput
                inputType="text"
                placeholder="Enter your name"
                title="Name"
                name="pname"
                required="required"
                onChange={(e) => { handleFormChange(e); }}
                value={FormModuleData.pname}
                errorMessage={FormErrors.pname}
            />
            {/* <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="Type"
                name="ptype"
                required="required"
                onChange={(e) => { handleFormChange(e); }}
                value={FormModuleData.ptype}
                errorMessage={FormErrors.ptype}
            /> */}
            {/* <FormInput
                inputType="file"
                title="Upload Icon"
                name="imageSrc"
                required="required"
                onChange={(e) => { handleFileChange(e); }}
                // value={FormModuleData.imageSrc}
                errorMessage={FormErrors.imageSrc}
            /> */}
            <div className="formField">
                <label><div className="feildRequired">Icon Image<span className="actionField errorBox">*</span></div></label>
                <FormUpload
                    logo={iconImage}
                    setLogo={setIconImage}
                    message="Drag and Drop image here"
                />
                {FormErrors.imageSrc && <div className="actionField errorBox">{FormErrors.imageSrc}</div>}
            </div>
            <div className="activeButton right">
                <Button
                    loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                    title={modalButton2}
                    onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                />
            </div>
        </Modal>
    );
};