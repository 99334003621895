import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentList from "./PaymentList";
import Button from "../../Components/Button";
import { Skeleton } from "antd";
import { fetchPaymentList, updatePayment } from "../../actions/payment";
import TableTitle from "../../Components/TableTitle";
import { closeModal, openModal } from "../../actions/modal";
import FormInput from "../../Components/FormInput";
import { emptyField, removeMainLoaderClass } from "../../common";
import DiseaseModals from "../Diseases/DiseaseModals";
import Modal from "../../Components/Modal";
import FormUpload from "../../Components/FormUpload";

export default function Payments(props) {
    const { modal, payments } = useSelector((state) => state);

    //for skeleton
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    let currentFormErrors = [];
    const [modalAction, setModalAction] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalButton2, setModalButton2] = useState('');
    const [FormPaymentData, setFormData] = useState({ "pname": '', "pkey": '', "imageSrc": "", });
    const [FormErrors, setFormErrors] = useState(currentFormErrors);

    const [name, setName] = useState('');

    const [iconImage, setIconImage] = useState('');
    const [showBtnLoader, setShowBtnLoader] = useState('');

    const resetModal = () => {
        setModalAction('');
        setModalTitle('');
        setModalButton2('');
    };

    const resetForm = () => {
        setFormData({
            "pname": '',
            "pkey": '',
            "imageSrc": "",
        });
        setName('');
        setFormErrors('');
        setIconImage('');
    };

    const modalClickAction = (type, action = '', data = {}) => {
        resetModal();
        resetForm();
        let changedField;
        switch (action) {
            case 'add':
                setModalTitle('Add Payment');
                setModalButton2('Add');
                break;
            case 'update':
                setModalTitle('Update Payment');
                setModalButton2('Update');
                changedField = { ...FormPaymentData };
                changedField["pname"] = data.name;
                changedField["pkey"] = data.key;
                changedField["imageSrc"] = data.imageSrc;
                setFormData({ ...changedField });
                setName(data.name);
                setIconImage(data.imageSrc)
                break;
            case 'delete':
                setModalTitle('Delete Payment');
                setModalButton2('Delete');
                changedField = { ...FormPaymentData };
                changedField["pname"] = data.name;
                changedField["pkey"] = data.key;
                setFormData({ ...changedField });
                setName(data.name);
                setIconImage(data.imageSrc)
                break;
            default:
        }
        setModalAction(action);
        if (type === 'open') {
            dispatch(openModal());
        } else {
            dispatch(closeModal());
        }
    };

    const dispatchAction = (action, data) => {
        if (emptyField(FormPaymentData.pname)) {
            currentFormErrors.pname = "Payment Name is required";
        }
        if (emptyField(FormPaymentData.pkey)) {
            currentFormErrors.pkey = "Payment Key is required";
        }
        if (!iconImage) {
            currentFormErrors.imageSrc = "Image is required";
        }
        setFormErrors(currentFormErrors);
        if ((Object.keys(currentFormErrors).length === 0 && (action === 'add' || action === 'update')) || action === 'delete') {
            switch (action) {
                case 'add':
                    let formData = new FormData();
                    formData.append("name", FormPaymentData.pname);
                    formData.append("key", FormPaymentData.pkey);
                    formData.append("paymentImage", iconImage);
                    setShowBtnLoader('show-button-loader');
                    dispatch(updatePayment(formData, FormPaymentData.pkey, payments, 'add')).then(res => {
                        setShowBtnLoader('');
                        modalClickAction('close');
                    }).catch(() => setShowBtnLoader(''));
                    break;
                case 'delete':
                    const deleteFormData = new FormData();
                    deleteFormData.append("name", FormPaymentData.pname);
                    deleteFormData.append("paymentImage", iconImage);
                    deleteFormData.append("key", FormPaymentData.pkey);
                    deleteFormData.append("pull", true);
                    setShowBtnLoader('show-button-loader');
                    dispatch(updatePayment(deleteFormData, FormPaymentData.pkey, payments, 'delete')).then(res => {
                        modalClickAction('close')
                        setShowBtnLoader('');
                    }).catch(() => setShowBtnLoader(''));
                    break;
                case 'update':
                    const updateFormData = new FormData();
                    updateFormData.append("key", FormPaymentData.pkey);
                    updateFormData.append("name", FormPaymentData.pname);
                    updateFormData.append("paymentImage", iconImage);
                    setShowBtnLoader('show-button-loader');
                    dispatch(updatePayment(updateFormData, FormPaymentData.pkey, payments, 'update')).then(res => {
                        modalClickAction('close');
                        setShowBtnLoader('');
                    }).catch(() => setShowBtnLoader(''));
                    break;
                default:
                    throw new Error('Modal action not match');
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        dispatch(fetchPaymentList()).then(() => {
            removeMainLoaderClass();
            setLoading(false);
        }).catch(() => {
            removeMainLoaderClass();
            setLoading(false);
        });
        //eslint-disable-next-line
    }, []);

    const [search, setSearch] = useState('');
    const [paymentList, setPaymentList] = useState([]);

    useEffect(() => {
        if (payments?.value?.length > 0) setPaymentList([...payments?.value]);
        else setPaymentList([]);
    }, [payments.value])

    useEffect(() => {
        if (payments?.value?.length > 0) {
            if (search !== "") {
                let arr = [];
                payments?.value?.map((v) => {
                    if (v.name.toLowerCase().includes(search?.toLowerCase())) arr.push(v);
                });
                setPaymentList([...arr])
            } else {
                setPaymentList([...payments?.value])
            }
        }
    }, [search])


    const handleFormChange = (e) => {
        let changedField = FormPaymentData;
        changedField[e.target.name] = e.target.value;
        setFormData({ ...changedField });
    };

    const handleFileChange = (e) => {
        let changedField = FormPaymentData;
        changedField[e.target.name] = e.target.files[0];
        setFormData({ ...changedField });
    };

    return (
        <>
            <div className="adminDashboardWidget">
                <div className="adminTable increaseheight">
                    <Skeleton active loading={loading} paragraph={{ rows: paymentList.length }}>
                        <div className="adminTableHeader">
                            <TableTitle title="Payments" />
                            <div className="table-header-search">
                                <div className='no-label table-search'>
                                    <FormInput
                                        inputType="text"
                                        placeholder="Search for data"
                                        onChange={(e) => { setSearch(e.target.value); }}
                                        value={search}
                                    />
                                </div>
                                <div className="add-btn">
                                    <Button
                                        title="Add"
                                        onClick={() => modalClickAction('open', 'add')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Table List */}
                        <div className="table payments">
                            <PaymentList
                                paymentList={paymentList}
                                modalClickAction={modalClickAction}
                            />
                        </div>
                    </Skeleton>
                </div>
            </div>
            <DiseaseModals
                modal={modal}
                modalAction={modalAction}
                modalClickAction={modalClickAction}
                showBtnLoader={showBtnLoader}
                dispatchAction={dispatchAction}
                name={name}
                resetForm={resetForm}
                isDelete={true}
                isMerge={false}
                isAdd={false}
            />
            <div className="AddNew">
                {
                    (['add', 'update']).includes(modalAction) &&
                    <Modal
                        ModalTitle={modalTitle}
                        onClose={() => modalClickAction('close')}
                        Modalclass={((modal) && (['add', 'update']).includes(modalAction)) ? 'show-modal' : ''}
                    >
                        <FormInput
                            inputType="text"
                            placeholder="Enter your key"
                            title="Payment Key"
                            name="pkey"
                            isReadonly={modalAction === 'update' ? true : false}
                            required="required"
                            onChange={(e) => { handleFormChange(e); }}
                            value={FormPaymentData.pkey}
                            errorMessage={FormErrors.pkey}
                        />
                        <FormInput
                            inputType="text"
                            placeholder="Enter your text"
                            title="Payment Type"
                            name="pname"
                            required="required"
                            onChange={(e) => { handleFormChange(e); }}
                            value={FormPaymentData.pname}
                            errorMessage={FormErrors.pname}
                        />
                        <div className="formField">
                            {console.log(iconImage,"iconImage")}
                            <label><div className="feildRequired">Upload Icon<span className="actionField errorBox">*</span></div></label>
                            <FormUpload
                                logo={iconImage}
                                setLogo={setIconImage}
                                message="Drag and Drop image here"
                            />
                            {FormErrors.imageSrc && <div className="actionField errorBox">{FormErrors.imageSrc}</div>}
                        </div>
                        <div className="activeButton right">
                            <Button
                                loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                                title={modalButton2}
                                onClick={(e) => { e.preventDefault(); dispatchAction(modalAction); }}
                            />
                        </div>
                    </Modal>
                }
            </div>
        </>
    );
};