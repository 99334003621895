import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import RouteList from './routes';
import { defaultAxios } from './api/general';
import { ADMIN_LOGOUT } from './actions/types';
import { CONSTANT_ROUTES } from './config/constantRoutes';
import { createBrowserHistory } from '@remix-run/router';

const history = createBrowserHistory();

defaultAxios.defaults.baseURL = process.env.REACT_APP_SERVICE_URL;
defaultAxios.defaults.headers.common['Content-Type'] = 'application/json';
defaultAxios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// 401 means user is not authenticated
defaultAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch({
        type: ADMIN_LOGOUT,
        payload: {}
      });
      history.push(CONSTANT_ROUTES.admin.login)
    }
    return Promise.reject(error);
  }
);

function App() {
  return (
    <>
      <Provider store={store}>
        <RouteList />
      </Provider>
    </>
  );
}

export default App;
