import { PANEL_LIST } from "../actions/types";

const initialState = {};

export default function panel(state = initialState, action) {

    switch (action.type) {
        case PANEL_LIST:
            return action.payload;
        default:
            return state;
    }
};