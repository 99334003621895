//admin login and logout
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

//open modal and close modal
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

//hospital list
export const HOSPITAL_LIST = "HOSPITAL_LIST";
export const HOSPITAL_SETTING = "HOSPITAL_SETTING";

//doctor list
export const DOCTOR_LIST = "DOCTOR_LIST";

//receptionist list
export const RECEPTIONIST_LIST = "RECEPTIONIST_LIST";

//Lab list
export const LAB_LIST = "LAB_LIST";

//Disease List
export const DISEASES_LIST = "DISEASES_LIST";

//Symptoms List
export const SYMPTOMS_LIST = "SYMPTOMS_LIST";

//Habits list
export const HABITS_LIST = "HABITS_LIST";

//pasthistory list
export const PASTHISTORY_LIST = "PASTHISTORY_LIST";

//Medicine list
export const MEDICINES_LIST = "MEDICINES_LIST";

//laboratory tests list
export const LABORATORY_TEST_LIST = "LABORATORY_TEST_LIST";

//admin lab panel list
export const PANEL_LIST = "PANEL_LIST";

//email notification list
export const EMAIL_TEMPLATE_LIST = "EMAIL_TEMPLATE_LIST";

//sms notification list
export const SMS_TEMPLATE_LIST = "SMS_TEMPLATE_LIST";

//laboratory test templates
export const TEMPLATES_LIST = "TEMPLATES_LIST";

//vitals list
export const VITALS_LIST = "VITALS_LIST";

//payment list
export const PAYMENT_LIST = "PAYMENT_LIST";

//user roles list
export const USER_ROLES_LIST = "USER_ROLES_LIST";

//module access list
export const MODULE_ACCESS_LIST = "MODULE_ACCESS_LIST";

//canvas icon list
export const CANVAS_ICON_LIST = "CANVAS_ICON_LIST";

//specialty list
export const SPECIALTY_LIST = "SPECIALTY_LIST";

//degree list
export const DEGREE_LIST = "DEGREE_LIST";

//HospitalSetting Suffix n Prefix
export const UHID_UPDATE_PREFIX_N_SUFFIX = "UHID_UPDATE_PREFIX_N_SUFFIX";
export const BILL_UPDATE_PREFIX_N_SUFFIX = "BILL_UPDATE_PREFIX_N_SUFFIX";
export const APPOINTMENT_ID_PREFIX_N_SUFFIX = "APPOINTMENT_ID_PREFIX_N_SUFFIX";

export const FILE_CATEGORY_LIST = "FILE_CATEGORY_LIST";
export const WP_TEMPLATE_LIST = "WP_TEMPLATE_LIST";

//error logs
export const ERROR_LOG_LIST = "ERROR_LOG_LIST";

// Hospital filterData
export const HOSPITAL_FILTER_DATA = "HOSPITAL_FILTER_DATA";

export const MENSTRUAL_TYPE_LIST = "MENSTRUAL_TYPE_LIST";