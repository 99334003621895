/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState } from "react";
import { PatternFormat } from 'react-number-format';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Button from "../../../Components/Button";
import FormInput from "../../../Components/FormInput";
import GoogleAddress from "../../../Components/GoogleAddress";
import Modal from "../../../Components/Modal";
import { countryCodes } from "../../../common";

export default function DoctorAddModal({ showBtnLoader, addHospitalName, setAddHospitalName, modal, modalTitle, modalAction, doctorFormData, setDoctorFormData, FormErrors, setFormErrors, modalClickAction, hospitals, modalButton1, modalButton2, resetForm, dispatchAction, specialtyList, degreeList, otherList, setOtherList,isHospitalMember }) {

    const [hospitalNames, setHopspitalNames] = useState([]);

    const handleFormChange = (e) => {
        let changedField = { ...doctorFormData };
        changedField[e.target.name] = e.target.value;
        setDoctorFormData({ ...changedField });
        let changeError = { ...FormErrors };
        delete changeError[e.target.name];
        setFormErrors({ ...changeError });
    };

    useEffect(() => {
        let arr = [];
        if (hospitals?.data?.length > 0) {
            hospitals.data.map((v) => {
                arr.push({ value: v._id, label: v.name })
            })
        }
        setHopspitalNames([...arr])
    }, [hospitals.data])

    // Multi select on change event function
    // const handleSelectChange = (e) => {
    //     let arr = [...hospitalNames]
    //     let changedField = { ...doctorFormData };
    //     let changeError = { ...FormErrors };
    //     if (e?.__isNew__) {
    //         changedField['hospitalIds'] = "";
    //         changedField['hospitalName'] = e.value;
    //         arr.push(e);
    //         delete changeError['hospitalName'];
    //     } else {
    //         if (e?.value) {
    //             changedField['hospitalIds'] = e.value;
    //             changedField['hospitalName'] = "";
    //             delete changeError['hospitalName'];
    //         } else {
    //             changedField['hospitalIds'] = "";
    //             changedField['hospitalName'] = "";
    //         }
    //     }
    //     setFormErrors({ ...changeError });
    //     setHopspitalNames([...arr]);
    //     setDoctorFormData({ ...changedField });
    // }

    // Multi select on change event function
    const handleMultipleSelect = (e, name) => {
        let changedField = { ...doctorFormData };
        let selectedValue = [];
        e.forEach((v) => {
            selectedValue.push(v.value);
        })
        if (name === "docDegree") {
            changedField["degreeIds"] = selectedValue
            if (!changedField["degreeIds"].includes('Other')) {
                changedField["degree"] = []
            }
        };
        if (name === "docSpecialty") changedField["specialty"] = selectedValue;
        setDoctorFormData({ ...changedField });

        let changeError = { ...FormErrors };
        if (name === "docDegree") delete changeError["degreeIds"];
        if (name === "docSpecialty") delete changeError["specialty"];
        setFormErrors({ ...changeError });
    }

    //multi Select past degree and add new degree
    const otherHandleMultiSelect = (e) => {
        let changedField = { ...doctorFormData };
        let selectedValue = [];
        let arr = [...otherList]
        e.forEach((v) => {
            if (v.value.trim() !== "") {
                if (v?.__isNew__) {
                    arr?.push({ value: v.value, label: v.value })
                }
                if (!selectedValue.includes(v.value)) {
                    selectedValue.push(v.value);
                }
            }
        })
        changedField["degree"] = selectedValue;
        let changeError = { ...FormErrors };
        delete changeError["other"];
        setFormErrors({ ...changeError });
        setOtherList([...arr])
        setDoctorFormData({ ...changedField });
    }

    const handleCodeChange = (e, name) =>{
        let changedField = { ...doctorFormData };
        changedField[name] = e?.value ? e.value : "";
        setDoctorFormData({ ...changedField });
    }

    const addressRef = useRef(null);

    return (
        ['add', 'update'].includes(modalAction) &&
        <Modal
            ModalTitle={modalTitle}
            onClose={() => modalClickAction('close')}
            Modalclass={(modal && ['add', 'update'].includes(modalAction)) ? 'show-modal' : isHospitalMember ? 'show-modal' : ''}
        >
            <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="First Name"
                name="firstName"
                required={!isHospitalMember && "required" || ''}
                onChange={(e) => { handleFormChange(e); }}
                value={doctorFormData.firstName}
                errorMessage={FormErrors.firstName}
            />
            <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="Last Name"
                name="lastName"
                required={!isHospitalMember && "required" || ''}
                onChange={(e) => { handleFormChange(e); }}
                value={doctorFormData.lastName}
                errorMessage={FormErrors.lastName}
            />
            <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="Doctor Email"
                // required={!isHospitalMember && "required" || ''}
                name="emailId"
                isReadonly={doctorFormData.isPasswordSet ? true : false}
                onChange={(e) => { handleFormChange(e); }}
                value={doctorFormData.emailId}
                // errorMessage={FormErrors.emailId}
            />
            <FormInput
                inputType="text"
                placeholder="Enter your text"
                title="MCI Reg No."
                required="required"
                name="mciRegNumber"
                onChange={(e) => { handleFormChange(e); }}
                value={doctorFormData.mciRegNumber}
                errorMessage={FormErrors.mciRegNumber}
            />
            <div className="formField">
                <label><div className="feildRequired">Degree{!isHospitalMember && <span className="actionField errorBox">*</span>}</div></label>
                <Select
                    isMulti={true}
                    required={!isHospitalMember && "required" || ''}
                    classNamePrefix="react-select"
                    options={degreeList}
                    placeholder="Select Degree"
                    onChange={(e) => { handleMultipleSelect(e, "docDegree"); }}
                    value={degreeList?.filter((option) => {
                        return (
                            doctorFormData?.degreeIds ? doctorFormData?.degreeIds?.includes(option.value) :
                                !doctorFormData?.degreeIds && doctorFormData.degree && doctorFormData.degree?.length > 0 ?
                                    option.value === "Other" :
                                    ""
                        );
                    })}
                />
                {FormErrors?.degreeIds && <div className="actionField errorBox">{FormErrors?.degreeIds}</div>}
            </div>
            {
                (doctorFormData?.degreeIds?.length > 0 && (doctorFormData?.degreeIds?.includes('Other'))) &&
                <div className="formField">
                    <label><div className="feildRequired">Other<span className="actionField errorBox">*</span></div></label>
                    <CreatableSelect
                        isClearable={true}
                        isSearchable={true}
                        isMulti
                        inputType="text"
                        placeholder="Enter Degree Name"
                        title="Other"
                        name="degree"
                        options={otherList}
                        required="required"
                        onChange={(e) => { otherHandleMultiSelect(e) }}
                        value={otherList?.filter((option) => {
                            return (doctorFormData.degree.includes(option.value));
                        })}
                    />
                    {FormErrors?.other && <div className="actionField errorBox">{FormErrors?.other}</div>}
                </div>
            }
            <div className="formField">
                <label><div className="feildRequired">Speciality<span className="actionField errorBox">*</span></div></label>
                <Select
                    isMulti={true}
                    required="required"
                    classNamePrefix="react-select"
                    options={specialtyList}
                    placeholder="Select Specialty"
                    onChange={(e) => { handleMultipleSelect(e, "docSpecialty"); }}
                    value={specialtyList.filter((option) => {
                        return (doctorFormData.specialty.includes(option.value));
                    })}
                />
                {FormErrors.specialty && <div className="actionField errorBox">{FormErrors.specialty}</div>}
            </div>
            <div className="formField contactformField">
                <div className="ex-label">
                    <label><div className="feildRequired">Contact<span className="errorBox">*</span></div></label>
                </div>
                {/* <FormInput
                    className={"countrycode"}
                    name="countryCode"
                    value={doctorFormData.countryCode}
                    isReadonly={true}
                    title=""
                /> */}
                 <Select
                    isClearable={false}
                    isSearchable={true}
                    className="AutoSuggest"
                    classNamePrefix="react-select"
                    options={countryCodes}
                    value={countryCodes.filter((a) => a.value === doctorFormData.countryCode)}
                    onChange={(e)=> handleCodeChange(e, "countryCode")}
                />
                <div className="usernumber formField">
                    <PatternFormat
                        format="##########"
                        mask="_"
                        name="contact"
                        id="contact"
                        placeholder="Enter contact number"
                        value={doctorFormData.contact}
                        onChange={(e) => handleFormChange(e)}
                    />
                </div>
                {FormErrors.contact && <div className="actionField errorBox">{FormErrors.contact}</div>}
            </div>
            <div className="formField">
                <label><div className="feildRequired">Hospital/Clinic Name<span className="errorBox">*</span></div></label>
                <Select
                    isClearable={false}
                    isSearchable={true}
                    className="AutoSuggest"
                    classNamePrefix="react-select"
                    options={hospitalNames}
                    value={hospitalNames.filter((option) => {
                        return option.value === (doctorFormData.hospitalIds === "" ? doctorFormData.hospitalName : doctorFormData.hospitalIds);
                    })}
                    onChange={(e)=> handleCodeChange(e, "hospitalIds")}
                />
                {/* <Creatable
                    isClearable={true}
                    isSearchable={true}
                    classNamePrefix="react-select"
                    options={hospitalNames}
                    tabSelectsValue={false}
                    name="hospitalIds"
                    id="hospitalIds"
                    value={hospitalNames.filter((option) => {
                        return option.value === (doctorFormData.hospitalIds === "" ? doctorFormData.hospitalName : doctorFormData.hospitalIds);
                    })}
                    onChange={(e) => {
                        handleSelectChange(e);
                    }}
                    placeholder="Search/Add Hospital"
                    openMenuOnFocus={true}
                    closeMenuOnSelect={true}
                /> */}
                {FormErrors.hospitalName && <div className="actionField errorBox">{FormErrors.hospitalName}</div>}
            </div>
            <div className="formField contactformField">
                <div className="ex-label">
                    <label><div className="feildRequired">Address<span className="errorBox"></span></div></label>
                </div>
                <FormInput
                    className={"countrycode"}
                    name="plotNo"
                    value={doctorFormData.plotNo}
                    title=""
                    placeholder="Plot"
                    onChange={(e) => handleFormChange(e)}
                />
                <div className="usernumber formField">
                    <GoogleAddress
                        id="onBoardDoctorAddress"
                        reference={addressRef}
                        showMap={false}
                        formData={doctorFormData}
                        setFormData={setDoctorFormData}
                    />
                </div>
            </div>
            <div className="activeButton right">
                <Button
                    title={modalButton1}
                    buttonType="reset"
                    onClick={(modalAction === 'approve') ? () => modalClickAction('close') : () => resetForm()}
                />
                <Button
                    loaderClass={["add", "update"].includes(modalAction) ? showBtnLoader : ""}
                    title={modalButton2}
                    type="button"
                    onClick={(e) => { dispatchAction(modalAction, e); }}
                />
            </div>
        </Modal>
    )
};